import React, { useEffect } from "react";
import Colors from "../../assets/Colors";

interface MyProps {
  label: string;
  value: string;
  colorValue?: string;
  children?: JSX.Element;
  autoWidth?: boolean;
  height?: "" | "h-20";
  loading?: boolean;
}

const TagValue: React.FC<MyProps> = ({
  label,
  value,
  colorValue,
  children,
  autoWidth,
  height,
  loading,
}) => {
  return (
    <div
      className={
        "tag-card rounded border flex flex-col items-left px-2 sm:px-5 py-4 justify-between mb-3" +
        (autoWidth ? "w-4/5 lg:w-3/5 max-w-2xl" : "") +
        (height ? ` h-16 sm:${height}` : "")
      }
      style={{
        backgroundColor: Colors.GrayBackGroud,
        borderColor: Colors.Gray4,
      }}
    >
      <div className="flex flex-row w-full justify-between text-xs sm:text-base">
        <p style={{ color: Colors.Gray3 }}>{label}</p>
        {loading ? (
          <div className="rounded animate-pulse w-20 bg-gray-200 h-3 mt-1 sm:w-40 sm:h-5"></div>
        ) : (
          <p style={{ color: colorValue ?? Colors.Gray2 }}>{value}</p>
        )}
      </div>
      {children}
    </div>
  );
};

TagValue.defaultProps = {
  autoWidth: true,
};

export default TagValue;
