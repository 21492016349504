import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Colors from "../../../../assets/Colors";
import BarButton from "../../../../components/BarButton/BarButton";
import AssetCard from "../../../../components/Cards/AssetCard/AssetCard";
import EmptyCard from "../../../../components/Cards/EmptyCard";
import FilterBar from "../../../../components/FilterBar/FilterBar";
import Footer from "../../../../components/Footer/Footer";
import history from "../../../../services/history";
import ClientDetailsFilterBar from "../ClientDetailsFilterBar/ClientDetailsFilterBar";
import * as API from "./../../../../api";
import * as Icons from "./../../../../assets/icons";

const ClientWalletPage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [tokens, setTokens] = useState<Array<any>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [clientDetails, setClientDetails] = useState<any>({});

  useEffect(() => {
    loadClientDetails();
    loadTokens();
  }, []);

  const loadTokens = () => {
    API.admin
      .getClientTokens(clientId)
      .then((data) => setTokens(data))
      .finally(() => setLoading(false));
  };

  const loadClientDetails = async () => {
    const data = await API.admin.loadClient(clientId);
    setClientDetails(data);
  };

  const byDateTime = (a, b) => {
    let redemptionDateA = moment(a.metadata.redemptionDate).toDate();
    let redemptionDateB = moment(b.metadata.redemptionDate).toDate();

    return redemptionDateA.getTime() - redemptionDateB.getTime();
  };

  return (
    <div
      className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
      style={{ color: Colors.Gray4 }}
    >
      <main
        className="flex-grow bg-gray-200 w-full"
        style={{ backgroundColor: Colors.GrayBackGroud }}
      >
        <ClientDetailsFilterBar
          clientName={clientDetails?.name}
          pageTitle="Carteira de tokens"
        />
        <div className="mx-5 flex flex-col">
          {tokens?.length > 0 ? (
            <h3 style={{ color: Colors.Gray2 }} className="text-lg my-4">
              Lista de tokens
            </h3>
          ) : (
            <div className="mt-5">
              <EmptyCard>
                <div className="flex flex-col items-center">
                  <strong style={{ color: Colors.Gray3 }}>
                    {isLoading
                      ? "Carregando tokens..."
                      : "Nenhum token encontrado"}
                  </strong>
                </div>
              </EmptyCard>
            </div>
          )}
          {tokens?.sort(byDateTime).map((token, index) => (
            <AssetCard token={token} key={index} />
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ClientWalletPage;
