import React, { CSSProperties, useEffect, useState } from "react";
import Colors from "../../assets/Colors";
import { CopyIcon } from "../../assets/icons";
import copyToClipboard from "../../utils/copyToClipboard";

type MyProps = {
  valueToCopy: string;
  className?: string;
  style?: CSSProperties;
};

const CopyButton: React.FC<MyProps> = ({ valueToCopy, className, style }) => {
  const [showCopiedTooltip, setShowCopiedTooltip] = useState<boolean>(false);

  useEffect(() => {
    const showTimeout: number = 1500;

    if (showCopiedTooltip) {
      const timeout = setTimeout(
        () => setShowCopiedTooltip(false),
        showTimeout
      );
      return () => clearTimeout(timeout);
    }
  }, [showCopiedTooltip]);

  const copyText = () => {
    copyToClipboard(valueToCopy);
    setShowCopiedTooltip(true);
  };

  return (
    <div className={className} style={style}>
      <CopyIcon
        height={25}
        width={25}
        className="absolute right-3 cursor-pointer"
        onClick={copyText}
      />
      <div
        className={`absolute right-11 top-4 ${
          showCopiedTooltip ? "" : "hidden"
        }`}
      >
        <div className="relative">
          <span
            className="relative z-10 p-1 rounded text-white"
            style={{ backgroundColor: Colors.Green4 }}
          >
            copiado
          </span>
          <div
            className="absolute z-0 transform right-0 top-3 w-5 h-5 -mt-2 rotate-45"
            style={{ backgroundColor: Colors.Green4 }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CopyButton;
