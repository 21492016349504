import React, { CSSProperties } from "react";

interface MyProps {
  children: any;
  className?: string;
  onClick?: () => any;
  borderColor?: string;
  backgroundColor?: string;
  style?: CSSProperties;
  textStyle?: CSSProperties;
}

const OutlineButton: React.FC<MyProps> = ({
  children,
  className,
  onClick,
  borderColor,
  backgroundColor,
  style,
  textStyle,
}) => {
  return (
    <button
      className={`rounded px-4 py-1 w-30 items-center text-lg font-medium	 ${
        className ?? ""
      }`}
      style={{
        borderWidth: 1,
        backgroundColor,
        borderColor,
        ...style,
      }}
      onClick={onClick}
    >
      <span style={{ color: borderColor, ...textStyle }}>{children}</span>
    </button>
  );
};
export default OutlineButton;
