import React, { CSSProperties } from "react";
import "./TokenDetailsResumeCard.css";

type MyProps = {
  textColor?: string;
  bgColor: string;
  title: string;
  titleStyle?: CSSProperties;
  value?: string;
  invert?: boolean;
  unity?: string;
  loading?: boolean;
  hiddenContent?: {
    content?: JSX.Element;
    showHiddenContent?: boolean;
  };
};

const TokenDetailsResumeCard = ({
  bgColor,
  textColor,
  title,
  titleStyle,
  value,
  invert,
  unity,
  loading,
  hiddenContent,
}: MyProps) => {
  const style: CSSProperties = {};

  if (invert) {
    style.color = bgColor;
    style.borderColor = bgColor;
    style.backgroundColor = "";
  } else {
    style.backgroundColor = bgColor;
    style.borderColor = bgColor;
    style.color = textColor;
  }

  style.borderWidth = "3px";

  return !loading ? (
    <div
      className="
                token-details-resume-card
                flex flex-col sm:flex-row md:flex-col w-full lg:w-1/3
                rounded my-1 py-3 lg:py-6 px-3 lg:px-6 
                justify-between items-center whitespace-pre
            "
      style={style}
    >
      <p className="card-title text-center font-medium" style={titleStyle}>
        {title}
      </p>

      {hiddenContent?.showHiddenContent ? (
        hiddenContent?.content
      ) : (
        <div className="card-value flex flex-row lg:text-2xl lg:mt-3 justify-center items-center">
          <p className="text-center font-bold">
            {value !== undefined ? value : "-"}
          </p>
          <p className="text-center font-light"> {unity}</p>
        </div>
      )}
    </div>
  ) : (
    <div className="animate-pulse bg-gray-200 w-full lg:w-1/3 rounded my-1 h-24 lg:h-36"></div>
  );
};

TokenDetailsResumeCard.defaultProps = {
  textColor: "white",
  loading: false,
  hiddenContent: { showHiddenContent: false },
};

export default TokenDetailsResumeCard;
