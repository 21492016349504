import { v4 } from "uuid";
import api from "../services/api";
import { Client, ClientStatusEnum, Stament, User, GoldToken } from "../models";
import { PlataformLimitEnum } from "../models/enums/plataformLimitEnum";
import { balance } from "../models/balance";

export const user = {
  async getStaments(): Promise<Array<Stament>> {
    const { status, data } = await api.exchange.get("/user/statement");

    if (status === 200) {
      const response: Array<Stament> = data
        .map((elm) => ({ ...elm, ...elm.metadata }))
        .map((elm) => ({
          ...elm,
          create_at: new Date(elm.create_at),
        }));
      return response;
    } else {
      throw data;
    }
  },
  async getBalance(): Promise<balance> {
    try {
      const { status, data } = await api.exchange.get("/user/balance");

      if (status === 200) {
        const gold = data.filter((project) => project.name === "GOLD");
        let goldAvailable = 0;
        let goldConsumed = 0;
        let goldTokenQuantity = 0;

        gold.forEach((project) => {
          goldTokenQuantity = project.tokens.length;
          project.tokens.forEach((token) => {
            goldAvailable += token.available;
            goldConsumed += token.consumed;
          });
        });

        const brl = data.filter((project) => project.name === "BRL");
        let brlAvailable = 0;
        let brlConsumed = 0;
        let brlTokenQuantity = 0;

        brl.forEach((project) => {
          brlTokenQuantity = project.length;
          project.tokens.forEach((token) => {
            brlAvailable += token.available;
            brlConsumed += token.consumed;
          });
        });

        return {
          gold: {
            available: goldAvailable,
            consumed: goldConsumed,
            full: goldAvailable + goldConsumed,
            tokenQuantity: goldTokenQuantity,
          },
          invoice: {
            available: 0,
            consumed: 0,
            full: 0,
            tokenQuantity: 0,
          },
          credit: {
            available: 0,
            consumed: 0,
            full: 0,
            tokenQuantity: 0,
          },
          currency: {
            available: brlAvailable,
            consumed: brlConsumed,
            full: brlAvailable + brlConsumed,
            tokenQuantity: brlTokenQuantity,
          },
        };
      } else {
        return {
          gold: {
            available: 0,
            consumed: 0,
            full: 0,
            tokenQuantity: 0,
          },
          invoice: {
            available: 0,
            consumed: 0,
            full: 0,
            tokenQuantity: 0,
          },
          credit: {
            available: 0,
            consumed: 0,
            full: 0,
            tokenQuantity: 0,
          },
          currency: {
            available: 0,
            consumed: 0,
            full: 0,
            tokenQuantity: 0,
          },
        };
        // throw new Error(data);
      }
    } catch (error) {
      throw error;
    }
  },
  async getUserTokens(): Promise<Array<GoldToken>> {
    try {
      const { status, data } = await api.exchange.get("/user/balance");
      if (status === 200) {
        let tokens = data
          .filter((project) => project.name === "GOLD")[0]
          .tokens.filter((t) => t.available + t.consumed > 0);

        return tokens;
      } else {
        return [];
      }
    } catch (error) {
      throw error;
    }
  },
  async getUserInfo(): Promise<User> {
    const response = await api.exchange.get("/user/info");
    const user = response.data;
    if (response.status === 200 && user) {
      const isValidArray = user.banks;
      const address = user?.addresses && user?.addresses[0];
      return {
        id: user.id ?? "",
        name: user?.name ?? "",
        role: user.role ?? "",
        userType: user.userType ?? "",
        phoneNumber: user?.phoneNumber ?? "",
        email: user.email ?? "",
        document: user?.document ?? "",
        partnerDocument: user.partnerDocument ?? "",
        partnerName: user.partnerName ?? "",
        partnerEmail: user.partnerEmail ?? "",
        status: user.status ?? "",
        pep: user.pep ?? false,
        userBankInfo: {
          bank: !isValidArray ? "" : user?.banks[0]?.bankCode ?? "",
          bankAccount: !isValidArray ? "" : user?.banks[0]?.bankAccount ?? "",
          bankAgency: !isValidArray ? "" : user?.banks[0]?.bankAgency ?? "",
          ispb: !isValidArray ? "" : user?.banks[0]?.ispd ?? "",
          shortName: !isValidArray ? "" : user?.banks[0]?.shortName ?? "",
          fullName: !isValidArray ? "" : user?.banks[0]?.fullName ?? "",
        },
        custodyBankInfo: {
          bank: "",
          bankAccount: "",
          bankAgency: "",
          ispb: "",
          shortName: "",
        },
        address: {
          street: address?.street ?? "",
          number: address?.number ?? "",
          neighborhood: address?.neighborhood ?? "",
          city: address?.city ?? "",
          state: address?.state ?? "",
          complement: address?.complement ?? "",
          postalCode: address?.postalCode ?? "",
          country: address?.country ?? "",
        },
        wallet: {
          publicKey: user?.wallet?.publicKey ?? "",
          secretKey: user?.wallet?.secretKey ?? "",
        },
        emailVerified: user.certifications.includes("EMAIL"),
        phoneVerified: user.certifications.includes("PHONE_NUMBER"),
        dataVerified: user.certifications.includes("DATA_VERIFIED"),
        federalId: user.certifications.includes("FEDERALID"),
        proofOfResidence: user.certifications.includes("PROOF_OF_RESIDENCE"),
        otpEmail: user.otpEmail ?? "",
        otpPhoneNumber: user.otpPhoneNumber ?? "",
        data: user.data ?? "",
        tenant: {
          id: user?.tenant?.id ?? "",
        },
        birthday: user?.birthday,
        motherName: user?.montherName,
        documentations: user?.documentations,
      };
    }

    throw new Error("Não foi possivel obter os dados do usuário.");
  },
  async getUserOnboardingInfo(id: string): Promise<Client> {
    const { status, data } = await api.exchange.get(
      "/onboarding/account/" + id
    );

    if (status === 200) {
      return data;
    }

    throw data;
  },
  async updateInfo(): Promise<null> {
    try {
      // return null;
      const { status, data } = await api.exchange.post("/user/info");

      if (status === 200) {
        return null;
      } else {
        throw new Error(data);
      }
    } catch (error) {
      throw error;
    }
  },
  async updateAddressInfo(body: any, userId: string): Promise<null> {
    try {
      // return null;
      const { status, data } = await api.exchange.patch(
        `/auth/signup/${userId}`,
        body
      );

      if (status === 200) {
        return null;
      } else {
        throw new Error(data);
      }
    } catch (error) {
      throw error;
    }
  },
  async getLimits(
    userId?: string
  ): Promise<Map<PlataformLimitEnum, user.PlataformLimit>> {
    const { status, data } = await api.exchange.get(
      "/book/daily-limit" + (userId ? `?user=${userId}` : "")
    );

    if (status === 200) {
      const mapLimites = data.reduce(
        (map, limit) =>
          map.set(limit.type, {
            type: limit.type,
            limit: limit.limit,
            consumed: limit.consumed,
          }),
        new Map<PlataformLimitEnum, user.PlataformLimit>()
      );
      return mapLimites;
    } else {
      throw data;
    }
  },
  async updateBankInfo(body: any, userId: string): Promise<null> {
    try {
      const { status, data } = await api.exchange.patch(
        `/auth/signup/${userId}`,
        {
          bank: {
            bankCode: Number(body.bank),
            bankAccount: body.bankAccount,
            bankAgency: body.bankAgency,
          },
        }
      );

      if (status === 200) {
        return null;
      } else {
        throw new Error(data);
      }
    } catch (error) {
      throw error;
    }
  },
  async getOptInState(): Promise<boolean> {
    // const response = await api.exchange.get('/user/limits');
    return !true;
  },
  async updateUserStatus(
    userId: string,
    newStatus: ClientStatusEnum
  ): Promise<null> {
    const { status, data } = await api.exchange.put(`user/status/${userId}`, {
      status: newStatus,
    });

    if (status === 200 || status === 204) {
      return null;
    } else {
      throw data;
    }
  },
};

// eslint-disable-next-line no-redeclare
export namespace user {
  export interface PlataformLimit {
    type: PlataformLimitEnum;
    limit: number;
    consumed: number;
  }
}
