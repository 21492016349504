import React, { useState } from "react";
import { Link } from "react-router-dom";

/// Assets
import Colors from "../../../assets/Colors";
import { OkIcon } from "../../../assets/icons";
import RoutesPaths from "../../../Routes/RoutesPaths";

/// Componentes
import Footer from "../../../components/Footer/Footer";
import TopBar from "../../../components/TopBar/TopBar";
import Divider from "../../../components/Divider/Divider";
import ModalCore from "../../../components/Modal/ModalCore";
import Button from "../../../components/Buttons/Button";
import FilterBar from "../../../components/FilterBar/FilterBar";
import DataBlock from "../../../components/DataBlock/DataBlock";

const MyAssetsCreateConfirm: React.FC = () => {
  const [modalState, setModalState] = useState<boolean>(false);

  function finishButton() {
    setModalState(true);
  }
  function sendAssetToNegociation() {
    console.log(
      "Isso aqui é executado antes de redirecionar. Salve aqui as informações a serem negociadas."
    );
  }

  return (
    <>
      <ModalCore
        state={modalState}
        changeState={(state) => setModalState(state)}
      >
        <div className="flex flex-col items-center">
          <OkIcon height="90" width="90" />
          <h3 className="text-center mt-4">Título cadastrado com sucesso!</h3>
          <p
            className="text-sm text-center mt-5 mb-7"
            style={{ color: Colors.Gray3 }}
          >
            Deseja negociaciar agora a antecipação do ativo desse título?
          </p>

          <div className="w-full">
            <hr className=" bg-gray-300" style={{ borderColor: "#BDBDBD" }} />
          </div>

          <div className="flex flex-row w-full my-3 px-10 justify-between">
            <Link
              to={RoutesPaths.MEUSATIVOS}
              className="font-semibold"
              style={{ color: Colors.Gray4 }}
            >
              Ainda não
            </Link>
            <Link
              to={RoutesPaths.MEUSATIVOSNEGOCIAR}
              className="font-semibold"
              style={{ color: Colors.Gray1 }}
              onClick={() => sendAssetToNegociation()}
            >
              Negociar agora
            </Link>
          </div>
        </div>
      </ModalCore>

      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <TopBar />
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar title="Novo ativo cadastrado" />

          <div className="p-5">
            <p>O token de seu ativo foi gerado com sucesso!</p>
          </div>
          <div
            className="flex flex-col rounded border bg-white px-2 py-1 mx-5 aling-center items-center"
            style={{ borderColor: Colors.Gray4 }}
          >
            <h1
              className="mt-4 text-lg md:text-xl text-center"
              style={{ color: Colors.Gray2 }}
            >
              Dados do título cadastrado para negociação via duplicata
            </h1>

            <Divider full={true} />

            <DataBlock
              title="DADOS DO TÍTULO"
              data={[
                { label: "Nº do título", value: "" },
                { label: "Nº de controle do participante", value: "" },
                { label: "Data de Vencimento", value: "" },
                { label: "R$ Valor", value: "" },
                { label: "R$ Valor líquido", value: "" },
              ]}
            />
            <br />

            <DataBlock
              title="DADOS DO SACADO"
              data={[
                { label: "Nº do título", value: "" },
                { label: "Nº de controle do participante", value: "" },
                { label: "Data de Vencimento", value: "" },
                { label: "R$ Valor", value: "" },
                { label: "R$ Valor líquido", value: "" },
              ]}
            />
            <br />

            <DataBlock
              title="ENDEREÇO SACADO"
              data={[
                { label: "Nº do título", value: "" },
                { label: "Nº de controle do participante", value: "" },
                { label: "Data de Vencimento", value: "" },
                { label: "R$ Valor", value: "" },
                { label: "R$ Valor líquido", value: "" },
              ]}
            />
            <br />

            <Divider full={true} top="mt-5 md:mt-10" bottom="mb-7 md:mb-10" />

            <Button onClick={() => finishButton()}>Confirmar cadastro</Button>
            <div className="my-5 items-center">
              <p className="underline">Cancelar Cadastro</p>
            </div>
          </div>
          <br />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MyAssetsCreateConfirm;
