import CryptoJS from "crypto-js";

const useTemporaryAuth = () => {
  const RANDOM_HASH_KEY: string = "@RandomHash1";
  const USER_HASH_KEY: string = "@RandomHash2";
  const SEPARATOR: string = "@SEPARATOR@";

  const createHash = () => {
    const hash = CryptoJS.lib.WordArray.random(32);
    window?.sessionStorage.setItem(RANDOM_HASH_KEY, hash?.toString());

    return hash.toString();
  };

  const readHash = () => window?.sessionStorage?.getItem(RANDOM_HASH_KEY);

  const readUserData = () => window?.sessionStorage.getItem(USER_HASH_KEY);

  const clearUserData = () => window?.sessionStorage.clear();

  const encryptUserData = (data: string) => {
    const hashKey = createHash();
    const cipherText = CryptoJS.AES.encrypt(data, hashKey);

    window?.sessionStorage.setItem(USER_HASH_KEY, cipherText.toString());
  };

  const decryptUserData = () => {
    const hashKey = readHash();
    const encrypted = readUserData();

    if (!encrypted || !hashKey) return "";

    const decrypted = CryptoJS.AES.decrypt(encrypted, hashKey);

    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  const setAuthData = (login: string, password: string) => {
    const userData = `${login}${SEPARATOR}${password}`;
    encryptUserData(userData);
  };

  const getAuthData = () => {
    const userData = decryptUserData();
    const output = userData.split(SEPARATOR);

    clearUserData();
    return {
      login: output?.[0],
      password: output?.[1],
    };
  };

  return { getAuthData, setAuthData };
};

export { useTemporaryAuth };
