import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import Colors from "../../assets/Colors";
import * as Icons from "../../assets/icons";
import BarButton from "../../components/BarButton/BarButton";
import OutlineButton from "../../components/Buttons/OutlineButton";
import FilterBar from "../../components/FilterBar/FilterBar";
import Footer from "../../components/Footer/Footer";
import BuyToken from "../../components/Modal/BuyToken/BuyToken";
import TokenDetailsBar from "../../components/PageTabBar/PageTabBar";
import TopBar from "../../components/TopBar/TopBar";
import OrderContext from "../../contexts/OrderProvider";
import { TokenTypeEnum } from "../../models/enums";
import * as API from "./../../api";
import TokenDetailsAttachments from "./TokenDetailsAttachments/TokenDetailsAttachments";
import TokenDetailsInformations from "./TokenDetailsInformations/TokenDetailsInformations";
import "./TokenDetailsPage.css";
import TokenDetailsSummary from "./TokenDetailsSummary/TokenDetailsSummary";

const TokenDetailsPage: React.FC<any> = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [attachmentAmount, setAttachmentAmount] = useState<number>(0);
  const { order, setOrder } = useContext(OrderContext);
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    if (!order) {
      loadOrder();
    } else {
      setAttachmentAmount(
        Object.keys(order?.tokens[0].metadata.properties.attachments).length
      );
    }
  }, []);

  async function loadOrder() {
    setLoading(true);
    const data = await API.book.getOrdersBalcao(TokenTypeEnum.GOLD);

    let filteredOrder = data.filter((order) => order.id == id)[0];
    setOrder(filteredOrder);

    let attachments = filteredOrder?.tokens[0].metadata.properties.attachments;
    setAttachmentAmount(Object.keys(attachments).length);

    setLoading(false);
  }

  const mockToken = {
    available: 10,
    type: "GOLD",
    metadata: {
      symbol: "OZGOLD",
      name: "Ouro onça troy",
      certificationCode: "1218JASAS56746546GJNN",
      redemptionDate: "2022-04-01",
    },
    attachments: [
      {
        title: "Título do documento",
        name: "attachment1.pdf",
        url: "attachmentUrl",
        uploadDate: new Date(),
      },
      {
        title: "Título do documento",
        name: "attachment2.pdf",
        url: "attachmentUrl",
        uploadDate: new Date(),
      },
      {
        title: "Título do documento",
        name: "attachment3.pdf",
        url: "attachmentUrl",
        uploadDate: new Date(),
      },
      {
        title: "Título do documento",
        name: "attachment4.pdf",
        url: "attachmentUrl",
        uploadDate: new Date(),
      },
      {
        title: "Título do documento",
        name: "attachment5.pdf",
        url: "attachmentUrl",
        uploadDate: new Date(),
      },
      {
        title: "Título do documento",
        name: "attachment6.pdf",
        url: "attachmentUrl",
        uploadDate: new Date(),
      },
    ],
  };

  const renderCurrentPage = (): JSX.Element => {
    if (order) {
      switch (currentPage) {
        case 1:
          return <TokenDetailsSummary order={order} />;
        case 2:
          return <TokenDetailsInformations order={order} />;
        case 3:
          return <></>;
        case 4:
          return <TokenDetailsAttachments order={order} />;
        case 5:
          return <></>;
        default:
          return <></>;
      }
    } else return <></>;
  };

  const toggleShowModal = (): void => {
    setShowModal(!showModal);
  };

  return (
    <>
      <BuyToken order={order} state={showModal} changeState={toggleShowModal} />
      <div
        className="flex flex-col min-h-screen text-sm text-gray-50"
        style={{ color: Colors.Gray4 }}
      >
        <TopBar />
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar goBackBtn title="Detalhes da oferta" />

          <div>
            {isLoading ? (
              <div className="animate-pulse w-full bg-gray-200 h-52"></div>
            ) : (
              <img className="w-full" src={Icons.GoldBanner2Icon} alt="" />
            )}
            <div className="page-content flex flex-col justify-center">
              <div className="flex flex-col items-center">
                {isLoading ? (
                  <>
                    <div className="banner-icon-container rounded-full animate-pulse bg-gray-400"></div>
                    <div className="py-9">
                      <div className="banner-content-title flex flex-col items-center font-normal">
                        <div className="bg-gray-200 rounded h-12 w-72 animate-pulse"></div>
                        <div className="bg-gray-200 rounded h-8 w-64 mt-5 animate-pulse"></div>
                      </div>
                    </div>
                    <div className="bg-gray-400 rounded h-12 w-52 my-3 animate-pulse"></div>
                  </>
                ) : (
                  <>
                    <div className={"icon-container rounded-full"}>
                      <img src={order?.tokens[0]?.metadata.image} alt="" />
                    </div>
                    <div className="py-6">
                      <p
                        className="token-title justify-center font-normal"
                        style={{ color: Colors.Gray1 }}
                      >
                        <span className="font-bold">
                          {" "}
                          {order?.tokens[0]?.metadata.symbol} -{" "}
                        </span>
                        <span className="">
                          {order?.tokens[0]?.metadata.name}
                        </span>
                      </p>
                      <div
                        className="createdAt flex justify-center mt-5"
                        style={{ color: Colors.Gray11 }}
                      >
                        Emitido em{" "}
                        {moment(order?.tokens[0]?.metadata.emissionDate).format(
                          "DD/MM/YYYY"
                        )}
                        <img className="ml-2" src={Icons.TagCertifiedIcon} />
                      </div>
                    </div>
                    <OutlineButton
                      backgroundColor={Colors.GrayBackGroud}
                      borderColor={Colors.Blue1}
                      className="my-3"
                      onClick={toggleShowModal}
                    >
                      Oferta disponível
                    </OutlineButton>
                  </>
                )}
              </div>

              <div className="flex flex-row w-full justify-center">
                <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
                  {isLoading ? (
                    <>
                      <div className="flex gap-3 mt-10 justify-around w-full">
                        <div className="bg-gray-300 rounded h-8 w-2/3 mt-5 animate-pulse"></div>
                        <div className="bg-gray-300 rounded h-8 w-2/3 mt-5 animate-pulse"></div>
                        <div className="bg-gray-300 rounded h-8 w-2/3 mt-5 animate-pulse"></div>
                      </div>
                      <div className="bg-gray-300 rounded h-80 w-full my-5 animate-pulse"></div>
                    </>
                  ) : (
                    <TokenDetailsBar
                      noGap
                      className="details-bar mt-10 justify-start md:justify-around"
                    >
                      <BarButton
                        showBottomBorder
                        borderColor={Colors.Gold4}
                        textColor={Colors.Gray1}
                        grow
                        state={currentPage === 1}
                        children="Resumo"
                        onClick={() => setCurrentPage(1)}
                      />
                      <BarButton
                        showBottomBorder
                        borderColor={Colors.Gold4}
                        textColor={Colors.Gray1}
                        grow
                        state={currentPage === 2}
                        children="Informações"
                        onClick={() => setCurrentPage(2)}
                      />
                      {/* <BarButton showBottomBorder borderColor={Colors.Gold4} textColor={Colors.Gray1} grow state={currentPage === 3} children="Cronograma" onClick={() => setCurrentPage(3)} /> */}
                      <BarButton
                        showBottomBorder
                        borderColor={Colors.Gold4}
                        textColor={Colors.Gray1}
                        grow
                        state={currentPage === 4}
                        children={`Anexos (${attachmentAmount})`}
                        onClick={() => setCurrentPage(4)}
                      />
                      {/* <BarButton showBottomBorder borderColor={Colors.Gold4} textColor={Colors.Gray1} grow state={currentPage === 5} children="Atividade" onClick={() => setCurrentPage(5)} /> */}
                    </TokenDetailsBar>
                  )}
                </div>
              </div>

              <div style={{ minHeight: "905px" }}>{renderCurrentPage()}</div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default TokenDetailsPage;
