import React from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import "./PhoneCodeInput.css";

type MyProps = {
  className?: string;
  length: number;
  change: any;
};

const PhoneCodeInput = React.forwardRef(
  ({ className, length, change }: MyProps, ref) => {
    return (
      <div
        className={
          "phone-verification-div rounded outline relative  text-gray-700" +
          className
        }
      >
        <ReactInputVerificationCode length={length} onChange={change} />
      </div>
    );
  }
);

PhoneCodeInput.defaultProps = {
  className: "",
};

export default PhoneCodeInput;
