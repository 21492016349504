import React from "react";
import { BorderColor, Gray3, GrayBackGroud } from "../../assets/Colors";

type MyProps = {
  title: string;
  data: Array<{
    label: string;
    value?: string;
  }>;
};

const DataBlock = ({ title, data }: MyProps): JSX.Element => {
  return (
    <>
      <h2 className="text-lg font-extralight mb-3" style={{ color: Gray3 }}>
        {title}
      </h2>

      <div
        className="rounded border flex flex-col items-left w-3/5 px-5 py-3"
        style={{ backgroundColor: GrayBackGroud, borderColor: BorderColor }}
      >
        {data.map(({ label, value }, index) => (
          <div className="flex flex-row my-2" key={index}>
            <p style={{ color: Gray3 }}>{label}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default DataBlock;
