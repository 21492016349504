import api from "../services/api";

export const viaCep = {
  async get(cep: string | number): Promise<any> {
    try {
      const { status, data } = await api.viaCep.get(`/${cep}/json`);

      if (status === 200) {
        return { status, data };
      } else {
        throw new Error(data);
      }
    } catch (error) {
      throw error;
    }
  },
};
