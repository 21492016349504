import { IValidation } from "./IValidation";

export class NotEmptyValidation implements IValidation {
  private value: string = "";
  msgError = "Este campo é obrigatório";

  isValid(value: string): boolean {
    this.value = value.trim();

    return this.value.length > 0;
  }

  isNotValid(value: string): boolean {
    return !this.isValid(value);
  }

  getErrorMessage(): string {
    if (this.isValid(this.value) || this.value === "") {
      return "";
    }
    return this.msgError;
  }
}
