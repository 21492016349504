import * as Icons from "../../assets/icons";

export enum TokenTypeEnum {
  BOND = "BOND",
  CREDIT = "CREDIT",
  BRL = "BRL",
  GOLD = "GOLD",
}

export class TokenTypeEnumInterpreter {
  static convert(type?: TokenTypeEnum) {
    switch (type) {
      case TokenTypeEnum.CREDIT:
        return {
          icon: "",
          sigla: "RCARD",
          name: "Recebível de cartão",
        };
      case TokenTypeEnum.BOND:
        return {
          icon: "",
          sigla: "RDUPL",
          name: "Recebivel de duplicata",
        };
      case "G" as any:
      case TokenTypeEnum.GOLD:
        return {
          icon: Icons.MoedaGoldIcon,
          sigla: "OZGOLD",
          name: "Ouro onça troy",

          unity: "oz",
          unityName: "onça troy",
        };
      default:
        return {
          icon: "",
          sigla: "",
          name: "",
        };
    }
  }

  static getIcon(type: TokenTypeEnum) {
    return this.convert(type).icon;
  }
  static translate(type: TokenTypeEnum) {
    return `${this.convert(type).sigla} - ${this.convert(type).name}`;
  }
  static getSigla(type: TokenTypeEnum) {
    return this.convert(type).sigla;
  }
}
