import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import * as API from "../../../../api";
import Colors from "../../../../assets/Colors";
import * as Icons from "../../../../assets/icons";
import EmptyCard from "../../../../components/Cards/EmptyCard";
import OrderCard from "../../../../components/Cards/GoldOrderCard";
import Divider from "../../../../components/Divider/Divider";
import FilterInput from "../../../../components/Inputs/FilterInput/FilterInput";
import FilterModal from "../../../../components/Modal/FilterModal/FilterModal";
import OrderContext from "../../../../contexts/OrderProvider";
import UserContext from "../../../../contexts/UserProvider";
import { Order } from "../../../../models";
import {
  OrderStatusEnum,
  OrderTypeEnum,
  TokenTypeEnum,
  TokenTypeEnumInterpreter,
} from "../../../../models/enums";
import history from "../../../../services/history";
import PageSkeleton from "../../../../components/PageSkeleton/PageSkeleton";
import ClientDetailsFilterBar from "../ClientDetailsFilterBar/ClientDetailsFilterBar";

const ClientOrdersPage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [clientDetails, setClientDetails] = useState<any>({});
  const userContext = useContext(UserContext);
  const orderContext = useContext(OrderContext);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [hasMore, sethasMore] = useState<boolean>(true);
  const [orders, setorders] = useState<Array<Order>>([]);
  const [currentActiveTab, setCurrentActiveTab] = useState<
    OrderTypeEnum | undefined
  >(undefined);
  const [filterStateIndex, setfilterStateIndex] = useState<
    OrderStatusEnum | "ALL"
  >("ALL");
  const [filterTypeIndex, setfilterTypeIndex] = useState<TokenTypeEnum | "ALL">(
    "ALL"
  );
  const [filtersCount, setFiltersCount] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    loadClientDetails();
    loadOrders();
  }, []);

  const loadClientDetails = async () => {
    const data = await API.admin.loadClient(clientId);
    setClientDetails(data);
  };

  const loadOrders = async (
    status?: OrderStatusEnum,
    typeReceivable?: TokenTypeEnum,
    type?: OrderTypeEnum
  ) => {
    try {
      setLoading(true);
      const orders = await API.book.getAllOrders({
        status,
        type,
        typeReceivable,
        userId: clientId,
      });

      setorders(orders);
      sethasMore(orders.length !== orders?.length);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderFilterSection = () => (
    <>
      <FilterModal
        filterBtnText="Filtrar ordens"
        state={modalIsOpen}
        title="Filtrar Ordens"
        subTitle="Selecione as caracteristicas e clique no botão"
        onFilterClick={handleFilterSearch}
        onClearFiltersClick={clearFilters}
        onCancelClick={() => setModalIsOpen(false)}
      >
        <FilterInput
          className="w-full"
          inputBoxClassName="filter-modal-input"
          label="Token"
          placeholder=""
          value={filterTypeIndex}
          type="select"
          onChange={(selectedOption) =>
            setfilterTypeIndex(selectedOption.target.value)
          }
          options={[
            { label: "Todos os tokens", value: "ALL" },
            {
              label: TokenTypeEnumInterpreter.getSigla(TokenTypeEnum.GOLD),
              value: TokenTypeEnum.GOLD,
            },
          ]}
          icon={Icons.ArrowIcon}
        />
        <FilterInput
          className="w-full"
          inputBoxClassName="filter-modal-input"
          label="Status"
          placeholder=""
          value={filterStateIndex}
          type="select"
          onChange={(selectedOption) =>
            setfilterStateIndex(selectedOption.target.value)
          }
          options={[
            { label: "Todos os status", value: "ALL" },
            { label: "Ativo", value: OrderStatusEnum.ACTIVE },
            { label: "Inativo", value: OrderStatusEnum.INACTIVE },
            { label: "Cancelado", value: OrderStatusEnum.CANCELED },
            { label: "Realizado", value: OrderStatusEnum.FULFILLED },
            { label: "Suspenso", value: OrderStatusEnum.SUSPENDED },
            { label: "Expirado", value: OrderStatusEnum.EXPIRED },
          ]}
          icon={Icons.ArrowIcon}
        />
      </FilterModal>

      <section className="flex flex-row gap-6 py-7 px-5 w-full">
        <FilterInput
          inputBoxClassName="filter-section-input"
          className="w-3/5"
          placeholder="Pesquisar ordens por ID ou Usuário"
          icon={Icons.SearchIcon}
          onIconClick={handleFilterSearch}
        />
        <FilterInput
          inputBoxClassName="filter-section-input"
          textColorActive={!!filtersCount}
          placeholder={filtersCount ? `Filtrar (${filtersCount})` : "Filtrar"}
          icon={Icons.FilterIcon}
          onClick={() => setModalIsOpen(true)}
        />
      </section>
      <Divider top="mt-0" bottom="mb-2" />
    </>
  );

  const handleFilterSearch = () => {
    setModalIsOpen(false);
  };

  const clearFilters = () => {
    setfilterStateIndex("ALL");
    setfilterTypeIndex("ALL");
  };

  return (
    <PageSkeleton
      isAdmin
      goBackBtn
      filterBar={
        <ClientDetailsFilterBar
          clientName={clientDetails?.name}
          pageTitle="Ordens"
        />
      }
      button2={{
        label: "Voltar ao resumo da conta",
        onClick: history.goBack,
      }}
    >
      {renderFilterSection()}

      <div className="px-5 flex flex-col w-full">
        <div className="ml-2 my-4">
          <h3 style={{ color: Colors.Gray2 }} className="text-lg">
            {currentActiveTab === undefined
              ? "Últimas ordens"
              : currentActiveTab === OrderTypeEnum.buy
              ? "Ordens de compra"
              : "Ordens  de venda"}
          </h3>
          {/* <p>{`Total: ${formatToCurrency(orders.reduce((atual, { amount }) => amount + atual, 0))} BRL`}</p> */}
        </div>

        {orders.length > 0 ? (
          <InfiniteScroll
            dataLength={orders.length}
            next={() => {
              let state =
                filterStateIndex !== "ALL" ? filterStateIndex : undefined;
              let tokenType =
                filterTypeIndex !== "ALL" ? filterTypeIndex : undefined;
              loadOrders(state, tokenType, currentActiveTab);
            }}
            hasMore={hasMore}
            loader={
              <p style={{ textAlign: "center" }}>
                <h4>Loading...</h4>
              </p>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Todos os registros exibidos</b>
              </p>
            }
          >
            {orders
              .sort((a, b) => b.updateDate.getTime() - a.updateDate.getTime())
              .map((order) => {
                return (
                  <OrderCard
                    order={order}
                    blackTheme={false}
                    detailClick={() => {}}
                    key={order.id}
                  />
                );
              })}
          </InfiniteScroll>
        ) : (
          <EmptyCard>
            <div className="flex flex-col items-center">
              <strong style={{ color: Colors.Gray3 }}>
                {isLoading
                  ? "Carregando ordens..."
                  : "Nenhuma ordem encontrada"}
              </strong>
            </div>
          </EmptyCard>
        )}
      </div>
    </PageSkeleton>
  );
};

export default ClientOrdersPage;
