import moment from "moment";

export const formatToCurrency = (
  value: number | undefined,
  currency: boolean = false,
  complex: boolean = false
): string => {
  var negative: boolean = false;

  value = Number(value);

  if (!value) value = 0;
  if (value < 0) {
    negative = true;
    value = Math.abs(value);
  }

  if (complex) {
    let aux = value.toString();

    let itl = Number(aux.substring(0, aux.length - 2));
    let flt = Number(aux.substring(aux.length - 2));

    value = itl + flt / 100;
  }

  // var result: string = new Intl.NumberFormat([], {
  //     style: "currency",
  //     currency: "BRL",
  // }).format(value);
  var result: string = value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  // result = result.replaceAll(',', ';');
  // result = result.replaceAll('.', ',');
  // result = result.replaceAll(';', '.');

  return currency
    ? `${negative ? "-" : ""}${result}`
    : result.substring(2, result.length);
};

export const formatCurrencyAsNumber = (value: string): number => {
  return Number(value.replace(/BRL|\.|\s/g, "").replaceAll(",", "."));
};

export const formatDecimalPlaces = (
  value: number | string,
  decimalPlaces: number = 2,
  decimalSeparator: string = ","
): string => {
  if (value == 0) return "0";
  return value
    ? Number(value).toFixed(decimalPlaces).replace(".", decimalSeparator)
    : "";
};

export const getResidualDate = (
  initDate: Date,
  finishDate: Date = new Date(),
  group: string = "days"
) => {
  return moment(initDate).diff(finishDate, group as any);
};

export const formatCPF = (cpf: string): string => {
  if (!cpf) return "###.###.###-##";

  var aux = cpf;
  aux = aux.substr(0, 3) + "." + aux.substr(3, aux.length);
  aux = aux.substr(0, 7) + "." + aux.substr(7, aux.length);
  aux =
    aux.substr(0, aux.length - 2) +
    "-" +
    aux.substr(aux.length - 2, aux.length);
  return aux;
};
export const formatCNPJ = (cnpj: string): string => {
  var aux = cnpj;
  aux = aux.substr(0, 2) + "." + aux.substr(2, aux.length);
  aux = aux.substr(0, 6) + "." + aux.substr(6, aux.length);
  aux = aux.substr(0, 10) + "/" + aux.substr(10, aux.length);
  aux =
    aux.substr(0, aux.length - 2) +
    "-" +
    aux.substr(aux.length - 2, aux.length);
  return aux;
};
export const formatCPFOrCPNJ = (document: string): string => {
  const CPFLength = 11;
  return document.length > CPFLength
    ? formatCNPJ(document)
    : formatCPF(document);
};
export const formatRG = (rg: string): string => {
  return rg.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
};
export const formatAccount = (account: string): string => {
  return account.replace(/(\d$)/, "-$1");
};
export const formatBankAgency = (agency: string): string => {
  for (let i = agency.length; i < 4; i++) {
    agency = "0" + agency;
  }

  return agency;
};
