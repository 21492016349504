import React, {
  ChangeEvent,
  createRef,
  CSSProperties,
  useEffect,
  useState,
} from "react";
import { formatToCurrency } from "../../utils/formatters";
import FloatInputCore, {
  FloatInputCoreProps,
} from "./FloatInputCore/FloatInputCore";

interface MyProps extends FloatInputCoreProps {
  onChange?: (p: ChangeEvent<HTMLInputElement>) => any;
  onChangeValue?: (p: number) => any;
  defaultValue?: number;
  className?: string;
  prefix?: string;
  suffix?: string;
  style?: CSSProperties;
  value?: number;
}

const FloatCurrencyInput = React.forwardRef<HTMLInputElement, MyProps>(
  (props, ref) => {
    const [buffer, _setBuffer] = useState<number>(props.defaultValue ?? 0);

    useEffect(() => {
      if (!Number.isNaN(props.value) && props.value !== undefined)
        _setBuffer(props.value);
    }, [props.value]);
    useEffect(() => {
      if (props.onChangeValue) props.onChangeValue(buffer);
    }, [buffer]);

    const formatBuffer = (money: number): string =>
      `${props.prefix ?? ""}${formatToCurrency(money)}${props.suffix ?? ""}`;

    return (
      <FloatInputCore
        ref={ref ?? createRef()}
        big={props.big}
        color={props.color}
        labelColor={props.labelColor}
        disabled={props.disabled}
        className={props.className}
        placeholder={props.placeholder}
        error={props.error}
        children={(ref1) => (
          <input
            ref={ref1}
            className="z-1 block p-2 w-full appearance-none focus:outline-none bg-transparent h-full"
            value={formatBuffer(buffer)}
            pattern="[0-9]*"
            onKeyDown={({ key }) => {
              let cleanBuffer = formatBuffer(buffer)
                .trim()
                .replace(/\D/g, "")
                .split("");

              if (!Number.isNaN(Number(key))) {
                cleanBuffer.push(key);
                if (cleanBuffer[0] === "0") {
                  cleanBuffer.splice(0, 1);
                }
              } else if (key === "Backspace") {
                cleanBuffer.splice(cleanBuffer.length - 1, 1);
              }

              cleanBuffer.splice(cleanBuffer.length - 2, 0, ".");
              _setBuffer(Number(cleanBuffer.join("")));
            }}
            onChange={props.onChange}
            style={{
              fontSize: "1.25rem",
              lineHeight: "1.75rem",
              ...props.style,
            }}
          />
        )}
      />
    );
  }
);

FloatCurrencyInput.defaultProps = {
  suffix: " BRL",
};

export default FloatCurrencyInput;
