import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Colors from "../../../assets/Colors";
import * as Icons from "../../../assets/icons";
import BarButton from "../../../components/BarButton/BarButton";
import ClientCard from "../../../components/Cards/ClientCard";
import Divider from "../../../components/Divider/Divider";
import FilterBar from "../../../components/FilterBar/FilterBar";
import FilterInput from "../../../components/Inputs/FilterInput/FilterInput";
import FilterModal from "../../../components/Modal/FilterModal/FilterModal";
import ClientsContext from "../../../contexts/ClientsProvider";
import {
  Client,
  ClientDocumentation,
  ClientStatusEnum,
  DocumentTypeEnum,
  TypeUserEnum,
} from "../../../models/clients";
import RoutesPaths from "../../../Routes/RoutesPaths";
import history from "../../../services/history";
import "./ClientsPage.css";
import * as API from "./../../../api";
import Footer from "../../../components/Footer/Footer";

const ClientsPage: React.FC = () => {
  const { setSelectedClient } = useContext(ClientsContext);

  const [error, setError] = useState<any>();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  // const [ orderIndex, setOrderIndex ] = useState<ClientOrderEnum>(ClientOrderEnum.MOST_RECENT);
  const [currentActiveTab, setCurrentActiveTab] = useState<string>("Clientes");
  const [clientTypeFilter, setClientTypeFilter] = useState<string>("ALL");
  const [statusTypeFilter, setStatusTypeFilter] = useState<string>("RECENT");
  const [filtersCount, setFiltersCount] = useState<number>(0);

  useEffect(() => {
    let count = 0;
    if (clientTypeFilter !== "ALL") count++;
    if (statusTypeFilter !== "RECENT") count++;

    setFiltersCount(count);
  }, [clientTypeFilter, statusTypeFilter]);

  const [filterStateIndex, setFilterStateIndex] = useState<
    ClientStatusEnum | "ALL"
  >("ALL");

  const [accounts, setClients] = useState<Array<Client>>([]);
  const loadClients = async () => {
    try {
      const accounts = await API.admin.loadAccounts();

      setClients(accounts);
      setHasMore(false);
      return null;
    } catch (error) {
      setHasMore(false);
      setError(error);
    }
  };
  useEffect(() => {
    setClients([]);
    setHasMore(true);
    loadClients();
  }, []);

  const renderFilterSection = () => {
    if (currentActiveTab === "Clientes") {
      return (
        <>
          <FilterModal
            state={modalIsOpen}
            filterBtnText="Filtrar clientes"
            title="Filtrar clientes"
            subTitle="Selecione as caracteristicas e clique no botão"
            onCancelClick={() => setModalIsOpen(false)}
            onFilterClick={handleFilterSearch}
            onClearFiltersClick={clearFilters}
          >
            <FilterInput
              className="w-full"
              inputBoxClassName="filter-modal-input"
              label="Tipo de cliente"
              placeholder=""
              value={clientTypeFilter}
              type="select"
              onChange={(selectedOption) =>
                setClientTypeFilter(selectedOption.target.value)
              }
              options={[
                { label: "Todos", value: "ALL" },
                { label: "Física", value: "INDIVIDUAL" },
                { label: "Jurídica", value: "CORPORATE" },
              ]}
              icon={Icons.ArrowIcon}
            />
            <FilterInput
              className="w-full"
              inputBoxClassName="filter-modal-input"
              label="Status"
              placeholder=""
              value={statusTypeFilter}
              type="select"
              onChange={(selectedOption) =>
                setStatusTypeFilter(selectedOption.target.value)
              }
              options={[{ label: "Mais recentes", value: "RECENT" }]}
              icon={Icons.ArrowIcon}
            />
          </FilterModal>

          <section className="flex flex-row gap-6 py-7 px-5">
            <FilterInput
              inputBoxClassName="filter-section-input"
              className="w-3/5"
              placeholder="Pesquisar clientes por nome, documento ou id"
              icon={Icons.SearchIcon}
            />
            <FilterInput
              inputBoxClassName="filter-section-input"
              textColorActive={!!filtersCount}
              placeholder={
                filtersCount ? `Filtrar (${filtersCount})` : "Filtrar"
              }
              icon={Icons.FilterIcon}
              onClick={() => setModalIsOpen(true)}
            />{" "}
          </section>
          <Divider top="mt-0" bottom="mb-2" />
        </>
      );
    }
  };

  const handleFilterSearch = () => {
    setModalIsOpen(false);
  };

  const clearFilters = () => {
    setClientTypeFilter("ALL");
    setStatusTypeFilter("RECENT");
  };

  return (
    <>
      <FilterBar className="pt-5" selection={true}>
        <div className="flex flex-row gap-14" style={{ paddingBottom: 0 }}>
          {[
            "Clientes",
            // 'Parceiros',
            // 'Admins'
          ].map((tabName, index) => (
            <BarButton
              btnClass="px-4"
              children={tabName}
              state={tabName === currentActiveTab}
              borderColor={Colors.Purple4}
              onClick={() => {
                setCurrentActiveTab(tabName);
              }}
              key={index}
            />
          ))}
        </div>
      </FilterBar>

      {/* {renderFilterSection()} */}

      <div className="mx-5 flex flex-col">
        <div className="flex flex-col mt-3 mb-5">
          <p className="text-lg" style={{ color: Colors.Gray2 }}>
            {currentActiveTab === "Admins"
              ? "Administradores"
              : currentActiveTab}
          </p>
          <p className="font-light" style={{ color: Colors.Gray3 }}>
            Total de: {accounts.length + " "}
            {accounts.length === 1
              ? currentActiveTab === "Admins"
                ? "Administrador"
                : currentActiveTab === "Clientes"
                ? "Cliente"
                : "Parceiro"
              : currentActiveTab === "Admins"
              ? "Administradores"
              : currentActiveTab}
          </p>
        </div>

        <InfiniteScroll
          dataLength={accounts.length}
          hasMore={hasMore}
          // next={() => loadClients()}
          next={() => {}}
          loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              {!!error ? error : <b>Todos os registros exibidos</b>}
            </p>
          }
        >
          {accounts
            .sort((a, b) => b.create_at.getTime() - a.create_at.getTime())
            .map((client) => (
              <ClientCard
                client={client}
                onClick={() => {
                  setSelectedClient(client);
                  history.push(
                    RoutesPaths.ADMIN_CLIENTS_DETAILS + "/" + client.id
                  );
                }}
                key={client.id}
              />
            ))}
        </InfiniteScroll>
      </div>
      <Footer />
    </>
  );
};

export default ClientsPage;
