import React from "react";
import ModalProviderRender from "../hooks/ModalProviderRender";
import useModal from "../hooks/useModal";

interface ModalContextData {
  modal: boolean;
  setModal(p: boolean): any;
  handleModal: any;

  ModalComponent?: { p1: any };
  setModalComponent: (p: any) => any;
  modalContent?: any;
}

const ModalContext = React.createContext<ModalContextData>(
  {} as ModalContextData
);

export const ModalProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  let {
    modal,
    setModal,
    handleModal,
    modalContent,
    ModalComponent,
    setModalComponent,
  } = useModal();

  return (
    <ModalContext.Provider
      value={{
        modal,
        setModal,
        handleModal,
        modalContent,
        ModalComponent,
        setModalComponent,
      }}
    >
      <ModalProviderRender />
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
