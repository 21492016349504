import { IValidation } from "./IValidation";

export class PhoneValidation implements IValidation {
  private regex = /\d{11,}/;
  private value: string = "";
  msgError = "Número de telefone inválido.";

  isValid(value: string): boolean {
    if (!value) {
      this.value = "";
      return false;
    }
    this.value = value;
    return this.regex.test(this.value);
  }

  isNotValid(value: string): boolean {
    return !this.isValid(value);
  }

  getErrorMessage(): string {
    if (this.isValid(this.value) || this.value === "") {
      return "";
    }
    return this.msgError;
  }
}
