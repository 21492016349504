import moment from "moment";
import { SignalStatementEnum, Stament } from "../../../models";
import Colors from "../../../assets/Colors";
import React, { CSSProperties } from "react";
import { InvoiceIcon } from "../../../assets/icons";
import { formatToCurrency } from "../../../utils/formatters";
import "./StatementsCard.css";

interface MyProps {
  statement: Stament;
  onClick(statement: Stament): any;
}

const StatementsCard: React.FC<MyProps> = ({ statement, onClick }) => {
  const titleStyle: CSSProperties = {};

  titleStyle.color = Stament.determineTextColor(statement.signal);

  return (
    <div
      className={`border-05 rounded flex flex-row justify-between px-3 py-4 mb-4 bg-white w-full`}
      style={{ borderColor: Colors.Gray4 }}
    >
      <div className="flex flex-col gap-3">
        <div className="flex flex-row font-normal">
          <div
            className={"text-black justify-center rounded py-1 "}
            style={titleStyle}
          >
            <p style={titleStyle} className="statement-title font-medium">
              {`${
                statement.signal === SignalStatementEnum.CREDIT ? "+" : "-"
              } ${formatToCurrency(statement.amount)} ${
                statement?.token?.metadata?.symbol
              }`}
            </p>
          </div>
        </div>
        <div className="flex flex-row text-base">
          <p className="break-words" style={{ color: Colors.Gray2 }}>
            {Stament.determineDescriptionText(statement).description}
          </p>
        </div>
        <div className="flex flex-row text-base whitespace-pre">
          <p style={{ color: Colors.Gray4 }}>
            {moment(statement.date).format("DD/MM/YYYY [às] HH:mm")}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-end flex-none">
        <div className="flex flex-row justify-end">
          <button
            className="rounded border border-gray-300 px-7 py-1 justify-center"
            style={{ backgroundColor: Colors.Gray6 }}
            onClick={() => onClick(statement)}
          >
            <img
              src={InvoiceIcon}
              alt="InvoiceIcon"
              style={{ height: "20px", width: "20px" }}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatementsCard;
