import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useParams } from "react-router-dom";
import * as API from "../../api";
import Colors from "../../assets/Colors";
import { GoldAssetCard } from "../../components/Cards/AssetCard/AssetCard";
import EmptyCard from "../../components/Cards/EmptyCard";
import FilterBar from "../../components/FilterBar/FilterBar";
import Footer from "../../components/Footer/Footer";
import GoldNegociationBar from "../../components/Modal/GoldNegociationBar/GoldNegociationBar";
import TopBar from "../../components/TopBar/TopBar";
import StamentContext from "../../contexts/AssetProvider";
import UserContext from "../../contexts/UserProvider";
import { Token } from "../../models";
import {
  TokenOrderingEnum,
  TokenStatusEnum,
  TokenTypeEnum,
  TokenTypeEnumInterpreter,
  UserRoleEnum,
} from "../../models/enums";
import RoutesPaths from "../../Routes/RoutesPaths";
import history from "../../services/history";
import * as Icons from "./../../assets/icons";
import TokenizeButton from "./TokenizeButton/TokenizeButton";

const MyAssets: React.FC = () => {
  const params: any = useParams();
  const userContext = useContext(UserContext);
  const stamentContext = useContext(StamentContext);

  const [hasMore, setHasMore] = useState<boolean>(true);
  const [_tokens, setTokens] = useState<Array<Token>>([]);
  const [token, setToken] = useState<Token>();
  const [_tokenStatusFilter, _setTokenStatusFilter] =
    useState<TokenStatusEnum>();
  const [negociationBarStatus, setNegociationBarStatus] =
    useState<boolean>(false);
  const [_tokenOrdering, setTokenOrdering] = useState<TokenOrderingEnum>(
    TokenOrderingEnum.EXPIRATION_DATE
  );

  const tokenType = TokenTypeEnum[params.type];

  useEffect(() => {
    if (userContext.user) {
      loadTokens(tokenType, _tokenStatusFilter, _tokenOrdering);
    }
  }, [_tokenStatusFilter, _tokenOrdering, userContext]);

  async function loadTokens(
    type: TokenTypeEnum | undefined = undefined,
    status: TokenStatusEnum | undefined = undefined,
    ordering: TokenOrderingEnum | undefined = undefined
  ) {
    setHasMore(true);

    const tokens = await API.token.getTokens({
      typeToken: type,
      status,
      ordering,
      userId: userContext.user?.id,
    });

    setTokens(tokens);
    setHasMore(tokens.length !== tokens?.length);
  }

  return (
    <>
      <GoldNegociationBar
        state={negociationBarStatus}
        token={token}
        changeState={() => {
          setNegociationBarStatus(!negociationBarStatus);
        }}
      />

      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <TopBar />
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar className="justify-between" selection={false}>
            <button
              className="flex flex-row gap-3 items-center"
              onClick={() => history.goBack()}
            >
              <img src={Icons.ArrowPointerIcon} alt="" />
              <p style={{ color: Colors.Gray2 }} className="font-light">
                <strong className="font-medium">Listagem de lotes</strong>
              </p>
            </button>

            <div className="flex flex-col">
              <div className="flex flex-col justify-end">
                <div className="flex flex-row justify-end">
                  <TokenizeButton type={tokenType} />
                </div>
              </div>
            </div>
          </FilterBar>

          <div className="mx-5 flex flex-col">
            <span
              style={{ color: Colors.Gray2 }}
              className="flex items-center gap-2 text-lg my-7"
            >
              <img src={Icons.Token2Icon} className="h-7 w-7" />
              <strong className="font-medium">
                {TokenTypeEnumInterpreter.convert(
                  tokenType
                ).sigla.toUpperCase()}
              </strong>{" "}
              - {TokenTypeEnumInterpreter.convert(tokenType).name.toUpperCase()}
            </span>
            {/* <FilterRow className='ml-2' title='Filtrar tokens: '>
						<FilterElement 
							states={[
								{ label: 'Todos status'  , value: undefined  },
								{ label: 'Em negociação' , value: TokenStatusEnum.RESERVED },
								{ label: 'Disponível'    , value: TokenStatusEnum.ACTIVE   },
								{ label: 'Removidos'     , value: TokenStatusEnum.FINISHED },
							]} 
							currentState={_tokenStatusFilter} 
							updateCurrent={({ value }) => _setTokenStatusFilter(value)}
						/>
						<FilterElement 
								states={[
									{ label: 'Vencimento próximo', value: TokenOrderingEnum.EXPIRATION_DATE },
									{ label: 'Maior valor'       , value: TokenOrderingEnum.HIGHEST_AMOUNT  },
									{ label: 'Menor valor'       , value: TokenOrderingEnum.LOWER_AMOUNT    },
									{ label: 'Mais recente'      , value: TokenOrderingEnum.CREATION_DATE   },
								]} 
								currentState={_tokenOrdering} 
								updateCurrent={({ value }) => setTokenOrdering(value)}
						/>
					</FilterRow>
					
					<Divider mx={0} top={'mt-0'}/> */}

            <InfiniteScroll
              dataLength={_tokens.length}
              next={() => {
                // loadTokens()
              }}
              hasMore={false && hasMore}
              loader={
                <p style={{ textAlign: "center" }}>
                  <h4>Carregando...</h4>
                </p>
              }
            >
              {_tokens.length > 0 || hasMore ? (
                _tokens
                  .sort(
                    (a, b) =>
                      (a as any).redemptionDate.getTime() -
                      (b as any).redemptionDate.getTime()
                  )
                  .map((token) => (
                    <GoldAssetCard
                      token={token as any}
                      onClick={() => {
                        setToken(token);
                        stamentContext.setAsset(token);
                        setNegociationBarStatus(true);
                      }}
                      onClick2={() => {
                        setToken(token);
                        stamentContext.setAsset(token);
                        history.push(RoutesPaths.MEUSATIVOSDETALHES);
                      }}
                      key={token.id}
                    />
                  ))
              ) : (
                <EmptyCard>
                  <div className="flex flex-col items-center">
                    <strong style={{ color: Colors.Gray3 }}>
                      Você não possui nenhum token atualmente
                    </strong>
                    {userContext.user?.role !== UserRoleEnum.USER && (
                      <Link
                        className="underline cursor-pointer text-center"
                        to={RoutesPaths.MEUSATIVOSCRIAR}
                      >
                        Realize agora o proceso de tokenização para adicionar
                        novos tokens
                      </Link>
                    )}
                  </div>
                </EmptyCard>
              )}
            </InfiniteScroll>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MyAssets;
