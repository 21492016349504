import React, { CSSProperties } from "react";
import { Gray2, Gray4, GrayBackGroud, Red4 } from "../../../assets/Colors";

import "./FloatInputCore.css";

export type FloatInputCoreProps = {
  placeholder: string;
  className?: string;
  color?: string;
  labelColor?: string;

  big?: boolean;

  error?: string;
  disabled?: boolean;
};
interface MyProps extends FloatInputCoreProps {
  children(ref: any): JSX.Element;
  buttonEnd?: () => JSX.Element;
}

const FloatInputCore = React.forwardRef<HTMLInputElement, MyProps>(
  (
    {
      placeholder,
      className,
      color,
      labelColor,
      big,
      children,
      error,
      disabled,
      buttonEnd,
    },
    ref
  ) => {
    const internalRef: React.RefObject<HTMLInputElement> =
      ref as React.RefObject<HTMLInputElement>;

    const style: CSSProperties = {};
    const labelStyle: CSSProperties = {};

    style.color = Gray2;
    style.borderColor = !error ? Gray4 : Red4;
    labelStyle.color = labelColor ?? Gray4;
    labelStyle.position = "absolute";
    labelStyle.top = big ? "12px" : "5px";

    if (disabled) {
      style.backgroundColor = labelStyle.backgroundColor = GrayBackGroud;
    }

    return (
      <>
        <div
          className={
            "flex flex-row rounded outline relative border focus-within:border-blue-500 " +
            className +
            " " +
            (big ? "big p-2 px-2 pt-2 mt-3" : "")
          }
          style={{ ...style }}
        >
          <div className="flex-grow">
            {children(ref)}
            <label
              className={
                "absolute top-0 px-1 my-1 z-0 duration-300 origin-0 cursor-text " +
                color
              }
              style={labelStyle}
              onClick={() => {
                if (
                  internalRef &&
                  internalRef.current &&
                  internalRef.current.focus
                ) {
                  internalRef.current.focus();
                }
              }}
            >
              {placeholder}
            </label>
          </div>
          {buttonEnd && buttonEnd()}
        </div>
        {error && <span className="text-red-600 error text-sm">{error}</span>}
      </>
    );
  }
);

FloatInputCore.defaultProps = {
  className: "",
  color: "bg-white",
  ref: React.createRef(),
};

export default FloatInputCore;
