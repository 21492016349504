import i18nTranslated from ".";

export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export function determineErrorMessage(error: any): string {
  if (error && error.response) {
    return i18nTranslated(error.response.data.message);
  } else if (error && error.message) {
    let msgError = "API_ERROR_UNEXPECTED";
    if (IsJsonString(error.message)) {
      const erroObject = JSON.parse(error.message);
      if (erroObject.statusCode !== 500) {
        msgError = erroObject.message;
      }
    }

    return i18nTranslated(msgError);
  }

  return "";
}
