import { TypeUserEnum } from "./clients";
import { UserRoleEnum } from "./enums/UserRoleEnum";

export enum UserStatusEnum {
  CREATED = "CREATED",
  ANALYSIS = "ANALYSIS",
  CERTIFICATE = "CERTIFICATE",
  SUSPENDED = "SUSPENDED",
  BANNED = "BANNED",
  FINISHED = "FINISHED",
}

// type User = {
//     data: any,
//     email: string,
//     password: string,
//     tenant: {
//         id: string,
//     }
//     document: string,
//     emailVerified: boolean | null,
//     phoneVerified: boolean | null,
//     dataVerified: boolean | null,
//     otpEmail: string,
//     otpPhoneNumber: string,
//     id: string,
//     role: number,
//     userType: string,
//     status: string,
//     create_at: string,
//     update_at: string,
// };

// type SignupStep = {
//     field: string,
//     status: boolean
// }
// interface NewAccountOnboarding {
//     user: User,
//     requestSteps: Array<SignupStep>;
// };

// eslint-disable-next-line no-redeclare
export interface User {
  //infos que ja existiam
  id: string;
  name: string;
  role: UserRoleEnum;
  userType: TypeUserEnum;
  phoneNumber: string;
  email: string;
  document: string;
  partnerDocument: string;
  partnerName: string;
  partnerEmail: string;
  status: UserStatusEnum;
  userBankInfo: {
    bank: string;
    bankAccount: string;
    bankAgency: string;
    ispb: string;
    shortName: string;
    fullName: string;
  };
  custodyBankInfo: {
    bank: string;
    bankAccount: string;
    bankAgency: string;
    ispb: string;
    shortName: string;
  };
  address: {
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    complement: string;
    postalCode: string;
    country: string;
  };
  wallet: {
    publicKey: string;
    secretKey: string;
  };
  //infos novas
  emailVerified: boolean | null;
  phoneVerified: boolean | null;
  dataVerified: boolean | null;
  federalId: boolean | null;
  proofOfResidence: boolean | null;
  otpEmail: string;
  otpPhoneNumber: string;
  data: any;
  tenant: {
    id: string;
  };
  //completando personaldata
  birthday: string;
  motherName: string;
  documentations: Array<any>;
  showWallet?: boolean;
  pep: boolean;
}
export interface NewUser {
  //aq sao as que precisa ser definidas durante cadastro
  name: string;
  address: any;
  userBankInfo: any;
  partnerName: any;
  // Aq pra baixo retornar no user/info
  data: any;
  email: string;
  password: string;
  tenant: {
    id: string;
  };
  document: string;
  emailVerified: boolean | null;
  phoneVerified: boolean | null;
  dataVerified: boolean | null;
  federalId: boolean | null;
  proofOfResidence: boolean | null;
  otpEmail: string;
  otpPhoneNumber: string;
  id: string;
  role: number;
  userType: TypeUserEnum;
  status: string;
  create_at: string;
  update_at: string;
  pep: boolean;
}

export namespace User {
  export type balance = {
    credit: {
      available: number;
      consumed: number;
      full: number;
      tokenQuantity: number;
    };
    invoice: {
      available: number;
      consumed: number;
      full: number;
      tokenQuantity: number;
    };
    currency: {
      available: number;
      consumed: number;
      full: number;
      tokenQuantity: number;
    };
  };
}
