import moment from "moment";
import React, { useContext, useState } from "react";
import Colors from "../../../assets/Colors";
import OrderContext from "../../../contexts/OrderProvider";
import { Order } from "../../../models";
import RoutesPaths from "../../../Routes/RoutesPaths";
import history from "../../../services/history";
import {
  formatDecimalPlaces,
  formatToCurrency,
} from "../../../utils/formatters";
import Button from "../../Buttons/Button";
import BuyToken from "../../Modal/BuyToken/BuyToken";
import "./TokenCard.css";

type MyProps = {
  order: Order;
  btnText?: string;
  onClick?: () => any;
  btnClick?: () => any;
  btnRender: () => JSX.Element;

  amount?: number;
  negociation?: number;
  available?: number;
  sold?: number;
  currencyFormatting?: boolean;
};

const TokenCard: React.FC<MyProps> = ({ onClick, order }) => {
  const [negociationBar, setNegociationBar] = useState<boolean>(false);
  const orderContext = useContext(OrderContext);
  const token = order.tokens[0];

  const handleClickDetails = () => {
    orderContext.setOrder(order);
    history.push(RoutesPaths.TOKEN_DETAILS + "/" + order?.id);
  };

  return (
    <>
      <BuyToken
        order={order}
        state={negociationBar}
        changeState={() => {
          setNegociationBar(!negociationBar);
        }}
      />
      <div
        className={`border-05 rounded flex flex-col justify-between p-2 px-4 mb-4 bg-white`}
        style={{ borderColor: Colors.Gray4 }}
      >
        <div
          className="flex flex-col pb-2 sm:flex-row"
          onClick={onClick}
          style={{ cursor: onClick ? "pointer" : "default" }}
        >
          <div className="flex flex-col w-2/4 my-2 justify-center items-center self-center sm:my-0 sm:w-40 sm:mr-4">
            <img src={token.metadata.image} alt="icone do token" />
          </div>
          <div className="flex flex-col flex-grow justify-start">
            <p
              className="justify-center text-xl font-light"
              style={{ color: Colors.Gray2 }}
            >
              <span className="font-semibold"> {token.metadata.symbol} - </span>
              <span className="font-normal">{token.metadata.name}</span>
            </p>
            <p className="mt-2 mb-2 font-light" style={{ color: Colors.Gray2 }}>
              <span className="flex">LOTE #{token.id}</span>
            </p>
            <p className="font-light text-base" style={{ color: Colors.Gray3 }}>
              Preço unitário:
              <span
                className="font-medium text-lg"
                style={{ color: Colors.Gray2 }}
              >
                {formatToCurrency(order.amount)}
              </span>
              <span className="text-lg"> BRL</span>
            </p>
            <p className="font-light text-base" style={{ color: Colors.Gray3 }}>
              Tokens ofertados:
              <span
                className="font-normal text-lg"
                style={{ color: Colors.Gray2 }}
              >
                {` ${formatDecimalPlaces(order.tokenQuantity)} ${
                  token.metadata.properties.unit
                }`}
              </span>
              <span>
                {` (${formatDecimalPlaces(order.remainingTokens)} à venda)`}
              </span>
            </p>
            <p className="font-light text-base" style={{ color: Colors.Gray3 }}>
              Carência:{" "}
              <span className="font-normal" style={{ color: Colors.Gray2 }}>
                {moment(token.metadata.redemptionDate).format("DD/MM/YYYY")}
              </span>
            </p>
          </div>
        </div>
        <div className="flex flex-row flex-grow py-2">
          <Button
            big={false}
            className="card-button details-btn font-normal text-base mr-3"
            textColor={Colors.Gray2}
            backgroundColor={"white"}
            borderColor={Colors.Gray3}
            disabled={order.finished || order.remainingTokens === 0}
            onClick={handleClickDetails}
          >
            Detalhes
          </Button>

          <Button
            big={false}
            className="card-button font-medium text-base"
            textColor={"white"}
            backgroundColor={Colors.Green3}
            borderColor={Colors.Green1}
            disabled={order.finished || order.remainingTokens === 0}
            onClick={() => {
              setNegociationBar(true);
              document.body.style.overflow = "hidden";
            }}
          >
            Comprar
          </Button>
        </div>
      </div>
    </>
  );
};

TokenCard.defaultProps = {};

export default TokenCard;
