import Currency from "currency.js";
import moment from "moment";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import * as API from "../../../api";
import Colors, { BorderColor } from "../../../assets/Colors";
import * as Gifs from "../../../assets/gifs";
import AssetContext from "../../../contexts/AssetProvider";
import ModalContext from "../../../contexts/ModalProvider";
import { GoldToken, Match } from "../../../models";
import { TaxTypeEnum, TokenTypeEnumInterpreter } from "../../../models/enums";
import { formatToCurrency } from "../../../utils/formatters";
import Button from "../../Buttons/Button";
import UnderlineButton from "../../Buttons/UnderlineButton";
import Divider from "../../Divider/Divider";
import MoneyInput from "../../Inputs/MoneyInput";
import AlertModal from "../AlertModal";
import Modal from "../Modal";
import "./GoldNegociationBar.css";
import "../style.css";
import CurrencyInput from "../../Inputs/CurrencyInput/CurrencyInput";

type MyProps = {
  state: boolean;
  backBlur?: boolean;
  token?: any;
  changeState(state: boolean): any;
};

const RowWithColumnsBettew = ({
  label,
  value,
  labelColor,
  valueColor,
  className,
}: any) => {
  return (
    <div className="flex flex-row justify-between my-0.5">
      <p className={className} style={{ color: labelColor }}>
        {label}
      </p>
      <p className={className} style={{ color: valueColor }}>
        {value}
      </p>
    </div>
  );
};

const hasOneTab: any = (options: Array<any>) => {
  return options.length === 1;
};

const SelectTable = ({
  options,
  current,
  onClick,
}: {
  onClick?: (p: any) => any;
  options: Array<{ label: string; value: any; onClick?: (p: any) => any }>;
  current: any;
}) => {
  return (
    <div className="flex flex-row text-lg font-medium w-full">
      {options.map(({ label, value }, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              if (onClick) onClick(value);
            }}
            className={`
                            cursor-pointer
                            flex flex-col flex-1 justify-center py-5 
                            ${
                              current === value && !hasOneTab
                                ? "border-b-2"
                                : "border-b"
                            }
                        `}
            style={{
              borderColor: hasOneTab
                ? Colors.Gray2
                : current === value
                ? Colors.Green2
                : Colors.Gray3,
            }}
          >
            <p>{label}</p>
          </div>
        );
      })}
    </div>
  );
};

enum SellType {
  OPEN = "OPEN",
  DIRECT = "DIRECT",
}

const CustomInput: React.FC<{
  maxValue: number;
  perMonthValue: string;
  setPerMonthValue: (p: string) => any;
  prefix?: any;
  suffix?: any;
}> = ({ maxValue, perMonthValue, setPerMonthValue, prefix, suffix }) => {
  // let myValue: any = [];
  // myValue[0] = perMonthValue - perMonthValue%1;
  // myValue[1] = perMonthValue%1;

  // console.log(myValue)
  // if(myValue[1] > 0) {
  //     if(myValue[1].length < 2) myValue[1] = `${myValue[1]}0`;
  //     myValue = `${myValue[0]},${myValue[1]}`;
  // } else {
  //     myValue = perMonthValue.toFixed(2);
  // }

  if (perMonthValue[0] === "0") {
    (perMonthValue as any).replace("0", "");
  }
  // console.log(perMonthValue)

  return (
    <div className="amount-input flex flex-row mt-2 mb-5 rounded overflow-hidden">
      <div
        className="amount-input-btn flex flex-col border border-r-0"
        style={{ backgroundColor: Colors.Gray5, borderColor: BorderColor }}
        onClick={() =>
          setPerMonthValue((Number(perMonthValue) - 0.01).toFixed(2))
        }
      >
        <p className="font-medium text-xl" style={{ color: Colors.Gray2 }}>
          -
        </p>
      </div>
      <div
        className="flex-grow flex flex-col py-2 px-5 border items-center justify-center overflow-hidden"
        style={{ backgroundColor: "#FFFFFF", borderColor: BorderColor }}
      >
        <div className="flex flex-row w-full font-medium text-xl">
          <p className="font-medium text-xl" style={{ color: Colors.Gray2 }}>
            {prefix}
          </p>
          <div className="flex-grow-0">
            <input
              type="number"
              className="font-medium text-xl text-center w-full h-full outline-none mycustominput"
              style={{ color: Colors.Gray2 }}
              value={perMonthValue}
              onChange={({ target }) => {
                let value: string = target.value || "0.01"; // 0.01 caso valor esteja vazio

                let intPart: string = value.split(".")[0] || "0";
                let floatPart: string = value.split(".")[1];
                if (floatPart?.length > 2) {
                  // move primeiro caracter do float para parte do int
                  // Ex: 9.00 => 9.001 => 90.01
                  intPart += floatPart[0];
                  floatPart = floatPart.substring(1);
                  value = intPart + "." + floatPart;
                } else if (floatPart?.length === 1) {
                  // move parte int para a parte float
                  // Ex: 91,00 => backspace no ultimo 0 => 91,0 => 9,10 => backspace denovo => 0,91
                  floatPart = intPart[intPart.length - 1] + floatPart;
                  intPart = intPart.substring(0, intPart.length - 1) || "0";
                  value = intPart + "." + floatPart;
                }

                let newValue: number = Number(value);
                if (maxValue) {
                  if (newValue === 0) {
                    newValue = 0.01;
                  } else if (newValue > maxValue) {
                    newValue = maxValue;
                  }
                }

                setPerMonthValue(newValue.toFixed(2));
              }}
            />
          </div>
          <p className="font-medium text-xl" style={{ color: Colors.Gray2 }}>
            {suffix}
          </p>
        </div>
      </div>
      <div
        className="amount-input-btn border border-l-0"
        style={{ backgroundColor: Colors.Gray5, borderColor: BorderColor }}
        onClick={() =>
          setPerMonthValue((Number(perMonthValue) + 0.01).toFixed(2))
        }
      >
        <p className="font-medium text-xl" style={{ color: Colors.Gray2 }}>
          +
        </p>
      </div>
    </div>
  );
};

const NegociationBar: React.FC<MyProps> = ({
  state,
  changeState,
  backBlur,
  token,
}) => {
  const assetContext = useContext(AssetContext);

  const { handleModal, setModal } = useContext(ModalContext);

  // Must devide by 100 every calculation logic due to currency input's display value
  const [_amount, _setAmount] = useState<number>(100);
  const [_costPerUnity, _setCostPerUnity] = useState<number>(0);

  const [_lastMatch, _setLastMatch] = useState<Match>();
  const lastNegociationValue =
    (_lastMatch?.amount ?? 0) / (_lastMatch?.tokenQuantity ?? 0);

  const [taxaVenda, _setTaxaVenda] = useState<number>(0);
  const [mynode] = useState(React.createRef<HTMLDivElement>());
  const [successState, setSuccessState] = useState<boolean>(false);
  const [sellType, setNegociationType] = useState<SellType>(SellType.OPEN);
  const [closeModalClassname, setCloseModalClassname] = useState<string>("");

  async function calcTaxaVenda() {
    const taxs = await API.tax.getTotalFees();
    const taxNetwork = taxs.get(TaxTypeEnum.NETWORK)?.value ?? 0;
    const taxInsurance = taxs.get(TaxTypeEnum.INSURANCE)?.value ?? 0;

    return taxNetwork + taxInsurance;
  }

  useEffect(() => {
    let amountValue = Number(_amount / 100);
    if (token?.available && amountValue > token.available) {
      amountValue = token.available;
    }
    _setAmount(amountValue * 100);
  }, []);

  useEffect(() => {
    calcTaxaVenda().then((data) => _setTaxaVenda(data));
  }, []);

  useEffect(() => {
    API.book.getAllMatchs({}).then((data) => {
      const aux = data[data.length - 1] as unknown as Match;
      if (aux) {
        _setLastMatch(aux);
        _setCostPerUnity((aux?.amount ?? 0) / (aux?.tokenQuantity ?? 0));
      }
    });
  }, []);

  const handleAmountChange = useCallback((val) => {
    if (token && Number(val) / 100 > token.available) {
      _setAmount(token?.available * 100);
    } else {
      _setAmount(val);
    }
  }, []);

  const handleCostPerUnitChange = useCallback((val) => {
    _setCostPerUnity(val);
  }, []);

  const criarOrdem = async () => {
    if (!token) return;

    try {
      const createOrderRequest = {
        price: _costPerUnity / 100,
        tokenId: Number(token.id),
        tokenQuantity: _amount / 100,
      };
      const data: GoldToken = (await API.book.createSellOrderGold({
        ...createOrderRequest,
        dryRun: true,
      })) as GoldToken;

      setModal(false);
      handleModal(Modal, {
        state: true,
        title: "Criar ordem de venda",
        titleColor: "white",
        titleBgColor: Colors.Red,

        confirmText: "CONFIRMAR",
        confirmButtonClick: async () => {
          await API.book.createSellOrderGold(createOrderRequest);
          setModal(false);

          handleModal(AlertModal, {
            title: "Token disponibilizado para venda!",
            src: Gifs.IconOkGif,
            titleColor: Colors.Green3,

            confirmClick: () => window.location.reload(),

            children: (
              <div className="flex flex-col justify-center text-center items-center">
                <p>Acompanhe o andamento em sua lista de ordens de venda.</p>
              </div>
            ),
          });
        },
        cancelButtonClick: () => handleCloseModal(),

        children: (
          <div
            className="flex flex-col justify-center text-justify items-start font-light mx-7 w-96"
            style={{ color: Colors.Gray3 }}
          >
            <p>
              QTD negociada:
              <span className="font-normal">
                {formatToCurrency((data as any).tokenQuantity)}{" "}
                {token?.metadata?.properties?.unit}
              </span>
            </p>
            <p>
              Preço por unidade:
              <span className="font-normal">
                {formatToCurrency(data.amount)} BRL
              </span>
            </p>
            {/* <p>Data de liberação: <span className='font-normal'>{moment(token.redemptionDate).format('DD/MM/YYYY')}</span></p> */}
            <p className="mt-3">
              Valor total:{" "}
              <strong>
                R${formatToCurrency((data as any).tokenQuantity * data.amount)}
              </strong>
            </p>
          </div>
        ),
      });
    } catch (error) {
      if (
        (error as any)?.message ===
        "user does not have limit to create new bond buy order "
      ) {
        handleModal(AlertModal, {
          title: "Limite insuficiente!",
          src: Gifs.IconAlertGif,
          titleColor: Colors.Red4,

          confirmClick: async () => handleCloseModal(),

          children: <></>,
        });
      } else {
        handleModal(AlertModal, {
          title: "Recebível indisponivel!",
          src: Gifs.IconAlertGif,
          titleColor: Colors.Red4,

          confirmClick: async () => handleCloseModal(),

          children: <></>,
        });
      }
    }
  };

  const handleCloseModal = (): void => {
    setCloseModalClassname("sell-token-close");
    setTimeout(() => {
      setCloseModalClassname("");
      setModal(false);
      changeState(false);
    }, 500);
  };

  return (
    <>
      <div
        className="fixed z-10 inset-0 overflow-y-auto"
        style={{ display: state ? "block" : "none", fontSize: "1.5em" }}
        onClick={({ target }) => {
          if (!mynode.current?.contains(target as any)) {
            handleCloseModal();
          }
        }}
      >
        <div className="flex items-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 w-full">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div
              className={`absolute inset-0 ${
                backBlur ? "bg-gray-500 opacity-75" : ""
              }`}
            ></div>
          </div>

          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className={`
                        sell-token
                        ${closeModalClassname}
                        flex flex-col align-bottom 
                        text-white
                        shadow-xl overflow-hidden
                        transform transition-all 
                        absolute bottom-0 left-0 right-0
                        w-full
                    `}
            style={{ color: Colors.Gray5, backgroundColor: Colors.Black4 }}
            ref={mynode}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex flex-col flex-grow items-center w-full">
              <SelectTable
                current={sellType}
                onClick={(p) => setNegociationType(p)}
                options={[
                  { label: "Ordem de venda", value: SellType.OPEN },
                  // { label: 'Venda direta', value: SellType.DIRECT },
                ]}
              />

              <div className="mt-5">
                <p className="text-2xl font-bold" style={{ color: "white" }}>
                  {assetContext.token?.metadata?.symbol} -{" "}
                  {assetContext.token?.metadata?.name}
                </p>
                <p
                  className="text-lg font-normal"
                  style={{ color: Colors.Gray4 }}
                >
                  LOTE #{token?.id}
                </p>
              </div>

              <p className="text-2xl font-normal mt-5">
                Quantidade negociada ({assetContext.token?.metadata.name}):
              </p>
              <p
                style={{ color: Colors.UserBGButton }}
                className="text-sm font-normal"
              >
                QTD disponível: {formatToCurrency(token?.available)}{" "}
                {token?.metadata?.properties?.unit}
              </p>

              {/* <CustomInput
                                maxValue={token.available}
                                perMonthValue={_amount}
                                setPerMonthValue={_setAmount}
                                suffix={assetContext.token?.metadata?.unit}
                            /> */}
              <div className="amount-input flex flex-row mt-2 mb-5 rounded overflow-hidden">
                <div
                  className="flex-grow flex flex-col w-5 border items-center justify-center"
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderColor: BorderColor,
                  }}
                >
                  <CurrencyInput
                    value={_amount}
                    onValueChange={handleAmountChange}
                    style={{
                      color: Colors.Gray2,
                      backgroundColor: "transparent",
                    }}
                    className="z-1 block p-2 w-full font-medium appearance-none focus:outline-none text-center"
                  />
                </div>
              </div>
              {sellType === SellType.OPEN ? (
                <>
                  <p className="text-2xl font-normal">
                    Preço por {assetContext.token?.metadata.name} (BRL):
                  </p>
                  <p
                    style={{ color: Colors.UserBGButton }}
                    className="text-sm font-normal"
                  >
                    última negociação: {formatToCurrency(lastNegociationValue)}{" "}
                    BRL
                  </p>

                  <div className="amount-input flex flex-row mt-2 mb-5 rounded overflow-hidden">
                    <div
                      className="flex-grow flex flex-col w-5 border items-center justify-center"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderColor: BorderColor,
                      }}
                    >
                      {/* <MoneyInput
                                                        prefix='R$ '

                                                        value={_costPerUnity.value}
                                                        defaultValue={_costPerUnity.value}
                                                        onChangeValue={(p) => _setCostPerUnity(Currency(p))}

                                                        style={{
                                                            color: Colors.Gray2,
                                                            backgroundColor: 'transparent',
                                                        }}
                                                        className="z-1 block p-2 w-full font-medium appearance-none focus:outline-none text-center"
                                                    /> */}
                      <CurrencyInput
                        value={_costPerUnity}
                        onValueChange={handleCostPerUnitChange}
                        style={{
                          color: Colors.Gray2,
                          backgroundColor: "transparent",
                        }}
                        className="z-1 block p-2 w-full font-medium appearance-none focus:outline-none text-center"
                      />
                    </div>
                    <div
                      className="flex flex-col w-12 py-2 px-5 items-center justify-center cursor-pointer"
                      style={{
                        backgroundColor: Colors.Gray5,
                        borderColor: BorderColor,
                      }}
                      onClick={() => {}}
                    >
                      <p
                        className="font-medium text-xl"
                        style={{ color: Colors.Gray2 }}
                      >
                        BRL
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p>Preço por grama:</p>
                  <strong style={{ color: Colors.Primary }} className="text-lg">
                    {formatToCurrency(lastNegociationValue)}
                  </strong>
                </>
              )}

              <p
                style={{ color: Colors.Red4 }}
                className="text-2xl font-bold mt-3 mb-4"
              >
                <span className="font-normal">Valor total:</span>{" "}
                <strong>
                  {formatToCurrency((_amount / 100) * (_costPerUnity / 100))}{" "}
                  BRL
                </strong>
              </p>

              <div className="flex-grow flex flex-col justify-center text-base">
                <Button
                  style={{ padding: "2px 4px", width: "300px", height: "50px" }}
                  onClick={criarOrdem}
                  backgroundColor={Colors.Red4}
                  borderColor={Colors.Red4}
                  textColor={"white"}
                  className="mt-3 mb-5"
                  disabled={_amount / 100 < 0.01 || _costPerUnity / 100 < 0.01}
                >
                  Criar ordem de venda
                </Button>
              </div>
            </div>

            <div className="flex flex-col h-15 w-full items-center">
              <Divider
                className="mt-0 mb-0"
                top="mt-0"
                bottom="mb-0"
                color={Colors.Gray2}
              />
              <UnderlineButton
                className="flex flex-row flex-grow justify-center my-0 text-lg font-medium"
                style={{ color: Colors.Gray3 }}
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Cancelar negociação
              </UnderlineButton>
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        state={successState}
        changeState={() => setSuccessState(!successState)}
        title="Token disponibilizado para venda!"
        src={Gifs.IconOkGif}
      >
        Acompanhe o andamento em sua lista de ordens.
      </AlertModal>
    </>
  );
};

export default NegociationBar;
