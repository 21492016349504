import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useTimer } from "react-timer-hook";
import Colors from "../../assets/Colors";
import DataRow from "../../components/DataRow/DataRow";
import AlertModal from "../../components/Modal/AlertModal";
import { InstructionsModal } from "../../components/Modal/InstructionsModal";
import PageBoardSkeleton from "../../components/PageBoardSkeleton/PageBoardSkeleton";
import ModalContext from "../../contexts/ModalProvider";
import UserContext from "../../contexts/UserProvider";
import {
  DepositMethodEnumInterpreter,
  DepositMethodsEnum,
} from "../../models/enums/DepositMethods";
import RoutesPaths from "../../Routes/RoutesPaths";
import history from "../../services/history";
import {
  formatCNPJ,
  formatToCurrency,
  formatBankAgency,
  formatAccount,
} from "../../utils/formatters";
import * as API from "./../../api";
import DepositData from "./DepositData/DepositData";

const getQueryParams = (t: string) => {
  if (!t || "string" != typeof t || t.length < 2) return new Map();

  const r: any = t
    .substr(1)
    .split("&")
    .map((t) => {
      const r = t.split("=");
      return [r[0], r[1]];
    });

  return new Map(r);
};

const DepositPage: React.FC = () => {
  const { handleModal, setModal } = useContext(ModalContext);
  const [depositData, setDepositData] = useState<API.bank.deposit | undefined>(
    undefined
  );

  const expiryTimestamp = moment(new Date()).add(5, "m").toDate();
  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  const { search } = useLocation();
  const params: any = useParams();

  const depositMethod = DepositMethodsEnum[params.method];
  const amount = Number(getQueryParams(search).get("amount"));
  const { user } = useContext(UserContext);

  async function loadDepositData() {
    try {
      const tenantInfo = await API.auth.getTenantInfo();
      if (tenantInfo) {
        setDepositData({
          bank: tenantInfo?.custody.name ?? "",
          bankAccount: tenantInfo.custody.account
            ? tenantInfo.custody.account
            : "",
          bankAgency: tenantInfo.custody.agency
            ? formatBankAgency(tenantInfo.custody.agency.toString())
            : "",
          bankNumber: tenantInfo.custody.number ?? "",
          favored: tenantInfo.custody.favored ?? "",
          document: tenantInfo.custody.document
            ? formatCNPJ(tenantInfo.custody.document.toString())
            : "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  function hideModalInstruction() {
    setModal(false);
  }

  function showInstructionModal() {
    handleModal(InstructionsModal, {
      depositMethod,
      onClick() {
        hideModalInstruction();
      },
    });
  }

  useEffect(() => {
    loadDepositData();
    // showInstructionModal()
  }, []);

  const finishDeposit = () => {
    handleModal(AlertModal, {
      title: "Atenção",
      confirmClick: () => {
        setModal(false);
        history.push(RoutesPaths.BALANCE);
      },
      children: (
        <div
          className="flex justify-center text-center items-center mb-3 lg:mb-5"
          style={{ color: Colors.Gray3 }}
        >
          <span>
            Favor aguardar o prazo de processamento de sua instituição
            financeira. Em seguida, seu recurso estará disponível na sua conta
            da plataforma.
          </span>
        </div>
      ),
    });
  };

  return (
    <>
      <PageBoardSkeleton
        filterBar={`Deposito via ${depositMethod}`}
        onClickFilterBar={history.goBack}
        button={{
          label: "Concluir",
          className: "mb-10",
          onClick: finishDeposit,
        }}
      >
        <h2
          className="font-normal text-lg my-3"
          style={{ color: Colors.Gray3 }}
        >
          MÉTODO DE DEPÓSITO
        </h2>
        <DataRow title="" value="">
          <div className="flex flex-row justify-between w-full text-sm">
            <div className="flex flex-row items-center gap-3">
              <img
                style={{ width: "35px" }}
                src={DepositMethodEnumInterpreter.getIcon(depositMethod)}
                alt=""
              />
              <span className="font-medium" style={{ color: Colors.Gray2 }}>
                {DepositMethodEnumInterpreter.translate(depositMethod)}
              </span>
            </div>
            <div className="flex flex-col justify-center">
              <button
                className="underline"
                style={{ color: Colors.Blue1 }}
                onClick={() => {
                  handleModal(InstructionsModal, {
                    depositMethod,
                    onClick() {
                      setModal(false);
                    },
                  });
                }}
              >
                Ver instruções
              </button>
            </div>
          </div>
        </DataRow>

        <h2
          className="font-normal text-lg mb-3 mt-5"
          style={{ color: Colors.Gray3 }}
        >
          VALOR DO PAGAMENTO
        </h2>
        <DataRow title={""} value={"R$ " + formatToCurrency(amount)} />

        <DepositData
          depositMethod={depositMethod}
          depositData={depositData}
          hideModalInstruction={hideModalInstruction}
          showInstructionModal={showInstructionModal}
        />

        <h3 className="font-medium mt-9 text-lg" style={{ color: Colors.Red5 }}>
          Tempo restante: {minutes}m {seconds}s
        </h3>
      </PageBoardSkeleton>
    </>
  );
};

export default DepositPage;
