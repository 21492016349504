import api from "../services/api";
import { Token } from "../models";
import { AxiosRequestConfig } from "axios";
import {
  TokenOrderingEnum,
  TokenStatusEnum,
  TokenTypeEnum,
} from "../models/enums";
import moment from "moment";

export const token = {
  async getTokens({
    status,
    typeToken,
    userId,
    ordering,
  }: token.getTokens): Promise<Array<Token>> {
    try {
      var config: AxiosRequestConfig = {};

      config.params = {
        status: status ?? null,
        userId: userId ?? null,
        typeToken: typeToken ?? TokenTypeEnum.GOLD,
        ordering: ordering ?? "CREATION_DATE",
      };

      const response = await api.exchange.get("/token", config);

      if (response.status === 200) {
        return response.data
          .map((elm) => ({ ...elm, ...elm.metadata }))
          .map((elm) => {
            const status: string = elm.status
              ? elm.status.toUpperCase()
              : TokenStatusEnum.ACTIVE;

            return {
              ...elm,

              status,
              amount: Number(elm.amount ?? elm.value),

              create_at: new Date(elm.create_at),
              emissionDate: new Date(elm.emissionDate),
              // settlementDate: new Date(elm.settlementDate),
              redemptionDate: moment(elm.redemptionDate, "YYYY-MM-DD").toDate(),
              redemptionDeadline: moment(
                elm.redemptionDeadline,
                "YYYY-MM-DD"
              ).toDate(),

              invoice:
                elm.invoice === undefined
                  ? undefined
                  : {
                      ...elm.invoce,
                      paidAmount: Number(elm.invoice?.paidAmount ?? 0),
                    },

              type:
                typeToken === TokenTypeEnum.GOLD
                  ? TokenTypeEnum.GOLD
                  : elm.type,
            } as Token;
          });
      } else if (response.status === 204) {
        return [];
      }

      throw response.data;
    } catch (error) {
      throw error;
    }
  },
  async getToken({ id }: token.getTokens): Promise<Token | null> {
    try {
      const response = await api.exchange.get(`/token/${id}`);

      if (response.status === 200) {
        const status: string = response.data.status.toUpperCase();
        return {
          ...response.data,
          status,
          amount: Number(response.data.amount),
          create_at: new Date(response.data.create_at),
          expiration: new Date(response.data.expiration),

          invoice: {
            ...response.data.invoice,
            emitDate: new Date(response.data.invoice.emitDate),
          },
        };
      } else if (response.status === 204) {
        return null;
      } else {
        throw response.data;
      }
    } catch (error) {
      throw error;
    }
  },
};

// eslint-disable-next-line no-redeclare
export namespace token {
  export type getTokens = {
    id?: string;
    startDate?: Date;
    endDate?: Date;

    status?: string;
    ordering?: TokenOrderingEnum;
    typeToken?: TokenTypeEnum;

    userId?: string;
  };
  export type getToken = {
    id: string;
  };
}
