import React, { useContext } from "react";
import { useEffect } from "react";
import Colors from "../assets/Colors";
import Modal from "../components/Modal/Modal";
import ModalContext from "../contexts/ModalProvider";
import * as Gifs from "../assets/gifs";
import { makeLogout } from "../services/jwt";

const useInterruptionModal = () => {
  const { handleModal, setModal } = useContext(ModalContext);

  const showInterruptionModal = (e) => {
    e.preventDefault();
    handleModal(Modal, {
      src: Gifs.IconAlertGif,
      title: "Interromper cadastro?",
      titleSize: "text-xl",
      titleColor: Colors.Gray1,
      children: (
        <div style={{ color: Colors.Gray3 }} className="text-lg text-center">
          Tem certeza que deseja interromper o processo de cadastro?
        </div>
      ),
      cancelButtonClick: () => setModal(false),
      confirmButtonClick: () => {
        setModal(false);
        makeLogout();
      },
      cancelText: "NÃO INTERROMPER",
      confirmText: "INTERROMPER",
      confirmColor: Colors.Red4,
      cancelColor: Colors.Gray1,
    });
  };

  useEffect(() => {
    const navBarLogo = document.getElementById("navbar-logo");
    navBarLogo?.addEventListener("click", showInterruptionModal);
    return () =>
      navBarLogo?.removeEventListener("click", showInterruptionModal);
  }, []);
};

export default useInterruptionModal;
