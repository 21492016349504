import React, { CSSProperties } from "react";
import Colors, { Gray2, Gray4, GrayBackGroud } from "../../../assets/Colors";
import * as Icons from "../../../assets/icons";
import "./FilterInput.css";

type FilterInputProps = {
  value?: string;
  placeholder: string;
  label?: string;
  icon?: string;
  type?: "text" | "select";
  options?: Array<{ label: string; value: any }>;
  className?: string;
  inputBoxClassName?: string;
  textColorActive?: boolean;
  onClick?: () => any;
  onIconClick?: () => any;
  onChange?: (selectedOption) => any;
};

const FilterInput: React.FC<FilterInputProps> = ({
  value,
  placeholder,
  label,
  className,
  inputBoxClassName,
  options,
  icon,
  type,
  textColorActive,
  onClick,
  onIconClick,
  onChange,
}) => {
  const renderSelectInput = () => {
    return (
      <select
        className="block p-2 flex-grow appearance-none focus:outline-none"
        style={{
          color: Colors.Gray2,
        }}
        value={value}
        onChange={onChange}
      >
        {placeholder && (
          <option value={0} selected>
            {placeholder}
          </option>
        )}
        {options?.map(({ label, value }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
        <img src={Icons.ArrowIcon} />
      </select>
    );
  };

  return (
    <div
      className={`flex items-center flex-col justify-center flex-grow ${className}`}
    >
      {label && (
        <span className="text-base font-normal" style={{ color: Colors.Gray4 }}>
          {label}
        </span>
      )}
      <div
        className={`flex bg-white border flex-grow w-full ${inputBoxClassName}`}
        style={{
          borderColor: Colors.Gray4,
          cursor: onClick ? "pointer" : "",
        }}
        onClick={onClick}
      >
        {onClick ? (
          <button
            className="w-full flex items-center text-lg"
            style={{
              cursor: "pointer",
              color: textColorActive ? Colors.Black2 : Colors.Gray4,
            }}
          >
            {placeholder}
          </button>
        ) : type === "select" ? (
          renderSelectInput()
        ) : (
          <input
            className="filter-input w-full text-lg mr-4"
            placeholder={placeholder}
          />
        )}
        {icon ? (
          <img
            src={icon}
            className={onIconClick ? "cursor-pointer" : ""}
            onClick={onIconClick}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

FilterInput.defaultProps = {
  className: "",
  type: "text",
  textColorActive: false,
};

export default FilterInput;
