import React, { ChangeEvent, RefObject, useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import Colors from "../../assets/Colors";

type MyProps = {
  className: string;
  placeholder?: string;
  defaultChecked?: any;
  options: Array<{ label: string; value: any }>;

  sufix?: JSX.Element;
  prefix?: JSX.Element;
  onChange?: (p: any) => any;
};

const Select = React.forwardRef(
  (
    {
      placeholder,
      className,
      options,
      prefix,
      sufix,
      onChange,
      defaultChecked,
    }: MyProps,
    ref
  ) => {
    const getSelectedLabel = () => {
      const text =
        options.find((item) => item.value === defaultChecked)?.label || "";

      setSelectedValue(defaultChecked);

      return text;
    };

    const [selectedValue, setSelectedValue] = useState("0");
    const [filteredValue, setFilteredValue] = useState("");
    const [showOptionsList, setShowOptionsList] = useState(false);

    useEffect(() => {
      if (options.length && defaultChecked)
        setFilteredValue(getSelectedLabel());
    }, [options, defaultChecked]);

    const filteredList = options.filter((item) => {
      if (item.label.toLowerCase().includes(filteredValue.toLowerCase())) {
        return item;
      }
    });

    const onChangeFilterHandler = (event) => {
      setFilteredValue(event.target.value);
      setSelectedValue("0");
      setShowOptionsList(true);
    };

    const onSelectHandler = (item: any) => {
      setFilteredValue(item.label);
      setTimeout(() => setSelectedValue(item.value), 200);
      setShowOptionsList(false);
    };

    const onBlurHandler = (event) => {
      setTimeout(() => setShowOptionsList(false), 200);
    };

    useEffect(() => {
      if (onChange) onChange(selectedValue);
    }, [selectedValue]);

    return (
      <div
        className={
          "rounded outline relative border focus-within:border-blue-500 flex flex-col" +
          className
        }
        style={{ borderColor: Colors.Gray4 }}
      >
        <div className="flex-grow w-full">
          <div className="flex flex-row no-wrap gap-1 items-center">
            <FaSearch className="ml-3" />
            <input
              style={{ color: Colors.Gray2 }}
              className={`block p-4 flex-grow appearance-none focus:outline-none bg-white`}
              type="text"
              name="search"
              value={filteredValue}
              autoComplete="off"
              placeholder={placeholder}
              onChange={(event) => onChangeFilterHandler(event)}
              onFocus={() => setShowOptionsList(true)}
              onBlur={(event) => onBlurHandler(event)}
            />
          </div>
          {showOptionsList && (
            <ul
              style={{
                width: "calc(100% + 2px)",
                left: "-1px",
                top: "calc(100% - 2px)",
                overflowY: "auto",
              }}
              className="absolute z-10 bg-gray-50 rounded-b w-full border border-t-0 max-h-56"
            >
              {filteredList.map((item: any, index) => {
                return (
                  <li
                    style={{ color: Colors.Gray2 }}
                    className="pl-10 p-2 text-base font-normal cursor-pointer hover:bg-gray-100"
                    key={`item-${item.value}`}
                    onClick={() => {
                      onSelectHandler(item);
                    }}
                  >
                    {item?.label}
                  </li>
                );
              })}
            </ul>
          )}

          <div style={{ overflow: "hidden", height: "0px" }}>
            {prefix}
            <select
              className="block p-2 flex-grow appearance-none focus:outline-none bg-white "
              style={{ color: Colors.Gray2 }}
              ref={ref as RefObject<HTMLSelectElement>}
              value={selectedValue}
            >
              {placeholder && (
                <option value={0} selected>
                  {placeholder}
                </option>
              )}
              {options.map(({ label, value }, index) => {
                if (value !== defaultChecked) {
                  return (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  );
                } else {
                  return (
                    <option key={index} value={value} selected>
                      {label}
                    </option>
                  );
                }
              })}
            </select>
            {sufix}
          </div>
        </div>
      </div>
    );
  }
);

Select.defaultProps = {
  className: "",
  placeholder: "",
};

export default Select;
