import React, { useState, useEffect } from "react";
import RoutesPaths from "../../../Routes/RoutesPaths";
import history from "../../../services/history";
import {
  EmailRegister,
  EmailKeyRegister,
  CellPhoneRegister,
  CellPhoneKeyRegister,
} from "./FirstStep/FirstStep";

export const PagesHandler: React.FC<any> = ({ defaultPage }) => {
  const [currentPage, setCurrentPage] = useState<number>(defaultPage ?? 1);
  const [phone, setPhone] = useState<string | undefined>();
  const [accountId, setAccountId] = useState<string>("");

  const nextPage = () => {
    if (currentPage < 5) {
      setCurrentPage(currentPage + 1);
    }
    if (currentPage === 5) {
      history.push(RoutesPaths.SIGNUP_REGISTER);
    }
  };

  useEffect(() => {
    const id = localStorage.getItem("@idOnBoarding") ?? "";
    setAccountId(id);
  }, []);

  const renderPage = () => {
    switch (currentPage) {
      case 1:
        return (
          <EmailRegister nextPage={nextPage} setAccountId={setAccountId} />
        );
      case 2:
        return (
          <EmailKeyRegister
            nextPage={nextPage}
            accountId={accountId}
            setCurrentPage={setCurrentPage}
          />
        );
      case 3:
        return (
          <CellPhoneRegister nextPage={nextPage} setPhoneMain={setPhone} />
        );
      case 4:
        return (
          <CellPhoneKeyRegister
            previousPage={() => setCurrentPage(currentPage - 1)}
            nextPage={nextPage}
            phone={phone as string}
          />
        );
      default:
        return <></>;
    }
  };

  return renderPage();
};
