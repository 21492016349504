import React from "react";
import Routes from "./Routes/routes";
import { ModalProvider } from "./contexts/ModalProvider";
import { UserContextProvider } from "./contexts/UserProvider";
import { AssetContextProvider } from "./contexts/AssetProvider";
import { OrderContextProvider } from "./contexts/OrderProvider";
import { BalanceContextProvider } from "./contexts/BalanceProvider";
import { ClientsContextProvider } from "./contexts/ClientsProvider";
import { TaxesContextProvider } from "./contexts/TaxesProvide";
import { LimitsContextProvider } from "./contexts/LimitsProvide";

const App = () => (
  <ModalProvider>
    <ClientsContextProvider>
      <UserContextProvider>
        <AssetContextProvider>
          <OrderContextProvider>
            <BalanceContextProvider>
              <TaxesContextProvider>
                <LimitsContextProvider>
                  <Routes />
                </LimitsContextProvider>
              </TaxesContextProvider>
            </BalanceContextProvider>
          </OrderContextProvider>
        </AssetContextProvider>
      </UserContextProvider>
    </ClientsContextProvider>
  </ModalProvider>
);

export default App;
