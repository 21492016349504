import React from "react";
import { LoaderSpin } from "./../../assets/icons";

import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader center">
      <img src={LoaderSpin} alt="" />
    </div>
  );
};

export default Loader;
