enum RoutesPaths {
  ACCOUT = "/conta",
  ACCOUT_BASE = "/conta",
  ACCOUT_LIMITES = "/conta/limitesetaxas",
  ACCOUT_BANK = "/conta/banco",
  ACCOUT_BANK_DOMICILIO = "/conta/banco/domicilio",
  ACCOUT_DOCUMENTS = "/conta/documentos",
  ACCOUT_ENDERECO = "/conta/endereco",
  ACCOUT_DATA = "/conta/dados",
  ACCOUT_PASSWORD = "/conta/senha",
  ACCOUT_LIVENESS = "/conta/liveness",
  ACCOUT_DOCUMENT = "/conta/document",
  ACCOUT_DOCUMENT_COMPLEMENTARY = "/conta/document/complementary",
  ACCOUT_DOCUMENT_ADDRESS = "/conta/document/address",

  BALANCE = "/saldo",

  DASHBOARD = "/dashboard",
  DASHBOARD_ADMIN = "/dashboard-admin",
  TOKENS = "/tokens",
  TOKEN_DETAILS = "/tokens/details",

  MERCADO = "/mercado",

  MINHASORDENS = "/ordens",
  MINHASORDENS_ADMIN = "/ordens-admin",
  ORDENDETALHES = "/ordens/detalhes",

  MEUSATIVOS = "/meusativos",
  MEUSATIVOS_ADMIN = "/meusativos-admin",
  MEUSATIVOSCRIAR = "/meusativos/criar",
  MEUSATIVOSDETALHES = "/meusativos/detalhes",
  MEUSATIVOSDETALHES_ADMIN = "/meusativos-admin/detalhes",
  MEUSATIVOSNEGOCIAR = "/meusativos/negociar",
  MEUSATIVOSCRIARONFIRM = "/meusativos/criar/confirmar",

  SIGNIN = "/auth/signin",

  FORGOTPASSWD = "/forgotpassword",
  RESETPASSWORD = "/reset",

  SIGNUP = "/signup",
  SIGNUP_REGISTER = "/signup/register",
  SIGNUP_1 = "/auth/signup/step1",
  SIGNUP_2 = "/auth/signup/step2",
  SIGNUP_3 = "/auth/signup/step3",
  SIGNUP_END = "/auth/signup/end",

  HOME = "/dashboard-admin",
  INDEX = "/",

  USETERM = "/useterm",

  ADMIN_CLIENTS = "/clients",
  ADMIN_CLIENTS_DETAILS = "/clients/details",
  ADMIN_CLIENTS_BALANCE = "/clients/details/balance",
  ADMIN_CLIENTS_WALLET = "/clients/details/wallet",
  ADMIN_CLIENTS_ORDERS = "/clients/details/orders",
  ADMIN_CLIENTS_LIMITS_TAXES = "/clients/details/limits-taxes",
  ADMIN_CLIENTS_BANK_ACCOUNTS = "/clients/details/accounts",
  ADMIN_CLIENTS_DATA = "/clients/details/data",
  ADMIN_CLIENTS_ADDRESS = "/clients/details/address",
  ADMIN_CLIENTS_DOCUMENTS = "/clients/details/documents",
  ADMIN_CLIENTS_DOCUMENTS_ANALYZE = "/clients/details/documents/analyze",
  ADMIN_CLIENTS_PERSONAL_DOCUMENTS_ANALYZE = "/clients/details/personal/documents/analyze",
  DEPOSIT = "/deposit",
  TRANSFER = "/transfer",
  MEUSATIVOSLIST = "MEUSATIVOSLIST",

  TEST = "/test",
}

export default RoutesPaths;
