import React, { useContext, useEffect, useState } from "react";
import Colors from "../../assets/Colors";
import BarButton from "../../components/BarButton/BarButton";
import Button from "../../components/Buttons/Button";
import HighlightCard from "../../components/Cards/HighlightCard/HighlightCard";
import Divider from "../../components/Divider/Divider";
import FilterBar from "../../components/FilterBar/FilterBar";
import Footer from "../../components/Footer/Footer";
import PageTabBar from "../../components/PageTabBar/PageTabBar";
import ResumeBar from "../../components/ResumeBar/ResumeBar";
import TopBar from "../../components/TopBar/TopBar";
import UserContext from "../../contexts/UserProvider";
import { Match } from "../../models";
import { balance } from "../../models/balance";
import { TokenTypeEnum } from "../../models/enums";
import RoutesPaths from "../../Routes/RoutesPaths";
import history from "../../services/history";
import { formatToCurrency } from "../../utils/formatters";
import TokenDetailsResumeCard from "../TokenDetailsPage/TokenDetailsResumeCard/TokenDetailsResumeCard";
import * as API from "./../../api";
import * as Icons from "./../../assets/icons";
import "./DashboardPage.css";

const DashboardPage: React.FC<any> = () => {
  const { user } = useContext(UserContext);

  const [userBalance, setUserBalance] = useState<balance>();
  const [informativeCards, setInformativeCards] = useState({
    tokensEstimatedValue: 0,
    totalBalanceFiat: 0,
    globalEstimatedBalance: 0,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loadingBoxInfo, setLoadingBoxInfo] = useState<boolean>(true);

  async function loadBalance() {
    if (!user) return;

    const balanceData: balance = await API.user.getBalance();
    setUserBalance(balanceData);

    setLoadingBoxInfo(true);
    API.book
      .getUserOrders(user.id)
      .then((data) => {
        if (balanceData) {
          let tokensEstimatedValue: number = data.reduce((prev, current) => {
            return (
              prev +
              (current.type === "buy"
                ? current.amount * current.tokenQuantity
                : 0)
            );
          }, 0);
          let totalBalanceFiat: number = balanceData.currency.full;
          let globalEstimatedBalance: number =
            tokensEstimatedValue + totalBalanceFiat;

          setInformativeCards({
            tokensEstimatedValue,
            totalBalanceFiat,
            globalEstimatedBalance,
          });
        }
      })
      .finally(() => {
        setLoadingBoxInfo(false);
      });
  }

  useEffect(() => {
    loadBalance();
  }, []);

  const renderCurrentPage = (): JSX.Element => {
    switch (currentPage) {
      case 1:
        return (
          <div className="mt-1">
            {[1, 2].map((item) => (
              <HighlightCard
                buttonColor={Colors.UserBGButton}
                buttonLabel={"Ver ofertas"}
                buttonClassName="show-offer-btn"
              />
            ))}
          </div>
        );
      default:
        return (
          <div className="mt-1">
            {[1, 2].map((item) => (
              <HighlightCard
                buttonColor={Colors.UserBGButton}
                buttonLabel={"Ver ofertas"}
                buttonClassName="show-offer-btn"
              />
            ))}
          </div>
        );
    }
  };

  return (
    <>
      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <TopBar />
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar title="Dashboard" />

          <div className="flex flex-row w-full justify-center">
            <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
              <div className="flex flex-col lg:flex-row mt-4 my-7 w-full">
                <TokenDetailsResumeCard
                  bgColor={Colors.valueBox}
                  title="Valor em tokens"
                  textColor={Colors.Gray1}
                  titleStyle={{ fontWeight: 400 }}
                  unity="BRL"
                  value={formatToCurrency(
                    informativeCards.tokensEstimatedValue
                  )}
                  loading={loadingBoxInfo}
                  hiddenContent={{
                    showHiddenContent: user?.showWallet === false,
                    content: (
                      <span
                        className="font-normal text-5xl lg:mt-3"
                        style={{ lineHeight: "2rem" }}
                      >
                        ••••
                      </span>
                    ),
                  }}
                />
                <div className="w-5"></div>
                <TokenDetailsResumeCard
                  bgColor={Colors.valueBox}
                  title={"Valor em conta"}
                  textColor={Colors.Gray1}
                  titleStyle={{ fontWeight: 400 }}
                  unity="BRL"
                  value={formatToCurrency(informativeCards.totalBalanceFiat)}
                  loading={loadingBoxInfo}
                  hiddenContent={{
                    showHiddenContent: user?.showWallet === false,
                    content: (
                      <span
                        className="font-normal text-5xl lg:mt-3"
                        style={{ lineHeight: "2rem" }}
                      >
                        ••••
                      </span>
                    ),
                  }}
                />
                <div className="w-5"></div>
                <TokenDetailsResumeCard
                  bgColor={Colors.valueBox}
                  title={"Patrimônio Consolidado"}
                  textColor={Colors.Gray1}
                  titleStyle={{ fontWeight: 400 }}
                  unity="BRL"
                  value={formatToCurrency(
                    informativeCards.globalEstimatedBalance
                  )}
                  loading={loadingBoxInfo}
                  hiddenContent={{
                    showHiddenContent: user?.showWallet === false,
                    content: (
                      <span
                        className="font-normal text-5xl lg:mt-3"
                        style={{ lineHeight: "2rem" }}
                      >
                        ••••
                      </span>
                    ),
                  }}
                />
              </div>
            </div>
          </div>

          <Divider top="mt-0" />

          <div className="flex flex-row w-full justify-center">
            <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
              <div className="flex flex-row justify-between w-full gap-5 mb-8">
                {[
                  {
                    label: "Depositar saldo",
                    icon: Icons.BigWalletIcon,
                    onClick: () => history.push(RoutesPaths.DEPOSIT),
                  },
                  {
                    label: "Meus limites",
                    icon: Icons.ChartIcon,
                    onClick: () => history.push(RoutesPaths.ACCOUT_LIMITES),
                  },
                  // { label: 'Aumentar seu limite', icon: Icons.CheckSuccessIcon, onClick: () => { history.push(RoutesPaths.ACCOUT_DOCUMENTS) } },
                  user?.dataVerified &&
                  user?.federalId &&
                  user?.proofOfResidence
                    ? {
                        label: "Conta certificada",
                        icon: Icons.OkGraySmallIcon,
                        disabled: true,
                        onClick: () => {},
                      }
                    : {
                        label: "Certifique sua conta",
                        icon: Icons.AlertGraySmallIcon,
                        onClick: () => {
                          history.push(RoutesPaths.ACCOUT_DOCUMENTS);
                        },
                      },
                ].map(({ icon, label, onClick, disabled }) => (
                  <button
                    key={label}
                    className={`
                          flex flex-col w-full md:w-1/3
                          rounded border py-3 px-3 md:px-6 
                          justify-between items-center
                          ${
                            disabled
                              ? "bg-gray-100 cursor-default"
                              : "hover-dark cursor-pointer"
                          }
                      `}
                    style={{
                      borderColor: disabled ? Colors.Gray4 : Colors.Gray3,
                    }}
                    disabled={disabled}
                    onClick={onClick}
                  >
                    <img src={icon} alt="" />
                    <h3
                      className="text-base font-medium text-center"
                      style={{ color: disabled ? Colors.Gray4 : Colors.Gray2 }}
                    >
                      {label}
                    </h3>
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* <Divider top='mt-0' bottom='mb-4' /> */}

          <div className="mx-3 md:mx-5 flex flex-col">
            {/* <PageTabBar className="details-bar mt-12 justify-start md:justify-around">
              <BarButton borderColor={Colors.Gold4} textColor={Colors.Gray1} grow state={currentPage === 1} children="Destaques" onClick={() => setCurrentPage(1)} />
              <BarButton borderColor={Colors.Gold4} textColor={Colors.Gray1} grow state={currentPage === 2} children="Recentes" onClick={() => setCurrentPage(2)} />
              <BarButton borderColor={Colors.Gold4} textColor={Colors.Gray1} grow state={currentPage === 3} children="Maior alta" onClick={() => setCurrentPage(3)} />
              <BarButton borderColor={Colors.Gold4} textColor={Colors.Gray1} grow state={currentPage === 4} children="Maior volume" onClick={() => setCurrentPage(4)} />
              <BarButton borderColor={Colors.Gold4} textColor={Colors.Gray1} grow state={currentPage === 5} children="Oportunidades" onClick={() => setCurrentPage(5)} />
            </PageTabBar> */}

            {/* {renderCurrentPage()} */}
          </div>
        </main>
        <Footer textColor={Colors.Black1} />
      </div>
    </>
  );
};

export default DashboardPage;
