import { AuthInfo } from "../features/auth/authTypes";

export const getCrendentialsOnStorage = (): AuthInfo | undefined => {
  const accessToken = window?.sessionStorage.getItem("accessToken");
  const refreshToken = window?.sessionStorage.getItem("refreshToken");
  const expiresIn = window?.sessionStorage.getItem("expiresIn");

  if (!accessToken || !refreshToken || !expiresIn) return;

  return {
    accessToken,
    refreshToken,
    expiresIn: Number(expiresIn),
  };
};
