import React from "react";

export interface ModalButton {
  text?: string;
  color?: string;
  render?: () => JSX.Element | Array<JSX.Element>;
  onClick?: () => any;
}

export interface ModalContent {
  title: string;
  buttons: Array<ModalButton>;
  onClick(): any;
}

export default () => {
  let [modal, setModal] = React.useState<boolean>(false);
  let [modalContent, setModalContent] = React.useState<any>();
  let [ModalComponent, setModalComponent] = React.useState<{ p1: any }>();

  const handleModal = (comp: any | undefined, data: any) => {
    if (comp) {
      setModalComponent({ p1: comp });
      setModalContent(data);
      setModal(true);
    } else {
      setModal(false);
    }
  };

  return {
    modal,
    setModal,
    handleModal,
    modalContent,
    ModalComponent,
    setModalComponent,
  };
};
