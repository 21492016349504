import moment from "moment";
import React, { CSSProperties } from "react";
import Colors from "../../assets/Colors";
import * as Icons from "../../assets/icons";
import { Client, ClientStatusEnum } from "../../models/clients";
import { formatCPF } from "../../utils/formatters";
import CardBase from "./CardBase";
import { getClientStatusBasedOnDocs } from "../../utils/ClientUtils";

type MyProps = {
  client: Client;
  onClick: () => any;
};

export const Tag: React.FC<{
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode | string | undefined;
}> = ({ children, className, style, ...rest }) => {
  return (
    <div
      className={
        "flex flex-row justify-center py-2 px-4 rounded text-white " +
        (className ?? "")
      }
      style={{
        ...style,
      }}
      {...rest}
    >
      {typeof children === "string" ? <p>{children}</p> : children}
    </div>
  );
};
export const ClientStatusTag: React.FC<{
  status: ClientStatusEnum;
  className?: string;
}> = ({ status, className }) => {
  return (
    <div
      className={
        "justify-center py-1 px-4 rounded text-white " + (className ?? "")
      }
      style={{
        backgroundColor: Client.getColorState(status),
      }}
    >
      {Client.getTranslateState(status)}
    </div>
  );
};

const ClientCard: React.FC<MyProps> = ({ onClick, client }) => {
  return (
    <CardBase
      onClick={onClick}
      style={{
        backgroundColor: Colors.White,
        borderColor: Colors.Gray3,
      }}
      left={
        <div className="flex flex-col mb-1 justify-start gap-1 text-base">
          <h2 className="text-sm font-normal" style={{ color: Colors.Gray3 }}>
            #{client.id}
          </h2>
          <h1 className="text-lg font-medium" style={{ color: Colors.Gray2 }}>
            {client.name}
          </h1>
          <div className="flex flex-row whitespace-pre">
            <h3 className="font-light" style={{ color: Colors.Gray3 }}>
              Documento:{" "}
            </h3>
            <p className="font-normal" style={{ color: Colors.Gray2 }}>
              {formatCPF(client.document)}
            </p>
          </div>
          <div className="flex flex-row whitespace-pre mb-3">
            <h3 className="font-light" style={{ color: Colors.Gray3 }}>
              Criado em:{" "}
            </h3>
            <p className="font-normal" style={{ color: Colors.Gray2 }}>
              {moment(client.create_at).format("DD/MM/YYYY")}
            </p>
          </div>
          <div className="flex flex-row">
            <ClientStatusTag status={getClientStatusBasedOnDocs(client)} />
          </div>
        </div>
      }
      right={
        <div className="flex flex-col justify-between h-full">
          <p className="text-base text-right">{Client.typeABRV(client.type)}</p>
          <div className="flex flex-row justify-end">
            {/* <div className='rounded border border-gray-300 px-5 mr:px-7 py-1 justify-center cursor-pointer' onClick={() => onClick()}>
                            <img src={Icons.IdentityInfoIcon} alt='IdentityInfoIcon' style={{ width: '20px', height: '20px' }}></img>
                        </div> */}
          </div>
        </div>
      }
    />
  );
};

export default ClientCard;
