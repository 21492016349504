import { DepositMethodsEnum } from "../models/enums/DepositMethods";

export const depositOptions = [
  {
    enable: true,
    type: DepositMethodsEnum.PIX,
    subLabel: "Depositar saldo via pix.",
  },
  // { enable: true, type: DepositMethodsEnum.TED, subLabel: 'Depositar saldo através de uma conta bancária.' },
  // { enable: false, type: DepositMethodsEnum.CREDIT, subLabel: 'Depositar saldo usando cartão de crédito.' },
  // { enable: false, type: DepositMethodsEnum.BITCOIN, subLabel: 'Depositar saldo via wallet bitcoin.' },
  // { label: 'Ethereum (ETH)', subLabel: 'Depositar saldo via wallet ethereum' },
];
