import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
/// Assets
import Colors from "../../../assets/Colors";
import AlertModal from "../../../components/Modal/AlertModal";
import PageSkeleton from "../../../components/PageSkeleton/PageSkeleton";
import ModalContext from "../../../contexts/ModalProvider";
import { ClientStatusEnum, UserStatusEnum } from "../../../models";
import RoutesPaths from "../../../Routes/RoutesPaths";
import history from "../../../services/history";
import {
  getClientStatusBasedOnDocs,
  checkClientAccountIncomplete,
} from "../../../utils/ClientUtils";
import * as API from "./../../../api";
import * as Gifs from "./../../../assets/gifs";
import * as Icons from "./../../../assets/icons";
import Modal from "../../../components/Modal/Modal";
import { balance } from "../../../models/balance";
import { formatToCurrency } from "../../../utils/formatters";
import ClientDetailsFilterBar from "./ClientDetailsFilterBar/ClientDetailsFilterBar";

interface CardProps {
  title: string | JSX.Element;
  description: string;
  route?: RoutesPaths | string;
  onClick?: () => any;
  alert?: boolean;
  accountIncomplete?: boolean;
  loading?: boolean;
}
const Card: React.FC<CardProps> = ({
  title,
  description,
  route,
  onClick,
  alert,
  accountIncomplete,
  loading,
}) => {
  const renderCardBody = () => (
    <>
      <div className="flex flex-col flex-grow">
        <h3 className="font-medium text-lg" style={{ color: Colors.Gray2 }}>
          {title}
        </h3>
        {accountIncomplete ? (
          <p className="text-xs font-bold" style={{ color: Colors.Red4 }}>
            Cadastro incompleto
          </p>
        ) : (
          <p className="font-light" style={{ color: Colors.Gray3 }}>
            {description}
          </p>
        )}
      </div>
      {alert && (
        <div className="flex flex-col justify-center">
          <img
            src={Icons.AlertIcon}
            alt="AlertIcon"
            style={{ height: "25px", width: "25px" }}
          />
        </div>
      )}
    </>
  );

  return (
    <>
      {loading ? (
        <div className="bg-gray-200 px-3 py-2 rounded animate-pulse w-full lg:w-2/5 h-16 my-1"></div>
      ) : accountIncomplete ? (
        <div
          className="px-3 py-2 rounded border flex flex-row w-full lg:w-2/5 my-1"
          style={{ borderColor: Colors.Gray4 }}
        >
          {renderCardBody()}
        </div>
      ) : (
        <Link
          className={
            "bg-white px-3 py-2 rounded border flex flex-row w-full lg:w-2/5 my-1"
          }
          style={{ borderColor: Colors.Gray4 }}
          to={route ? route : "/conta"}
          onClick={onClick}
        >
          {renderCardBody()}
        </Link>
      )}
    </>
  );
};

interface ShowModalArgs {
  title: string;
  confirmText: string;
  confirmColor: string;
  text1: string;
  text2: string;
  newStatus: ClientStatusEnum.SUSPENDED | ClientStatusEnum.ACTIVE;
}

const ClientDetailsPage: React.FC<any> = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [clientDetails, setClientDetails] = useState<any>(undefined);
  const [isClientDetailsLoading, setIsClientDetailsLoading] =
    useState<boolean>(false);
  const [clientBalance, setClientBalance] = useState<balance>();
  const { handleModal, setModal } = useContext(ModalContext);

  useEffect(() => {
    loadClientDetails();
    API.admin
      .loadClientBalance(clientId)
      .then((data) => setClientBalance(data));
  }, []);

  const loadClientDetails = async () => {
    try {
      setIsClientDetailsLoading(true);
      const data = await API.admin.loadClient(clientId);
      setClientDetails(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsClientDetailsLoading(false);
    }
  };

  const renderClientStatusHeader = () => {
    let text1: string = "";
    let text2: string = "";
    let backgroundColor: string = "";

    switch (getClientStatusBasedOnDocs(clientDetails)) {
      case ClientStatusEnum.ANALYSIS:
        text1 = "Conta em processo de análise";
        text2 = "Clique para verificar os itens pendentes de análise";
        backgroundColor = Colors.Blue1;
        break;
      case ClientStatusEnum.CERTIFICATE:
        text1 = "Esta conta está certificada!";
        text2 = "Todos os processos de verificação estão realizados";
        backgroundColor = Colors.Green3;
        break;
      case ClientStatusEnum.SUSPENDED:
        text1 = "Esta conta está suspensa!";
        text2 = "Clique para remover a suspensão desta conta";
        backgroundColor = Colors.Orange2;
        break;
      case ClientStatusEnum.CREATED:
        text1 = "Certificação pendente";
        text2 = "Clique para visualizar o status das verificações";
        backgroundColor = Colors.Red5;
        break;
      default:
        return <></>;
    }

    return !isClientDetailsLoading ? (
      <button
        className="my-2 py-3 px-4 rounded border flex flex-col w-full lg:w-2/5 items-center justify-center"
        style={{
          backgroundColor,
          cursor: "pointer",
        }}
        onClick={() =>
          history.push(`${RoutesPaths.ADMIN_CLIENTS_DOCUMENTS}/${clientId}`)
        }
      >
        <p className="text-white font-medium md:text-base py-1">{text1}</p>
        <p className="text-white md:text-sm py-1">{text2}</p>
      </button>
    ) : (
      <div className="rounded animate-pulse w-full lg:w-2/5 bg-gray-200 h-20"></div>
    );
  };

  const handleUserActionClick = () => {
    if (clientDetails.status === ClientStatusEnum.SUSPENDED) {
      showConfirmUserStatusModal({
        title: "Reativar esta conta?",
        confirmText: "REATIVAR",
        confirmColor: Colors.Green3,
        text1:
          "Após reativar a conta o usuário poderá voltar acessar sua conta normalmente.",
        text2: "Deseja confirmar a reativação da conta?",
        newStatus: clientDetails.oldStatus,
      });
    } else {
      showConfirmUserStatusModal({
        title: "Suspender conta?",
        confirmText: "SUSPENDER",
        confirmColor: Colors.Red,
        text1:
          "Após suspender a conta o usuário será notificado por email e não poderá mais acessar a plataforma enquanto a sua conta estiver suspensa.",
        text2: "Você poderá remover a suspensão da conta posteriormente.",
        newStatus: ClientStatusEnum.SUSPENDED,
      });
    }
  };

  const showConfirmUserStatusModal = ({
    title,
    confirmText,
    confirmColor,
    text1,
    text2,
    newStatus,
  }: ShowModalArgs) => {
    handleModal(Modal, {
      title,
      titleColor: Colors.Gray1,
      state: true,
      width: "lg:w-4/12",
      src: Gifs.IconAlertGif,
      confirmText,
      confirmColor,
      confirmButtonClick: () => handleUpdateUserStatus(newStatus),
      cancelButtonClick: () => setModal(false),

      children: (
        <div
          className="flex flex-col justify-center text-center items-center"
          style={{ color: Colors.Gray3 }}
        >
          <span className="mb-5">{text1}</span>
          <span>{text2}</span>
        </div>
      ),
    });
  };

  const handleUpdateUserStatus = async (status: ClientStatusEnum) => {
    try {
      await API.user.updateUserStatus(clientId, status);

      let modalTitle: string;
      let bodyText: string;
      // Ajustar lógica abaixo se o status nessa funcionalidade tenha que ter outras alteraçoes alem de SUSPENDED ou ACTIVE
      if (status === ClientStatusEnum.SUSPENDED) {
        modalTitle = "A conta foi suspensa";
        bodyText =
          "Você poderá alterar o status dessa conta novamente acessando a página de dados da conta.";
      } else {
        modalTitle = "A conta foi reativada";
        bodyText = "O usuário já pode voltar acessar sua conta novamente.";
      }

      handleModal(AlertModal, {
        src: Gifs.IconOkGif,
        title: modalTitle,
        confirmClick: () => window.location.reload(),
        children: <h3 className="my-6">{bodyText}</h3>,
      });
    } catch (error) {
      handleModal(AlertModal, {
        src: Gifs.IconAlertGif,
        title: "Erro ao atualizar status da conta",
      });
    }
  };

  const getBalanceTitle = (): JSX.Element => {
    const titleColor = accountIsIncomplete ? Colors.Gray4 : Colors.Gray2;
    const subContentColor = accountIsIncomplete ? Colors.Gray4 : Colors.Green3;

    return (
      <span style={{ color: subContentColor }}>
        <span style={{ color: titleColor }}>Saldo em conta: </span>
        {clientBalance ? (
          <span>{formatToCurrency(clientBalance?.currency.available)}</span>
        ) : (
          <svg
            className="animate-spin w-6 h-6 inline"
            fill="none"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 3.5C6.41015 3.5 3.5 6.41015 3.5 10C3.5 10.4142 3.16421 10.75 2.75 10.75C2.33579 10.75 2 10.4142 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C9.58579 18 9.25 17.6642 9.25 17.25C9.25 16.8358 9.58579 16.5 10 16.5C13.5899 16.5 16.5 13.5899 16.5 10C16.5 6.41015 13.5899 3.5 10 3.5Z"
              fill={subContentColor}
            />
          </svg>
        )}

        <span className="font-light"> BRL</span>
      </span>
    );
  };

  const getTokenWalletTitle = (): JSX.Element => {
    const titleColor = accountIsIncomplete ? Colors.Gray4 : Colors.Gray2;

    return <span style={{ color: titleColor }}>Carteira de tokens</span>;
  };

  const accountIsIncomplete = checkClientAccountIncomplete(clientDetails);

  return (
    <>
      <PageSkeleton
        isAdmin
        goBackBtn
        filterBar={<ClientDetailsFilterBar clientName={clientDetails?.name} />}
        useContentCard={false}
        button={
          clientDetails && {
            label:
              clientDetails?.status === ClientStatusEnum.SUSPENDED
                ? "Reativar conta"
                : "Suspender conta",
            bgColor:
              clientDetails?.status === ClientStatusEnum.SUSPENDED
                ? Colors.Green3
                : undefined,
            onClick: handleUserActionClick,
          }
        }
        button2={{
          label: "Voltar à listagem",
          onClick: history.goBack,
        }}
      >
        <div className="flex flex-col items-center sm:mt-10 w-full">
          <div
            className="rounded-full h-32 w-32 flex items-center justify-center "
            style={{ backgroundColor: Colors.Gray4 }}
          >
            <p className="text-7xl text-white font-medium">
              {clientDetails?.name ? clientDetails?.name[0].toUpperCase() : "U"}
            </p>
          </div>
          <h1
            className="mt-2 text-3xl font-bold text-center"
            style={{ color: Colors.Gray1 }}
          >
            {!isClientDetailsLoading ? (
              clientDetails?.name
            ) : (
              <div className="rounded animate-pulse w-40 bg-gray-200 h-5 mt-1"></div>
            )}
          </h1>
          <h3
            className="my-3 text-base sm:text-xl font-normal text-center"
            style={{ color: Colors.Gray4 }}
          >
            {!isClientDetailsLoading ? (
              `#${clientDetails?.id}`
            ) : (
              <div className="rounded animate-pulse w-80 bg-gray-200 h-5 my-2"></div>
            )}
          </h3>

          <div className="w-full flex flex-row items-center justify-center px-5">
            {renderClientStatusHeader()}
          </div>
          <br />

          <div className="flex flex-col w-full px-5 items-center">
            <Card
              loading={isClientDetailsLoading}
              accountIncomplete={accountIsIncomplete}
              title={getBalanceTitle()}
              description="Veja os detalhes do saldo e movimentações financeiras do cliente"
              route={RoutesPaths.ADMIN_CLIENTS_BALANCE + "/" + clientId}
            />
            <Card
              loading={isClientDetailsLoading}
              accountIncomplete={accountIsIncomplete}
              title={getTokenWalletTitle()}
              description="Consulte o balanço da carteira de tokens do cliente"
              route={RoutesPaths.ADMIN_CLIENTS_WALLET + "/" + clientId}
            />
            <Card
              title="Ordens"
              description="Veja o histórico de negociação do cliente"
              route={RoutesPaths.ADMIN_CLIENTS_ORDERS + "/" + clientId}
            />
            <Card
              title="Limites e taxas"
              description="Gerencie os limites e taxas de utilização do cliente"
              route={RoutesPaths.ADMIN_CLIENTS_LIMITS_TAXES + "/" + clientId}
            />
            <Card
              title="Contas de Depósitos e Saques"
              description="Veja as contas de depósitos e saques do cliente"
              route={RoutesPaths.ADMIN_CLIENTS_BANK_ACCOUNTS + "/" + clientId}
            />
            <Card
              title="Dados de cadastro"
              description="Veja os dados de cadastro da conta"
              route={RoutesPaths.ADMIN_CLIENTS_DATA + "/" + clientId}
            />
            <Card
              title="Endereço de cadastro"
              description="Veja o endereço de cadastro da conta"
              route={RoutesPaths.ADMIN_CLIENTS_ADDRESS + "/" + clientId}
            />
          </div>
        </div>
      </PageSkeleton>
    </>
  );
};

export default ClientDetailsPage;
