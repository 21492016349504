import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Colors, { GrayBackGroud } from "../../assets/Colors";
import * as Icons from "../../assets/icons";
import BarButton from "../../components/BarButton/BarButton";
import EmptyCard from "../../components/Cards/EmptyCard";
import MyOrderCard from "../../components/Cards/GoldOrderCard";
import Divider from "../../components/Divider/Divider";
import FilterBar from "../../components/FilterBar/FilterBar";
import Footer from "../../components/Footer/Footer";
import FilterInput from "../../components/Inputs/FilterInput/FilterInput";
import FilterModal from "../../components/Modal/FilterModal/FilterModal";
import OrderContext from "../../contexts/OrderProvider";
import UserContext from "../../contexts/UserProvider";
import { Order } from "../../models";
import {
  OrderStatusEnum,
  OrderTypeEnum,
  TokenTypeEnum,
  TokenTypeEnumInterpreter,
} from "../../models/enums";
import RoutesPaths from "../../Routes/RoutesPaths";
import history from "../../services/history";

const MyOrdersAdminPage: React.FC = () => {
  const userContext = useContext(UserContext);
  const orderContext = useContext(OrderContext);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [hasMore, sethasMore] = useState<boolean>(true);
  const [orders, setorders] = useState<Array<Order>>([]);
  const [currentActiveTab, setCurrentActiveTab] = useState<
    OrderTypeEnum | undefined
  >(undefined);
  const [filterStateIndex, setfilterStateIndex] = useState<
    OrderStatusEnum | "ALL"
  >("ALL");
  const [filterTypeIndex, setfilterTypeIndex] = useState<TokenTypeEnum | "ALL">(
    "ALL"
  );
  const [filtersCount, setFiltersCount] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let count = 0;
    if (filterTypeIndex !== "ALL") count++;
    if (filterStateIndex !== "ALL") count++;

    setFiltersCount(count);
  }, [filterTypeIndex, filterStateIndex]);

  async function loadOrders(
    status?: OrderStatusEnum,
    typeReceivable?: TokenTypeEnum,
    type?: OrderTypeEnum
  ) {
    // try {
    //   setLoading(true);
    //   const orders = await API.book.getAllOrders({
    //     status,
    //     type,
    //     typeReceivable,
    //     userId: userContext.user?.id,
    //   });
    //   setorders(orders);
    //   sethasMore(orders.length !== orders?.length);
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setLoading(false);
    // }
  }

  useEffect(() => {
    let state = filterStateIndex !== "ALL" ? filterStateIndex : undefined;
    let tokenType = filterTypeIndex !== "ALL" ? filterTypeIndex : undefined;
    loadOrders(state, tokenType, currentActiveTab);
  }, [filterStateIndex, filterTypeIndex, currentActiveTab]);

  async function showOrderDetails(order: Order) {
    orderContext.setOrder(order);
    history.push(RoutesPaths.ORDENDETALHES);
  }

  const renderFilterSection = () => (
    <>
      <FilterModal
        filterBtnText="Filtrar ordens"
        state={modalIsOpen}
        title="Filtrar Ordens"
        subTitle="Selecione as caracteristicas e clique no botão"
        onFilterClick={handleFilterSearch}
        onClearFiltersClick={clearFilters}
        onCancelClick={() => setModalIsOpen(false)}
      >
        <FilterInput
          className="w-full"
          inputBoxClassName="filter-modal-input"
          label="Token"
          placeholder=""
          value={filterTypeIndex}
          type="select"
          onChange={(selectedOption) =>
            setfilterTypeIndex(selectedOption.target.value)
          }
          options={[
            { label: "Todos os tokens", value: "ALL" },
            {
              label: TokenTypeEnumInterpreter.getSigla(TokenTypeEnum.GOLD),
              value: TokenTypeEnum.GOLD,
            },
          ]}
          icon={Icons.ArrowIcon}
        />
        <FilterInput
          className="w-full"
          inputBoxClassName="filter-modal-input"
          label="Status"
          placeholder=""
          value={filterStateIndex}
          type="select"
          onChange={(selectedOption) =>
            setfilterStateIndex(selectedOption.target.value)
          }
          options={[
            { label: "Todos os status", value: "ALL" },
            { label: "Ativo", value: OrderStatusEnum.ACTIVE },
            { label: "Inativo", value: OrderStatusEnum.INACTIVE },
            { label: "Cancelado", value: OrderStatusEnum.CANCELED },
            { label: "Realizado", value: OrderStatusEnum.FULFILLED },
            { label: "Suspenso", value: OrderStatusEnum.SUSPENDED },
            { label: "Expirado", value: OrderStatusEnum.EXPIRED },
          ]}
          icon={Icons.ArrowIcon}
        />
      </FilterModal>

      <section className="flex flex-row gap-6 py-7 px-5">
        <FilterInput
          inputBoxClassName="filter-section-input"
          className="w-3/5"
          placeholder="Pesquisar ordens por ID ou Usuário"
          icon={Icons.SearchIcon}
          onIconClick={handleFilterSearch}
        />
        <FilterInput
          inputBoxClassName="filter-section-input"
          textColorActive={!!filtersCount}
          placeholder={filtersCount ? `Filtrar (${filtersCount})` : "Filtrar"}
          icon={Icons.FilterIcon}
          onClick={() => setModalIsOpen(true)}
        />
      </section>
      <Divider top="mt-0" bottom="mb-2" />
    </>
  );

  const handleFilterSearch = () => {
    setModalIsOpen(false);
  };

  const clearFilters = () => {
    setfilterStateIndex("ALL");
    setfilterTypeIndex("ALL");
  };

  return (
    <>
      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: GrayBackGroud }}
        >
          <FilterBar>
            <BarButton
              state={currentActiveTab === undefined}
              onClick={() => setCurrentActiveTab(undefined)}
              borderColor={Colors.Purple4}
            >
              Todas ordens
            </BarButton>
            {/* <BarButton
              state={currentActiveTab === OrderTypeEnum.buy}
              onClick={() => setCurrentActiveTab(OrderTypeEnum.buy)}
              borderColor={Colors.Purple4}
            >Compra</BarButton>
            <BarButton
              state={currentActiveTab === OrderTypeEnum.sell}
              onClick={() => setCurrentActiveTab(OrderTypeEnum.sell)}
              borderColor={Colors.Purple4}
            >Venda</BarButton> */}
          </FilterBar>

          {renderFilterSection()}

          <div className="mx-5 flex flex-col">
            {/* <FilterRow className='ml-2' title='Filtrar ordens: '>
                        <FilterElement 
                            states={[
                                { label: 'Todos status', value: undefined },
                                { label: 'Em andamento', value: OrderStatusEnum.ACTIVE },
                                { label: 'Executada', value: OrderStatusEnum.FULFILLED },
                                { label: 'Cancelada', value: OrderStatusEnum.CANCELED },
                                { label: 'Expirada', value: OrderStatusEnum.INACTIVE },
                            ]} 
                            currentState={filterStateIndex} 
                            updateCurrent={({ value }) => {
                                setfilterStateIndex(value);
                            }}
                        />
                        <FilterElement 
                            states={[
                                { label: 'Todos tipos', value: undefined },
                                { label: 'Recebíveis de Cartão', value: TokenTypeEnum.GOLD },
                                { label: 'Outros tipos', value: undefined },
                            ]} 
                            currentState={filterTypeIndex} 
                            updateCurrent={({ value }) => {
                                setfilterTypeIndex(value);
                            }}
                        />
                    </FilterRow>
                    
                    <Divider full={true} mx={0} top={'mt-0'} bottom={'mb-0'}/> */}

            <div className="ml-2 my-4">
              <h3 style={{ color: Colors.Gray2 }} className="text-lg">
                {currentActiveTab === undefined
                  ? "Últimas ordens"
                  : currentActiveTab === OrderTypeEnum.buy
                  ? "Ordens de compra"
                  : "Ordens  de venda"}
              </h3>
              {/* <p>{`Total: ${formatToCurrency(orders.reduce((atual, { amount }) => amount + atual, 0))} BRL`}</p> */}
            </div>

            {orders.length > 0 ? (
              <InfiniteScroll
                dataLength={orders.length}
                next={() => {
                  let state =
                    filterStateIndex !== "ALL" ? filterStateIndex : undefined;
                  let tokenType =
                    filterTypeIndex !== "ALL" ? filterTypeIndex : undefined;
                  loadOrders(state, tokenType, currentActiveTab);
                }}
                hasMore={hasMore}
                loader={
                  <p style={{ textAlign: "center" }}>
                    <h4>Loading...</h4>
                  </p>
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Todos os registros exibidos</b>
                  </p>
                }
              >
                {orders
                  .sort(
                    (a, b) => b.updateDate.getTime() - a.updateDate.getTime()
                  )
                  .map((order) => {
                    return (
                      <MyOrderCard
                        order={order}
                        blackTheme={false}
                        detailClick={() => showOrderDetails(order)}
                        key={order.id}
                      />
                    );
                  })}
              </InfiniteScroll>
            ) : (
              <EmptyCard>
                <div className="flex flex-col items-center">
                  <strong style={{ color: Colors.Gray3 }}>
                    {isLoading
                      ? "Carregando ordens..."
                      : "Nenhuma ordem encontrada"}
                  </strong>
                </div>
              </EmptyCard>
            )}
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MyOrdersAdminPage;
