import api from "../services/api";

export const bank = {
  async getDeposit(): Promise<bank.deposit> {
    try {
      const { status, data } = await api.exchange.get("/bank/deposit");

      if (status === 200) {
        return {
          bank: data.bank,
          bankAccount: data.bankAccount,
          bankAgency: data.bankAgency,
          bankNumber: data.bankNumber,
          favored: data.favored,
          document: data.document,
        };
      } else {
        throw new Error(data);
      }
    } catch (error) {
      throw error;
    }
  },
  async postWithdraw({ amount }: { amount: number }): Promise<null> {
    try {
      const { status, data } = await api.exchange.post("/bank/withdraw", {
        amount,
      });

      if (status === 200 || status === 201) {
        return null;
      } else {
        throw new Error(data);
      }
    } catch (error: any) {
      throw new Error(error);
    }
  },
  async getQRCode(amount: string): Promise<any> {
    try {
      const { status, data } = await api.exchange.post("/bank/qrcode", {
        amount: Number(amount),
      });

      if (status === 200 || status === 201) {
        return data;
      } else {
        throw new Error(data);
      }
    } catch (error) {}
  },
};

// eslint-disable-next-line no-redeclare
export namespace bank {
  export type deposit = {
    bank: string;
    bankAccount: string;
    bankAgency: string;
    bankNumber: string;
    favored: string;
    document: string;
  };
}
