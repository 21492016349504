import React from "react";

import history from "../../services/history";
import ModalCore from "./ModalCore";
import Divider from "../Divider/Divider";
import { makeLogout } from "../../services/jwt";
import RoutesPaths from "../../Routes/RoutesPaths";
import Colors, { AzulEmAndamento } from "../../assets/Colors";

type MyProps = {
  state: boolean;
  changeState(state: boolean): any;
  clickExit(): any;
  email: string;
};

const AccoutTolltip = ({ state, email, changeState, clickExit }: MyProps) => {
  // const [ mynode ] = useState(React.createRef<HTMLDivElement>());

  function toAccout() {
    changeState(false);
    history.push(RoutesPaths.ACCOUT);
  }

  // const handleClick = () => {
  //     changeState(!state);
  // }
  // const handleOutsideClick = (e) => {
  //     if(!mynode.current?.contains(e.target)) {
  //         handleClick();
  //     }
  // }

  const handleClickExit = () => {
    clickExit();
    changeState(false);
  };

  return (
    <ModalCore
      state={state}
      backBlur={false}
      replaceClassName={true}
      changeState={changeState}
      className="
                inline-block
                align-bottom
                bg-white
                rounded-lg
                overflow-hidden
                shadow-xl
                transform
                transition-all
                absolute top-20 right-10 border
            "
    >
      <div className="flex flex-col w-52 px-4 pt-5 pb-4 justify-center text-center">
        <p style={{ color: AzulEmAndamento }}>{email}</p>
        <Divider top="mt-3" bottom="mb-3" full={true} />
        <button onClick={() => toAccout()} style={{ color: Colors.Gray3 }}>
          Minha conta
        </button>
        <Divider top="mt-3" bottom="mb-3" full={true} />
        <button onClick={handleClickExit} style={{ color: Colors.Gray3 }}>
          Sair
        </button>
      </div>
    </ModalCore>
    // <div className="fixed z-10 inset-0 overflow-y-auto" style={{ display: state ? 'block' : 'none'}} onClick={handleOutsideClick}>
    //     <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    //         <div className="fixed inset-0 transition-opacity" aria-hidden="true">
    //             <div className="absolute inset-0"></div>
    //         </div>

    //         {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
    //         <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    //         <div
    //             className="inline-block align-bottom bg-white rounded-lg overflow-hidden shadow-xl transform transition-all absolute top-20 right-10 border"
    //             style={{ borderColor: Gray4}}
    //             role="dialog"
    //             aria-modal="true"
    //             aria-labelledby="modal-headline"
    //             ref={mynode}
    //         >
    //             <div className='flex flex-col w-52 px-4 pt-5 pb-4 justify-center text-center'>
    //                 <p style={{ color: AzulEmAndamento }}>{ email }</p>
    //                 <Divider top='mt-3' bottom='mb-3' full={true} />
    //                 <button onClick={() => toAccout()} style={{ color: Colors.Gray3 }}>Minha conta</button>
    //                 <Divider top='mt-3' bottom='mb-3' full={true} />
    //                 <button onClick={() => {}} style={{ color: Colors.Gray3 }}>Sair</button>
    //             </div>
    //         </div>
    //     </div>
    // </div>
  );
};

AccoutTolltip.defaultProps = {};

export default AccoutTolltip;
