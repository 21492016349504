import moment from "moment";
import React, { CSSProperties } from "react";
import Colors from "../../assets/Colors";
import { InvoiceIcon, TrashIcon } from "../../assets/icons";
import { Order } from "../../models/order";
import { determineOrderStatusColor } from "../../utils/determineOrderStatusColor";
import { formatDecimalPlaces, formatToCurrency } from "../../utils/formatters";

type MyProps = {
  order: Order;

  showStatus?: boolean;
  blackTheme?: boolean;
  deleteClick?: () => any;
  detailClick?: () => any;
};

const GoldOrderCard: React.FC<MyProps> = ({
  detailClick,
  deleteClick,
  showStatus,
  blackTheme,
  order,
}) => {
  const buy: boolean = order.type === "buy";

  var text1: CSSProperties = {};
  var text2: CSSProperties = {};
  var text3: CSSProperties = {};
  var cardStyle: CSSProperties = {};
  var titleStyle: CSSProperties = {};
  var textStatus: CSSProperties = {};

  textStatus.color = determineOrderStatusColor(order.status);

  if (blackTheme) {
    cardStyle.backgroundColor = "black";
    text1.color = Colors.Gray4;
    text2.color = Colors.Gray5;
  } else {
    text1.color = Colors.Gray3;
    text2.color = Colors.Gray2;
    text3.color = Colors.Gray4;
    cardStyle.backgroundColor = "white";
  }

  if (buy) {
    titleStyle.color = Colors.Green3;
  } else {
    titleStyle.color = Colors.Red;
  }

  cardStyle.borderColor = Colors.Gray3;

  return (
    <div
      style={cardStyle}
      className={`border-05 rounded flex flex-row justify-between p-2 mb-4 `}
    >
      <div className="flex flex-col">
        <div className="flex flex-row mb-1 justify-start">
          <p style={titleStyle}>{Order.determineTypeText(order.type)}</p>
        </div>
        <div className="flex flex-row whitespace-pre my-1">
          <p style={text1} className="font-light">
            Valor:
          </p>
          <p style={{ ...text2, fontSize: "16px" }} className="font-medium">
            {formatToCurrency(order.amount * order.tokenQuantity).toString() +
              " "}
          </p>
          <p style={{ ...text3, fontSize: "16px" }} className="font-light">
            BRL{" "}
          </p>
        </div>
        <div className="flex flex-row whitespace-pre my-1">
          <p style={text1} className="font-light">
            Preço x Qtd:
          </p>
          <p
            style={{ ...text2, fontSize: "16px" }}
            className="font-medium"
          >{`${formatToCurrency(order.amount)} BRL (${formatDecimalPlaces(
            order.tokenQuantity
          )} ${order?.tokens[0]?.metadata?.properties?.unit || "OZG"})`}</p>
        </div>
        <div className="flex flex-row whitespace-pre my-1">
          <p style={text2} className="font-medium">
            <span style={text1} className="font-light">
              Data:{" "}
            </span>
            {moment(order.creationDate).format("DD/MM/YYYY HH:mm")}
          </p>
        </div>
        {showStatus && (
          <div className="flex flex-row whitespace-pre my-1 mt-2">
            <div
              className="justify-center py-1 px-4 rounded"
              style={{
                backgroundColor: Order.determineStatusColor(order.status),
              }}
            >
              <p className="text-white">
                {Order.determineStatusText(order.status)}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col justify-between">
        <div className="flex flex-row justify-end">
          {
            // condiçao "deleteClick &&" foi adicionada para desabilitar o botao de invoice por enquanto.
            // devemos remove-la quando voltarmos a funcionalidade
            deleteClick && (
              <div
                className="rounded border border-gray-300 px-5 mr:px-7 py-1 justify-center cursor-pointer"
                onClick={detailClick ?? deleteClick}
              >
                {detailClick ? (
                  <img
                    src={InvoiceIcon}
                    alt="InvoiceIcon"
                    style={{ width: "20px", height: "20px" }}
                  ></img>
                ) : (
                  <img
                    src={TrashIcon}
                    alt="TrashIcon"
                    style={{ width: "20px", height: "20px" }}
                  ></img>
                )}
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

GoldOrderCard.defaultProps = {
  blackTheme: true,
  showStatus: true,
};

export default GoldOrderCard;
