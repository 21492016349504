import React from "react";
import Colors from "../../assets/Colors";
import Footer from "../Footer/Footer";
import TopBar from "../TopBar/TopBar";
import Divider from "../Divider/Divider";
import BigButton from "../Buttons/BigButton";
import FilterBar from "../FilterBar/FilterBar";
import Button from "../Buttons/Button";
import UnderlineButton from "../Buttons/UnderlineButton";
import "./PageSkeleton.css";

interface MyProps {
  filterBar?: string | JSX.Element | Array<JSX.Element>;
  subTitle?: string | JSX.Element | Array<JSX.Element>;
  button?: {
    label: string;
    disabled?: boolean;
    bgColor?: string;
    onClick(): any;
  };
  button2?: {
    label: string;
    onClick(): any;
  };
  useContentCard?: boolean;
  loading?: boolean;
  goBackBtn?: boolean;
  children: JSX.Element | Array<JSX.Element> | any;
  isAdmin?: boolean;
}

const PageSkeleton: React.FC<MyProps> = ({
  filterBar,
  subTitle,
  button,
  button2,
  children,
  useContentCard,
  loading,
  goBackBtn,
  isAdmin,
}) => {
  if (button && !button?.bgColor) {
    button.bgColor = isAdmin ? Colors.Gold4 : Colors.UserBGButton;
  }

  return (
    <div className="flex flex-col min-h-screen text-sm text-gray-400 font-sans">
      {!isAdmin && <TopBar />}
      <main
        className="flex-grow bg-gray-200 w-full"
        style={{ backgroundColor: Colors.GrayBackGroud }}
      >
        {typeof filterBar === "string" && (
          <FilterBar goBackBtn={goBackBtn} title={filterBar} />
        )}
        {typeof filterBar !== "string" && filterBar}

        {subTitle ? (
          typeof subTitle === "string" && (
            <div className="p-5">
              <p>{subTitle}</p>
            </div>
          )
        ) : (
          <div className="p-3" />
        )}
        {typeof subTitle !== "string" && subTitle}

        <div
          className={`flex flex-col align-center items-center ${
            useContentCard
              ? "bg-white px-4 md:px-2 pt-8 mx-5 rounded border"
              : ""
          }`}
          style={{ borderColor: Colors.Gray4 }}
        >
          {children}

          {(button || button2) && <Divider top="mt-8" bottom="mb-0" />}
          {!button && !button2 && <div className="mt-8" />}

          {button && (
            <Button
              {...button}
              textColor={isAdmin ? Colors.White : Colors.ButtonText}
              backgroundColor={button.bgColor}
              className="skeleton-btn mt-8 md:mt-11"
              loading={loading}
            >
              {button.label}
            </Button>
          )}
          {button2 && (
            <UnderlineButton
              className={`${
                button ? " md:mt-11 md:mb-14" : " md:my-14"
              } text-lg font-medium`}
              style={{ color: Colors.Gray3 }}
              onClick={button2.onClick}
            >
              {button2.label}
            </UnderlineButton>
          )}
        </div>
        <br />
      </main>
      <Footer />
    </div>
  );
};

PageSkeleton.defaultProps = {
  useContentCard: false,
  isAdmin: false,
};

export default PageSkeleton;
