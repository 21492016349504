import { estados } from "../../constants/states";
import { IValidation } from "./IValidation";

export class UfValidation implements IValidation {
  private regexUF = /[A-Z]{2}/;
  private ufList = estados;
  private value: string = "";
  msgError = "Informe um estado válido.";

  isValid(value: string): boolean {
    if (!value) {
      this.value = "";
      return false;
    }
    this.value = value.trim().toUpperCase();
    return this.regexUF.test(this.value) && this.ufList.includes(this.value);
  }

  isNotValid(value: string): boolean {
    return !this.isValid(value);
  }

  getErrorMessage(): string {
    if (this.isValid(this.value) || this.value === "") {
      return "";
    }
    return this.msgError;
  }
}
