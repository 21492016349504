import React, { useContext } from "react";
import Colors from "../../assets/Colors";
import UserContext from "../../contexts/UserProvider";
import { UserRoleEnum } from "../../models/enums";

type MyProps = {
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  children: string | JSX.Element;

  onClick(): any;

  className?: string;
  disabled?: boolean;
};

const BigButton = ({
  backgroundColor,
  borderColor,
  textColor,
  children,
  onClick,
  className,
  disabled,
}: MyProps) => {
  const { user } = useContext(UserContext);

  // redefine a cor padrao para a cor padrao do user
  if (backgroundColor === Colors.Primary && user?.role === UserRoleEnum.USER) {
    backgroundColor = Colors.UserBGButton;
  }

  return (
    <button
      className={`custom-button font-medium rounded px-7 py-4 items-center w-80 shadow-xl ${className}`}
      style={{
        backgroundColor,
        opacity: disabled ? "30%" : "100%",
        cursor: disabled ? "not-allowed" : "pointer",
        borderColor,
        borderWidth: "1px",
        color: textColor,
      }}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      {children}
    </button>
  );
};

BigButton.defaultProps = {
  backgroundColor: Colors.Primary,
  borderColor: "rgba(0, 0, 0, 0.1)",
  textColor: Colors.ButtonText,
  disabled: false,
};

export default BigButton;
