import { DocumentsTypeEnum } from "../models/enums";
import {
  ClientDocStatusEnum,
  ClientDocumentStatusEnum,
  DocumentTypeEnum,
  UserStatusEnum,
  ClientStatusEnum,
  Client,
} from "../models";

export function getLoggedUserDocStatus(
  documentTypes: Array<DocumentsTypeEnum>,
  clientDetails
) {
  if (!clientDetails) return;

  let docStatus;
  for (let doc of clientDetails?.documentations) {
    for (let docType of documentTypes) {
      if (doc.type === docType) {
        if (doc.status !== ClientDocumentStatusEnum.APPROVED) {
          return doc.status === ClientDocumentStatusEnum.WAITING
            ? ClientDocStatusEnum.Waiting
            : ClientDocStatusEnum.Disapproved;
        }
        docStatus = ClientDocStatusEnum.Approved;
      }
    }
  }
  return docStatus;
}

export function getClientStatusBasedOnDocs(clientDetails): ClientStatusEnum {
  if (clientDetails?.status === ClientStatusEnum.SUSPENDED)
    return ClientStatusEnum.SUSPENDED;

  if (
    !clientDetails?.documentations ||
    clientDetails?.documentations?.length === 0
  )
    return ClientStatusEnum.CREATED;

  let approvedCount = 0;
  for (const doc of clientDetails?.documentations) {
    if (doc.status === ClientDocumentStatusEnum.WAITING) {
      return ClientStatusEnum.ANALYSIS;
    }

    if (doc.status === ClientDocumentStatusEnum.APPROVED) {
      approvedCount++;
    }
  }

  if (clientDetails.documentations.length < 3) return ClientStatusEnum.CREATED;

  if (approvedCount === clientDetails.documentations.length) {
    return ClientStatusEnum.CERTIFICATE;
  } else {
    return ClientStatusEnum.CREATED;
  }
}

export function checkClientAccountIncomplete(clientDetails: Client): boolean {
  return (
    clientDetails &&
    (!clientDetails.dataVerified ||
      !clientDetails.emailVerified ||
      !clientDetails.phoneVerified)
  );
}
