import { Order } from "./order";

export type match = {
  ask: number;
  tokenQuantity: number;
  id: string;
  token: string;
  fee: number;
  amount: number;
  createDate: Date;

  buyOrder: Order;
  buyOrderId: string;

  sellOrder: Order;
  sellOrderId: string;

  // Not present in MockServer
  buy: boolean;
  sell: boolean;
};

export namespace Book {
  export namespace order {
    // export type status = OrderStatusEnum;
    // export type typeReceivable = OrderTypeReceivableEnum;
    export type type = "buy" | "sell";
  }
}
