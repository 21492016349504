import React from "react";
import Colors from "../../assets/Colors";

type MyProps = {
  top?: string;
  bottom?: string;
  full?: boolean;
  mx?: 0 | 1 | 2 | 3 | 4 | 5;
  color?: string;
  className?: string;
};

const Divider = ({ top, bottom, full, mx, color, className }: MyProps) => {
  return (
    <div className="flex flex-row w-full">
      <hr
        className={[
          " bg-gray-300",
          `max-${mx}`,
          full ? "w-full" : "",
          top,
          bottom,
          className,
        ].join(" ")}
        style={{ borderColor: color }}
      />
    </div>
  );
};

Divider.defaultProps = {
  top: "mt-10",
  bottom: "mb-10",
  full: true,
  mx: 4,
  color: Colors.Gray4,
};

export default Divider;
