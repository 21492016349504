import React, { useState } from "react";

type MyProps = {
  state: boolean;
  children: React.ReactNode | Array<React.ReactNode>;
  className?: string;
  containerClassName?: string;
  changeState(state: boolean): any;

  replaceClassName?: boolean;
  backBlur?: boolean;
};

const ModalCore: React.FC<MyProps> = ({
  state,
  children,
  changeState,
  className,
  containerClassName,
  replaceClassName,
  backBlur,
}) => {
  const [mynode] = useState(React.createRef<HTMLDivElement>());

  const handleClick = () => {
    changeState(!state);
  };
  const handleOutsideClick = (e) => {
    if (!mynode.current?.contains(e.target)) {
      handleClick();
    }
  };

  return (
    <div
      className="fixed z-50 inset-0 overflow-y-auto"
      style={{ display: state ? "block" : "none" }}
      onClick={handleOutsideClick}
    >
      <div
        className={`flex items-center pt-4 px-4 pb-20 text-center sm:block sm:p-0 ${containerClassName}`}
      >
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div
            className={`absolute inset-0 ${
              backBlur ? "bg-black opacity-50" : ""
            }`}
          ></div>
        </div>

        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className={
            (!replaceClassName
              ? `
                        inline-block align-bottom
                        bg-white
                        rounded-lg
                        text-left
                        overflow-hidden
                        shadow-xl
                        transform
                        transition-all
                        sm:my-8 sm:align-middle -sm:w--80 lg:w-auto
                    `
              : "") +
            " " +
            className
          }
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          ref={mynode}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

ModalCore.defaultProps = {
  backBlur: true,
  containerClassName: "h-screen",
};

export default ModalCore;
