import React, { CSSProperties } from "react";
import { formatToCurrency } from "../../../utils/formatters";
import "./ResumeCard.css";

type MyProps = {
  textColor?: string;
  bgColor: string;
  title: string;
  value?: number;
  invert?: boolean;
  unit?: string;
  hiddenContent?: {
    content?: JSX.Element;
    showHiddenContent?: boolean;
  };
};

const ResumeCard = ({
  bgColor,
  textColor,
  title,
  value,
  invert,
  unit,
  hiddenContent,
}: MyProps) => {
  const style: CSSProperties = {};

  if (invert) {
    style.color = bgColor;
    style.borderColor = bgColor;
    style.backgroundColor = "";
  } else {
    style.backgroundColor = bgColor;
    style.borderColor = bgColor;
    style.color = textColor;
  }

  return (
    <div
      className="
                resume-card
                flex flex-col sm:flex-row lg:flex-col w-full lg:w-1/3
                rounded border-3 my-1 py-3 lg:py-6 px-3 lg:px-6 
                justify-between items-center whitespace-pre"
      style={style}
    >
      <p className="text-center font-normal md:text-2xl">{title}</p>
      {hiddenContent?.showHiddenContent ? (
        hiddenContent?.content
      ) : (
        <div className="card-value flex flex-row lg:mt-6 justify-center items-center">
          <p className="text-center font-medium">
            {value !== undefined ? formatToCurrency(value) : "-"}
          </p>
          <p className="text-center font-light"> {unit}</p>
        </div>
      )}
    </div>
  );
};

ResumeCard.defaultProps = {
  textColor: "white",
  unit: "BRL",
  hiddenContent: { showHiddenContent: false },
};

export default ResumeCard;
