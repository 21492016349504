import { Tax } from "../models";
import api from "../services/api";
import { TaxTypeEnum } from "../models/enums";

export const tax = {
  getTotalFees: async (): Promise<Map<TaxTypeEnum, Tax>> => {
    const { status, data } = await api.exchange.get(`/tax/total-fees`);

    if (status === 200) {
      return data.reduce(
        (map, fee) =>
          map.set(fee.type, {
            type: fee.type,
            value: fee.value,
          }),
        new Map<TaxTypeEnum, Tax>()
      );
    } else {
      throw data;
    }
  },
};
