import React, { useEffect } from "react";
import PageSkeleton from "../OnboardingPageSkeleton/OnboardingPageSkeleton";
import * as Gifs from "../../../../assets/gifs";
import Colors from "../../../../assets/Colors";
import * as API from "../../../../api";
import { getIdOnboarding } from "../../../../api";

const RegisterSuccessPage: React.FC<any> = () => {
  useEffect(() => {
    sendWelcomeEmail();
  }, []);

  const sendWelcomeEmail = async () => {
    try {
      const id = getIdOnboarding();
      if (!id) return;

      await API.signup.sendWelcomeEmail(id);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <PageSkeleton
      title="Cadastro concluído com sucesso!"
      subTitle=""
      nextPage={() => window.location.reload()}
      nextPageLabel="Acessar minha conta!"
      finalNote=""
    >
      <div className="flex flex-col items-center w-96 mb-12">
        <img
          src={Gifs.CadastroConcluidoGif}
          className="w-64"
          alt="piled gold plates"
        />
        <h5
          className="text-sm font-normal text-center"
          style={{ color: Colors.Gray3 }}
        >
          Verifique sua caixa de e-mail e siga as instruções para certificação
          da sua conta, suas negociações na plataforma serão habilitas somente
          após o envio dos documentos necessários. Certifique que a mensagem
          enviada não tenha ido para spam, nosso e-mail é no-reply@oz.inc
        </h5>
      </div>
    </PageSkeleton>
  );
};

export default RegisterSuccessPage;
