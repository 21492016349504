import React from "react";
import Modal from "../Modal/Modal";
import Colors from "../../assets/Colors";
import ModalCore from "../Modal/ModalCore";
import Button from "../Buttons/Button";
import Divider from "../Divider/Divider";

const TermsOfUseModal: React.FC<{
  isOpen: boolean;
  handleState(state: boolean): any;
}> = ({ isOpen, handleState }) => {
  return (
    <ModalCore
      state={isOpen}
      changeState={handleState}
      className="h-full my-2"
      containerClassName="h-full"
    >
      <div className="flex flex-col items-center h-full w-full">
        <h1
          className="text-2xl font-medium my-5 text-center"
          style={{ color: Colors.Gray2 }}
        >
          Termos de Uso
        </h1>
        <h2
          className="text-base font-normal text-center"
          style={{ color: Colors.Gray3 }}
        >
          Leia abaixo os termos de uso da plataforma
        </h2>
        <Divider top="mt-4" bottom="mb-4" />

        <h2
          className="text-lg font-normal text-center"
          style={{ color: Colors.Gray3 }}
        >
          Atualizado em DD/MM/AAAA
        </h2>
        <div
          className="flex flex-col gap-3 px-10 pt-12 overflow-y-scroll text-xl h-auto"
          style={{ color: Colors.Gray3 }}
        >
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>

          <p
            className="text-lg my-4 text-center"
            style={{ color: Colors.Gray4 }}
          >
            Ao confirmar o cadastro declaro estar ciente e de acordo com os{" "}
            <u>termos de uso</u> da plataforma.
          </p>
        </div>
        <Divider top="mt-4" bottom="mb-0" />
        <div className="flex-grow">
          <Button
            backgroundColor={Colors.UserBGButton}
            className="my-8 skeleton-btn"
            onClick={() => handleState(false)}
          >
            OK
          </Button>
        </div>
      </div>
    </ModalCore>
  );
};

export default TermsOfUseModal;
