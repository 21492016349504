import React, { useEffect, useState } from "react";
import Colors from "../../assets/Colors";
import ModalCore from "./ModalCore";

type MyProps = {
  state: boolean;

  children: JSX.Element | Array<JSX.Element>;

  title: string;
  titleColor: string;
  titleBgColor?: string;

  titleSize: "text-lg" | "text-2xl";

  confirmText: string;
  confirmColor: string;
  confirmButtonClick?: () => any;

  src?: string;
  iconSize?: "32" | "40";

  cancelText: string;
  cancelColor: string;
  cancelButtonClick?: () => any;

  width?: string;
  useDefaultFooter?: boolean;

  dialogClass?: string;
};

const Modal = ({
  state,
  children,
  title,
  titleColor,
  titleBgColor,
  titleSize,
  confirmText,
  confirmColor,
  confirmButtonClick,
  src,
  iconSize,
  cancelText,
  cancelColor,
  cancelButtonClick,
  width,
  useDefaultFooter,
  dialogClass,
}: MyProps) => {
  const [myState, setMyState] = useState<boolean>(state);

  useEffect(() => {
    setMyState(state);
  }, [state]);

  const cancel = (p: any) => {
    if (!cancelButtonClick) {
      setMyState(false);
    } else {
      cancelButtonClick();
    }
  };
  const confirm = (p: any) => {
    if (!confirmButtonClick) {
      setMyState(false);
    } else {
      confirmButtonClick();
    }
  };

  return (
    <ModalCore
      state={myState}
      changeState={(state) =>
        cancelButtonClick ? cancelButtonClick() : setMyState(state)
      }
      className={dialogClass + " " + width ?? "lg:w-4/12"}
    >
      {src && (
        <img
          src={src}
          className={`w-${Number(iconSize) - 4} lg:w-${iconSize} mx-auto mt-2`}
          alt=""
          height="90"
          width="90"
        />
      )}
      <h3
        style={{ color: titleColor, backgroundColor: titleBgColor }}
        className={`text-center py-4 leading-6 font-medium ${titleSize}`}
        id="modal-headline"
      >
        {title}
      </h3>

      <div className="text-sm mx-4 my-5">{children}</div>

      {/* Barra inferior */}
      {useDefaultFooter && (
        <>
          <hr />
          <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row justify-between">
            <button
              type="button"
              className={
                "w-full inline-flex justify-center px-4 py-2 text-base font-medium text-black sm:w-auto sm:text-sm "
              }
              style={{
                color: cancelColor,
              }}
              onClick={cancel}
            >
              {cancelText}
            </button>
            <button
              type="button"
              className={
                "w-full inline-flex justify-center px-4 py-2 text-base font-medium text-gray-500 sm:w-auto sm:text-sm "
              }
              style={{
                color: confirmColor,
              }}
              onClick={confirm}
            >
              {confirmText}
            </button>
          </div>
        </>
      )}
    </ModalCore>
  );
};

Modal.defaultProps = {
  confirmText: "SIM",
  confirmColor: Colors.Gray2,
  confirmButtonClick: () => {},

  cancelText: "CANCELAR",
  cancelColor: Colors.Gray4,
  cancelButtonClick: () => {},

  titleSize: "text-lg",
  titleBgColor: "white",
  useDefaultFooter: true,
  iconSize: "32",
};

export default Modal;
