import { UfValidation } from "./UfValidation";
import { CityValidation } from "./cityValidation";
import { NeighborhoodValidation } from "./neighborhoodValidation";
import { StreetValidation } from "./streetValidation";
import { StreetNumberValidation } from "./streetNumberValidation";
import { CpfValidation } from "./cpfValitation";
import { BirthDateValidation } from "./birthDateValidation";
import { NotEmptyValidation } from "./notEmptyValidation";
import { EmailValidation } from "./emailValidation";
import { PhoneValidation } from "./phoneValidation";

const instances = {
  uf: new UfValidation(),
  city: new CityValidation(),
  neighborhood: new NeighborhoodValidation(),
  street: new StreetValidation(),
  streetNumber: new StreetNumberValidation(),
  cpf: new CpfValidation(),
  birthDate: new BirthDateValidation(),
  notEmpty: new NotEmptyValidation(),
  email: new EmailValidation(),
  phone: new PhoneValidation(),
};

export default instances;
