import { user } from "../api";
import * as API from "./../api";
import { PlataformLimitEnum } from "../models/enums";
import React, { createContext, useState } from "react";

interface TaxesextData {
  limits: Map<PlataformLimitEnum, user.PlataformLimit>;
  updateLimits(forceUpdate: boolean): any;
  setLimits(p: any): any;
}

const LimitsContext = createContext<TaxesextData>({} as TaxesextData);

export const LimitsContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [limits, setLimits] = useState<
    Map<PlataformLimitEnum, user.PlataformLimit>
  >(new Map());

  const updateLimits = (forceUpdate = false) => {
    //TODO Colocar verificacao do size do map para so chamar se for 0, ou chamar quando force update for true
    if (limits.size === 0 || forceUpdate) {
      API.user
        .getLimits()
        .then((data) => {
          setLimits(data);
        })
        .catch(() => {});
    }
  };

  return (
    <LimitsContext.Provider value={{ limits, setLimits, updateLimits }}>
      {children}
    </LimitsContext.Provider>
  );
};

export default LimitsContext;
