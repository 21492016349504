import React, { CSSProperties, useState } from "react";
import Colors from "../../assets/Colors";
import "./style.css";

type MyProps = {
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  children: string | JSX.Element;

  onClick?: (e?: any) => any;

  className?: string;
  disabled?: boolean;
  big?: boolean;

  loading?: boolean;

  type?: "button" | "reset" | "submit";

  style?: CSSProperties;
};

const Loading: React.FC<{ textColor?: string }> = ({ textColor }) => (
  <div className="flex justify-center items-center relative">
    <svg
      className="animate-spin absolute w-8 h-8"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3.5C6.41015 3.5 3.5 6.41015 3.5 10C3.5 10.4142 3.16421 10.75 2.75 10.75C2.33579 10.75 2 10.4142 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C9.58579 18 9.25 17.6642 9.25 17.25C9.25 16.8358 9.58579 16.5 10 16.5C13.5899 16.5 16.5 13.5899 16.5 10C16.5 6.41015 13.5899 3.5 10 3.5Z"
        fill={textColor}
      />
    </svg>
  </div>
);

const Button: React.FC<MyProps> = ({
  big,
  backgroundColor,
  borderColor,
  textColor,
  children,
  onClick,
  className,
  disabled,
  loading,
  type,
  style,
}) => {
  const [ref] = useState(React.useRef<HTMLDivElement>(null));
  const mystyle: CSSProperties = {};

  if (loading) {
    disabled = true;
  }

  if (disabled) {
    mystyle.opacity = "50%";
    mystyle.cursor = "not-allowed";
  } else {
    mystyle.opacity = "100%";
    mystyle.cursor = "pointer";
  }

  if (style && big) {
    style.width = ""; // desativa o valor default do props
  }

  return (
    <button
      className={`custom-button rounded px-7 ${
        big ? "py-4 w-80" : "py-2 w-30"
      } items-center shadow-xl ${className ?? ""}`}
      style={{
        ...mystyle,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: borderColor ? "1px" : "0px",
        color: textColor,
        ...style,
      }}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      <div ref={ref}>
        {" "}
        {loading ? <Loading textColor={textColor} /> : children}{" "}
      </div>
    </button>
  );
};

Button.defaultProps = {
  backgroundColor: Colors.Primary,
  borderColor: "rgba(0, 0, 0, 0.1)",
  textColor: Colors.ButtonText,
  disabled: false,
  big: true,
  type: "button",

  style: {
    width: "100%",
  },
};

export default Button;
