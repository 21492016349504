const a = {
  API_ERROR_INVALID_DOC_NUMBER:
    "Número do documento do titular da conta inválido. Verifique e tente novamente.",
  API_ERROR_PARTNER_INFO_NOT_SEND:
    "Número do documento do sócio / administrador inválido. Verifique e tente novamente.",
  API_ERROR_INVALID_PARTNER_DOC_NUMBER:
    "Número do documento do sócio / administrador inválido. Verifique e tente novamente.",
  API_ERROR_BANK_NOT_FOUND:
    "Número do banco inválido. Verifique e tente novamente.",
  API_ERROR_USER_ALREADY_EXIST:
    "Erro de comunicação interna. Por favor recarregue a página e tente novamente.",
  API_ERROR_UNEXPECTED_BITID:
    "Erro de comunicação interna (Bit_ID). Por favor recarregue a página e tente novamente.",
  API_ERROR_UNEXPECTED_LOGIN_BITID:
    "Erro de comunicação interna (Bit_ID). Por favor recarregue a página e tente novamente.",
  API_ERROR_LOGIN_EXCHANGE:
    "Erro de comunicação interna. Por favor recarregue a página e tente novamente.",
  API_ERROR_UNEXPECTED:
    "Erro de comunicação interna. Por favor recarregue a página e tente novamente.",
  API_ERROR_EMAIL_ALREADY_REGISTERED: "Por favor, verifique suas credenciais",
  API_ERROR_USER_NOT_FOUND:
    "Algo inesperado aconteceu. Por favor recarregue a página e tente novamente",
  API_ERROR_INVALID_EMAIL_CONFIRMATION_CODE:
    "Código de confirmação de email inválido. Verifique e tente novamente.",
  API_ERROR_CALL_BLOCKED_BY_STATUS:
    "Algo inesperado aconteceu. Por favor recarregue a página e tente novamente.",
  "Docs does not match": "Os documentos enviados estão invalido",
  "Email not confirmed yet":
    "Algo inesperado aconteceu. Por favor recarregue a página e tente novamente.",
  USER_ALREADY_EXISTS: "Usuário já cadastrado na plataforma.",
};

export default a;
