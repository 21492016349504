import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import Colors, { Purple3 } from "../../../../assets/Colors";
import ResumeCard from "../../../../components/Cards/ResumeCard/ResumeCard";
import StatementsCard from "../../../../components/Cards/StatementsCard/StatementsCard";
import Divider from "../../../../components/Divider/Divider";
import ReceiveModal from "../../../../components/Modal/ReceiveModal";
import PageSkeleton from "../../../../components/PageSkeleton/PageSkeleton";
import ResumeBar from "../../../../components/ResumeBar/ResumeBar";
import ModalContext from "../../../../contexts/ModalProvider";
import { Stament } from "../../../../models";
import * as API from "./../../../../api";
import { balance } from "../../../../models/balance";
import ClientDetailsFilterBar from "../ClientDetailsFilterBar/ClientDetailsFilterBar";

const Staments: React.FC<{ isExtractTab: boolean }> = ({ isExtractTab }) => {
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [statements, setStaments] = useState<Array<Stament>>([]);
  const [modalReceipt, setModalReceipt] = useState<{
    state: boolean;
    statement: Stament;
  }>({ state: false, statement: {} as Stament });

  useEffect(() => {
    loadStaments();
  }, []);

  async function loadStaments() {
    // const newStaments = await API.user.getStaments();
    // setStaments(newStaments);
    // setHasMore(statements.length !== newStaments.length);
  }

  return (
    <>
      <ReceiveModal
        state={modalReceipt.state}
        title={"Detalhes da movimentação"}
        stament={modalReceipt.statement}
        onChange={() => setModalReceipt({ ...modalReceipt, state: false })}
      />

      <div className="flex flex-col items-start justify-start w-full ml-3 mb-4">
        <p className="font-normal text-lg" style={{ color: Colors.Gray2 }}>
          Movimentações da conta
        </p>
        <p className="font-normal text-base" style={{ color: Colors.Gray4 }}>
          Extrato de movimentações da conta
        </p>
      </div>

      <div style={{ display: "block", width: "100%" }}>
        {statements && (
          <InfiniteScroll
            dataLength={statements.length}
            next={() => {
              loadStaments();
            }}
            hasMore={hasMore}
            loader={
              <p style={{ textAlign: "center" }}>
                <h4>Carregando movimentações...</h4>
              </p>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Todos os registros exibidos</b>
              </p>
            }
          >
            {statements.map((statement) => (
              <StatementsCard
                statement={statement}
                onClick={() => {
                  setModalReceipt({
                    state: true,
                    statement,
                  });
                }}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
      <br />
    </>
  );
};

const ClientBalancePage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { handleModal, setModal } = useContext(ModalContext);
  const [clientBalance, setClientBalance] = useState<balance>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [clientDetails, setClientDetails] = useState<any>({});

  const loadClientDetails = async () => {
    const data = await API.admin.loadClient(clientId);
    setClientDetails(data);
  };

  useEffect(() => {
    setLoading(true);

    loadClientDetails();

    API.admin
      .loadClientBalance(clientId)
      .then((data) => setClientBalance(data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <PageSkeleton
      isAdmin
      goBackBtn
      filterBar={
        <ClientDetailsFilterBar
          clientName={clientDetails?.name}
          pageTitle="Saldo em conta"
        />
      }
    >
      <div className="flex flex-row w-full justify-center">
        <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
          {isLoading ? (
            <ResumeBar>
              <div
                className="md:flex-col md:w-1/3 border-2 my-1 py-0 px-3 md:p-6 
                                rounded animate-pulse w-full lg:w-2/5 bg-gray-200 h-7 md:h-36"
              ></div>

              <div
                className="md:flex-col md:w-1/3 border-2 my-1 py-0 px-3 md:p-6 
                                rounded animate-pulse w-full lg:w-2/5 bg-gray-200 h-7 md:h-36"
              ></div>

              <div
                className="md:flex-col md:w-1/3 border-2 my-1 py-0 px-3 md:p-6 
                                rounded animate-pulse w-full lg:w-2/5 bg-gray-200 h-7 md:h-36"
              ></div>
            </ResumeBar>
          ) : (
            <ResumeBar>
              <ResumeCard
                bgColor={Colors.Green2}
                textColor="white"
                title="Saldo disponível"
                value={clientBalance?.currency?.available ?? 0}
              />
              <ResumeCard
                bgColor={Colors.Blue1}
                textColor="white"
                title={"Saldo retido em negociação"}
                value={clientBalance?.currency?.consumed ?? 0}
              />
              <ResumeCard
                bgColor={Purple3}
                textColor="white"
                title={"Saldo total da conta"}
                value={clientBalance?.currency?.full ?? 0}
              />
            </ResumeBar>
          )}

          <Divider top="mt-2" bottom="mb-8" />

          <Staments isExtractTab={false} />
          {/* <UnderlineButton onClick={() => { }}>Ver mais movimentações</UnderlineButton> */}
        </div>
      </div>
    </PageSkeleton>
  );
};

export default ClientBalancePage;
