import React, { CSSProperties } from "react";
import Colors from "../../assets/Colors";

type MyProps = {
  state: boolean;
  borderColor?: string;
  textColor?: string;
  grow?: boolean;
  showBottomBorder?: boolean;
  btnClass?: string;
  style?: CSSProperties;
  onClick: () => any;
};

const BarButton: React.FC<React.PropsWithChildren<MyProps>> = ({
  state,
  borderColor,
  textColor,
  grow,
  showBottomBorder,
  children,
  btnClass,
  onClick,
  style,
}) => {
  const buttonStyle: CSSProperties = {};
  buttonStyle.outline = "none";

  if (state) {
    buttonStyle.color = textColor ? textColor : Colors.Gray2;
    buttonStyle.borderColor = borderColor ? borderColor : Colors.Gold4;
  } else {
    buttonStyle.color = Colors.Gray4;
    buttonStyle.borderColor = Colors.Gray5;
  }

  return (
    <button
      className={`
				font-medium pb-5
				${btnClass}
				${state || showBottomBorder ? "border-b-2" : ""}
				${grow ? "flex-grow" : ""}
			`}
      style={{ ...buttonStyle, ...style }}
      onClick={() => onClick()}
    >
      <span>{children}</span>
    </button>
  );
};

export default BarButton;
