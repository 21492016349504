import React, { CSSProperties } from "react";

interface MyProps {
  children: any;
  className?: string;
  onClick?: () => any;
  style?: CSSProperties;
}

const UnderlineButton: React.FC<MyProps> = ({
  children,
  className,
  onClick,
  style,
}) => {
  return (
    <div
      className={`my-5 items-center cursor-pointer ${className}`}
      style={style}
      onClick={onClick}
    >
      <p className="underline brightness-filter">{children}</p>
    </div>
  );
};
export default UnderlineButton;
