import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { createRef, useImperativeHandle, useRef, useState } from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import * as Yup from "yup";
import Colors, { GrayBackGroud } from "../../../assets/Colors";
import { StylePatterns } from "../../../assets/patterns_tailwind_class";
import Button from "../../../components/Buttons/Button";
import UnderlineButton from "../../../components/Buttons/UnderlineButton";
import Divider from "../../../components/Divider/Divider";
import FilterBar from "../../../components/FilterBar/FilterBar";
import Footer from "../../../components/Footer/Footer";
import CurrencyInput from "../../../components/Inputs/CurrencyInput";
import FloatInput from "../../../components/Inputs/FloatInput";
import FloatInputCore from "../../../components/Inputs/FloatInputCore/FloatInputCore";
import MaskedInput1 from "../../../components/Inputs/MaskedInput/MaskedInput1";
import Loader from "../../../components/Loader/Loader";
import AlertModal from "../../../components/Modal/AlertModal";
import { SelectMethod } from "../../../components/SelectMethod";
import TopBar from "../../../components/TopBar/TopBar";
import UnformInputAdapt from "../../../components/unform/UnformInputAdapt/UnformInputAdapt";
import { TokenTypeEnum, TokenTypeEnumInterpreter } from "../../../models/enums";
import { assetTypeMethods } from "../../../options/assetTypeMethods";
import RoutesPaths from "../../../Routes/RoutesPaths";
import history from "../../../services/history";
import { UnFormValidate, YupAdaptErrorToUnform } from "../../../utils/yupUtils";
import * as API from "../../../api";
import { FrequencyFeeGoldCustodyEnum, GoldTokenTypeEnum } from "../../../api";
import * as Icons from "../../../assets/icons";
import * as Gifs from "../../../assets/gifs";
import PageTopBanner from "../../../components/PageTopBanner/PageTopBanner";
import InlineInput from "../../../components/Inputs/InlineInput/InlineInput";
import { formatToCurrency } from "../../../utils/formatters";

const schema = Yup.object()
  .shape({
    internalBatchCode: Yup.string().required(),
    emissionDate: Yup.date().required(),
    endDate: Yup.date().required(),
    settlementDate: Yup.date().required(),
    // custodyTax: Yup.number().required(),
    quantity: Yup.number().required(),
  })
  .required();

const MyAssetsCreate: React.FC<any> = () => {
  const [formRef] = useState(useRef<FormHandles>(null));
  const [error, seterror] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const [_firstValidate, _setFirstValidate] = useState<boolean>(true);

  const handleForm = async (input: any) => {
    if (!formRef.current) return undefined;

    try {
      let [monthSettlementDate, daySettlementDate, yearSettlementDate] =
        input.settlementDate.split("/");
      input.settlementDate = `${daySettlementDate}/${monthSettlementDate}/${yearSettlementDate}`;
      let [monthEndDate, dayEndDate, yearEndDate] = input.endDate.split("/");
      input.endDate = `${dayEndDate}/${monthEndDate}/${yearEndDate}`;

      const data = await schema.validate(input, { abortEarly: false });

      await API.register.tokenizeGold({
        amount: data.quantity.toString(),
        certificationCode: data.internalBatchCode,
        description: "",
        feeCustody: "0", //data.custodyTax.toString(),
        frequencyFee: FrequencyFeeGoldCustodyEnum.MONTHLY,
        emissionDate: data.emissionDate,
        redemptionDate: data.endDate,
        redemptionDeadline: data.settlementDate,
        unit: GoldTokenTypeEnum.G,
      });
    } catch (error) {
      _setFirstValidate(false);
      if (error instanceof Yup.ValidationError) {
        // @ts-ignore
        formRef.current.setErrors(YupAdaptErrorToUnform(error));
      }
    }
  };

  return (
    <>
      <AlertModal
        state={loading}
        changeState={() => setloading(!loading)}
        title="Tokenização em andamento"
        confirmClick={() => {
          setloading(false);
          history.push(RoutesPaths.MEUSATIVOS + "/" + TokenTypeEnum.GOLD);
        }}
      >
        <div className="flex flex-col items-center w-100 sm:w-80 my-6">
          <Loader />
          <br />
          <p className="font-light text-center" style={{ color: Colors.Gray3 }}>
            O processo de tokenização já esta em andamento e poderá demorar
            algum tempo.
            <br />
            <br />
            Após a conclusão os tokens serão registrados na wallet de destino
          </p>
        </div>
      </AlertModal>
      <AlertModal
        title="Error"
        src={Gifs.IconAlertGif}
        state={error}
        changeState={() => seterror(false)}
      />
      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <TopBar />
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: GrayBackGroud }}
        >
          <FilterBar goBackBtn title="Tokenizar / Emitir novo lote" />

          <PageTopBanner
            title="SIGLA"
            subtitle="Nome do token"
            bgIcon={Icons.GoldTextureBgIcon}
            imgIcon={Icons.AUChartCardIcon}
          />

          <div
            className="bg-white px-2 py-1 mt-5 mx-5 flex flex-col aling-center items-center rounded border pt-5"
            style={{ borderColor: Colors.Gray4 }}
          >
            <div className={"" + StylePatterns.ELEMENT_WIDTH}>
              <Form
                onSubmit={handleForm}
                ref={formRef}
                className="flex flex-col w-full"
                onChange={() => {
                  if (!_firstValidate) {
                    UnFormValidate(formRef, schema);
                  }
                }}
                initialData={{
                  quantity: 1000,
                  certBatchCode: "123123",
                  internalBatchCode: "ABCDEFGHIJKLMNOPQRSTUVXZ",
                  note: "uma nota",
                  custodyTax: 10,
                  frequencyFee: FrequencyFeeGoldCustodyEnum.DAILY,
                  endDate: "01/02/2022",
                  settlementDate: "01/03/2022",
                  type: GoldTokenTypeEnum.G,
                }}
              >
                <p
                  className="my-3 font-normal text-xl text-center"
                  style={{ color: Colors.Gray3 }}
                >
                  DETALHES DO LOTE EMITIDO
                </p>
                {/* <UnformInputAdapt
								name='certBatchCode'
								children={({ ref, error, defaultValue }) =>
									<FloatInput
										big={true}
										className='px-2 py-2 my-3'
										placeholder='Código de certificação externo'
										ref={ref} error={error} defaultValue={defaultValue}
									/>
								}
							/> */}
                <UnformInputAdapt
                  name="emissionDate"
                  children={({ ref, error, defaultValue }) => (
                    <InlineInput
                      error={error}
                      type="date"
                      className="px-2 py-2 my-3"
                      label="Emissão:"
                      bottomText="*A data de emissão é registrada automaticamente pelo sistema no ato da emissão."
                      ref={ref}
                      children={() => (
                        <MaskedInput1
                          ref={ref}
                          mask={"99/99/9999"}
                          placeholder="DD/MM/AAAA"
                          defaultValue={defaultValue}
                          className="inline-input text-xl p-0 w-full appearance-none focus:outline-none"
                        />
                      )}
                    />
                  )}
                />
                <UnformInputAdapt
                  name="endDate"
                  children={({ ref, error, defaultValue }) => (
                    <InlineInput
                      error={error}
                      type="date"
                      className="px-2 py-2 my-3"
                      label="Resgate:"
                      bottomText="*Data de liberação dos ativos ou vantagens vinculadas ao token. (Deixe em branco caso não haja resgate)"
                      ref={ref}
                      children={() => (
                        <MaskedInput1
                          ref={ref}
                          mask={"99/99/9999"}
                          placeholder="DD/MM/AAAA"
                          defaultValue={defaultValue}
                          className="inline-input text-xl p-0 w-full appearance-none focus:outline-none"
                        />
                      )}
                    />
                  )}
                />
                <UnformInputAdapt
                  name="settlementDate"
                  children={({ ref, error, defaultValue }) => (
                    <InlineInput
                      error={error}
                      type="date"
                      className="px-2 py-2 my-3"
                      label="Validade:"
                      bottomText="*Data limite para realização do resgate dos tokens. (Deixe em branco caso não haja validade)"
                      ref={ref}
                      children={() => (
                        <MaskedInput1
                          ref={ref}
                          mask={"99/99/9999"}
                          placeholder="DD/MM/AAAA"
                          defaultValue={defaultValue}
                          className="inline-input text-xl p-0 w-full appearance-none focus:outline-none"
                        />
                      )}
                    />
                  )}
                />
                <UnformInputAdapt
                  name="internalBatchCode"
                  children={({ ref, error, defaultValue }) => (
                    <InlineInput
                      error={error}
                      type="text"
                      className="px-2 py-2 my-3"
                      placeholder="Código interno do lote"
                      bottomText="*Código para identificação interna dos ativos vinculados ao lote de tokens. (Campo opcional)"
                      ref={ref}
                      children={(ref) => {
                        return false;
                      }}
                    />
                  )}
                />
                <UnformInputAdapt
                  name="quantity"
                  children={({ ref, error, defaultValue }) => (
                    <InlineInput
                      error={error}
                      type="text"
                      className="px-2 py-2 my-3"
                      placeholder="Quantidade de tokens emitidos"
                      bottomText="*É a quantidade de tokens que serão criados nesta emissão."
                      ref={ref}
                      children={(ref) => {
                        return false;
                      }}
                    />
                  )}
                />
                {/* <UnformInputAdapt
								name='custodyTax' refPath='props'
								children={({ ref, error, defaultValue }) =>
									<FloatInputCore
										big={true}
										ref={ref} error={error}
										className='px-2 py-2 my-3'
										placeholder='Taxa de custódia (R$)'
										children={() => (
											<MaskedInput
												ref={ref}
												mask={createNumberMask({
													prefix: '',
        									suffix: '',
												})}
												value={100}
												defaultValue={defaultValue}
												onChange={({ target }) => {
													let aux = target.value.replaceAll('.', '').replaceAll(',', '.').split(' ')[0];
												}}
												className='block p-2 w-full appearance-none focus:outline-none'
											/>
										)}
									/>
								}
							/>
							<UnformInputAdapt
								name='valorLiquido'
								children={({ ref, error, defaultValue }) =>
									<FloatInput
										big={true}
										ref={ref} error={error} defaultValue={defaultValue}
										className='px-2 py-2 my-3'
										placeholder='Frequência da taxa (diária, semanal, Mensal, Ano)'
									/>
								}
							/> */}
                {/* <UnformInputAdapt
								name='quantity'
								children={({ ref, error, defaultValue }) =>
									<FloatInput
										big={true}
										ref={ref} error={error} defaultValue={defaultValue}
										className='px-2 py-2 my-3'
										placeholder='Quantidade de tokens gerados'
									/>
								}
							/> */}
                {/* <UnformInputAdapt
								name='note'
								children={({ ref, error, defaultValue }) =>
									<FloatInput
										big={true}
										ref={ref} error={error} defaultValue={defaultValue}
										className='px-2 py-2 my-3 h-32'
										placeholder='Detalhe do token...'
									/>
								}
							/> */}
              </Form>

              <p
                className="mt-12 mb-3 font-normal text-xl text-center"
                style={{ color: Colors.Gray3 }}
              >
                CUSTOS DE EMISSÃO
              </p>
              <div
                className="border rounded p-5"
                style={{
                  borderColor: Colors.Gray4,
                  backgroundColor: Colors.Gray7,
                }}
              >
                <ul className="flex flex-col gap-2">
                  <li className="text-xl" style={{ color: Colors.Gray3 }}>
                    <span className={"font-normal"}>Quantidade emitida: </span>
                    <span
                      className={"font-medium"}
                      style={{ color: Colors.Gray2 }}
                    >
                      {0} SIGLA
                    </span>
                  </li>
                  <li className="text-base" style={{ color: Colors.Purple4 }}>
                    <span className={"font-normal"}>Taxa de emissão: </span>
                    <span className={"font-medium"}>
                      {formatToCurrency(12000)} BRL
                    </span>
                  </li>
                  <li className="text-base" style={{ color: Colors.Green2 }}>
                    <span className={"font-normal"}>
                      Saldo atual do emissor:{" "}
                    </span>
                    <span className={"font-medium"}>
                      {formatToCurrency(12000)} BRL
                    </span>
                  </li>
                </ul>
              </div>

              <p
                style={{ color: Colors.Red5 }}
                className="my-12 font-light text-base text-center"
              >
                Atenção:
                <br />
                Verifique todos os dados antes de confirmar a emissão dos
                tokens. Após a criação, as informações de contrato dos tokens
                emitidos não poderão mais ser alteradas.
              </p>
            </div>

            <Divider full={true} top="mt-0" bottom="mb-0" />

            <Button
              className="text-lg mt-16 mb-8"
              backgroundColor={Colors.Gold4}
              textColor={Colors.White}
              onClick={() => {
                if (formRef.current) {
                  formRef.current.submitForm();
                  setloading(true);
                }
              }}
            >
              Confirmar emissão
            </Button>
            <UnderlineButton
              className="text-lg mb-16"
              style={{ color: Colors.Gray3 }}
              onClick={() => history.push(RoutesPaths.MEUSATIVOS)}
            >
              Cancelar emissão
            </UnderlineButton>
          </div>
          <br />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MyAssetsCreate;
