import { Form } from "@unform/web";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as API from "../../api";
import Colors from "../../assets/Colors";
import * as Gifs from "../../assets/gifs";
import Button from "../../components/Buttons/Button";
import FloatInput from "../../components/Inputs/FloatInput";
import AlertModal from "../../components/Modal/AlertModal";
import ModalContext from "../../contexts/ModalProvider";
import RoutesPaths from "../../Routes/RoutesPaths";
import history from "../../services/history";

export const ResetPasswordPage: React.FC<any> = (props) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const [ref_pswd1] = useState<React.RefObject<HTMLInputElement>>(useRef(null));
  const [ref_pswd2] = useState<React.RefObject<HTMLInputElement>>(useRef(null));
  const [pswd1, setPswd1] = useState<string>("");
  const [pswd2, setPswd2] = useState<string>("");

  const [minChars, setMinChars] = useState<boolean>(false);
  const [passwordMatch, setPasswordMatch] = useState<boolean>(false);
  const [weakPassword, setWeakPassword] = useState<boolean>(true);
  const [hasUppercase, setHasUppercase] = useState<boolean>(false);
  const [hasLowercase, setHasLowercase] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] =
    useState<boolean>(false);

  const [error, setError] = useState<string | undefined>();
  const [firstSubmit, setFirstSubmit] = useState<boolean>(true);

  const [passwordIsInvalid, setPasswordIsInvalid] = useState<boolean>(true);
  const { handleModal, setModal } = useContext(ModalContext);
  const { tokenId } = useParams<{ tokenId: string }>();

  useEffect(() => {
    if (!tokenId) history.push(RoutesPaths.SIGNIN);
  }, []);

  useEffect(() => {
    validatePassword();
  }, [pswd1, pswd2]);

  const validatePassword = () => {
    const regexStrongPassword =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#!])[0-9a-zA-Z$*&@#!]{8,}$/;
    const regexUppercase = /(?=.*[A-Z])/;
    const regexLowercase = /(?=.*[a-z])/;
    const regexNumber = /(?=.*\d)/;
    const regexSpecialCharacters = /(?=.*[!@#$%¨&*])/;
    const errors: Array<String> = [];
    let isValid = true;

    if (pswd1.length < 8) {
      errors.push("Senha muito curta!");
      setMinChars(false);
      isValid = false;
    } else {
      setMinChars(true);
    }

    if (pswd1 !== pswd2) {
      errors.push("Senhas não coicidem!");
      setPasswordMatch(false);
      isValid = false;
    } else {
      setPasswordMatch(true);
    }

    if (!regexStrongPassword.test(pswd1)) {
      errors.push("Senha muito fraca!");
      setWeakPassword(true);
      isValid = false;
    } else {
      setWeakPassword(false);
    }

    if (regexUppercase.test(pswd1)) {
      setHasUppercase(true);
    } else {
      errors.push("Senha deve possuir letra maiúscula!");
      setHasUppercase(false);
      isValid = false;
    }

    if (regexLowercase.test(pswd1)) {
      setHasLowercase(true);
    } else {
      errors.push("Senha deve possuir letra minúscula!");
      setHasLowercase(false);
      isValid = false;
    }

    if (regexNumber.test(pswd1)) {
      setHasNumber(true);
    } else {
      errors.push("Senha deve possuir números!");
      setHasNumber(false);
      isValid = false;
    }

    if (regexSpecialCharacters.test(pswd1)) {
      setHasSpecialCharacter(true);
    } else {
      errors.push(
        "Senha deve conter pelo menos 1 caracter especial! (ex: “ ! @ # $ % ¨& *)"
      );
      setHasSpecialCharacter(false);
      isValid = false;
    }

    setPasswordIsInvalid(!isValid);

    setError(errors.join("\n"));
  };

  const handleSubmit = async () => {
    try {
      setLoadingState(true);
      await API.signup.updatePassword(tokenId, pswd1);

      handleModal(AlertModal, {
        title: "Senha redefinida com sucesso",
        src: Gifs.IconOkGif,

        confirmClick: async () => {
          setModal(false);
          history.push(RoutesPaths.SIGNIN);
        },

        children: (
          <div className="flex flex-col justify-center text-center items-center">
            <p>
              A partir de agora utilize sua nova senha para acessar sua conta.
            </p>
          </div>
        ),
      });
    } catch (error) {
      // const errorType: ApiErrorTypeEnum = JSON.parse(error.message).message;
      // setErrorModalState(true);
      // setTextError(ApiErrorTypeEnumInterpreter.getMessage(errorType));
    } finally {
      setLoadingState(false);
    }
  };

  return (
    <>
      <div
        className="signin-page-container w-4/5 md:w-2/5 flex flex-col justify-center items-center"
        style={{ maxWidth: "448px" }}
      >
        <h1
          className="text-2xl font-medium mt-16 mb-10"
          style={{ color: Colors.Gray2 }}
        >
          Redefinição de senha
        </h1>

        <p className="text-base" style={{ color: Colors.Gray3 }}>
          Redefina abaixo sua nova senha de acesso.
        </p>

        <Form
          onSubmit={handleSubmit}
          ref={undefined}
          className="forgot-password-form w-80"
        >
          <FloatInput
            ref={ref_pswd1}
            big={true}
            type="password"
            className="w-full my-3"
            placeholder="Senha de acesso"
            onChange={({ target }) => setPswd1(target.value)}
          />
          <FloatInput
            ref={ref_pswd2}
            big={true}
            type="password"
            className="w-full my-3"
            placeholder="Confirmar senha de acesso"
            onChange={({ target }) => setPswd2(target.value)}
          />

          {(pswd1 || pswd2) && (
            <ul className="text-base" style={{ color: Colors.Gray2 }}>
              <li>
                <span
                  className={hasUppercase ? "text-green-500" : "text-red-400"}
                >
                  <input type="checkbox" checked={hasUppercase} readOnly /> Ao
                  menos 1 letra maiúscula
                </span>
              </li>
              <li>
                <span
                  className={hasLowercase ? "text-green-500" : "text-red-400"}
                >
                  <input type="checkbox" checked={hasLowercase} readOnly /> Ao
                  menos 1 letra minúscula
                </span>
              </li>
              <li>
                <span className={hasNumber ? "text-green-500" : "text-red-400"}>
                  <input type="checkbox" checked={hasNumber} readOnly /> Ao
                  menos 1 Número
                </span>
              </li>
              <li>
                <span
                  className={
                    hasSpecialCharacter ? "text-green-500" : "text-red-400"
                  }
                >
                  <input
                    type="checkbox"
                    checked={hasSpecialCharacter}
                    readOnly
                  />{" "}
                  1 caracter especial (ex: " ! @ # $ % & *)
                </span>
              </li>
              <li>
                <span className={minChars ? "text-green-500" : "text-red-400"}>
                  <input type="checkbox" checked={minChars} readOnly /> Mínimo
                  de 8 caracteres
                </span>
              </li>
              <li>
                <span
                  className={passwordMatch ? "text-green-500" : "text-red-400"}
                >
                  <input type="checkbox" checked={passwordMatch} readOnly />{" "}
                  Confirmação de senha está correta
                </span>
              </li>
            </ul>
          )}
          {error && !firstSubmit ? (
            <p className="font-light underline text-red-600">{error}</p>
          ) : (
            <></>
          )}

          <p
            className="text-xs font-normal mt-7 mb-5 mx-2 text-center"
            style={{ color: Colors.Gray4 }}
          >
            Ao prosseguir declaro estar de acordo com os termos de uso e
            políticas da plataforma.
          </p>

          <div className="my-2">
            <Button
              type="submit"
              loading={loadingState}
              backgroundColor={Colors.UserBGButton}
              className="forgot-password-btn"
              disabled={passwordIsInvalid}
            >
              Confirmar
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ResetPasswordPage;
