const message = {
  "email must be an email": "Preencha um email válido por favor",
  "*": "Houve não identificado no servidor, aguarde alguns segundos e tente novamente.",
};

export const translateErrorMessages = (
  index: string
): (typeof message)[keyof typeof message] => {
  if (!Object.keys(message).includes(index)) return message["*"];

  return message[index];
};
