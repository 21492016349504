import Colors from "../assets/Colors";
import * as Icons from "./../assets/icons";
import { UserRoleEnum } from "./enums";

export enum ClientStatusEnum {
  CREATED = "CREATED",
  ANALYSIS = "ANALYSIS",
  CERTIFICATE = "CERTIFICATE",
  SUSPENDED = "SUSPENDED",
  BANNED = "BANNED",
  FINISHED = "FINISHED",
  ACTIVE = "ACTIVE",
}
export enum ClientDocStatusEnum {
  // CERTFICATE = 'CERTFICATE',
  // PENDING = 'PENDING',
  // ANALISE = 'ANALISE',
  Waiting = "Waiting",
  Approved = "Approved",
  Disapproved = "Disapproved",
}
export enum ClientDocumentStatusEnum {
  WAITING = "WAITING",
  APPROVED = "APPROVED",
  DISAPPROVED = "DISAPPROVED",
}
export enum DocumentTypeEnum {
  DriversLicense = "DriversLicense",
  FederalID = "FederalID",
  Irpf = "Irpf",
  CompanyParticipation = "CompanyParticipation",
  Signature = "Signature",
  liveness = "liveness",
  SocialContract = "SocialContract",
  Assembly = "Assembly",
  Procuration = "Procuration",
  AbcCurve = "AbcCurve",
  BalanceSheet = "BalanceSheet",
  LastBillingList = "LastBillingList",
  PenultimateBillingList = "PenultimateBillingList",
  AntiPenultimateBillingList = "AntiPenultimateBillingList",
  ProofOfRedence = "ProofOfRedence",

  individualLiveness = "individualLiveness",

  /// ParnerEnum
  partnerPrincipalDoc = "partnerPrincipalDoc",
  partnerIrpf = "partnerIrpf",
  partnerCompanyParticipation = "partnerCompanyParticipation",
  partnerSignature = "partnerSignature",
  partnerlLiveness = "partnerlLiveness",
  partnerProofOfRedence = "partnerProofOfRedence",
}
export enum ClientOrderEnum {
  MOST_RECENT = "MOST_RECENT",
  MOST_OLD = "MOST_OLD",
  ALPHABETIC_ASC = "ALPHABETIC_ASC",
  ALPHABETIC_DEC = "ALPHABETIC_DEC",
}
export enum TypeUserEnum {
  INDIVIDUAL = "INDIVIDUAL",
  CORPORATE = "CORPORATE",
}
export class TypeUserEnumInterpreter {
  static convert(type: TypeUserEnum) {
    switch (type) {
      case TypeUserEnum.CORPORATE:
        return {
          abrv: "PJ",
          label: "Pessoa Jurídica",
        };
      case TypeUserEnum.INDIVIDUAL:
        return {
          abrv: "PF",
          label: "Pessoa Fisica",
        };
    }
  }
  static abrv(type?: TypeUserEnum) {
    return type ? this.convert(type).abrv : "";
  }
  static label(type?: TypeUserEnum) {
    return type ? this.convert(type).label : "";
  }
}

export interface ClientDocumentation {
  // status: ClientDocStatusEnum,
  // docs: Array<Client.doc>,
  // partners: Array<Client.doc>

  id: string;
  urlFront: string;
  urlBack: string;
  principal: boolean;
  createdAt: Date;
  updatedAt: Date;
  docType: DocumentTypeEnum;
  status: ClientDocStatusEnum;
  featureId: number;
}

export interface ClientAdress {
  country: string;
  postalCode: string;
  state: string;
  city: string;
  street: string;
  neighborhood: string;
  streetNumber: string;
  complement?: string;
}
export interface BankInfo {
  ispb: string;
  bank: number;
  shortName: string;
  bankAgency: string;
  bankAccount: number;
  fullName: number;
}
export class Client {
  public id!: string;
  public name!: string;
  public role!: UserRoleEnum;
  public userType!: TypeUserEnum;
  public phoneNumber!: string;
  public email!: string;
  public document!: string;
  public partnerDocument!: string;
  public partnerName!: string;
  public partnerEmail!: string;
  public status!: ClientStatusEnum;
  public userBankInfo!: BankInfo;

  public custodyBankInfo!: BankInfo;

  public certifications!: Array<any>;
  public documentations!: Array<any>;

  public address!: {
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    complement: string;
    postalCode: string;
    country: string;
  };

  public wallet!: {
    publicKey: string;
    secretKey: string;
  };

  public emailVerified!: boolean | null;
  public phoneVerified!: boolean | null;
  public dataVerified!: boolean | null;
  public otpEmail!: string;
  public otpPhoneNumber!: string;
  public data!: any;

  public tenant!: {
    id: string;
  };
  public birthday!: string;
  public motherName!: string;
  public create_at!: Date;
  public oldStatus!: ClientStatusEnum;
  public type!: TypeUserEnum;
  // public docs: Map<DocumentTypeEnum, ClientDocumentation>;

  constructor(obj: any) {
    for (let key of Object.keys(this).filter((key) => key !== "docs")) {
      this[key] = obj[key];
    }
    // this.docs = obj.docs.reduce(
    //     (prev, current: ClientDocumentation) => prev.set(current.docType, current),
    //     new Map<string, ClientDocumentation>()
    // );
  }

  static typeABRV(type: TypeUserEnum) {
    switch (type) {
      case TypeUserEnum.CORPORATE:
        return "PJ";
      case TypeUserEnum.INDIVIDUAL:
        return "PF";
    }
  }
  static getColorState(status?: ClientStatusEnum): string {
    if (!status) return "";
    return this.getStatusData(status).color;
  }
  static getTranslateState(status?: ClientStatusEnum): string {
    if (!status) return "";
    return this.getStatusData(status).translate;
  }
  static getIconState(status?: ClientStatusEnum | ClientDocStatusEnum) {
    if (!status) return "";
    return this.getStatusData(status as ClientStatusEnum).icon;
  }
  static getStatusData(state: ClientStatusEnum) {
    switch (state) {
      case ClientStatusEnum.CERTIFICATE:
        return {
          translate: "Certificado",
          color: Colors.Green3,
          icon: Icons.SuccessIcon,
        };
      case ClientStatusEnum.CREATED:
        return {
          translate: "Pendente",
          color: Colors.Red4,
          icon: Icons.AlertIcon,
        };
      case ClientStatusEnum.ANALYSIS:
        return {
          translate: "Analisar",
          color: Colors.Blue1,
          icon: Icons.SmallWaitingIcon,
        };
      case ClientStatusEnum.SUSPENDED:
        return {
          translate: "Suspenso",
          color: Colors.Purple1,
          icon: Icons.AlertIcon,
        };
      case ClientStatusEnum.BANNED:
        return {
          translate: "Banido",
          color: Colors.Gray2,
          icon: Icons.AlertIcon,
        };
      case ClientStatusEnum.FINISHED:
        return {
          translate: "Encerrada",
          color: Colors.Gray4,
          icon: Icons.AlertIcon,
        };
      case ClientStatusEnum.ACTIVE:
        return {
          translate: "Ativo",
          color: Colors.Green3,
          icon: Icons.SuccessIcon,
        };
    }
  }
}

// eslint-disable-next-line no-redeclare
export namespace Client {
  export interface doc {
    id: string;
    status: ClientDocStatusEnum;
    type: DocumentTypeEnum;
    links: Array<string>;
  }
}
