import React, { useContext, useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

/// Utils
import { UnFormValidate, YupAdaptErrorToUnform } from "../../../utils/yupUtils";
import { holderDetailsValidation } from "../../../validations/holderDetailsValidation";
import UnformInputAdapt from "../../../components/unform/UnformInputAdapt/UnformInputAdapt";

/// Assets
import * as API from "../../../api";
import history from "../../../services/history";
import RoutesPaths from "../../../Routes/RoutesPaths";
import * as Gifs from "../../../assets/gifs";
import Colors from "../../../assets/Colors";

/// Components
import PageSkeleton from "../../../components/PageSkeleton/PageSkeleton";
import UserContext from "../../../contexts/UserProvider";
import AlertModal from "../../../components/Modal/AlertModal";
import FloatInput from "../../../components/Inputs/FloatInput";
import { StylePatterns } from "../../../assets/patterns_tailwind_class";
import PageBoardSkeleton from "../../../components/PageBoardSkeleton/PageBoardSkeleton";
import FloatInputCore from "../../../components/Inputs/FloatInputCore/FloatInputCore";

const HolderDetailsPage: React.FC<any> = () => {
  const { user } = useContext(UserContext);
  const { setUser } = useContext(UserContext);
  const [formRef] = useState<React.RefObject<FormHandles>>(useRef(null));

  const [modalSuccessState, setmodalSuccessState] = useState<boolean>(false);
  const [modalErrorState, setmodalErrorState] = useState<boolean>(false);
  const [formInitialData, setformInitialData] = useState<any | undefined>(
    undefined
  );
  const [firstSubmit] = useState<boolean>(true);

  setInitialData();
  function setInitialData() {
    if (user && !formInitialData) {
      setformInitialData({
        nome: user.name,
        documento: user.document,
        socio: user.partnerName,
        cpf: user.partnerDocument,
        email: user.email,
        telefone: user.phoneNumber,
      });
    }
  }

  async function updateUser() {
    try {
      const data = await API.user.getUserInfo();
      setUser(data);
    } catch (error) {
      // console.error(error);
    }
  }

  async function updateData(data: any) {
    try {
      //await API.user.updateInfo();
      setmodalSuccessState(true);
      await updateUser();
    } catch (error) {
      setmodalErrorState(true);
    }
  }

  const handleFormSubmit = async (input: any, { reset }) => {
    if (!formRef.current) return null;

    try {
      const schema = holderDetailsValidation;
      const data = await schema.validate(input, {
        abortEarly: false,
      });

      updateData(data);
    } catch (error: any) {
      formRef.current.setErrors(YupAdaptErrorToUnform(error));
    }
  };

  return (
    <>
      <AlertModal
        src={Gifs.IconOkGif}
        title="Atualização realizada com sucesso!"
        state={modalSuccessState}
        confirmClick={() => history.push(RoutesPaths.ACCOUT)}
      >
        <p>Clique no botão para voltar a tela principal da sua conta.</p>
      </AlertModal>
      <AlertModal
        src={Gifs.IconAlertGif}
        title="Erro na atualização!"
        state={modalErrorState}
        confirmClick={() => {
          setmodalErrorState(false);
        }}
      />

      <PageBoardSkeleton
        onClickFilterBar={() => history.goBack()}
        filterBar="Dados Pessoais"
        button2={{
          label: "Voltar à minha conta",
          onClick() {
            history.push(RoutesPaths.ACCOUT);
          },
        }}
      >
        <div
          className={
            "flex flex-col w-full items-center " + StylePatterns.ELEMENT_WIDTH
          }
        >
          <span
            className="text-xl font-normal flex justify-center items-center mt-3 gap-2"
            style={{ color: Colors.Gray3 }}
          >
            DADOS PESSOAIS
          </span>

          <Form
            className="account-form text-lg font-medium w-full"
            ref={formRef}
            onSubmit={handleFormSubmit}
            initialData={formInitialData}
            onChange={() => {
              if (!firstSubmit) {
                UnFormValidate(formRef, holderDetailsValidation);
              }
            }}
          >
            {user?.pep && (
              <FloatInputCore
                big
                disabled
                children={(ref) => (
                  <label className="p-2">
                    Pessoa Física{" "}
                    <span style={{ color: Colors.Red6 }}>(PEP)</span>
                  </label>
                )}
                placeholder=""
                color="bg-white"
                className="account-form text-lg font-normal w-full account-form-input"
              />
            )}
            <UnformInputAdapt
              name="nome"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  disabled={true}
                  big={true}
                  defaultValue={defaultValue}
                  error={error}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="Nome do titular"
                  type="text"
                  ref={ref}
                />
              )}
            />
            <UnformInputAdapt
              name="documento"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  disabled={true}
                  big={true}
                  defaultValue={defaultValue}
                  error={error}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="Documento"
                  type="text"
                  ref={ref}
                />
              )}
            />
            {/* <UnformInputAdapt
                        name='socio'
                        children={({ ref, error, defaultValue }) => <FloatInput disabled={true} big={true} defaultValue={defaultValue} error={error} color='bg-white' className='account-form-input' placeholder='Sócio Responsável' type='text' ref={ref}/>}
                    />
                    <UnformInputAdapt
                        name='cpf'
                        children={({ ref, error, defaultValue }) => <FloatInput disabled={true} big={true} defaultValue={defaultValue} error={error} color='bg-white' className='account-form-input' placeholder='CPF Sócio' type='text' ref={ref}/>}
                    /> */}
            <UnformInputAdapt
              name="email"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  disabled={true}
                  big={true}
                  defaultValue={defaultValue}
                  error={error}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="Email"
                  type="text"
                  ref={ref}
                />
              )}
            />
            <UnformInputAdapt
              name="telefone"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  disabled={true}
                  big={true}
                  defaultValue={defaultValue}
                  error={error}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="DDD + Telefone"
                  type="text"
                  ref={ref}
                />
              )}
            />
          </Form>
          <p
            style={{ color: Colors.Gray3 }}
            className="underline mt-14 text-sm"
          >
            ATENÇÃO:
          </p>
          <p
            style={{ color: Colors.Gray3 }}
            className="text-center mb-8 text-sm"
          >
            Certifique-se de que os dados informados acima são verdadeiros e
            estão atualizados. Estas informações serão utilizadas para a
            certificação da sua conta e comunicação da plataforma.
          </p>
        </div>
      </PageBoardSkeleton>
    </>
  );
};

export default HolderDetailsPage;
