import React, { useContext, useEffect, useState } from "react";
import Colors from "../../assets/Colors";
import BarButton from "../../components/BarButton/BarButton";
import Button from "../../components/Buttons/Button";
import Divider from "../../components/Divider/Divider";
import FilterBar from "../../components/FilterBar/FilterBar";
import Footer from "../../components/Footer/Footer";
import PageTabBar from "../../components/PageTabBar/PageTabBar";
import TopBar from "../../components/TopBar/TopBar";
import UserContext from "../../contexts/UserProvider";
import { balance } from "../../models/balance";
import RoutesPaths from "../../Routes/RoutesPaths";
import history from "../../services/history";
import TokenDetailsResumeCard from "../TokenDetailsPage/TokenDetailsResumeCard/TokenDetailsResumeCard";
import * as Icons from "./../../assets/icons";
import "./DashboardAdminPage.css";
import * as API from "../../api";
import HighlightCard from "../../components/Cards/HighlightCard/HighlightCard";
import { formatToCurrency } from "../../utils/formatters";

const DashboardAdminPage: React.FC<any> = () => {
  const { user } = useContext(UserContext);
  const [userBalance, setUserBalance] = useState<balance>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [informativeCards, setInformativeCards] = useState({
    registeredClients: 0,
    totalTraded: 0,
  });

  async function loadinfos() {
    const registeredClients: number = (await API.admin.loadAccounts()).length;
    let totalTraded = 0;

    (await API.book.getAllMatchs({})).forEach((match) => {
      totalTraded += match.tokenQuantity * match.ask;
    });
    setInformativeCards({ registeredClients, totalTraded });
  }

  useEffect(() => {
    loadinfos();
  }, []);

  const renderCards = () => (
    <div className="mt-4">
      {[1, 2].map((item) => (
        <HighlightCard
          buttonColor={Colors.Gold4}
          buttonLabel="Negociar"
          buttonStyle={{ color: Colors.White }}
          className="mx-3 md:mx-5"
          buttonClassName="dashboard-negotiate-btn"
        />
      ))}
    </div>
  );

  const renderCurrentPage = (): JSX.Element => {
    switch (currentPage) {
      case 1:
        return renderCards();
      case 2:
        return renderCards();
      case 3:
        return renderCards();
      case 4:
        return renderCards();
      case 5:
        return renderCards();
      default:
        return renderCards();
    }
  };

  return (
    <>
      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar title="Dashboard" />

          <div className="flex flex-row w-full justify-center">
            <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
              <div className="flex flex-col lg:flex-row mt-4 my-7 w-full">
                <TokenDetailsResumeCard
                  bgColor={Colors.valueEstimatedToken}
                  title="Total de projetos"
                  textColor={Colors.Gray1}
                  titleStyle={{ fontWeight: 400 }}
                  unity=""
                  value={"1"}
                />
                <div className="w-5"></div>
                <TokenDetailsResumeCard
                  bgColor={Colors.totalBalanceFiat}
                  title={"Clientes cadastrados"}
                  textColor={Colors.Gray1}
                  titleStyle={{ fontWeight: 400 }}
                  unity="clientes"
                  value={String(informativeCards.registeredClients)}
                />
                <div className="w-5"></div>
                <TokenDetailsResumeCard
                  bgColor={Colors.globalEstimatedBalance}
                  title={"Total negociado"}
                  textColor={Colors.Gray1}
                  titleStyle={{ fontWeight: 400 }}
                  unity="BRL"
                  value={formatToCurrency(informativeCards.totalTraded)}
                />
              </div>
            </div>
          </div>

          <Divider top="mt-0" />

          <div className="flex flex-row w-full justify-center">
            <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
              <div className="flex flex-row justify-between w-full gap-5 mb-8">
                {[
                  {
                    label: "Emitir tokens",
                    icon: Icons.Token2Icon,
                    disabled: true,
                    onClick: () => history.push(RoutesPaths.MEUSATIVOSCRIAR),
                  },
                  {
                    label: "Saque",
                    icon: Icons.BigWalletIcon,
                    onClick: () => history.push(RoutesPaths.TRANSFER),
                  },
                  {
                    label: "Configurações",
                    icon: Icons.GearIcon,
                    onClick: () => {},
                  },
                ].map(({ icon, label, onClick, disabled }) => (
                  <button
                    className={`
                              flex flex-col w-full md:w-1/3
                              rounded border py-3 px-3 md:px-6
                              justify-between items-center
                              ${
                                disabled
                                  ? "bg-gray-100 cursor-default"
                                  : "hover-dark cursor-pointer"
                              }
                      `}
                    style={{
                      borderColor: disabled ? Colors.Gray4 : Colors.Gray3,
                    }}
                    disabled={disabled}
                    onClick={onClick}
                  >
                    <img
                      style={{ filter: disabled ? "brightness(1.5)" : "" }}
                      src={icon}
                      alt=""
                    />
                    <h3
                      className="text-base font-medium text-center"
                      style={{ color: disabled ? Colors.Gray4 : Colors.Gray2 }}
                    >
                      {label}
                    </h3>
                  </button>
                ))}
              </div>
            </div>
          </div>

          <Divider top="mt-0" bottom="mb-4" />

          <div className="flex flex-row w-full justify-center">
            <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
              {/* <PageTabBar className="details-bar mt-12 justify-start md:justify-around">
                <BarButton borderColor={Colors.Purple4} textColor={Colors.Gray1} grow state={currentPage === 1} children="Destaques" onClick={() => setCurrentPage(1)} />
                <BarButton borderColor={Colors.Purple4} textColor={Colors.Gray1} grow state={currentPage === 2} children="Recentes" onClick={() => setCurrentPage(2)} />
                <BarButton borderColor={Colors.Purple4} textColor={Colors.Gray1} grow state={currentPage === 3} children="Maior alta" onClick={() => setCurrentPage(3)} />
                <BarButton borderColor={Colors.Purple4} textColor={Colors.Gray1} grow state={currentPage === 4} children="Maior volume" onClick={() => setCurrentPage(4)} />
                <BarButton borderColor={Colors.Purple4} textColor={Colors.Gray1} grow state={currentPage === 5} children="Oportunidades" onClick={() => setCurrentPage(5)} />
              </PageTabBar> */}
            </div>
          </div>

          {/* {renderCurrentPage()} */}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default DashboardAdminPage;
