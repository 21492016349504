import React from "react";
import { Link } from "react-router-dom";
import RoutesPaths from "../../Routes/RoutesPaths";
import { Gray4, GrayBackGroud } from "../../assets/Colors";

const link = "";

interface MyProps {
  backgroundColor?: string;
  textColor?: string;
  blackTheme?: boolean;
}

const Footer: React.FC<MyProps> = ({
  blackTheme,
  backgroundColor,
  textColor,
}) => {
  return (
    <footer
      className={"mx-0 flex flex-row items-center justify-center p-5 "}
      style={{
        backgroundColor:
          backgroundColor ?? (blackTheme ? "black" : GrayBackGroud),
        color: textColor ?? Gray4,
      }}
    >
      <Link to={RoutesPaths.USETERM} className="underline">
        Termos de uso
      </Link>
      <p className="whitespace-pre">
        {" "}
        | Privacidade - © {new Date().getFullYear()}{" "}
        <a href={link} target="_blank" rel="noopener noreferrer">
          Oz
        </a>
      </p>
    </footer>
  );
};

Footer.defaultProps = {
  blackTheme: false,
};

export default Footer;
