import i18nErros from "./i18n-erros"


const keys = {
    ...i18nErros
}
const i18nTranslated = (text) => {
    const translated = keys[text];

    if (translated) {
        return translated;
    } else {
        return text;
    }
}

export default i18nTranslated