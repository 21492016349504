import React, { CSSProperties } from "react";
import Colors from "../../assets/Colors";
import * as Icons from "../../assets/icons";

interface MyProps {
  icon?: any;
  iconAlert?: any;
  title: string | JSX.Element;
  subTitle: string;
  children: JSX.Element | Array<JSX.Element>;
  className?: string;
  childrenClassName?: string;
  alert?: boolean;
  onClick?: () => any;

  autoWidth?: boolean;
}

export const EmptyChildToSplitCard: React.FC<{
  children: string | JSX.Element | Array<JSX.Element>;
  className?: string;
  style?: CSSProperties;
}> = ({ children, className, style }) => {
  return (
    <>
      {typeof children === "string" && (
        <p
          className={"font-light text-sm " + className}
          style={{ color: Colors.Gray3, ...style }}
        >
          {children}
        </p>
      )}
      {typeof children !== "string" && children}
    </>
  );
};

const SplitedCard: React.FC<MyProps> = ({
  icon,
  title,
  subTitle,
  children,
  className,
  childrenClassName,
  alert,
  onClick,
  autoWidth,
  iconAlert,
}) => {
  return (
    <div
      className={
        (!autoWidth ? "w-full sm:w-4/5 lg:w-3/5 max-w-2xl" : "") +
        " flex flex-col border rounded overflow-hidden " +
        className
      }
      style={{ borderColor: Colors.Gray4 }}
    >
      <div
        className="flex flex-row flex-1 justify-start items-center pt-8 pb-7 px-4 cursor-pointer"
        onClick={onClick}
      >
        {icon && <img className="mr-3" src={icon} alt="" style={{}} />}
        <div className="flex flex-row flex-grow justify-between">
          <div className="flex flex-col flex-grow justify-between">
            <strong
              className="text-xl font-medium"
              style={{ color: Colors.Gray2 }}
            >
              {title}
            </strong>
            <p className="font-normal text-sm" style={{ color: Colors.Gray3 }}>
              {subTitle}
            </p>
          </div>
          {alert && (
            <img
              src={iconAlert ?? Icons.AlertIcon}
              alt=""
              style={{ height: "25px", width: "25px" }}
            />
          )}
        </div>
      </div>
      <div
        className={"flex-1 py-9 px-6 border-t " + childrenClassName}
        style={{
          backgroundColor: Colors.GrayBackGroud,
          borderColor: Colors.Gray4,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default SplitedCard;
