import React, { useEffect, useRef, useState } from "react";
import Colors from "../../assets/Colors";
import EmptyCard from "../../components/Cards/EmptyCard";
import TokenCard from "../../components/Cards/TokenCard/TokenCard";
import Divider from "../../components/Divider/Divider";
import FilterBar from "../../components/FilterBar/FilterBar";
import Footer from "../../components/Footer/Footer";
import TopBar from "../../components/TopBar/TopBar";
import { TokenTypeEnum } from "../../models/enums";
import RoutesPaths from "../../Routes/RoutesPaths";
import history from "../../services/history";
import * as API from "./../../api";

const TokensPage: React.FC<any> = () => {
  const loadMoreRef = useRef<HTMLParagraphElement>(null);
  const [_orderReceivableType, _setOrderReceivableType] =
    useState<TokenTypeEnum>(TokenTypeEnum.GOLD);

  const [_availableOrders, _setAvailableOrders] = useState<Array<any>>([]);
  const [_finishedOrders, _setFinishedOrders] = useState<Array<any>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  async function loadAvailableOrders() {
    setLoading(true);
    const data = await API.book.getOrdersBalcao(_orderReceivableType);

    if (data?.length > 0) {
      let finished: Array<any> = [];
      let available = data.filter((order) => {
        if (order.remainingTokens === 0 || order.finished) {
          finished.push(order);
          return false;
        } else return true;
      });

      _setAvailableOrders(available);
      _setFinishedOrders(finished);
    }

    setLoading(false);
  }

  useEffect(() => {
    loadAvailableOrders();
  }, []);

  const renderOrders = (
    orders: Array<any>,
    notFoundText: string
  ): JSX.Element | any[] => {
    return orders.length > 0 ? (
      orders.map((order) =>
        order.tokens
          .sort(
            (a, b) =>
              new Date(
                (a as any)?.metadata?.properties?.redemptionDate
              ).getTime() -
              new Date(
                (b as any)?.metadata?.properties?.redemptionDate
              ).getTime()
          )
          .map((token, index) => (
            <TokenCard
              order={order}
              currencyFormatting={false}
              btnRender={() => <></>}
              key={index}
            />
          ))
      )
    ) : (
      <div>
        <EmptyCard>
          <div className="flex flex-col items-center">
            <strong style={{ color: Colors.Gray3 }}>
              Não há ofertas {notFoundText}
            </strong>
          </div>
        </EmptyCard>
      </div>
    );
  };

  return (
    <>
      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <TopBar />
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar title="Ofertas" />

          <div ref={loadMoreRef} className="mx-2 md:mx-5 flex flex-col">
            {_availableOrders.length > 0 || _finishedOrders.length > 0 ? (
              <>
                <h3 style={{ color: Colors.Gray2 }} className="text-lg my-4">
                  Disponíveis
                </h3>
                {renderOrders(_availableOrders, "disponíveis")}

                <Divider />

                <h3 style={{ color: Colors.Gray2 }} className="text-lg mb-4">
                  Finalizadas
                </h3>
                {renderOrders(_finishedOrders, "finalizadas")}
              </>
            ) : (
              <div className="mt-5">
                <EmptyCard>
                  <div className="flex flex-col items-center">
                    <strong style={{ color: Colors.Gray3 }}>
                      {isLoading ? "Carregando ofertas..." : "Não há ofertas"}
                    </strong>
                  </div>
                </EmptyCard>
              </div>
            )}
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default TokensPage;
