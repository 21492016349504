import React, { useContext, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useParams } from "react-router-dom";
import * as API from "../../../api";
import * as Colors from "../../../assets/Colors";
import EmptyCard from "../../../components/Cards/EmptyCard";
import FilterBar from "../../../components/FilterBar/FilterBar";
import GoldNegociationBar from "../../../components/Modal/GoldNegociationBar/GoldNegociationBar";
import UserContext from "../../../contexts/UserProvider";
import StamentContext from "../../../contexts/AssetProvider";

import { TokenTypeEnumInterpreter, UserRoleEnum } from "../../../models/enums";
import RoutesPaths from "../../../Routes/RoutesPaths";
import history from "../../../services/history";
import * as Icons from "../../../assets/icons";
import TokenizeButton from "./TokenizeButton/TokenizeButton";
import { TokenProjectsEnum } from "../../../models/enums/tokenProjectsEnum";
import ChartCard from "../../../components/Cards/ChartCard";
import { token } from "../../../api";
import Footer from "../../../components/Footer/Footer";
import { GoldToken } from "../../../models";

const AssetListPageAdmin: React.FC = () => {
  const params: any = useParams();
  const userContext = useContext(UserContext);

  const [hasMore, setHasMore] = useState<boolean>(true);
  const [tokens, setTokens] = useState<Array<any>>([]);
  const [negociationBarStatus, setNegociationBarStatus] =
    useState<boolean>(false);
  const stamentContext = useContext(StamentContext);

  const tokenType = TokenProjectsEnum[params.type];
  const [chosenToken, setChosenToken] = useState<GoldToken>();

  useEffect(() => {
    if (userContext.user) {
      loadTokens(tokenType);
    }
  }, [userContext]);

  const tokenUnitName = useMemo(() => {
    if (tokens?.length > 0) {
      return {
        unit: tokens[0]?.metadata?.properties?.unit || "",
        name: tokens[0]?.metadata?.name || "",
      };
    }
    return { unit: "", name: "" };
  }, [tokens]);

  async function loadTokens(type: TokenProjectsEnum | undefined = undefined) {
    try {
      setHasMore(true);

      const tokensProject = await API.admin.getTokensAdmin(type);

      setTokens(tokensProject.tokens);
      setHasMore(tokensProject.tokens.length !== tokensProject.tokens?.length);
    } catch (error) {
      console.error(error);
    }
  }

  const goToDetails = (tokenId: string) => {
    history.push(
      RoutesPaths.MEUSATIVOSDETALHES_ADMIN + `/${tokenType}/${tokenId}`
    );
  };

  return (
    <>
      {chosenToken && (
        <GoldNegociationBar
          state={negociationBarStatus}
          token={chosenToken}
          changeState={() => {
            setNegociationBarStatus(!negociationBarStatus);
          }}
        />
      )}

      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar className="justify-between" selection={false}>
            <button
              className="flex flex-row gap-3 items-center"
              onClick={() => history.goBack()}
            >
              <img src={Icons.ArrowPointerIcon} alt="" />
              <p style={{ color: Colors.Gray2 }} className="font-light">
                <strong className="font-medium">Listagem de lotes</strong>
              </p>
            </button>
          </FilterBar>
          <div className="mx-5 flex flex-col">
            <div
              style={{ color: Colors.Gray2 }}
              className="flex items-center justify-between gap-2 text-lg my-7"
            >
              <div>
                <img src={Icons.Token2Icon} className="h-7 w-7 inline mr-2" />
                <strong className="font-medium">
                  {tokenUnitName?.unit}
                </strong> - {tokenUnitName?.name}
              </div>
              <TokenizeButton type={tokenType} />
            </div>

            <InfiniteScroll
              dataLength={tokens.length}
              next={() => {
                // loadTokens()
              }}
              hasMore={hasMore}
              loader={
                <p style={{ textAlign: "center" }}>
                  <h4>Carregando...</h4>
                </p>
              }
            >
              {tokens.length > 0 || hasMore ? (
                tokens
                  .sort((a, b) => {
                    const aRedemptionDate = new Date(
                      (a as any)?.metadata?.properties?.redemptionDate
                    )?.getTime();
                    const bRedemptionDate = new Date(
                      (b as any)?.metadata?.properties?.redemptionDate
                    )?.getTime();
                    return aRedemptionDate - bRedemptionDate;
                  })
                  .map((token) => (
                    <ChartCard
                      id={token.id}
                      amount={token.available + token.consumed}
                      negociation={token.consumed}
                      available={token.available}
                      redemptionDate={
                        token.metadata?.properties?.redemptionDate
                      }
                      unit={token.metadata?.properties?.unit}
                      onClick={() => goToDetails(token.id)}
                      onClickMore={() => goToDetails(token.id)}
                      negociationClick={() => {
                        setChosenToken(token);
                        stamentContext.setAsset(token);
                        setNegociationBarStatus(true);
                      }}
                      key={token.id}
                      showNegociation={true}
                    />
                  ))
              ) : (
                <EmptyCard>
                  <div className="flex flex-col items-center">
                    <strong style={{ color: Colors.Gray3 }}>
                      Você não possui nenhum token atualmente
                    </strong>
                    {userContext.user?.role !== UserRoleEnum.USER && (
                      <Link
                        className="underline cursor-pointer text-center"
                        to={RoutesPaths.MEUSATIVOSCRIAR}
                      >
                        Realize agora o processo de tokenização para adicionar
                        novos tokens
                      </Link>
                    )}
                  </div>
                </EmptyCard>
              )}
            </InfiniteScroll>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default AssetListPageAdmin;
