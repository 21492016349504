import api from "../services/api";

export default {
  async saveImage(image: string): Promise<void> {
    if (image && image !== "") {
      try {
        const form = new FormData();
        form.append("photo", image);
        const { status, data } = await api.exchange.post(
          "onboarding/liveness",
          form
        );

        if (status === 200) {
          return data;
        } else {
          throw new Error("Não foi possível salvar a imagem");
        }
      } catch (error) {
        throw error;
      }
    }
  },
};

export namespace liveness {
  export type image = {
    liveness: boolean;
  };
}
