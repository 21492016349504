import * as API from "./../api";
import { balance } from "../models/balance";
import React, { createContext, useState } from "react";

interface BalanceContextData {
  balance: balance;
  setBalance(p: any): any;

  currentPage: number;
  setCurrentPage(p: number): any;
  updateBalance(forceUpdate: boolean): any;
}

const BalanceContext = createContext<BalanceContextData>(
  {} as BalanceContextData
);

export const BalanceContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [balance, setBalance] = useState<balance>({
    gold: { available: 0, consumed: 0, full: 0, tokenQuantity: 0 },
    credit: { available: 0, consumed: 0, full: 0, tokenQuantity: 0 },
    invoice: { available: 0, consumed: 0, full: 0, tokenQuantity: 0 },
    currency: { available: 0, consumed: 0, full: 0, tokenQuantity: 0 },
    hasData: false,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);

  const updateBalance = (forceUpdate = false) => {
    if (!balance.hasData || forceUpdate) {
      API.user
        .getBalance()
        .then((data) => {
          setBalance({ ...data, hasData: true });
        })
        .catch(() => {});
    }
  };

  return (
    <BalanceContext.Provider
      value={{
        balance,
        setBalance,
        currentPage,
        setCurrentPage,
        updateBalance,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

export default BalanceContext;
