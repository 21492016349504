import React from "react";
import Colors from "../../assets/Colors";

const EmptyCard = ({
  children,
  blackTheme,
}: {
  children: string | JSX.Element | Array<JSX.Element>;
  blackTheme?: boolean;
}) => {
  return (
    <div
      className={`border-05 rounded flex flex-row justify-center p-2 py-12 mb-4 ${
        blackTheme ? "bg-black" : "bg-white"
      }`}
      style={{ borderColor: Colors.Gray4 }}
    >
      {typeof children === "string" ? (
        <p className="underline text-center">{children}</p>
      ) : (
        children
      )}
    </div>
  );
};

export default EmptyCard;
