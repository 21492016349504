import React from "react";
import * as API from "./../../api";
import { useContext } from "react";
import Colors from "../../assets/Colors";
import Modal from "../../components/Modal/Modal";
import Select from "../../components/Inputs/Select";
import AlertModal from "../../components/Modal/AlertModal";

export function handleOptIn(handleModal, setModal) {
  var months: number = 12;

  handleModal(Modal, {
    title: "Realizar opt-in?",
    titleColor: "text-black",
    confirmText: "CONFIRMAR",
    confirmColor: Colors.Green3,
    cancelText: "CANCELAR",
    cancelColor: "black",

    children: (
      <div className="text-center">
        <p>
          Após realizar o Opt-in seus recebíveis de cartão presentes na
          registradora serão tokenizados pelo sistema e ficarão disponíveis para
          negociação.
        </p>
        <p>Vamos começar?</p>
      </div>
    ),

    cancelButtonClick: () => setModal(false),
    confirmButtonClick: async () => {
      await API.register.optIn(12);

      setModal(false);
      handleModal(AlertModal, {
        buttonText: "IMPORTAR RECEBÍVEIS",
        buttonColor: Colors.Green3,

        children: (
          <div className="flex flex-col my-6 items-center gap-3">
            <div className="flex flex-col items-center gap-4">
              <h2 className="text-xl" style={{ color: Colors.Gray2 }}>
                Importar recebíveis
              </h2>
              <h3 className="" style={{ color: Colors.Gray3 }}>
                Selecione o período de importação dos recebíveis
              </h3>
            </div>
            <Select
              className="w-full text-black"
              defaultChecked={months}
              onChange={(p) => (months = Number(p.target.value))}
              options={[3, 6, 12, 24, 48, 120].map((e) => ({
                label: `Proximos ${e} meses`,
                value: e,
              }))}
            />
          </div>
        ),

        confirmClick: async () => {
          await API.register.tokenizeSchedule(months);

          setModal(false);
          handleModal(AlertModal, {
            title: "Tokenização em andamento",
            children: (
              <div className="flex flex-col text-center text-xs gap-2">
                <p>
                  O processo de importação e tokenização dos recebíveis de
                  cartão pode levar algum tempo.
                </p>
                <p>
                  Após a conclusão, você será notificado via email e seus
                  recebíveis de cartão irão automaticamente aparecer em sua
                  lista de recebíveis.
                </p>
              </div>
            ),
          });
        },
      });
    },
  });
}

export function handleOptOut(handleModal, setModal) {
  handleModal(Modal, {
    title: "Tokenização em andamento",
    confirmText: "PROSSEGUIR",
    confirmColor: Colors.Red4,
    cancelText: "MANTER RECEBÍVEIS",
    cancelColor: Colors.Gray3,
    children: (
      <div
        className="flex flex-col text-center gap-2"
        style={{ color: Colors.Gray3 }}
      >
        <p>
          Após realizar o Opt-out todas suas ordens de negociação de recebíveis
          de cartão serão interrompidas e os recebíveis serão removidos do
          sistema, não podendo mais ser acessados.
        </p>
        <p>Deseja mesmo realizar o Opt-out?</p>
      </div>
    ),

    cancelButtonClick: () => setModal(false),
    confirmButtonClick: async () => {
      await API.register.optOut();

      setModal(false);
      handleModal(AlertModal, {
        title: "Opt-out realizado",
        children: (
          <div className="flex flex-col text-center text-xs gap-2">
            <p>
              Todos seus recebíveis de cartão serão removidos do sistema. Por
              favor aguarde o processo.
            </p>
          </div>
        ),
      });
    },
  });
}
