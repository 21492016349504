import React from "react";
import Colors from "../../assets/Colors";
import "./ProgressBar.css";

interface MyProps {
  progress: number;
}

const ProgresBar: React.FC<MyProps> = (props) => {
  return (
    <div
      className="progress-bar flex flex-row w-full"
      style={{
        backgroundColor: Colors.Gray5,
      }}
    >
      <div
        className="h-full"
        style={{
          backgroundColor: Colors.Green3,
          width: `${props.progress}%`,
        }}
      />
    </div>
  );
};

export default ProgresBar;
