import React from "react";
import InputMask, { ReactInputMask } from "react-input-mask";

type MyProps = {
  placeholder: string;
  className: string;
  mask: string;
  defaultValue?: any;
};

const MaskedInput = React.forwardRef<ReactInputMask, MyProps>(
  ({ placeholder, className, mask, defaultValue }, ref) => {
    return (
      <InputMask
        mask={mask}
        placeholder={placeholder}
        ref={ref /*as RefObject<ReactInputMask>*/}
        defaultValue={defaultValue}
        /*onChange={props.onChange} value={props.value}*/
        // className='block p-1 w-full appearance-none focus:outline-none bg-transparent'
        className={
          "block p-1 w-full appearance-none focus:outline-none bg-transparent " +
          className
        }
      />
    );
  }
);

MaskedInput.defaultProps = {
  className: "",
  placeholder: "",
};

export default MaskedInput;
