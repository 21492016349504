import { FrequencyFeeGoldCustodyEnum } from "../api";
import Colors, { Gray4, Red4 } from "../assets/Colors";
import { TokenStatusEnum, TokenTypeEnum } from "./enums";
import { MatchStatusEnum } from "./enums/MatchStatusEnum";

export class Token {
  static getStatusData(status: TokenStatusEnum) {
    switch (status) {
      case TokenStatusEnum.PENDING:
        return {
          translate: "Tokenizando",
          color: Colors.Orange2,
        };
      case TokenStatusEnum.ACTIVE:
        return {
          translate: "Disponível",
          color: Colors.Green3,
        };
      case MatchStatusEnum.PROCESSING as any:
      case TokenStatusEnum.RESERVED:
        return {
          translate: "Em negociação",
          color: Colors.Blue1,
        };
      case TokenStatusEnum.CANCELED:
      case TokenStatusEnum.REMOVED:
        return {
          translate: "Removido",
          color: Gray4,
        };
      case TokenStatusEnum.EXPIRATE:
        return {
          translate: "Expirado",
          color: Red4,
        };
      case TokenStatusEnum.FINISHED:
        return {
          translate: "Negociado",
          color: Colors.Gray4,
        };
    }
  }

  static translateStatus = (status: TokenStatusEnum): string =>
    Token.getStatusData(status)?.translate ?? "";
  static getStatusColor = (status: TokenStatusEnum): string =>
    Token.getStatusData(status)?.color ?? "";
}

export interface GoldToken extends Token {
  amount: number;
  certificationCode: string;
  description: string;
  feeCustody: number;
  frequencyFee: FrequencyFeeGoldCustodyEnum;
  redemptionDate: Date;
  redemptionDeadline: Date;

  remainingQuantity: number;
  available: number;
  consumed: number;
  // sagaId: "08de61a1-8c12-4523-9e5c-0b35e36d1ce8"
  // userId: "97b209ed-cbbc-454a-bfa9-bdb3eb8d7fb2"
}

// eslint-disable-next-line no-redeclare
export type invoice = {
  accessKey: string;
  emitDate: Date;
  emitName: string;
  emitDocument: string;
  destName: string;
  destDocument: string;
  emitCompanyName: string;
  destCompanyName: string;
  paidAmount: number;
};

// eslint-disable-next-line no-redeclare
export type creditReceivable = {
  id: string;
  holderDocument: string;
  settlementDate: Date;
  totalConstitutedValue: number;
  antecipatedConstitutedValue: number;
  valueBlocked: number;
  create_at: Date;
  receivingEndUserDocument: string;
};

interface TokenMetadataProperties {
  unit: string;
  risks: string;
  rewards: string;
  warranty: string;
  feeCustody: number;
  attachments: any;
  frequencyFee: string;
  redemptionDate: string;
  certificationCode: string;
  redemptionDeadline: string;
}

interface TokenMetadata {
  id: number;
  name: string;
  type: string;
  image: string;
  sagaId: string;
  symbol: string;
  userId: string;
  decimals: number;
  tenantId: string;
  properties: TokenMetadataProperties;
  description: string;
  emissionDate: string;
  contractAddress: string;
}

// eslint-disable-next-line no-redeclare
export interface Token {
  id: string;
  amount: number;
  type: TokenTypeEnum;
  status: TokenStatusEnum;
  create_at: Date;
  updateDate: Date; /// DEVO atualizar na api
  emissionDate: Date; /// DEVO atualizar na api
  settlementDate: Date; /// DEVO atualizar na api
  tokenizationDate: Date;

  /// Apenas em detalhes
  invoice: invoice;
  creditReceivable: creditReceivable;

  receivingEndUserDocument: string;
  registeringEntity: string;
  accreditationInstitution: string;
  paymentArrangementCode: string;
  metadata: any;
  totalIssued: number;
  maxSupply: number;
  burned: number;

  /**
   * payee: "payee name"
   * payeeDocument: "payee document"
   * payer: "payer name"
   * payerDocument: "payer document"
   */
}
