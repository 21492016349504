import React from "react";
import Colors from "../assets/Colors";
import Footer from "../components/Footer/Footer";
import TopBar from "../components/TopBar/TopBar";

const SplashScreen: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen text-sm font-sans">
      <TopBar showBlankTopBar />
      <main
        className="flex-grow flex flex-col p-3 w-full items-center justify-center"
        style={{ backgroundColor: Colors.BackGroudIndexPage }}
      >
        <div
          className="spinner"
          style={{
            height: "180px",
            bottom: "calc(50% + 0px)",
            left: "calc(50% - 100px)",
            position: "absolute",
          }}
        >
          <svg viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="20" />
          </svg>
        </div>
      </main>
    </div>
  );
};

export default SplashScreen;
