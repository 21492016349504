import React, { useEffect, useState } from "react";
import Colors from "../../../assets/Colors";
import * as Gifs from "../../../assets/gifs";
import Button from "../../../components/Buttons/Button";
import FilterBar from "../../../components/FilterBar/FilterBar";
import PageSkeleton from "../../../components/PageSkeleton/PageSkeleton";
import { ClientDocumentStatusEnum } from "../../../models";
import { DocumentsTypeEnum } from "../../../models/enums";
import RoutesPaths from "../../../Routes/RoutesPaths";
import history from "../../../services/history";
import { useParams } from "react-router-dom";
import * as API from "../../../api";
import ClientDetailsFilterBar from "../ClientDetailsPage/ClientDetailsFilterBar/ClientDetailsFilterBar";

const ClientDocumentsPage: React.FC<any> = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [clientDetails, setClientDetails] = useState<any>(undefined);
  const [isClientDetailsLoading, setIsClientDetailsLoading] =
    useState<boolean>(false);

  useEffect(() => {
    setIsClientDetailsLoading(true);
    API.admin
      .loadClient(clientId)
      .then((details) => setClientDetails(details))
      .catch((error) => console.error(error))
      .finally(() => setIsClientDetailsLoading(false));
  }, []);

  const getDocStatus = (
    documentTypes: Array<DocumentsTypeEnum>
  ): ClientDocumentStatusEnum | undefined => {
    let approvedCount = 0;
    if (!clientDetails?.documentations) return undefined;

    const documentsToValidate = clientDetails?.documentations.filter((doc) =>
      documentTypes.includes(doc.type)
    );

    if (documentsToValidate.length === 0) return undefined;

    for (const doc of documentsToValidate) {
      if (doc.status === ClientDocumentStatusEnum.WAITING) {
        return ClientDocumentStatusEnum.WAITING;
      }

      if (doc.status === ClientDocumentStatusEnum.APPROVED) {
        approvedCount++;
      }
    }

    if (approvedCount === documentsToValidate.length) {
      return ClientDocumentStatusEnum.APPROVED;
    } else {
      return ClientDocumentStatusEnum.DISAPPROVED;
    }
  };

  const certificationSteps = [
    {
      title: "Documento de identificação",
      // description: `Data da verificação: DD/MM/AAAA`,
      description: "",
      route:
        getDocStatus([
          DocumentsTypeEnum.FEDERALID_FRONT,
          DocumentsTypeEnum.FEDERALID_BACK,
        ]) === ClientDocumentStatusEnum.WAITING
          ? `${RoutesPaths.ADMIN_CLIENTS_PERSONAL_DOCUMENTS_ANALYZE}/${clientId}`
          : null,
      status: getDocStatus([
        DocumentsTypeEnum.FEDERALID_FRONT,
        DocumentsTypeEnum.FEDERALID_BACK,
      ]),
      hidden: false,
    },
    {
      title: "Enviar comprovante de endereço",
      // description: `Data da verificação: DD/MM/AAAA`,
      description: "",
      route:
        getDocStatus([DocumentsTypeEnum.PROOF_OF_RESIDENCE]) ===
        ClientDocumentStatusEnum.WAITING
          ? `${RoutesPaths.ADMIN_CLIENTS_DOCUMENTS_ANALYZE}/${clientId}`
          : null,
      status: getDocStatus([DocumentsTypeEnum.PROOF_OF_RESIDENCE]),
      hidden: false,
    },
  ];

  return (
    <PageSkeleton
      isAdmin
      useContentCard
      filterBar={
        <ClientDetailsFilterBar
          clientName={clientDetails?.name}
          pageTitle="Certificação"
        />
      }
      button2={{
        label: "Voltar ao resumo da conta",
        onClick: () => history.goBack(),
      }}
    >
      <div className="w-4/5 lg:w-3/5 max-w-2xl flex flex-col gap-3 my-3 items-center">
        <h1
          className="text-center text-2xl font-normal"
          style={{ color: Colors.Gray2 }}
        >
          {/* <span className="font-medium">Certifique sua conta</span> ({TypeUserEnumInterpreter.label(user?.userType)}) */}
          <span className="font-medium">Certificação da conta</span>
        </h1>

        <img
          style={{ width: "290px" }}
          src={Gifs.IconAccountCertificate}
          alt=""
        />

        <h2
          className="text-center text-lg font-normal mb-14 whitespace-pre-line"
          style={{ color: Colors.Gray2 }}
        >
          Veja abaixo o status atual dos processos de verificação desta conta.
        </h2>
        {certificationSteps
          .filter(({ hidden }) => !hidden)
          .map((elm, index) => {
            let color: string = "";
            let label: string = "";

            switch (elm.status) {
              case ClientDocumentStatusEnum.APPROVED:
                color = Colors.Green3;
                label = "Ok";
                break;
              case ClientDocumentStatusEnum.DISAPPROVED:
                color = Colors.RedMyAccount;
                label = "Recusado";
                break;
              case ClientDocumentStatusEnum.WAITING:
                color = Colors.Blue2;
                label = "Em análise";
                break;
              default:
                color = Colors.RedMyAccount;
                label = "Pendente";
            }

            return (
              <div
                className="cliente-doc-status-card p-5 rounded border w-full"
                style={{ borderColor: Colors.Gray4 }}
                key={index}
              >
                <strong
                  style={{ color: Colors.Gray2 }}
                  className="text-lg font-medium"
                >{`${index + 1} - ${elm.title}`}</strong>
                <p
                  className="text-sm font-normal"
                  style={{ color: Colors.Gray3 }}
                >
                  {elm.description}
                </p>
                <Button
                  loading={isClientDetailsLoading}
                  className="documents-btn mt-5"
                  style={{ backgroundColor: color, borderColor: color }}
                  textColor={Colors.White}
                  onClick={() => {
                    if (elm.route) {
                      console.log(elm.route);
                      history.push(elm.route);
                    }
                  }}
                >
                  {label}
                </Button>
              </div>
            );
          })}
      </div>
    </PageSkeleton>
  );
};

export default ClientDocumentsPage;
