import React, { createRef, useEffect, useRef, useState } from "react";
import Colors from "../../assets/Colors";
import * as Icons from "./../../assets/icons";

export interface UploadDocProps {
  title?: string;
  document: {
    identifier: string;
    label: string;
    description: string;
  };
  type: "PDF" | "IMAGE" | "ALL";
  className?: string;
  fullW?: boolean;
  eventOnChange?: (file, identifier) => void;
}

const UploadDoc: React.FC<UploadDocProps> = ({
  title,
  document,
  type,
  className,
  fullW,
  eventOnChange,
}) => {
  const [uploader] = useState<React.RefObject<HTMLInputElement>>(useRef(null));
  const [nameFile, setNameFile] = useState("");

  let typeUploader;
  switch (type) {
    case "IMAGE":
      typeUploader = "image/*";
      break;
    case "PDF":
      typeUploader = "image/*, application/pdf";
      break;
    default:
      typeUploader = "application/pdf";
      break;
  }

  const onClickUploader = () => {
    if (uploader && uploader.current) {
      uploader.current.click();
    }
  };

  const changeFile = (value) => {
    if (value.currentTarget.files && value.currentTarget.files.length > 0) {
      const file = value.currentTarget.files[0];
      setNameFile(file.name);
      if (eventOnChange) {
        eventOnChange(file, document.identifier);
      }
    }
  };

  return (
    <>
      {title && (
        <h2 className="text-lg mb-3" style={{ color: Colors.Gray2 }}>
          {title}
        </h2>
      )}
      <div
        className={
          "flex flex-col border rounded overflow-hidden items-center px-4 sm:px-5 py-5 mb-5 cursor-pointer " +
          (fullW ? "w-full" : "w-4/5 lg:w-3/5 max-w-2xl") +
          " " +
          className
        }
        style={{ borderColor: Colors.Gray4 }}
        onClick={() => onClickUploader()}
      >
        <p className="text-base font-medium" style={{ color: Colors.Gray2 }}>
          {document.label}
        </p>
        <p
          className="text-xs sm:text-sm font-normal mt-3 mb-4"
          style={{ color: Colors.Gray3 }}
        >
          {document.description}
        </p>
        <div
          className="flex flex-row border rounded w-full overflow-hidden"
          style={{ borderColor: Colors.Gray4 }}
        >
          <div
            className="flex flex-row px-3 py-3 items-center border-r"
            style={{ backgroundColor: Colors.Gray6, borderColor: Colors.Gray4 }}
          >
            <p
              className="text-sm md:text-lg font-normal"
              style={{ color: Colors.Gray3 }}
            >
              Selecionar<span className="hidden sm:inline"> arquivo</span>:
            </p>
            <input
              type="file"
              ref={uploader}
              id="uploader"
              name="filename"
              accept={typeUploader}
              onChange={(value) => changeFile(value)}
              style={{ opacity: 0, position: "absolute", zIndex: -1 }}
            ></input>
          </div>
          <div className="text-sm md:text-lg font-normal flex flex-row justify-between px-3 py-3 items-center flex-grow">
            {nameFile !== "" ? (
              <p style={{ color: Colors.Gray2 }}>{nameFile}</p>
            ) : (
              <p style={{ color: Colors.Gray4 }}>Nenhum arquivo anexado</p>
            )}
            <img src={Icons.UploadIcon} alt="upload icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadDoc;
