import exchange from "./api/exchange";
import onboarding from "./api/exchange";
import { viaCep } from "./api/viacep";

export default {
  exchange,
  onboarding,
  viaCep,
};

export interface ResponseModel {
  success: boolean;
  error: string;
  data: any;
  jwt: string;
}
