import Colors from "../../assets/Colors";
import * as Icons from "./../../assets/icons";

export enum TokenStatusEnum {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  RESERVED = "RESERVED",
  REMOVED = "REMOVED",
  EXPIRATE = "EXPIRATE",
  FINISHED = "FINISHED",
  CANCELED = "CANCELED",
}

export class TokenStatusEnumInterpreter {
  static convert(status: TokenStatusEnum) {
    switch (status) {
      case TokenStatusEnum.ACTIVE:
        return {
          translate: "Disponível",
          color: Colors.Blue1,
          icon: Icons.SmallWaitingIcon,
        };
      case TokenStatusEnum.FINISHED:
        return {
          translate: "Negociado",
          color: Colors.Green3,
          icon: Icons.SmallOkIcon,
        };
      case TokenStatusEnum.CANCELED:
      case TokenStatusEnum.REMOVED:
        return {
          translate: "Removido",
          color: Colors.Gray4,
          icon: Icons.SmallCanceledIcon,
        };
      case TokenStatusEnum.EXPIRATE:
        return {
          translate: "Expirado",
          color: Colors.Red4,
          icon: Icons.SmallCanceledIcon,
        };
      default:
        return {
          icon: Icons.ErrorIcon,
        };
    }
  }
}

var asdasd = {
  id: "3ff9a91b-0735-472e-99c8-60d4d8d6e116",
  amount: 51,
  fee: 0,
  tokenExpirationDateStart: "2022-01-03T00:00:00.000Z",
  tokenExpirationDateEnd: "2022-01-03T00:00:00.000Z",
  typeReceivable: "GOLD",
  userId: "8c65cb35-63e1-4dc6-96a1-2b82dbbb24cb",
  creationDate: "2021-12-13T18:13:38.304Z",
  type: "sell",
  receivable: null,
  feeType: "FIXED",
  consumed: 0,
  processing: 0,
  available: 51,
  tokens: [
    {
      id: 5542,
      type: "GOLD",
      tokenType: "FUNGIBLE",
      metadata: {
        type: "GOLD",
        unit: "G",
        amount: "1000",
        sagaId: "2285975a-b9b6-4995-9752-eca45a496628",
        userId: "8c65cb35-63e1-4dc6-96a1-2b82dbbb24cb",
        feeCustody: "100",
        description: "uma nota",
        frequencyFee: "DAILY",
        redemptionDate: "2022-01-03",
        certificationCode: "123123",
        redemptionDeadline: "2022-01-02",
      },
      finished: false,
    },
  ],
  matchs: [],
};
