import React, { createContext, useState } from "react";
import { Token } from "../models";

interface AssetContextData {
  token?: Token;
  setAsset(p: Token): any;

  cadastredToken?: string;
  setCadastredToken(p: string | undefined): any;
}

const AssetContext = createContext<AssetContextData>({} as AssetContextData);

export const AssetContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [cadastredToken, setCadastredToken] = useState<string | undefined>(
    undefined
  );
  const [token, setAsset] = useState<Token | undefined>(undefined);

  return (
    <AssetContext.Provider
      value={{ token, setAsset, cadastredToken, setCadastredToken }}
    >
      {children}
    </AssetContext.Provider>
  );
};

export default AssetContext;
