import React from "react";
import PhoneInput from "react-phone-input-2";
import Colors from "../../../assets/Colors";

type MyProps = {
  className?: string;
  textColor?: string;
  country: string;
  value: string;
  change: any;
  onKeyDown?: any;
};

const PhoneInputCustom = React.forwardRef(
  (
    { className, textColor, value, country, change, onKeyDown }: MyProps,
    ref
  ) => {
    return (
      <div className={"rounded outline relative text-gray-700 " + className}>
        <PhoneInput
          containerStyle={{
            width: "100%",
          }}
          inputStyle={{
            width: "100%",
            paddingLeft: 60,
            borderLeft: 0,
            borderColor: Colors.Gray4,
            color: "#444",
            height: "58px",
            fontWeight: 400,
            fontSize: 18,
          }}
          buttonStyle={{
            backgroundColor: "#fff",
            borderRight: 0,
            borderColor: Colors.Gray4,
            paddingLeft: "14px",
          }}
          country={country}
          value={value}
          onChange={change}
          onKeyDown={onKeyDown}
        />
      </div>
    );
  }
);

PhoneInputCustom.defaultProps = {
  className: "",
};

export default PhoneInputCustom;
