import React from "react";
import ReactDOM from "react-dom";
import ModalContext from "../contexts/ModalProvider";

const ModalProviderRender = () => {
  const { modalContent, ModalComponent, modal, setModal } =
    React.useContext(ModalContext);

  if (modal && ModalComponent) {
    const Component = (() => ModalComponent.p1)() as any;

    return ReactDOM.createPortal(
      <Component
        state={true}
        changeState={() => setModal(false)}
        {...modalContent}
      />,
      document.querySelector("#modal-root") as Element
    );
  } else return null;
};

export default ModalProviderRender;
