import React from "react";
import Colors from "../assets/Colors";
import Button from "../components/Buttons/Button";
import Footer from "../components/Footer/Footer";
import RoutesPaths from "../Routes/RoutesPaths";
import history from "../services/history";
import * as Images from "./../assets/images";

const IndexPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen text-sm font-sans">
      <main
        className="flex-grow flex flex-col p-3 w-full items-center justify-start pt-28"
        style={{ backgroundColor: Colors.BaseUser }}
      >
        <img src={Images.LogoBlack} alt="logo" />

        <div className="my-5 self-center flex flex-col items-center pt-2">
          <img src={Images.Slogan} alt="slogan" />
        </div>

        <div className="pt-20">
          <div className="my-2 w-80">
            <Button
              big={false}
              className="font-bold text-lg py-0 w-full"
              onClick={() => {
                history.push(RoutesPaths.SIGNIN);
              }}
            >
              Acesse sua conta
            </Button>
          </div>

          <div className="my-2 w-80">
            <Button
              big={false}
              className="font-bold text-lg py-0"
              textColor={Colors.White}
              backgroundColor="transparent"
              borderColor={Colors.White}
              onClick={() => {
                history.push("signup");
              }}
            >
              Para quem quer fazer muito com token
            </Button>
          </div>
        </div>
      </main>
      <Footer
        backgroundColor={Colors.BaseUser}
        textColor={Colors.CorDetalhes}
      />
    </div>
  );
};

export default IndexPage;
