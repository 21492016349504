import React, { useContext } from "react";

/// Assets
import history from "../../services/history";
import Colors from "../../assets/Colors";
import * as Icons from "../../assets/icons";
import RoutesPaths from "../../Routes/RoutesPaths";

/// Components
import SplitedCard from "../../components/Cards/SplitedCard";
import UserContext from "../../contexts/UserProvider";
import PageBoardSkeleton from "../../components/PageBoardSkeleton/PageBoardSkeleton";

const BankAccoutPage: React.FC = () => {
  const userContext = useContext(UserContext);
  let bank = {
    bank: "001",
    bankAccount: "12345-6",
    bankAgency: "000001",
    ispb: "0",
    shortName: "Bank unknown",
  };

  if (userContext.user && userContext.user.userBankInfo) {
    bank = userContext.user.userBankInfo;
  }

  return (
    <>
      <PageBoardSkeleton
        filterBar="Dados Bancários"
        onClickFilterBar={() => history.push(RoutesPaths.ACCOUT)}
        button2={{
          label: "Voltar à minha conta",
          onClick: () => history.push(RoutesPaths.ACCOUT),
        }}
      >
        <SplitedCard
          icon={Icons.BankIcon}
          title={
            <div className="text-xl" style={{ color: Colors.Gray2 }}>
              <span className="font-medium">Dados Bancários</span>
              <span className="font-light"> (BRL)</span>
            </div>
          }
          subTitle="Conta bancária para saques na plataforma via PIX"
          className="mt-8"
          childrenClassName="bank-account-content"
          onClick={() => history.push(RoutesPaths.ACCOUT_BANK_DOMICILIO)}
        >
          <div
            className="flex flex-row w-full items-center cursor-pointer"
            onClick={() => history.push(RoutesPaths.ACCOUT_BANK_DOMICILIO)}
          >
            <div
              className="flex flex-col flex-grow text-base font-medium"
              style={{ color: Colors.Gray2 }}
            >
              <p>
                Banco: {bank.shortName} ({bank.bank})
              </p>
              <p>AG: {bank.bankAgency}</p>
              <p>CC: {bank.bankAccount}</p>
            </div>
          </div>
        </SplitedCard>

        <div
          className="w-full sm:w-4/5 lg:w-3/5 max-w-2xl text-center mt-11 mb-1 flex flex-col items-center text-sm font-normal"
          style={{ color: Colors.Gray3 }}
        >
          <p className="underline mb-3">ATENÇÃO:</p>
          <p>
            Mantenha seus dados bancários sempre atualizados. Não nos
            responsabilizamos por erros ocasionados pelo preenchimento de dados
            incorretos.
          </p>
        </div>
      </PageBoardSkeleton>
    </>
  );
};

export default BankAccoutPage;
