import React, { CSSProperties } from "react";

interface MyProps {
  left: JSX.Element | Array<JSX.Element>;
  right: JSX.Element | Array<JSX.Element>;
  styli?: CSSProperties;
  style?: CSSProperties;
  onClick?: () => any;
}

const CardBase: React.FC<MyProps> = ({
  left,
  right,
  styli,
  style,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      style={{ ...styli, ...style }}
      className={`border-05 rounded flex flex-row justify-between p-2 mb-4 cursor-pointer`}
    >
      <div className="flex flex-col">{left}</div>
      <div className="flex flex-col">{right}</div>
    </div>
  );
};

export default CardBase;
