import { miniSerializeError } from "@reduxjs/toolkit";
import api from "../../../services/api";
import { AuthRequest, AuthResponse } from "./authTypes";

export const authSignin = (payload: AuthRequest) => {
  return new Promise<AuthResponse>(async (resolve, reject) => {
    const { email, password } = payload;
    const { status, data } = await api.exchange.post("/auth/signin", {
      email,
      password,
    });

    if (![200, 201].includes(status)) return reject(data.message?.[0]);

    return resolve({
      data,
    });
  });
};
