import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

/// Assets
import history from "../../../../services/history";
import * as Colors from "../../../../assets/Colors";
import * as Icons from "../../../../assets/icons";
import * as API from "../../../../api";

/// Componentes
import Divider from "../../../../components/Divider/Divider";
import StamentContext from "../../../../contexts/AssetProvider";
import Button from "../../../../components/Buttons/Button";
import FilterBar from "../../../../components/FilterBar/FilterBar";
import UnderlineButton from "../../../../components/Buttons/UnderlineButton";

import GoldNegociationBar from "../../../../components/Modal/GoldNegociationBar/GoldNegociationBar";
import "./../style.css";
import "./MyAssetDetailsAdmin.css";
import PageTopBanner from "../../../../components/PageTopBanner/PageTopBanner";
import ResumeBar from "../../../../components/ResumeBar/ResumeBar";
import ResumeCard from "../../../../components/Cards/ResumeCard/ResumeCard";
import { useParams } from "react-router-dom";
import { TokenProjectsEnum } from "../../../../models/enums/tokenProjectsEnum";
import { formatToCurrency } from "../../../../utils/formatters";
import Footer from "../../../../components/Footer/Footer";

const MyAssetsDetailsAdmin: React.FC<any> = () => {
  const [token, setToken] = useState<any>(undefined);
  const [negociationBar, setNegociationBar] = useState<boolean>(false);
  const stamentContext = useContext(StamentContext);

  const params: { type: TokenProjectsEnum; tokenId: string } = useParams();

  useEffect(() => {
    loadTokenDetails();
  }, []);

  const loadTokenDetails = async () => {
    try {
      const token = await API.admin.getTokenDetailsAdmin(
        params.type,
        Number(params.tokenId)
      );
      setToken(token);
      stamentContext.setAsset(token);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {token && (
        <GoldNegociationBar
          state={negociationBar}
          token={token}
          changeState={() => {
            setNegociationBar(!negociationBar);
          }}
        />
      )}
      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar goBackBtn title="Detalhes do lote" />

          <PageTopBanner
            title={token?.metadata?.properties?.unit}
            loading={!token}
            subtitle={token?.metadata?.name}
            bgIcon={Icons.GoldBanner2Icon}
            imgIcon={token?.metadata.image}
            borderlessInnerIcon
          />

          <div
            className="bg-white px-2 py-1 mx-5 mb-0 flex flex-col aling-center items-center rounded border"
            style={{ borderColor: Colors.Gray4 }}
          >
            <div className="whitespace-pre my-3 px-3 w-full justify-start">
              <div
                className="flex gap-1 mb-3 font-medium text-xl"
                style={{ color: Colors.Gray2 }}
              >
                <span>LOTE #{token?.id}</span>
                <img src={Icons.InfoBlueIcon} />
              </div>

              <p className="text-sm font-light" style={{ color: Colors.Gray3 }}>
                Status atual:{" "}
                <span className="font-medium" style={{ color: Colors.Gray2 }}>
                  MERCADO
                </span>
              </p>
              <p
                className="text-sm font-light mb-2"
                style={{ color: Colors.Gray3 }}
              >
                Código interno: ---
              </p>
              <p
                style={{ color: Colors.Gray3 }}
                className="text-base font-light"
              >
                Emissão:{" "}
                <span className="font-normal">
                  {moment(token?.metadata?.emissionDate).format("DD/MM/YYYY")}
                </span>
              </p>
              <p
                style={{ color: Colors.Gray3 }}
                className="text-base font-light"
              >
                Vencimento:{" "}
                <span className="font-normal">
                  {moment(token?.metadata?.properties?.redemptionDate).format(
                    "DD/MM/YYYY"
                  )}
                </span>
              </p>
              <p
                style={{ color: Colors.Gray3 }}
                className="text-base font-light"
              >
                Validade:{" "}
                <span className="font-normal">
                  {moment(
                    token?.metadata?.properties?.redemptionDeadline
                  ).format("DD/MM/YYYY")}
                </span>
              </p>
              <p
                style={{ color: Colors.Gray2 }}
                className="mt-4 text-base font-semibold"
              >
                Total emitido: {formatToCurrency(token?.totalIssued)}{" "}
                {token?.metadata?.properties?.unit}
              </p>
              <p
                style={{ color: Colors.Gray3 }}
                className="text-base font-light"
              >
                Máx. Supply:{" "}
                <span className="font-normal">
                  {formatToCurrency(token?.maxSupply)}{" "}
                  {token?.metadata?.properties?.unit}
                </span>
              </p>
            </div>

            <Divider color={Colors.Gray4} top="mt-1" bottom="b-0" />

            <div className="flex flex-row w-full justify-center">
              <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
                <ResumeBar>
                  <ResumeCard
                    bgColor={Colors.Green2}
                    invert
                    textColor="white"
                    title="Disponíveis"
                    value={token?.available}
                    unit=""
                  />
                  <div className="w-5"></div>
                  <ResumeCard
                    bgColor={Colors.Blue1}
                    invert
                    textColor="white"
                    title="À venda"
                    value={token?.consumed}
                    unit=""
                  />
                  <div className="w-5"></div>
                  <ResumeCard
                    bgColor={Colors.Purple2}
                    invert
                    textColor="white"
                    title="Emitidos"
                    value={token?.totalIssued}
                    unit=""
                  />
                  <div className="w-5"></div>
                  <ResumeCard
                    bgColor={Colors.Orange2}
                    invert
                    textColor="white"
                    title="Queimados"
                    value={token?.burned}
                    unit=""
                  />
                </ResumeBar>
              </div>
            </div>

            <p
              className="text-2xl font-normal my-6"
              style={{ color: Colors.Gray2 }}
            >
              Total em wallet:
              <span className="font-medium">
                {" "}
                {formatToCurrency(token?.available + token?.consumed)}
              </span>{" "}
              {token?.metadata?.properties?.unit}
            </p>

            <Button
              className="text-lg"
              backgroundColor={Colors.Gold4}
              textColor={Colors.White}
              disabled={!token || token?.available <= 0}
              onClick={() => {
                setNegociationBar(!negociationBar);
              }}
            >
              Negociar no mercado
            </Button>

            <Button
              className="my-5 text-lg"
              disabled={!token || token?.available <= 0}
              onClick={() => {
                setNegociationBar(!negociationBar);
              }}
              backgroundColor={Colors.Orange2}
              textColor={Colors.White}
            >
              Queimar tokens
            </Button>
            <UnderlineButton
              className="font-medium text-lg"
              style={{ color: Colors.Gray3 }}
              onClick={history.goBack}
            >
              Voltar a listagem
            </UnderlineButton>
          </div>
          <br />
        </main>
        <Footer />
      </div>
    </>
  );
};
export default MyAssetsDetailsAdmin;
