import { IValidation } from "./IValidation";

export class BirthDateValidation implements IValidation {
  private regex =
    /(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/((19|20|21)\d{2})/;
  private value: string = "";
  msgError = "Data de nascimento inválida.";

  isValid(value: string): boolean {
    this.value = value;
    return this.regex.test(this.value);
  }

  isNotValid(value: string): boolean {
    return !this.isValid(value);
  }

  getErrorMessage(): string {
    if (this.isValid(this.value) || this.value === "") {
      return "";
    }
    return this.msgError;
  }
}
