import * as Yup from "yup";

export const holderDetailsValidation = Yup.object()
  .shape({
    nome: Yup.string().required(),
    documento: Yup.string().required(),
    socio: Yup.string().required(),
    cpf: Yup.string().required(),
    email: Yup.string().email().required(),
    telefone: Yup.string().required(),
  })
  .required();
