import React from "react";
import RoutesPaths from "./RoutesPaths";
import history from "../services/history";
import { Route, Router, Switch } from "react-router-dom";
import { AuthRoute, NoAuthRoute, PublicRoute } from "./routeTypes";

/// Pages
import IndexPage from "../pages/IndexPage";
import Mercado from "../pages/Mercado/Mercado";
import UseTermPage from "../pages/UseTermPage";
import MyOrders from "../pages/MyOrders/MyOrders";
import SigninPage from "../pages/Auth/SigninPage/SigninPage";
import MyAssets from "../pages/MyAssets/MyAssets";
import AddressPage from "../pages/Account/AddressPage/AddressPage";
import MyBalance from "../pages/MyBalance/MyBalance";
import DepositPage from "../pages/MyBalance/DepositPage";
import OrderDetails from "../pages/MyOrders/OrderDetails";
import ClientsPage from "../pages/AdminPanel/Clients/ClientsPage";
import DocumentsPage, {
  ComplementaryDocumentationPage,
  DocumentPage,
  LivenessPage,
} from "../pages/Account/DocumentsPage/DocumentsPage";
import TransferPage from "../pages/TransferPage/TransferPage";
import AssetListPage from "../pages/MyAssets/AssetListPage";
import BankAccoutPage from "../pages/Account/BankAccoutPage";
import MyAssetsCreate from "../pages/MyAssets/MyAssetsCreate/MyAssetsCreate";
import MyAssetDetails from "../pages/MyAssets/MyAssetDetails/MyAssetDetails";
import PreDepositPage from "../pages/MyBalance/PreDepositPage";
import { PagesHandler } from "../pages/Auth/Onboarding/PagesHandler";
import LimitsPage from "../pages/Account/LimitesPage/LimitesPage";
import AccountPage from "../pages/Account/AccountPage/AccountPage";
import PasswordPage from "../pages/Account/PasswordPage/PasswordPage";
import ClientDetailsPage from "../pages/AdminPanel/ClientDetailsPage/ClientDetailsPage";
import BankingDomicilePage from "../pages/Account/BankingDomicilePage/BankingDomicilePage";
import MyAssetsNegociation from "../pages/MyAssets/MyAssetsNegociation";
import { RegisterPageHandler } from "../pages/Auth/Onboarding/RegisterSteps";
import ForgotPasswordPage from "../pages/ForgotPasswordPage/ForgotPasswordPage";
import HolderDetailsPage from "../pages/Account/HolderDetailsPage/HolderDetailsPage";
import MyAssetsCreateConfirm from "../pages/MyAssets/MyAssetsCreateConfirm/MyAssetsCreateConfirm";
import BanAccountPage from "../pages/AdminPanel/BanAccountPage";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import TokensPage from "../pages/TokensPage/TokensPage";
import TokenDetailsPage from "../pages/TokenDetailsPage/TokenDetailsPage";
import DashboardAdminPage from "../pages/DashboardAdminPage/DashboardAdminPage";
import MyOrdersAdminPage from "../pages/MyOrdersAdminPage/MyOrdersAdminPage";
import ClientBankAccountPage from "../pages/AdminPanel/ClientDetailsPage/ClientBankAccountPage/ClientBankAccountPage";
import ClientAddressPage from "../pages/AdminPanel/ClientDetailsPage/ClientAddressPage/ClientAddressPage";
import ClientBalancePage from "../pages/AdminPanel/ClientDetailsPage/ClientBalancePage/ClientBalancePage";
import ClientOrdersPage from "../pages/AdminPanel/ClientDetailsPage/ClientOrdersPage/ClientOrdersPage";
import ClientDataPage from "../pages/AdminPanel/ClientDetailsPage/ClientDataPage/ClientDataPage";
import ClientWalletPage from "../pages/AdminPanel/ClientDetailsPage/ClientWalletPage/ClientWalletPage";
import ClientLimitTaxesPage from "../pages/AdminPanel/ClientDetailsPage/ClientLimitTaxesPage/ClientLimitTaxesPage";
import DocumentAddressPage from "../pages/Account/DocumentsPage/AddressDocumentPage/AddressDocumentPage";
import ClientDocumentsPage from "../pages/AdminPanel/ClientDocumentsPage/ClientDocumentsPage";
import ClientDocumentsAnalyzePage from "../pages/AdminPanel/ClientDocumentsAnalyzePage/ClientDocumentsAnalyzePage";
import MyAssetsAdmin from "../pages/AdminPanel/MyAssets/MyAssetsAdmin";
import AssetListPageAdmin from "../pages/AdminPanel/MyAssets/AssetListPageAdmin";
import MyAssetsDetailsAdmin from "../pages/AdminPanel/MyAssets/MyAssetDetailsAdmin/MyAssetDetailAdmin";
import ResetPasswordPage from "../pages/ResetPasswordPage/ResetPasswordPage";
import ClientPersonalDocumentsAnalyzePage from "../pages/AdminPanel/ClientPersonalDocumentsAnalyzePage/ClientPersonalDocumentsAnalyzePage";

const Routes: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path={RoutesPaths.ACCOUT_BASE}>
          <Switch>
            <AuthRoute
              exact
              path={RoutesPaths.ACCOUT}
              component={AccountPage}
            />
            <AuthRoute
              exact
              path={RoutesPaths.ACCOUT_LIMITES}
              component={LimitsPage}
            />
            <AuthRoute
              exact
              path={RoutesPaths.ACCOUT_BANK}
              component={BankAccoutPage}
            />
            <AuthRoute
              exact
              path={RoutesPaths.ACCOUT_BANK_DOMICILIO}
              component={BankingDomicilePage}
            />
            <AuthRoute
              exact
              path={RoutesPaths.ACCOUT_DOCUMENTS}
              component={DocumentsPage}
            />
            <AuthRoute
              exact
              path={RoutesPaths.ACCOUT_ENDERECO}
              component={AddressPage}
            />
            <AuthRoute
              exact
              path={RoutesPaths.ACCOUT_DATA}
              component={HolderDetailsPage}
            />
            <AuthRoute
              exact
              path={RoutesPaths.ACCOUT_PASSWORD}
              component={PasswordPage}
            />
            <AuthRoute
              exact
              path={RoutesPaths.ACCOUT_LIVENESS}
              component={LivenessPage}
            />
            <AuthRoute
              exact
              path={RoutesPaths.ACCOUT_DOCUMENT}
              component={DocumentPage}
            />
            <AuthRoute
              exact
              path={RoutesPaths.ACCOUT_DOCUMENT_COMPLEMENTARY}
              component={ComplementaryDocumentationPage}
            />
            <AuthRoute
              exact
              path={
                RoutesPaths.ACCOUT_DOCUMENT_COMPLEMENTARY +
                "/:typeDocumentation"
              }
              component={ComplementaryDocumentationPage}
            />
            <AuthRoute
              exact
              path={RoutesPaths.ACCOUT_DOCUMENT_ADDRESS}
              component={DocumentAddressPage}
            />
          </Switch>
        </Route>
        <AuthRoute
          exact
          path={RoutesPaths.DASHBOARD}
          component={DashboardPage}
        />
        <AuthRoute exact path={RoutesPaths.TOKENS} component={TokensPage} />
        <AuthRoute
          exact
          path={RoutesPaths.TOKEN_DETAILS + "/:id"}
          component={TokenDetailsPage}
        />
        <AuthRoute
          admin
          exact
          path={RoutesPaths.DASHBOARD_ADMIN}
          component={DashboardAdminPage}
        />
        {/* <AuthRoute admin exact path={RoutesPaths.MERCADO} blackTheme={true} component={Mercado} /> */}
        <AuthRoute exact path={RoutesPaths.MINHASORDENS} component={MyOrders} />
        <AuthRoute
          exact
          path={RoutesPaths.ORDENDETALHES}
          component={OrderDetails}
        />
        <AuthRoute exact path={RoutesPaths.MEUSATIVOS} component={MyAssets} />
        <AuthRoute
          exact
          path={RoutesPaths.MEUSATIVOSCRIAR}
          component={MyAssetsCreate}
        />
        <AuthRoute
          exact
          path={RoutesPaths.MEUSATIVOSNEGOCIAR}
          component={MyAssetsNegociation}
        />
        <AuthRoute
          exact
          path={RoutesPaths.MEUSATIVOSCRIARONFIRM}
          component={MyAssetsCreateConfirm}
        />
        <AuthRoute
          exact
          path={RoutesPaths.MEUSATIVOS + "/:type"}
          component={AssetListPage}
        />
        <AuthRoute
          exact
          path={RoutesPaths.MEUSATIVOSDETALHES + "/:tokenId"}
          component={MyAssetDetails}
        />
        <AuthRoute exact path={RoutesPaths.BALANCE} component={MyBalance} />
        <AuthRoute exact path={RoutesPaths.TRANSFER} component={TransferPage} />
        <AuthRoute
          exact
          path={RoutesPaths.DEPOSIT}
          component={PreDepositPage}
        />
        <AuthRoute
          exact
          path={RoutesPaths.DEPOSIT + "/:method"}
          component={DepositPage}
        />
        <NoAuthRoute exact path={RoutesPaths.SIGNIN} component={SigninPage} />
        <NoAuthRoute
          exact
          path={RoutesPaths.FORGOTPASSWD}
          component={ForgotPasswordPage}
        />
        <NoAuthRoute
          exact
          path={RoutesPaths.RESETPASSWORD + "/:tokenId"}
          component={ResetPasswordPage}
        />
        <AuthRoute
          exact
          path={RoutesPaths.ADMIN_CLIENTS}
          admin
          component={ClientsPage}
        />
        <AuthRoute
          exact
          path={RoutesPaths.ADMIN_CLIENTS_DETAILS + "/:clientId"}
          admin
          component={ClientDetailsPage}
        />
        <AuthRoute
          exact
          path={RoutesPaths.ADMIN_CLIENTS_BALANCE + "/:clientId"}
          admin
          component={ClientBalancePage}
        />
        <AuthRoute
          exact
          path={RoutesPaths.ADMIN_CLIENTS_WALLET + "/:clientId"}
          admin
          component={ClientWalletPage}
        />
        <AuthRoute
          exact
          path={RoutesPaths.ADMIN_CLIENTS_ORDERS + "/:clientId"}
          admin
          component={ClientOrdersPage}
        />
        <AuthRoute
          exact
          path={RoutesPaths.ADMIN_CLIENTS_LIMITS_TAXES + "/:clientId"}
          admin
          component={ClientLimitTaxesPage}
        />
        <AuthRoute
          exact
          path={RoutesPaths.ADMIN_CLIENTS_BANK_ACCOUNTS + "/:clientId"}
          admin
          component={ClientBankAccountPage}
        />
        <AuthRoute
          exact
          path={RoutesPaths.ADMIN_CLIENTS_DATA + "/:clientId"}
          admin
          component={ClientDataPage}
        />
        <AuthRoute
          exact
          path={RoutesPaths.ADMIN_CLIENTS_ADDRESS + "/:clientId"}
          admin
          component={ClientAddressPage}
        />
        <AuthRoute
          exact
          path={RoutesPaths.ADMIN_CLIENTS_DOCUMENTS + "/:clientId"}
          admin
          component={ClientDocumentsPage}
        />
        <AuthRoute
          exact
          path={RoutesPaths.ADMIN_CLIENTS_DOCUMENTS_ANALYZE + "/:clientId"}
          admin
          component={ClientDocumentsAnalyzePage}
        />
        <AuthRoute
          exact
          path={
            RoutesPaths.ADMIN_CLIENTS_PERSONAL_DOCUMENTS_ANALYZE + "/:clientId"
          }
          admin
          component={ClientPersonalDocumentsAnalyzePage}
        />

        <AuthRoute
          exact
          path={RoutesPaths.MINHASORDENS_ADMIN}
          admin
          component={MyOrdersAdminPage}
        />
        <AuthRoute
          admin
          exact
          path={RoutesPaths.ADMIN_CLIENTS_DETAILS + "/:clientId/ban"}
          component={BanAccountPage}
        />
        <AuthRoute
          admin
          exact
          path={RoutesPaths.MEUSATIVOS_ADMIN}
          component={MyAssetsAdmin}
        />
        <AuthRoute
          admin
          exact
          path={RoutesPaths.MEUSATIVOS_ADMIN + "/:type"}
          component={AssetListPageAdmin}
        />
        <AuthRoute
          admin
          exact
          path={RoutesPaths.MEUSATIVOSDETALHES_ADMIN + "/:type/:tokenId"}
          component={MyAssetsDetailsAdmin}
        />
        <Route path={RoutesPaths.SIGNUP}>
          <Switch>
            <NoAuthRoute
              exact
              path={RoutesPaths.SIGNUP}
              component={PagesHandler}
            />
            <NoAuthRoute
              exact
              path={RoutesPaths.SIGNUP_REGISTER}
              component={RegisterPageHandler}
            />
          </Switch>
        </Route>
        <PublicRoute exact path={RoutesPaths.INDEX} component={IndexPage} />
        <PublicRoute exact path={RoutesPaths.USETERM} component={UseTermPage} />
        <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
    </Router>
  );
};

export default Routes;
