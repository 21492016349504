export const CorPrincipal2: string = "#E5174B";
export const Gray1: string = "#333333";
export const Gray2: string = "#4F4F4F";
export const Gray3: string = "#828282";
export const Gray4: string = "#BDBDBD";
export const Gray5: string = "#E0E0E0";
export const Gray6: string = "#F2F2F2";
export const Gray7: string = "#F5F5F5";
export const Gray8: string = "#EDEDED";
export const Gray9: string = "#FDFDFD";
export const Gray10: string = "#BDBBCC";
export const Gray11: string = "#8685A0";

export const GrayBlue1: string = "#5D5B7E";

export const Green1: string = "#219653";
export const Green2: string = "#27AE60";
export const Green3: string = "#02C076";
export const Green4: string = "#02C076";

export const Purple1: string = "#9B51E0";
export const Purple2: string = "#BB6BD9";
export const Purple3: string = "#6551E0";
export const Purple4: string = "#5F45FF";

export const Orange1: string = "#F93F39";
export const Orange2: string = "#F2994A";

export const GrayButton = "#F0F0F0";
export const BorderColor = "rgba(0, 0, 0, 0.1)";
export const GrayBackGroud = "#F8F8F8";
export const TituloFeature = "#8685A0";

export const AzulEmAndamento = "#298FC2";
export const OrangeCanceled = "#F2994A";
export const PurpleExpirete = "#BB6BD9";
export const GrayExecuted = Gray10;

export const Red = "#EB5757";
export const Red4 = "#E5174B";
export const Red5 = "#F84960";
export const Red6 = "#FF5757";
export const RedMyAccount = "#F84960";

export const Black1 = "#1C1C1C";
export const Black2 = "#1A1A1A";
export const Black3 = "#464646";
export const Black4 = "#1A1D20";
export const Black5 = "#3F3F3F";

export const Blue1 = "#2F80ED";
export const Blue2 = "#2D9CDB";

export const Gold3 = "#FF9519";
export const Gold4 = "#B2855E";

export const White = "rgba(255, 255, 255)";
export const White5 = "rgba(255, 255, 255, 0.5)";
export const White7 = "rgba(255, 255, 255, 0.13)";

export const Yellow1 = "#FEED00";

const TematicColors = {
  Base: Black4,
  BaseADM: Black5,
  BaseUser: Gold4,
  Primary: Yellow1,
  ButtonText: Black1,
  UserBGButton: "#FFFB64",
  CorDetalhes: Black3,

  BackGroudIndexPage: "#1A1D20",
};

const DashboardColors = {
  valueBox: "#2BBA80",
  valueEstimatedToken: "#A4F7CC",
  totalBalanceFiat: "#60F1C8",
  globalEstimatedBalance: "#2CD8C5",
};

const SidebarHeaderColors = {
  clientHeader: "#7d5d42",
  userIcon: "#6a4f38",
};

export default {
  ...TematicColors,
  ...DashboardColors,
  ...SidebarHeaderColors,
  // CorPrincipal2: CorPrincipal2,
  Black1,
  Black2,
  Black3,
  Black4,
  Blue1,
  Blue2,
  Gray1,
  Gray2,
  Gray3,
  Gray4,
  Gray5,
  Gray6,
  Gray7,
  Gray8,
  Gray9,
  Gray10,
  Gray11,
  GrayBlue1,
  Green1,
  Green2,
  Green3,
  Green4,
  Gold4,
  Orange1,
  Orange2,
  Purple1,
  Purple2,
  Purple4,
  Red,
  Red4,
  Red5,
  Red6,
  RedMyAccount,
  White,
  White5,
  White7,
  GrayButton,
  BorderColor,
  GrayBackGroud,
  TituloFeature,
};
