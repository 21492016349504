import React from "react";

/// Assets
import history from "../services/history";
import Colors, { GrayBackGroud } from "../assets/Colors";

/// Components
import Footer from "../components/Footer/Footer";
import TopBar from "../components/TopBar/TopBar";
import Divider from "../components/Divider/Divider";
import Button from "../components/Buttons/Button";
import FilterBar from "../components/FilterBar/FilterBar";
import BarButton from "../components/BarButton/BarButton";

interface StateProps {}
interface DispatchProps {}
interface OwnPropos {}
interface MyProps {}

type Props = StateProps & DispatchProps & MyProps & OwnPropos;

class UseTermPage extends React.Component<Props> {
  render() {
    return (
      <>
        <div
          className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
          style={{ color: Colors.Gray4 }}
        >
          <TopBar />
          <main
            className="flex-grow bg-gray-200 w-full"
            style={{ backgroundColor: GrayBackGroud }}
          >
            <FilterBar>
              <BarButton state={false} onClick={() => {}}>
                Termos de uso
              </BarButton>
            </FilterBar>

            <div className="m-6">
              <p>Leia abaixo os termos de uso da plataforma.</p>
            </div>

            <div
              className="bg-white px-2 pt-1 mx-5 flex flex-col aling-center items-center rounded border"
              style={{ borderColor: Colors.Gray4 }}
            >
              <h1 className="text-lg my-6" style={{ color: Colors.Gray2 }}>
                Termos de uso
              </h1>

              <Divider top="mt-0" bottom="mb-5" />

              <h2
                className="font-light text-lg mb-4"
                style={{ color: Colors.Gray3 }}
              >
                ATUALIZADO EM DD/MM/AAAA
              </h2>

              <br />

              <div
                style={{ color: Colors.Gray2 }}
                className="mx-5 text-justify"
              >
                <p className="my-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <p className="my-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <p className="my-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <p className="my-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <p className="my-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <p className="my-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat. Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                  in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>

              <Divider top="mt-5" bottom="mb-6" />

              <Button
                onClick={() => {
                  history.goBack();
                }}
              >
                OK
              </Button>
              <br />
            </div>
            <br />
          </main>
          <Footer />
        </div>
      </>
    );
  }
}

export default UseTermPage;
