import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { createRef, useContext, useState } from "react";
import * as Yup from "yup";
import * as API from "../../../../api";
import Colors from "../../../../assets/Colors";
import * as Gifs from "../../../../assets/gifs";
import FloatInput from "../../../../components/Inputs/FloatInput";
import Select from "../../../../components/Inputs/Select";
import AlertModal from "../../../../components/Modal/AlertModal";
import UnformInputAdapt from "../../../../components/unform/UnformInputAdapt/UnformInputAdapt";
import UserContext from "../../../../contexts/UserProvider";
import i18nTranslated from "../../../../services/i18n";
import { IsJsonString } from "../../../../services/i18n/aux";
import { UnFormValidate } from "../../../../utils/yupUtils";
import PageSkeleton from "../OnboardingPageSkeleton/OnboardingPageSkeleton";
import { basicPropsPages } from "../RegisterSteps";

export const IndividualBankPage: React.FC<basicPropsPages> = (props) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [errorModalState, setErrorModalState] = useState<boolean>(false);
  const [modalSuccesState, setModalSuccesState] = useState<boolean>(false);

  const [bankOptions, setBankOptions] = useState([]);
  const [textError, setTextError] = useState<string>(
    "Verifique se todos os campos foram preenchidos corretamente e tente novamente."
  );
  const [formRef] = useState<React.RefObject<FormHandles>>(createRef());
  const [firstValidate] = useState<boolean>(true);
  const { user } = useContext(UserContext);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  if (!bankOptions.length) {
    API.signup.getBank().then((result) => {
      const options = result.map((item) => {
        return {
          label: `${item.number} - ${item.fullName}`,
          value: item.number,
        };
      });
      setBankOptions(options as never[]);
    });
  }

  const setCodeBank = (value) => {
    if (formRef.current) {
      const data = formRef.current.getData();
      formRef.current.setData({
        ...data,
        bank: value,
      });
    }
  };

  const schema = Yup.object()
    .shape({
      bank: Yup.string().required(),
      bankAgency: Yup.string().required(),
      bankAccount: Yup.string().required(),
      bankAccountDigit: Yup.string().required(),
    })
    .required();

  const onClick = async () => {
    const data = await schema.validate(formRef.current?.getData());
    setLoadingState(true);
    const id = user?.id;

    if (id && data) {
      try {
        data.bankAccount = `${data.bankAccount}-${data.bankAccountDigit}`;
        await API.signup.individual.registerIndividualBank(id, { ...data });
        props.nextPage();
        setLoadingState(false);
      } catch (err) {
        console.error(err);
        const error = err as any;

        // setTextError('');
        // if (error && error.response) {
        //   setTextError(i18nTranslated(error.response.data.message));
        // } else if (error && error.message) {
        //   let msgError = 'API_ERROR_UNEXPECTED';
        //   if (IsJsonString(error.message)) {
        //     const erroObject = JSON.parse(error.message);
        //     if (erroObject.statusCode !== 500) {
        //       msgError = erroObject.message;
        //     }
        //   }

        //   setTextError(i18nTranslated(msgError));
        // }

        setErrorModalState(true);
        setLoadingState(false);
      }
    } else {
      setTextError(
        "Verifique se todos os campos foram preenchidos corretamente e tente novamente."
      );
      setErrorModalState(true);
      setLoadingState(false);
    }
  };

  function validateFormAndSetButtonDisabled() {
    const bank = formRef?.current?.getFieldValue("bank");
    const bankAgency = formRef?.current?.getFieldValue("bankAgency");
    const bankAccount = formRef?.current?.getFieldValue("bankAccount");
    const bankAccountDigit =
      formRef?.current?.getFieldValue("bankAccountDigit");

    if (
      bank == "0" ||
      bankAgency == "" ||
      bankAccount == "" ||
      bankAccountDigit == ""
    ) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !buttonDisabled) {
      onClick();
    }
  };

  return (
    <>
      <AlertModal
        state={errorModalState}
        title="Falha na validação."
        src={Gifs.IconAlertGif}
        confirmClick={() => setErrorModalState(false)}
      >
        <>
          <p>{textError}</p>
        </>
      </AlertModal>

      <PageSkeleton
        loading={loadingState}
        title="Dados bancários do titular"
        subTitle="A conta bancária deve pertencer ao titular cadastrado na plataforma"
        prevPage={props.prevPage}
        nextPage={onClick}
        nextPageLabel="Concluir"
        buttonDisabled={buttonDisabled}
        finalNote="A conta informada será utilizada para sua movimentação bancária na plataforma."
      >
        <Form
          onSubmit={() => {}}
          className="w-11/12 text-lg"
          ref={formRef}
          onChange={validateFormAndSetButtonDisabled}
          onKeyDown={handleKeyDown}
        >
          <UnformInputAdapt
            name="bank"
            children={({ ref, error }) => (
              <Select
                className="py-2 mb-3 bg-white"
                placeholder="Instituição Bancária"
                ref={ref}
                options={bankOptions}
                onChange={(value) => {
                  setCodeBank(value);
                  validateFormAndSetButtonDisabled();
                }}
              />
            )}
          />
          <UnformInputAdapt
            name="bankAgency"
            children={({ ref, error }) => (
              <FloatInput
                big={true}
                error={error}
                color="bg-white"
                labelColor={Colors.Gray3}
                className="py-2 mb-3"
                placeholder="Agência (Sem dígito)"
                onInput={validateFormAndSetButtonDisabled}
                type="number"
                length={4}
                pattern="[0-9]*"
                ref={ref}
              />
            )}
          />
          <div className="flex gap-2">
            <UnformInputAdapt
              name="bankAccount"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  big={true}
                  error={error}
                  color="bg-white"
                  labelColor={Colors.Gray3}
                  className="py-2 mb-3 w-3/4"
                  placeholder="Conta corrente"
                  onInput={validateFormAndSetButtonDisabled}
                  type="number"
                  pattern="[0-9]*"
                  ref={ref}
                />
              )}
            />
            <UnformInputAdapt
              name="bankAccountDigit"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  big={true}
                  error={error}
                  color="bg-white"
                  labelColor={Colors.Gray3}
                  className="py-2 mb-3 w-1/4"
                  placeholder="Dígito"
                  type="number"
                  onInput={validateFormAndSetButtonDisabled}
                  length={2}
                  pattern="[0-9]*"
                  ref={ref}
                />
              )}
            />
          </div>
          <h4 className="text-xs">(Para dígito X utilize o nº 0)</h4>
        </Form>
      </PageSkeleton>
    </>
  );
};
