export default (v: string): string => {
  const dates = v.replace(/\D/g, "");

  return dates
    .split("")
    .map((char, i) => {
      if (i === 2 || i === 4) return `/${char}`;
      return char;
    })
    .join("")
    .substring(0, 10);
};
