import moment from "moment";
import api from "../services/api";
import { AxiosRequestConfig } from "axios";
import { Book, Order, match } from "../models";
import {
  FeeTypeEnum,
  OrderTypeEnum,
  TokenStatusEnum,
  OrderStatusEnum,
  TokenTypeEnum,
} from "../models/enums";
import { isValidDate } from "../utils/DateUtils";

const determineOrderStatus = (elm) => {
  if (elm.canceled) return OrderStatusEnum.CANCELED;

  if (elm.finished) {
    return elm.remainingTokens === 0
      ? OrderStatusEnum.FULFILLED
      : OrderStatusEnum.CANCELED;
  }

  return OrderStatusEnum.ACTIVE;
};
const determineTokenStatus = (elm) => {
  if (elm.canceled) return TokenStatusEnum.CANCELED;
  return elm.finished ? TokenStatusEnum.FINISHED : TokenStatusEnum.ACTIVE;
};

export const book = {
  async getUserOrders(userId: string): Promise<Array<any>> {
    try {
      const { status, data } = await api.exchange.get(
        `/book/order?userId=${userId}`
      );
      if (status === 200) {
        return data;
      } else {
        throw new Error(data);
      }
    } catch (error) {
      throw error;
    }
  },
  async getAllOrders({
    status,
    type,
    typeReceivable,
    userId,
  }: {
    type?: Book.order.type;
    status?: OrderStatusEnum;
    typeReceivable?: TokenTypeEnum; //TODO: mudar esse nome
    userId?: string;
  }): Promise<Array<Order>> {
    try {
      var config: AxiosRequestConfig = {};
      config.params = {
        type: type ?? null,
        userId: userId ?? null,
        typeToken: typeReceivable ?? null,
      };

      const response = await api.exchange.get("/book/order", config);

      if (response.status === 200) {
        let orders: Array<Order> = response.data.map(
          (elm) =>
            ({
              ...elm,

              status: determineOrderStatus(elm),
              creationDate: new Date(elm.creationDate),
              updateDate: new Date(elm.updateDate),
              tokenExpirationDateEnd: new Date(elm.tokenExpirationDateEnd),
              tokenExpirationDateStart: new Date(elm.tokenExpirationDateStart),

              tokens: elm.tokens.map((token) => ({
                ...token,
                ...token.metadata,
                id: token.id,
                status: determineTokenStatus(token),
                amount: Number(token.amount ?? (token as any).value),

                create_at: new Date(token.create_at),
                emissionDate: new Date(token.emissionDate),
                settlementDate: new Date(token.settlementDate),

                invoice:
                  token.invoice === undefined
                    ? undefined
                    : {
                        ...token.invoice,
                        paidAmount: Number(token.invoice?.paidAmount ?? 0),
                      },
              })),
            } as Order)
        );

        return orders;
        // .filter(order => {
        //     let semaforo = true;
        //     if(status) semaforo = semaforo && order.status === status;
        //     return semaforo;
        // });
      } else {
        // return getAllOrdersMockImplementation(userId, type, typeReceivable);
        throw new Error(response.data);
      }
    } catch (error: any) {
      throw new Error(error);
    }
  },
  async getOrdersBalcao(typeReceivable?: TokenTypeEnum): Promise<Array<Order>> {
    let orders = await book.getAllOrders({
      typeReceivable,
      status: OrderStatusEnum.ACTIVE,
      type: OrderTypeEnum.sell,
    });

    orders = orders.filter((order) => order.type === "sell");

    return orders;
  },
  async getOrderDetails({
    orderId,
    orderType,
  }: {
    orderId: string;
    orderType: OrderTypeEnum;
  }): Promise<Order.Detail> {
    try {
      const response = await api.exchange.get(
        `/book/${
          orderType === "sell" ? "sell-order" : "buy-order"
        }/details/${orderId}`
      );
      if (response.status === 200) {
        let order = {
          ...response.data,
          status: determineOrderStatus(response.data),
          update_at: new Date(response.data.update_at),
          create_at: new Date(response.data.create_at),
          orderExpirationDateEnd: new Date(
            response.data.orderExpirationDateEnd
          ),
          orderExpirationDateStart: new Date(
            response.data.orderExpirationDateStart
          ),

          matchs: response.data.matchs.map((elm) => ({
            ...elm,
            created_at: new Date(elm.created_at),
            updated_at: new Date(elm.updated_at),
          })),
          tokens: response.data.tokens
            .map((token) => ({ ...token, ...token.metadata }))
            .map((token) => ({
              ...token,
              id: token.id,
              amount: token.amount,
              fee: response.data.fee,
              feeType: response.data.feeType?.toUpperCase(),
              emissionDate: new Date(token.emissionDate),
              type: token.type?.toUpperCase(),
              settlementDate: new Date(token.settlementDate),
              tokenizationDate: new Date(token.tokenizationDate),
              status: determineTokenStatus(token),
            })),
        };

        return order as Order.Detail;
      } else {
        throw response.data;
      }
    } catch (error: any) {
      throw new Error(error);
    }
  },

  async deleteOrder({
    id,
    type,
  }: { id: string; type: OrderTypeEnum } | Order): Promise<null> {
    try {
      const { data, status } = await api.exchange.delete(
        `/book/${type}-order/${id}`
      );

      if (status === 202) {
        return null;
      } else {
        throw data;
      }
    } catch (error) {
      throw error;
    }
  },

  async getAllMatchs({
    id,
    token,
    sellOrderId,
    buyOrderId,
    amount,
    createDate,
    fee,
    typeReceivable,
  }: any): Promise<Array<match>> {
    try {
      var config: AxiosRequestConfig = {};
      config.params = {
        // id: id ?? null,
        // token: token ?? null,
        // sellOrderId: sellOrderId ?? null,
        // buyOrderId: buyOrderId ?? null,
        // amount: amount ?? null,
        // createDate: createDate ?? null,
        // fee: fee ?? null,
        // typeReceivable: typeReceivable ?? null
      };

      const { data, status } = await api.exchange.get("/book/match", config);

      if (status === 200) {
        return data.map((elm) => ({
          ...elm,
          createDate: new Date(elm.createDate),
        }));
      } else {
        // return getAllMatchsMockImplementation();
        throw new Error(data);
      }
    } catch (error) {
      throw error;
    }
  },

  async createBuyOrderByToken({
    tokenId,
    tokenQuantity,
    sellOrderId,
    dryRun,
  }: {
    tokenId: number;
    sellOrderId: string;
    tokenQuantity: number;
    dryRun?: boolean;
  }) {
    try {
      const body = {
        token: tokenId,
        dryRun,
        tokenQuantity,
        sellOrderId,
      };

      const response = await api.exchange.post("book/buy-order/token", body);

      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw response.data;
      }
    } catch (error) {
      throw error;
    }
  },
  async createOrder({
    amount,
    typeReceivable,
    tokenExpirationDateStart,
    tokenExpirationDateEnd,
    dryRun,
    tokenQuantity,
    orderType,
  }: {
    amount: number;
    tokenQuantity: number;
    typeReceivable: TokenTypeEnum;
    tokenExpirationDateStart: Date;
    tokenExpirationDateEnd: Date;
    dryRun?: boolean;
    orderType: OrderTypeEnum;
  }): Promise<Order> {
    try {
      let body: any = {
        amount,
        typeReceivable,
        tokenQuantity,
        dryRun,
      };
      if (isValidDate(tokenExpirationDateStart))
        body.tokenExpirationDateStart = moment(tokenExpirationDateStart).format(
          "YYYY-MM-DD"
        );
      if (isValidDate(tokenExpirationDateEnd))
        body.tokenExpirationDateEnd = moment(tokenExpirationDateEnd).format(
          "YYYY-MM-DD"
        );

      const response = await api.exchange.post(`book/${orderType}-order`, body);

      if (response.status >= 200 && response.status < 300) {
        return {
          ...response.data,
          tokenExpirationDateEnd: new Date(
            response.data.tokenExpirationDateEnd
          ),
          tokenExpirationDateStart: new Date(
            response.data.tokenExpirationDateStart
          ),
        };
      } else {
        throw response.data;
      }
    } catch (error) {
      throw error;
    }
  },
  async createSellOrderGold({
    tokenId,
    price,
    tokenQuantity,
    dryRun,
  }: {
    tokenId: number;
    price: number;
    tokenQuantity: number;
    dryRun?: boolean;
  }) {
    const { status, data } = await api.exchange.post("book/sell-order/token", {
      tokenId,
      price,
      tokenQuantity,
      dryRun,
    });

    if (status >= 200 && status < 300) {
      return data;
    }

    throw data;
  },
  async createSellOrderByToken({
    fee,
    feeType,
    tokenId,
    dryRun,
  }: {
    fee: number;
    feeType: FeeTypeEnum;
    tokenId: string;
    dryRun?: boolean;
  }): Promise<Order> {
    try {
      const body = {
        fee,
        feeType,
        tokenId,
        dryRun,
      };
      const response = await api.exchange.post(`book/sell-order/token`, body);

      if (response.status === 200 || response.status === 201) {
        return {
          ...response.data,
          tokenExpirationDateEnd: new Date(
            response.data.tokenExpirationDateEnd
          ),
          tokenExpirationDateStart: new Date(
            response.data.tokenExpirationDateStart
          ),
        };
      } else {
        throw response.data;
      }
    } catch (error) {
      throw error;
    }
  },
};
