import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import "./SigninPage.css";

/// API
import * as API from "../../../api";

/// Assets
import Colors from "../../../assets/Colors";
import * as Gifs from "../../../assets/gifs";
import RoutesPaths from "../../../Routes/RoutesPaths";
import {
  clearForcedLogout,
  getForcedLogout,
  makeLogin,
} from "../../../services/jwt";

/// Components
import Button from "../../../components/Buttons/Button";
import FloatInput from "../../../components/Inputs/FloatInput";
import AlertModal from "../../../components/Modal/AlertModal";
import TermsOfUseModal from "../../../components/TermsOfUseModal/TermsOfUseModal";
import UnformInputAdapt from "../../../components/unform/UnformInputAdapt/UnformInputAdapt";
import inputValidations from "../../../utils/inputValidations";

const SigninPage: React.FC = () => {
  const [firstValidate, setFirstValidate] = useState<boolean>(true);
  const [errorAlert, setErrorAlert] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [showTermsOfUse, setShowTermsOfUse] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailValidationStatus, setEmailValidationStatus] = useState({
    messageError: "",
    touched: false,
  });

  const formRef: React.RefObject<FormHandles> = React.createRef();

  const schema = Yup.object()
    .shape({
      email: Yup.string().required(),
      password: Yup.string().required(),
    })
    .required();

  const handleLoginClick = () => {
    setLoadingState(true);
    formRef.current?.submitForm();
  };

  const login = async (inputData: any, { reset }) => {
    if (!formRef.current) return undefined;
    if (firstValidate) {
      setFirstValidate(false);
    }

    try {
      const { email, password } = await schema.validate(inputData, {
        abortEarly: false,
      });

      const { accessToken, refreshToken } = await API.auth.signin({
        email,
        password,
      });

      makeLogin(accessToken, refreshToken);
      setLoadingState(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setLoadingState(false);
        setErrorAlert(true);
      } else {
        setErrorAlert(true);
        setLoadingState(false);
      }
    }
  };

  const emailValidation = (email: string) => {
    inputValidations.email.isValid(email);
    setEmailValidationStatus((status) => {
      return {
        ...status,
        messageError: inputValidations.email.getErrorMessage(),
      };
    });
  };

  const emailBlurHandler = (event) => {
    setEmailValidationStatus((status) => {
      return {
        ...status,
        touched: true,
      };
    });
    const email = event.target.value.toString().trim();
    setEmail(email);
    emailValidation(email);
  };

  const emailChangeValidateHandler = (event) => {
    if (!emailValidationStatus.touched) {
      return;
    }
    const email = event.target.value.toString().trim();
    setEmail(email);
    emailValidation(email);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !handleButtonDisable()) {
      formRef.current?.submitForm();
    }
  };

  const handleButtonDisable = () => {
    return (
      email.toString().trim() === "" ||
      inputValidations.email.isNotValid(email) ||
      password.toString().trim() === ""
    );
  };

  const [forcedLogout, setForcedLogout] = useState<boolean>(false);
  const checkForcedLogout = () => {
    return getForcedLogout();
  };
  useEffect(() => {
    if (checkForcedLogout()) setForcedLogout(true);
  }, []);

  return (
    <>
      <TermsOfUseModal
        isOpen={showTermsOfUse}
        handleState={setShowTermsOfUse}
      />
      <AlertModal
        state={forcedLogout}
        changeState={() => {
          setForcedLogout(false);
          clearForcedLogout();
        }}
        title="Login expirado"
        children={
          <span>
            Por segurança sua sessão foi desconectada por inatividade.
            <br />
            Clique no botão abaixo para realizar o login novamente.
          </span>
        }
        src={Gifs.IconAlertGif}
      />
      <AlertModal
        state={errorAlert}
        changeState={() => setErrorAlert(false)}
        title="Dados incorretos"
        children={
          <span>Por favor verifique seus dados e tente novamente.</span>
        }
        src={Gifs.IconAlertGif}
      />

      <div className="signin-page-container flex flex-col items-center">
        <h1
          className="text-2xl font-medium mt-16 mb-10"
          style={{ color: Colors.Gray2 }}
        >
          Acesse sua conta
        </h1>

        <Form
          onSubmit={login}
          onKeyDown={handleKeyDown}
          ref={formRef}
          className="signin-form w-80"
        >
          <UnformInputAdapt
            name="email"
            children={({ ref, error }) => (
              <FloatInput
                big={true}
                error={error || emailValidationStatus.messageError}
                color="bg-white"
                className="text-lg px-2 py-2 my-3 bg-white"
                placeholder="Email de cadastro"
                type="email"
                onChange={emailChangeValidateHandler}
                onBlur={emailBlurHandler}
                ref={ref}
              />
            )}
          />
          <UnformInputAdapt
            name="password"
            children={({ ref, error }) => (
              <FloatInput
                big={true}
                error={error}
                color="bg-white"
                className="text-lg px-2 py-2 my-3 bg-white"
                placeholder="Senha de acesso"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                ref={ref}
              />
            )}
          />
        </Form>

        <button
          onClick={() => {
            setShowTermsOfUse(true);
          }}
        >
          <p
            className="text-xs font-normal mt-7 mb-5 mx-2 text-center"
            style={{ color: Colors.Gray4 }}
          >
            Ao prosseguir declaro estar de acordo com os termos de uso e
            privacidade da plataforma.
          </p>
        </button>

        <div className="my-2">
          <Button
            backgroundColor={Colors.UserBGButton}
            className="login-btn"
            loading={loadingState}
            disabled={handleButtonDisable()}
            onClick={handleLoginClick}
          >
            Prosseguir
          </Button>
        </div>

        <Link
          to={RoutesPaths.FORGOTPASSWD}
          className="underline text-lg cursor-pointer my-2"
          style={{ color: Colors.Gray2 }}
        >
          Esqueceu sua senha?
        </Link>
      </div>
    </>
  );
};

export default SigninPage;
