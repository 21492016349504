import React, { useContext, useEffect, useState, useCallback } from "react";
import { user } from "../../api";
import Colors from "../../assets/Colors";
import Divider from "../../components/Divider/Divider";
import MoneyInput from "../../components/Inputs/MoneyInput";
import { SelectMethod, SelectMethods } from "../../components/SelectMethod";
import ModalContext from "../../contexts/ModalProvider";
import TaxesContext from "../../contexts/TaxesProvide";
import { PlataformLimitEnum, TaxTypeEnum } from "../../models/enums";
import {
  DepositMethodEnumInterpreter,
  DepositMethodsEnum,
} from "../../models/enums/DepositMethods";
import { depositOptions } from "../../options";
import RoutesPaths from "../../Routes/RoutesPaths";
import history from "../../services/history";
import { formatToCurrency } from "../../utils/formatters";
import * as API from "./../../api";
import PageBoardSkeleton from "../../components/PageBoardSkeleton/PageBoardSkeleton";
import CurrencyInput from "../../components/Inputs/CurrencyInput/CurrencyInput";

const PreDepositPage: React.FC = () => {
  const { taxs } = useContext(TaxesContext);
  const { handleModal, setModal } = useContext(ModalContext);

  // Must devide by 100 every calculation logic due to currency input's display value
  const [_amount, _setAmount] = useState<number>(0);

  const [_currenctMethod, _setCurrentMethod] = useState<DepositMethodsEnum>(
    DepositMethodsEnum.PIX
  );
  const [userLimits, setUserLimits] = useState<
    Map<PlataformLimitEnum, user.PlataformLimit>
  >(new Map());
  const [availableLimit, setAvailableLimit] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);

  const tax = taxs.get(TaxTypeEnum.TED_DEPOSIT)?.value ?? 0;
  const credited = _amount > tax ? _amount - tax : 0;

  useEffect(() => {
    getLimits();
  }, []);

  const getLimits = async () => {
    setLoading(true);
    let limits = await API.user.getLimits();
    setUserLimits(limits);

    let available =
      (limits.get(PlataformLimitEnum.DEPOSIT)?.limit ?? 0) -
      (limits.get(PlataformLimitEnum.DEPOSIT)?.consumed ?? 0) -
      tax;
    setAvailableLimit(available > 0 ? available : 0);
    setLoading(false);
  };

  const onClick = () => {
    handleModal(SelectMethods, {
      depositMethods: depositOptions,
      currenctMethod: _currenctMethod,
      onClick(method) {
        _setCurrentMethod(method);
        setModal(false);
      },
    });
  };

  const goToDeposit = (): void =>
    history.push(
      RoutesPaths.DEPOSIT + `/${_currenctMethod}?amount=${_amount / 100}`
    );

  const goBack = (): void => history.goBack();

  const handleAmountChange = useCallback((inputData) => {
    const val = inputData.val;
    const limit = inputData.availableLimit;

    if (Number(val) / 100 > limit) {
      _setAmount(limit * 100);
    } else {
      _setAmount(val);
    }
  }, []);

  return (
    <>
      <PageBoardSkeleton
        filterBar="Depositar Saldo"
        onClickFilterBar={goBack}
        button={{
          label: "Prosseguir",
          onClick: goToDeposit,
          disabled: _amount === 0,
          className: "h-14",
        }}
        button2={{
          label: "Cancelar depósito",
          onClick: goBack,
        }}
      >
        <h2 className="text-xl mt-5 mb-4" style={{ color: Colors.Gray3 }}>
          METÓDO DE DEPÓSITO
        </h2>

        <div className="relative flex flex-row rounded w-full py-3 md:w-2/3 lg:w-1/2 max-w-3xl">
          <SelectMethod
            title="Selecionar método de depósito"
            subtitle="Selecione uma das opções de depósito disponíveis:"
            methods={depositOptions}
            myClass={DepositMethodEnumInterpreter}
            currenctMethod={_currenctMethod}
            changeCurrentMethod={(method) => _setCurrentMethod(method)}
          />
        </div>

        <Divider />

        {isLoading ? (
          <div className="bg-gray-200 rounded h-8 w-full md:w-2/3 lg:w-1/2 mb-10 animate-pulse"></div>
        ) : (
          <h3
            className="font-light text-lg mb-10 text-center sm:text-xl"
            style={{ color: Colors.Orange1 }}
          >
            Seu limite mensal disponível para depósito é:
            {formatToCurrency(availableLimit)} BRL
          </h3>
        )}

        <p className="text-xl font-normal" style={{ color: Colors.Gray3 }}>
          VALOR DE DEPÓSITO
        </p>
        <div
          className="border rounded justify-center items-center  w-full md:w-2/3 lg:w-1/2 max-w-3xl overflow-hidden my-4"
          style={{ borderColor: Colors.Gray3 }}
        >
          {/* <MoneyInput
            prefix='R$ '
            className='w-full h-full text-left px-4 py-4 text-xl'
            style={{
              color: Colors.Gray2
            }}
            onChangeValue={p => _setAmount(p)}
          /> */}
          <CurrencyInput
            value={_amount}
            onValueChange={(val) => {
              const inputData = { val, availableLimit };
              handleAmountChange(inputData);
            }}
            style={{ color: Colors.Gray2, backgroundColor: "transparent" }}
            className="z-1 block p-2 w-full font-medium appearance-none focus:outline-none text-center"
          />
        </div>
        {/* <p className="text-lg font-normal" style={{ color: Colors.Gray4 }}>( Taxa: R${formatToCurrency(tax)} )</p>

            <h3 className='font-normal text-xl mt-10' style={{ color: Colors.Green3 }}>
              Será creditado em seu saldo: R${formatToCurrency(credited)}
            </h3> */}
      </PageBoardSkeleton>
    </>
  );
};

export default PreDepositPage;
