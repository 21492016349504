import { IValidation } from "./IValidation";

export class EmailValidation implements IValidation {
  private regex =
    /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
  private value: string = "";
  msgError = "Informe um e-mail válido.";

  isValid(value: string): boolean {
    if (!value) {
      this.value = "";
      return false;
    }
    this.value = value;
    return this.regex.test(this.value) && this.value.trim().length <= 255;
  }

  isNotValid(value: string): boolean {
    return !this.isValid(value);
  }

  getErrorMessage(): string {
    if (this.isValid(this.value) || this.value === "") {
      return "";
    }
    return this.msgError;
  }
}
