import Colors from "../assets/Colors";
import * as Icons from "../assets/icons";
import React, { useContext } from "react";
import ModalCore from "./Modal/ModalCore";
import ModalContext from "../contexts/ModalProvider";
import {
  DepositMethodEnumInterpreter,
  DepositMethodsEnum,
} from "../models/enums/DepositMethods";

interface MethodsOption<t> {
  type: t;
  enable: boolean;
  subLabel: string;
}

interface MyProps {
  title: string;
  subtitle: string;
  methods: Array<MethodsOption<DepositMethodEnumInterpreter>>;
  currenctMethod: DepositMethodEnumInterpreter;
  onClick(method: DepositMethodEnumInterpreter): any;
  changeState(): any;
  myClass: any;
}

interface SelectMethodProps {
  title: string;
  subtitle: string;
  myClass: any;
  currenctMethod: any;
  methods: Array<MethodsOption<any>>;
  changeCurrentMethod(method: DepositMethodsEnum): any;
}

export const SelectMethod: React.FC<SelectMethodProps> = ({
  title,
  subtitle,
  methods,
  currenctMethod,
  changeCurrentMethod,
  myClass,
}) => {
  const { handleModal, setModal } = useContext(ModalContext);

  return (
    <div
      className="flex flex-row bg-white border rounded justify-between items-center p-2 sm:p-3 w-full cursor-pointer"
      style={{ borderColor: Colors.Gray4 }}
      onClick={() => {
        handleModal(SelectMethods, {
          title,
          subtitle,
          methods,
          myClass,
          currenctMethod,
          changeState() {
            setModal(false);
          },
          onClick(method) {
            changeCurrentMethod(method);
            setModal(false);
          },
        });
      }}
    >
      <div className="flex flex-row items-center gap-2 sm:gap-3">
        <img
          src={myClass.getIcon(currenctMethod)}
          alt=""
          style={{ width: "35px" }}
        />
        <p
          className="text-base font-sm sm:text-xl"
          style={{ color: Colors.Gray2 }}
        >
          {myClass.translate(currenctMethod)}
        </p>
      </div>
      <div className="flex flex-col">
        <img src={Icons.ArrowIcon} alt="" />
      </div>
    </div>
  );
};

export const SelectMethods: React.FC<MyProps> = (props) => {
  return (
    <ModalCore
      className="lg:w-5/12"
      state={true}
      changeState={() => props.changeState()}
    >
      <div className="flex flex-col mx-4 my-5 items-center">
        <h1 className="text-xl font-medium" style={{ color: Colors.Gray1 }}>
          {props.title}
        </h1>
        <h2 className="text-sm font-normal" style={{ color: Colors.Gray3 }}>
          {props.subtitle}
        </h2>

        <div className="flex flex-col gap-2 my-7 w-full max-h-96 overflow-y-auto">
          {props.methods.map(({ subLabel, type, enable }) => (
            <button
              key={type as string}
              className="flex flex-row border rounded py-5 px-3 justify-between items-center"
              style={{
                opacity: enable ? "100%" : "50%",
                cursor: enable ? "pointer" : "not-allowed",
              }}
              disabled={!enable}
              onClick={() => props.onClick(type)}
            >
              <div className="flex flex-row gap-3">
                <img src={props.myClass.getIcon(type as any)} alt="" />
                <div className="flex flex-col items-start">
                  <strong className="text-base" style={{ color: Colors.Gray2 }}>
                    {props.myClass.translate(type as any)}
                  </strong>
                  <p className="text-xs" style={{ color: Colors.Gray3 }}>
                    {subLabel}
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-center h-full">
                <input
                  type="radio"
                  checked={props.currenctMethod === type}
                  style={{ width: "20px", height: "20px" }}
                />
              </div>
            </button>
          ))}
        </div>
      </div>
    </ModalCore>
  );
};
