import moment from "moment";
import React, { useState } from "react";
import Colors from "../../../assets/Colors";
import * as Icons from "../../../assets/icons";
import Button from "../../../components/Buttons/Button";
import DetailsCard, {
  DetailsSection,
  DetailsSectionRow,
} from "../../../components/Cards/DetailsCard/DetailsCard";
import OrderCard from "../../../components/Cards/OrderCard";
import BuyToken from "../../../components/Modal/BuyToken/BuyToken";
import ResumeBar from "../../../components/ResumeBar/ResumeBar";
import {
  formatDecimalPlaces,
  formatToCurrency,
} from "../../../utils/formatters";
import TokenDetailsResumeCard from "../TokenDetailsResumeCard/TokenDetailsResumeCard";
import HashMask from "../../../components/Inputs/MaskedInput/Mask/HashMask";

type MyProps = {
  order?: any;
};

const TokenDetailsSummary: React.FC<MyProps> = ({ order }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const token = order?.tokens[0];

  const renderCatchmentBar = (): JSX.Element => {
    const percentage = 65;
    const softCap: number = 25;
    const hardCap: number = 75;
    return (
      <>
        <div
          className="my-8 mb-16 catchment-bar relative border rounded-sm w-full text-base"
          style={{ borderColor: Colors.Gray5, color: Colors.Gray11 }}
        >
          <div
            className="absolute h-full"
            style={{ backgroundColor: Colors.Green3, width: `${percentage}%` }}
          ></div>{" "}
          {/* green bar */}
          <div className="absolute h-full" style={{ left: `${softCap}%` }}>
            <div className="v-bar" style={{ borderColor: Colors.Gray11 }}></div>
          </div>
          <div
            className="absolute h-full"
            style={{ left: `${softCap}%`, transform: "translate(-250%, 0)" }}
          >
            <span className="cap-text">Soft cap</span>
          </div>
          <div className="absolute h-full" style={{ left: `${hardCap}%` }}>
            <div className="v-bar" style={{ borderColor: Colors.Gray11 }}></div>
          </div>
          <div
            className="absolute h-full"
            style={{ left: `${hardCap}%`, transform: "translate(-300%, 0)" }}
          >
            <span className="cap-text">Hard cap</span>
          </div>
          <div className="absolute h-full" style={{ left: `0%` }}>
            <span className="cap-text">0 BRL</span>
          </div>
          <div className="absolute h-full" style={{ left: `98%` }}>
            <span className="cap-text">3 mm</span>
          </div>
        </div>
      </>
    );
  };

  const detalhesTokenRows: Array<DetailsSectionRow> = [
    { title: "Tipo: ", value: token?.type },
    { title: "Categoria: ", value: "Antecipação de ativos" },
    { title: "Emissor: ", value: "OZ Ativos digitais SA" },
    {
      title: "Contrato: ",
      children: (
        <a
          href={
            "https://explorer.bitshopp.com/contracts/" +
            token?.metadata.contractAddress
          }
          className="font-normal break-all cursor-pointer hover:underline"
          style={{ color: Colors.Blue1 }}
        >
          {HashMask(token?.metadata.contractAddress)}
        </a>
      ),
    },
    {
      title: "Lastro: ",
      value: (
        <>
          <span>
            1 onça troy (oz) de ouro físico de reserva{" "}
            <span className="italic">in situ</span>.{" "}
            <span className="font-light">
              (Cada onça troy equivale a 31,10g de ouro com 99% de pureza)
            </span>
          </span>
        </>
      ),
    },
  ];

  const detalhesLoteRows: Array<DetailsSectionRow> = [
    {
      value: `LOTE #${token?.id}`,
      className: "text-xl font-medium",
      style: { color: Colors.Gray1 },
    },
    {
      title: "Emissão: ",
      value: moment(token?.metadata.emissionDate).format("DD/MM/YYYY"),
    },
    // { title: 'Lockup / Liberação: ', value: moment(token?.metadata.redemptionDate).format('DD/MM/YYYY') },
    {
      title: "Carência: ",
      value: moment(token?.metadata.redemptionDate).format("DD/MM/YYYY"),
    },
    { title: "Liquidação: ", value: "D+2 após data de carência" },
    { title: "Validade: ", value: "Não possui" },
    {
      title: "Valor total da oferta: ",
      value: `${formatToCurrency(order.tokenQuantity * order.amount)} BRL`,
    },
    // {
    //   title: 'Periodo de reserva: ',
    //   value: ` de ${moment(token?.metadata.emissionDate).format('DD/MM/YYYY')} à ${moment(token?.metadata.redemptionDate).format('DD/MM/YYYY')}`
    // },
  ];

  const sections: Array<DetailsSection> = [
    {
      title: "Detalhes do token",
      sectionRows: detalhesTokenRows,
    },
    {
      title: "Informações do lote",
      sectionRows: detalhesLoteRows,
      // children: renderCatchmentBar()
    },
    {
      children: (
        <div className="flex mb-4 flex-col items-center justify-center">
          <p
            className="flex mb-4 text-2xl font-normal"
            style={{ color: Colors.Gray2 }}
          >
            Preço unitário:{" "}
            <span className="font-medium mr-1">
              {formatToCurrency(order.amount)}
            </span>
            <span className="font-light">BRL</span>
          </p>
          <Button
            big={false}
            className="buy-now-button"
            textColor={"white"}
            backgroundColor={Colors.Green3}
            borderColor={Colors.Green1}
            onClick={() => {
              setShowModal(!showModal);
              document.body.style.overflow = "hidden";
            }}
          >
            Comprar token
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <BuyToken
        order={order}
        state={showModal}
        changeState={() => {
          setShowModal(!showModal);
        }}
      />
      <div className="flex flex-row w-full justify-center">
        <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
          <ResumeBar>
            <TokenDetailsResumeCard
              bgColor={Colors.Gray11}
              invert
              textColor="black"
              title="Ofertados"
              value={formatDecimalPlaces(order.tokenQuantity)}
            />
            <TokenDetailsResumeCard
              bgColor={Colors.Gray11}
              invert
              textColor="black"
              title="Negociados"
              value={formatDecimalPlaces(
                order.tokenQuantity - order.remainingTokens
              )}
            />
            <TokenDetailsResumeCard
              bgColor={Colors.Gray11}
              invert
              textColor="black"
              title="À venda"
              value={formatDecimalPlaces(order.remainingTokens)}
            />
          </ResumeBar>

          <DetailsCard sections={sections} />
        </div>
      </div>
    </>
  );
};

export default TokenDetailsSummary;
