import React, { createContext, useEffect, useState } from "react";
import { Client } from "../models/clients";
import * as API from "./../api";

interface ClientsContextData {
  selectedClient?: Client;
  getClientDetails(id: string): any;
  refreshClientDetails(): void;
  setSelectedClient(p: Client): any;
}

const ClientsContext = createContext<ClientsContextData>(
  {} as ClientsContextData
);

export const ClientsContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [selectedClient, setSelectedClient] = useState<Client | undefined>(
    undefined
  );

  const getClientDetails = async (id: string) => {
    // const data = await API.clients.loadClientDetail(id);
    const data = await API.admin.loadClient(id);
    console.log("teste");
    setSelectedClient(new Client(data));
  };
  const refreshClientDetails = async () => {
    if (!selectedClient) throw "no client";
    await getClientDetails(selectedClient.id);
  };

  return (
    <ClientsContext.Provider
      value={{
        selectedClient,
        setSelectedClient,
        getClientDetails,
        refreshClientDetails,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};

export default ClientsContext;
