import React, { useState } from "react";
import Modal from "../Modal";
import Button from "../../Buttons/Button";
import Colors from "../../../assets/Colors";
import "./FilterModal.css";

type FilterModalProps = {
  title: string;
  subTitle: string;
  filterBtnText: string;
  onFilterClick: () => any;
  onClearFiltersClick: () => any;
  onCancelClick: () => any;
  state: boolean;
  children: JSX.Element | JSX.Element[];
};

const FilterModal: React.FC<FilterModalProps> = ({
  title,
  subTitle,
  filterBtnText,
  onFilterClick,
  onClearFiltersClick,
  onCancelClick,
  state,
  children,
}: FilterModalProps) => {
  return (
    <Modal
      width={"w-10"}
      state={state}
      cancelButtonClick={onCancelClick}
      confirmButtonClick={() => {}}
      title={title}
      titleColor={"black"}
      titleSize="text-2xl"
      useDefaultFooter={false}
      dialogClass="filter-modal-dialog sm:"
    >
      <div className="flex flex-col gap-4">
        {subTitle && (
          <span
            className="font-normal text-lg text-center"
            style={{ color: Colors.Gray3 }}
          >
            {subTitle}
          </span>
        )}

        {children}

        <div className="flex flex-col gap-2">
          <Button
            className="filter-modal-btn"
            big={true}
            textColor={"white"}
            backgroundColor={Colors.Gold4}
            onClick={onFilterClick}
          >
            {filterBtnText}
          </Button>
          <Button
            className="filter-modal-btn"
            big={true}
            borderColor={Colors.Gray3}
            backgroundColor="white"
            onClick={onClearFiltersClick}
          >
            Limpar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
