import { FeeTypeEnum, GoldToken, Match, Token } from ".";
import Colors, { Blue1, Gray10 } from "../assets/Colors";
import { OrderStatusEnum, OrderTypeEnum, TokenTypeEnum } from "./enums";
export class Order {
  static determine = (status: OrderStatusEnum) => {
    switch (status) {
      case OrderStatusEnum.ACTIVE:
        return {
          statusText: "Em andamento",
          statusColor: Colors.Blue1,
        };
      case OrderStatusEnum.INACTIVE:
        return {
          statusText: "Interrompida",
          statusColor: Colors.Purple1,
        };
      case OrderStatusEnum.CANCELED:
        return {
          statusText: "Interrompida",
          statusColor: Colors.Purple1,
        };
      case OrderStatusEnum.FULFILLED:
        return {
          statusText: "Concluída",
          statusColor: Colors.Green3,
        };
      case OrderStatusEnum.SUSPENDED:
        return {
          statusText: "Interrompida",
          statusColor: Colors.Purple1,
        };
      case OrderStatusEnum.EXPIRED:
        return {
          statusText: "Expirada",
          statusColor: Colors.Gray4,
        };
    }
  };

  static determineStatusColor = (status?: OrderStatusEnum): string =>
    status ? Order.determine(status).statusColor : "";
  static determineStatusText = (status?: OrderStatusEnum): string =>
    status ? Order.determine(status).statusText : "";

  static determineStatusBgColors = (status: OrderStatusEnum) => {
    switch (status) {
      case OrderStatusEnum.ACTIVE:
        return Blue1;
      case OrderStatusEnum.CANCELED:
        return Gray10;
      case OrderStatusEnum.FULFILLED:
    }
  };
  static determineTypeText = (type?: OrderTypeEnum) => {
    switch (type) {
      case OrderTypeEnum.buy:
        return "Ordem de compra";
      case OrderTypeEnum.sell:
        return "Ordem de venda";
      default:
        return undefined;
    }
  };

  /// TODO
  static determineTypeReceivText = (type?: TokenTypeEnum) => {
    switch (type) {
      // case OrderTypeReceivableEnum.BOND:
      //     return 'Duplicata';
      // case OrderTypeReceivableEnum.CREDIT:
      //     return 'Cartão';
      default:
        return undefined;
    }
  };
  static determineTypeReceivTextResume = (type?: TokenTypeEnum) => {
    switch (type) {
      // case OrderTypeReceivableEnum.BOND:
      //     return 'RDUPL';
      // case OrderTypeReceivableEnum.CREDIT:
      //     return 'RCART';
      default:
        return undefined;
    }
  };
  static determineTypeReceivTextResumeCmpl = (type?: TokenTypeEnum) => {
    return `${Order.determineTypeReceivText(
      type
    )} (${Order.determineTypeReceivTextResume(type)})`;
  };

  static determineTypeText2 = (type?: OrderTypeEnum) => {
    switch (type) {
      case OrderTypeEnum.buy:
        return "compra";
      case OrderTypeEnum.sell:
        return "venda";
      default:
        return undefined;
    }
  };
}

// eslint-disable-next-line no-redeclare
export interface Order {
  id: string;
  fee: number;
  feeType: FeeTypeEnum;
  status: OrderStatusEnum;
  amount: number;
  consumed: number;
  processing: number;

  updateDate: Date;
  creationDate: Date;
  tokenExpirationDateEnd: Date;
  tokenExpirationDateStart: Date;

  type: OrderTypeEnum;
  typeReceivable: TokenTypeEnum;

  tokens: Array<Token>;
  finished: boolean;

  /// Only token Ouro
  quantity: number;
  available: number;
  tokenQuantity: number;
  remainingTokens: number;
}

// eslint-disable-next-line no-redeclare
export namespace Order {
  export interface Detail extends Order {
    averageFeeNegotiated: number;
    create_at: Date;
    freeAmount: number;
    fullAmount: number;
    orderExpirationDateEnd: Date;
    orderExpirationDateStart: Date;
    status: OrderStatusEnum;
    type: OrderTypeEnum;
    typeReceivable: TokenTypeEnum;
    update_at: Date;
    usedAmount: number;
    userId: string;

    matchs: Array<Match>;
    tokens: Array<GoldToken>;
    statusRunningOrder: "ACTIVE" | "CANCELED" | "FULFILLED" | "EXPIRED";

    executed?: number;
  }
}
