import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

/// Assets
import RoutesPaths from "../../Routes/RoutesPaths";
import Colors from "../../assets/Colors";

/// Icons
import { OkIcon } from "../../assets/icons";

/// Utils
import { formatToCurrency } from "../../utils/formatters";
import FilterBar from "../../components/FilterBar/FilterBar";

/// Componentes
import Footer from "../../components/Footer/Footer";
import TopBar from "../../components/TopBar/TopBar";
import Divider from "../../components/Divider/Divider";
import ModalCore from "../../components/Modal/ModalCore";
import MoneyInput from "../../components/Inputs/MoneyInput";
import { determineOrderStatusColor } from "../../utils/determineOrderStatusColor";

const MyAssetsNegociation: React.FC<any> = () => {
  // const assetContext = useContext(AssetContext);

  const receivedOrder = {
    amount: 1500000,
    expire: new Date(),
    status: "ACTIVE",
  };

  const [modalState, setModalState] = useState<boolean>(false);
  const [fee, setFeeValue] = useState<number>(1.9);
  const [value, setValue] = useState<number>(0);

  function setFee(add: boolean = true) {
    var newFee: number = fee + (add ? 0.1 : -0.1);
    newFee = Number(fee.toPrecision(2));
    if (fee >= 0.1) {
      setFeeValue(newFee);
      calculateValue(newFee);
    } else {
      calculateValue();
    }
  }

  useEffect(() => {
    calculateValue();
  }, []);

  function calculateFee(valueP?: number) {
    const a = receivedOrder.amount;
    const x = valueP ?? value;

    const fee: number = (100 * (a - x)) / a;

    setFeeValue(fee);
    setValue(value ?? x);
  }
  function calculateValue(feeP?: number) {
    const a = receivedOrder.amount;
    const y = feeP ?? fee;

    const value: number = a - (a * y) / 100;

    setValue(value);
  }

  function finishButton() {
    setModalState(true);
  }
  function sendAssetToNegociation() {
    console.log(
      "Isso aqui é executado antes de redirecionar. Salve aqui as informações a serem negociadas."
    );
  }

  return (
    <>
      <ModalCore
        state={modalState}
        changeState={(state) => setModalState(state)}
      >
        <div className="flex flex-col items-center">
          <OkIcon height="90" width="90" />
          <h3 className="text-center mt-4">
            Token disponibilizado para venda!
          </h3>
          <p
            className="text-sm text-center mt-5 mb-7"
            style={{ color: Colors.Gray3 }}
          >
            Acompanhe o andamento em sua lista de ordens de venda.
          </p>

          <div className="w-full">
            <hr className=" bg-gray-300" style={{ borderColor: "#BDBDBD" }} />
          </div>

          <div className="flex flex-row w-full my-3 px-10 justify-center">
            <Link
              to={RoutesPaths.MEUSATIVOS}
              className="font-semibold"
              style={{ color: Colors.Gray2 }}
              onClick={() => sendAssetToNegociation()}
            >
              OK
            </Link>
          </div>
        </div>
      </ModalCore>

      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <TopBar />
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar title="Negociar ativo" />

          <div className="p-5">
            <p>Defina o valor de negociação do ativo:</p>
          </div>
          <div
            className="bg-white px-5 py-1 mx-5 flex flex-col aling-center items-center rounded border"
            style={{ borderColor: Colors.Gray4 }}
          >
            <div className="flex flex-col items-start w-full p-2">
              <p style={{ color: Colors.Gray2 }}>
                {"Recebível de " +
                  /*props.actives.newActiveFlow.type_cartao*/ (false
                    ? "Cartão"
                    : "Duplicata")}
              </p>

              <div className="flex flex-row whitespace-pre">
                <p className="font-light" style={{ color: Colors.Gray3 }}>
                  {"Valor (BRL): "}
                </p>
                <p className="font-medium" style={{ color: Colors.Gray2 }}>
                  {formatToCurrency(receivedOrder.amount)}
                </p>
              </div>
              <div className="flex flex-row whitespace-pre">
                <p className="font-light" style={{ color: Colors.Gray3 }}>
                  {"Venc: "}
                </p>
                <p className="font-medium" style={{ color: Colors.Gray2 }}>
                  {moment(receivedOrder.expire).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="flex flex-row">
                <p
                  className="font-light whitespace-pre"
                  style={{ color: Colors.Gray3 }}
                >
                  {"Status: "}
                </p>
                <p
                  className="font-medium"
                  style={{
                    color: determineOrderStatusColor(receivedOrder.status),
                  }}
                >
                  {"Disponivel"}
                </p>
              </div>
            </div>

            <div className="w-full">
              <Divider top="mt-0" bottom="mt-5" />
            </div>

            <p className="my-5 text-lg" style={{ color: Colors.Gray2 }}>
              Informe o deságio máximo aceito para negociação do ativo:
            </p>
            <div className="flex flex-row w-56">
              <div
                className="flex flex-col w-5 py-2 px-5 my-2 rounded-l border border-r-0 items-center justify-center cursor-pointer"
                style={{
                  backgroundColor: Colors.Gray5,
                  borderColor: Colors.BorderColor,
                }}
                onClick={() => setFee(false)}
              >
                <p
                  className="font-medium text-xl"
                  style={{ color: Colors.Gray2 }}
                >
                  -
                </p>
              </div>
              <div
                className="flex-grow flex flex-col w-5 py-2 px-5 my-2 border items-center justify-center"
                style={{
                  backgroundColor: "#FFFFF",
                  borderColor: Colors.BorderColor,
                }}
              >
                <p
                  className="font-medium text-xl"
                  style={{ color: Colors.Gray2 }}
                >
                  {fee.toFixed(2)} %
                </p>
              </div>
              <div
                className="flex flex-col w-5 py-2 px-5 my-2 rounded-r border border-l-0 items-center justify-center cursor-pointer"
                style={{
                  backgroundColor: Colors.Gray5,
                  borderColor: Colors.BorderColor,
                }}
                onClick={() => setFee(true)}
              >
                <p
                  className="font-medium text-xl"
                  style={{ color: Colors.Gray2 }}
                >
                  +
                </p>
              </div>
            </div>

            <br />
            <br />

            <p style={{ color: Colors.Gray2 }}>
              Valor mínimo de recebimento*:{" "}
            </p>
            <p className="font-light">(*Baseado no deságio máximo aceito)</p>

            <br />

            <div
              className="h-12 border rounded justify-center items-center"
              style={{ borderColor: Colors.BorderColor, minWidth: "220px" }}
            >
              <MoneyInput
                prefix="R$"
                defaultValue={value}
                onChange={(p) => {
                  calculateFee(Number(p.target.value));
                }}
              />
            </div>

            <div className="w-full">
              <Divider />
            </div>

            <button
              className="rounded px-7 py-4 items-center text-white"
              style={{ backgroundColor: Colors.Base }}
              onClick={() => finishButton()}
            >
              Disponibilizar para venda
            </button>
            <Link
              to={RoutesPaths.MEUSATIVOS}
              className="my-5 items-center underline"
            >
              Cancelar negociação
            </Link>
          </div>
          <br />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MyAssetsNegociation;
