import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Colors from "../../assets/Colors";
import BarButton from "../../components/BarButton/BarButton";
import UnderlineButton from "../../components/Buttons/UnderlineButton";
import ResumeCard from "../../components/Cards/ResumeCard/ResumeCard";
import StatementsCard from "../../components/Cards/StatementsCard/StatementsCard";
import Divider from "../../components/Divider/Divider";
import FilterBar from "../../components/FilterBar/FilterBar";
import FilterInput from "../../components/Inputs/FilterInput/FilterInput";
import FilterModal from "../../components/Modal/FilterModal/FilterModal";
import ReceiveModal from "../../components/Modal/ReceiveModal";
import ResumeBar from "../../components/ResumeBar/ResumeBar";
import TopBar from "../../components/TopBar/TopBar";
import BalanceContext from "../../contexts/BalanceProvider";
import { Stament } from "../../models";
import RoutesPaths from "../../Routes/RoutesPaths";
import history from "../../services/history";
import * as Icons from "./../../assets/icons";
import * as API from "../../api";
import Footer from "../../components/Footer/Footer";
import UserContext from "../../contexts/UserProvider";

const Staments: React.FC<{ isExtractTab: boolean }> = ({ isExtractTab }) => {
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [statements, setStaments] = useState<Array<Stament>>([]);
  const [modalReceipt, setModalReceipt] = useState<{
    state: boolean;
    statement: Stament;
  }>({ state: false, statement: {} as Stament });

  useEffect(() => {
    loadStaments();
  }, []);

  async function loadStaments() {
    const newStaments = await API.user.getStaments();

    setStaments(newStaments);
    // setHasMore(statements.length !== newStaments.length);
  }

  return (
    <>
      <ReceiveModal
        state={modalReceipt.state}
        title={"Detalhes da movimentação"}
        stament={modalReceipt.statement}
        onChange={() => {
          document.body.style.overflow = "unset";
          setModalReceipt({ ...modalReceipt, state: false });
        }}
      />

      {isExtractTab ? (
        <div className="flex flex-col items-start justify-start w-full my-6">
          <p className="font-medium text-lg" style={{ color: Colors.Gray2 }}>
            Extrato financeiro
          </p>
          <p className="font-normal text-base" style={{ color: Colors.Gray4 }}>
            Veja as movimentações de sua conta
          </p>
        </div>
      ) : (
        <div className="flex flex-col items-start justify-start w-full ml-3 mb-4">
          <p className="font-medium text-lg" style={{ color: Colors.Gray2 }}>
            Últimas movimentações
          </p>
          <p className="font-normal text-base" style={{ color: Colors.Gray4 }}>
            Veja suas movimentações recentes
          </p>
        </div>
      )}

      <div style={{ display: "block", width: "100%" }}>
        {statements && (
          <InfiniteScroll
            dataLength={statements.length}
            next={() => {
              loadStaments();
            }}
            hasMore={hasMore}
            loader={
              <p style={{ textAlign: "center" }}>
                <h4>Carregando movimentações...</h4>
              </p>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Todos os registros exibidos</b>
              </p>
            }
          >
            {statements.map((statement) => (
              <StatementsCard
                statement={statement}
                onClick={() => {
                  document.body.style.overflow = "hidden";
                  setModalReceipt({
                    state: true,
                    statement,
                  });
                }}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
      <br />
    </>
  );
};

const MyBalance: React.FC = () => {
  const { balance } = useContext(BalanceContext);
  const { user } = useContext(UserContext);

  const { currentPage, setCurrentPage } = useContext(BalanceContext);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [currentActiveTab, setCurrentActiveTab] = useState<string>("Clientes");
  const [userTypeFilter, setUserTypeFilter] = useState<string>("ALL");
  const [transactionTypeFilter, setTransactionTypeFilter] =
    useState<string>("ALL");
  const [filtersCount, setFiltersCount] = useState<number>(0);

  useEffect(() => {
    setCurrentPage(1);
    return () => setCurrentPage(1);
  }, []);

  useEffect(() => {
    let count = 0;
    if (userTypeFilter !== "ALL") count++;
    if (transactionTypeFilter !== "ALL") count++;

    setFiltersCount(count);
  }, [userTypeFilter, transactionTypeFilter]);

  const renderFilterSection = () => {
    return (
      <>
        <FilterModal
          state={modalIsOpen}
          filterBtnText="Filtrar movimentações"
          title="Filtrar movimentações"
          subTitle="Selecione as caracteristicas e clique no botão"
          onCancelClick={() => setModalIsOpen(false)}
          onFilterClick={handleFilterSearch}
          onClearFiltersClick={clearFilters}
        >
          <FilterInput
            className="w-full"
            inputBoxClassName="filter-modal-input"
            label="Tipos de usuário"
            placeholder=""
            value={userTypeFilter}
            type="select"
            onChange={(selectedOption) =>
              setUserTypeFilter(selectedOption.target.value)
            }
            options={[
              { label: "Todos", value: "ALL" },
              { label: "Física", value: "INDIVIDUAL" },
              { label: "Jurídica", value: "CORPORATE" },
            ]}
            icon={Icons.ArrowIcon}
          />
          <FilterInput
            className="w-full"
            inputBoxClassName="filter-modal-input"
            label="Tipos de movimentação"
            placeholder=""
            value={transactionTypeFilter}
            type="select"
            onChange={(selectedOption) =>
              setTransactionTypeFilter(selectedOption.target.value)
            }
            options={[
              { label: "Todos tipos de movimentação", value: "ALL" },
              { label: "Saque", value: "DRAFT" },
              { label: "Depósito", value: "DEPOSIT" },
            ]}
            icon={Icons.ArrowIcon}
          />
        </FilterModal>

        <section className="flex flex-row gap-6 py-7 px-5">
          <FilterInput
            inputBoxClassName="filter-section-input"
            className="w-3/5"
            placeholder="Pesquisar movimentações por código ou descrição"
            icon={Icons.SearchIcon}
          />
          <FilterInput
            inputBoxClassName="filter-section-input"
            textColorActive={!!filtersCount}
            placeholder={filtersCount ? `Filtrar (${filtersCount})` : "Filtrar"}
            icon={Icons.FilterIcon}
            onClick={() => setModalIsOpen(true)}
          />
        </section>
        <Divider top="mt-0" bottom="mb-2" />
      </>
    );
  };

  const handleFilterSearch = () => {
    setModalIsOpen(false);
  };

  const clearFilters = () => {
    setUserTypeFilter("ALL");
    setTransactionTypeFilter("ALL");
  };

  const renderCurrentPage = (): JSX.Element => {
    switch (currentPage) {
      case 1:
        return (
          <div className="flex flex-row w-full justify-center">
            <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
              <ResumeBar>
                <ResumeCard
                  bgColor={Colors.Gray2}
                  textColor="white"
                  title="Saldo disponível"
                  value={balance?.currency?.available ?? 0}
                  hiddenContent={{
                    showHiddenContent: user?.showWallet === false,
                    content: (
                      <span
                        className="font-normal text-5xl lg:mt-6"
                        style={{ lineHeight: "2rem" }}
                      >
                        ••••
                      </span>
                    ),
                  }}
                />
                <ResumeCard
                  bgColor={Colors.Gray2}
                  textColor="white"
                  title={"Saldo retido em negociação"}
                  value={balance?.currency?.consumed ?? 0}
                  hiddenContent={{
                    showHiddenContent: user?.showWallet === false,
                    content: (
                      <span
                        className="font-normal text-5xl lg:mt-6"
                        style={{ lineHeight: "2rem" }}
                      >
                        ••••
                      </span>
                    ),
                  }}
                />
                <ResumeCard
                  bgColor={Colors.Gray2}
                  textColor="white"
                  title={"Saldo total da conta"}
                  value={balance?.currency?.full ?? 0}
                  hiddenContent={{
                    showHiddenContent: user?.showWallet === false,
                    content: (
                      <span
                        className="font-normal text-5xl lg:mt-6"
                        style={{ lineHeight: "2rem" }}
                      >
                        ••••
                      </span>
                    ),
                  }}
                />
              </ResumeBar>

              <Divider top="mt-2" bottom="mb-8" />

              <div className="flex flex-row justify-between w-full gap-5 mb-8">
                {[
                  {
                    label: "Depositar saldo",
                    icon: Icons.BigWalletIcon,
                    onClick: () => history.push(RoutesPaths.DEPOSIT),
                  },
                  {
                    label: "Saque",
                    icon: Icons.TransferIcon,
                    onClick: () => history.push(RoutesPaths.TRANSFER),
                  },
                  {
                    label: "Ver extrato",
                    icon: Icons.ExtratoIcon,
                    onClick: () => setCurrentPage(2),
                  },
                ].map(({ icon, label, onClick }) => (
                  <button
                    className="
                                            flex flex-col w-full gap-2 md:w-1/3
                                            rounded border-2 py-3 px-3 md:px-6 
                                            justify-between items-center
                                        "
                    style={{
                      borderWidth: "1px",
                      borderColor: Colors.Gray3,
                    }}
                    onClick={onClick}
                  >
                    <img src={icon} alt="" />
                    <h3
                      className="text-base font-normal text-center"
                      style={{ color: Colors.Gray2 }}
                    >
                      {label}
                    </h3>
                  </button>
                ))}
              </div>

              <Divider top="mt-0" />

              <Staments isExtractTab={false} />

              <UnderlineButton onClick={() => setCurrentPage(2)}>
                Ver mais movimentações
              </UnderlineButton>
            </div>
          </div>
        );
      case 2:
        return (
          <>
            {/* {renderFilterSection()} */}
            <div className="mx-4">
              <Staments isExtractTab />
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <TopBar />
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar className="pt-5">
            <BarButton
              state={currentPage === 1}
              children="Meu saldo"
              onClick={() => setCurrentPage(1)}
            />
            <BarButton
              state={currentPage === 2}
              children="Meu extrato"
              onClick={() => setCurrentPage(2)}
            />
          </FilterBar>

          {renderCurrentPage()}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MyBalance;
