import React, { useContext, useState, useEffect } from "react";
import { Form } from "@unform/web";

/// Utils
import { UnFormValidate, YupAdaptErrorToUnform } from "../../../utils/yupUtils";
import { bankingDomicileValidation } from "../../../validations/bankingDomicileValidation";
import UnformInputAdapt from "../../../components/unform/UnformInputAdapt/UnformInputAdapt";

/// Assets
import * as Icons from "../../../assets/icons";
import * as Gifs from "../../../assets/gifs";
import * as API from "../../../api";
import history from "../../../services/history";
import RoutesPaths from "../../../Routes/RoutesPaths";
import Colors from "../../../assets/Colors";
import "./../style.css";

/// Components
import { FormHandles } from "@unform/core";
import UserContext from "../../../contexts/UserProvider";
import AlertModal from "../../../components/Modal/AlertModal";
import FloatInput from "../../../components/Inputs/FloatInput";
import Select from "../../../components/Inputs/Select";
import PageBoardSkeleton from "../../../components/PageBoardSkeleton/PageBoardSkeleton";
import BankAccountMask from "../../../components/Inputs/MaskedInput/Mask/BankAccountMask";

const BankingDomicilePage: React.FC<any> = () => {
  const userContext = useContext(UserContext);
  const { setUser } = useContext(UserContext);

  const [formRef] = useState<React.RefObject<FormHandles>>(React.useRef(null));

  const [bankOptions, setBankOptions] = useState([]);

  const [modalSuccessState, setModalSuccessState] = useState<boolean>(false);
  const [modalErrorState, setModalErrorState] = useState<boolean>(false);
  const [firstSubmit, setFirstSubmit] = useState<boolean>(true);

  const [formInitialData, setFormInitialData] = useState<
    | {
        favorecido: string;
        documento: string;
        bank: string;
        bankAgency: string;
        bankAccount: string;
        bankAccountDigit: string;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    loadBankOptions();
  }, []);

  const loadBankOptions = async () => {
    if (!bankOptions.length) {
      API.signup.getBank().then((result) => {
        const options = result.map((item) => {
          return {
            label: `${item.number} - ${item.fullName}`,
            value: item.number,
          };
        });
        setBankOptions(options as never[]);
        setInitialData();
      });
    }
  };

  const setInitialData = async () => {
    if (userContext.user && !formInitialData) {
      const favorecido = userContext.user.name;
      const documento = userContext.user.document;
      const bank = userContext.user.userBankInfo.bank.toString();
      const bankAgency = userContext.user.userBankInfo.bankAgency;
      const bankAccount =
        userContext.user.userBankInfo.bankAccount.split("-")[0];
      const bankAccountDigit =
        userContext.user.userBankInfo.bankAccount.split("-")[1];
      setFormInitialData({
        favorecido: favorecido,
        documento: documento,
        bank: bank,
        bankAgency: bankAgency,
        bankAccount: bankAccount,
        bankAccountDigit: bankAccountDigit,
      });

      if (favorecido && documento && bank != "0" && bankAgency && bankAccount) {
        setButtonSubmitProps({
          ...buttonSubmitProps,
          disabled: false,
        });
      } else {
        setButtonSubmitProps({
          ...buttonSubmitProps,
          disabled: true,
        });
      }
    }
  };

  const setCodeBank = (event) => {
    if (formRef?.current) {
      const data = formRef?.current.getData();
      formRef.current.setData({
        ...data,
        bank: event?.currentTarget?.value,
      });
    }
  };

  const updateData = async (data: any) => {
    // setLoading(true);
    setButtonSubmitProps({
      ...buttonSubmitProps,
      loading: true,
    });

    if (firstSubmit) {
      setFirstSubmit(false);
    }

    try {
      const newValues = {
        bank: data.bank,
        bankAccount: [data.bankAccount, data.bankAccountDigit].join("-"),
        bankAgency: data.bankAgency,
      };
      await API.user.updateBankInfo(newValues, userContext.user?.id as string);
      await userContext.updateUser();
      setModalSuccessState(true);
      document.body.style.overflow = "hidden";
      await updateUser();
    } catch (error) {
      setModalErrorState(true);
    } finally {
      // setLoading(false);
      setButtonSubmitProps({
        ...buttonSubmitProps,
        loading: false,
      });
    }
  };

  async function updateUser() {
    try {
      const data = await API.user.getUserInfo();
      setUser(data);
    } catch (error) {
      // console.error(error);
    }
  }

  const handleFormSubmit = async (input: any, { reset }) => {
    if (!formRef.current) return null;

    try {
      const schema = bankingDomicileValidation;
      const data = await schema.validate(input, {
        abortEarly: false,
      });

      updateData(data);
    } catch (error: any) {
      formRef.current.setErrors(YupAdaptErrorToUnform(error));
    }
  };

  const saveAccount = (): void => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const [buttonSubmitProps, setButtonSubmitProps] = useState<any>({
    label: "Salvar conta",
    onClick: saveAccount,
    loading: false,
    className: "skeleton-btn",
    disabled: true,
  });

  function formatCPF(cpf) {
    cpf = String(cpf);
    cpf = cpf.replace(/\D/g, ""); //Remove tudo o que não é dígito
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos

    return cpf;
  }

  function validateFormAndSetButtonDisabled() {
    const bank = formRef?.current?.getFieldValue("bank");
    const bankAgency = formRef?.current?.getFieldValue("bankAgency");
    const bankAccount = formRef?.current?.getFieldValue("bankAccount");
    const bankAccountDigit =
      formRef?.current?.getFieldValue("bankAccountDigit");

    if (bank == "0" || bankAgency == "" || bankAccount == "") {
      setButtonSubmitProps({
        ...buttonSubmitProps,
        disabled: true,
      });
    } else {
      setButtonSubmitProps({
        ...buttonSubmitProps,
        disabled: false,
      });
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !buttonSubmitProps.disabled) {
      buttonSubmitProps.onClick();
    }
  };

  return (
    <>
      <AlertModal
        src={Gifs.IconOkGif}
        title="Conta atualizada com sucesso!"
        state={modalSuccessState}
        confirmClick={() => {
          history.push(RoutesPaths.ACCOUT);
          document.body.style.overflow = "unset";
        }}
      >
        <p>Clique no botão para voltar a listagem de contas</p>
      </AlertModal>
      <AlertModal
        src={Gifs.IconAlertGif}
        title="Falha na validação!"
        state={modalErrorState}
        confirmClick={() => {
          setModalErrorState(false);
          document.body.style.overflow = "unset";
        }}
      >
        Verifique se todos os campos foram preenchidos corretamente e tente
        novamente
      </AlertModal>
      <PageBoardSkeleton
        filterBar="Dados Bancários"
        onClickFilterBar={() => history.goBack()}
        button={buttonSubmitProps}
        button2={{
          label: "Cancelar edição",
          onClick: () => history.push(RoutesPaths.ACCOUT_BANK),
        }}
      >
        {/* <div className="flex flex-col w-4/5 lg:w-3/5 max-w-2xl border rounded overflow-hidden" style={{ borderColor: Colors.Gray4 }}></div> */}
        <div className="flex flex-col items-center w-full md:w-4/5 max-w-3xl">
          <div
            className="text-xl font-normal flex justify-center items-center mt-4 mb-5 gap-2"
            style={{ color: Colors.Gray3 }}
          >
            <img src={Icons.BankIcon} className="h-10 w-10" alt="" />
            <span>DADOS BANCÁRIOS</span>
          </div>

          <Form
            className="account-form text-lg font-medium w-full"
            ref={formRef}
            onSubmit={handleFormSubmit}
            initialData={formInitialData}
            onChange={validateFormAndSetButtonDisabled}
            onKeyDown={handleKeyDown}
          >
            {formInitialData?.favorecido !== undefined ? (
              <UnformInputAdapt
                name="favorecido"
                children={({ ref, error, defaultValue }) => (
                  <FloatInput
                    big={true}
                    disabled={true}
                    defaultValue={defaultValue}
                    color="bg-white"
                    className="account-form-input"
                    placeholder="Favorecido"
                    type="text"
                    ref={ref}
                  />
                )}
              />
            ) : (
              <FloatInput
                big={true}
                disabled={true}
                defaultValue=""
                color="bg-white"
                className="account-form-input"
                placeholder="Favorecido"
                type="text"
              />
            )}
            {formInitialData?.documento !== undefined ? (
              <UnformInputAdapt
                name="documento"
                children={({ ref, error, defaultValue }) => (
                  <FloatInput
                    big={true}
                    disabled={true}
                    defaultValue={formatCPF(defaultValue)}
                    color="bg-white"
                    className="account-form-input"
                    placeholder="CPF"
                    type="text"
                    ref={ref}
                  />
                )}
              />
            ) : (
              <FloatInput
                big={true}
                disabled={true}
                defaultValue=""
                color="bg-white"
                className="account-form-input"
                placeholder="CPF"
                type="text"
              />
            )}
            <UnformInputAdapt
              name="bank"
              children={({ ref, error, defaultValue }) => (
                <Select
                  className="account-form-input bg-white"
                  defaultChecked={defaultValue}
                  placeholder="Código do banco"
                  ref={ref}
                  options={bankOptions}
                  onChange={(value) => setCodeBank(value)}
                />
              )}
            />
            <UnformInputAdapt
              name="bankAgency"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  big={true}
                  defaultValue={defaultValue}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="Agência (Sem dígito)"
                  type="number"
                  length={4}
                  onInput={validateFormAndSetButtonDisabled}
                  ref={ref}
                />
              )}
            />
            <div className="flex gap-2">
              <UnformInputAdapt
                name="bankAccount"
                children={({ ref, error, defaultValue }) => (
                  <FloatInput
                    defaultValue={defaultValue}
                    big={true}
                    color="bg-white"
                    className="account-form-input w-3/4"
                    placeholder="Conta corrente"
                    type="number"
                    pattern="[0-9]*"
                    onInput={validateFormAndSetButtonDisabled}
                    ref={ref}
                  />
                )}
              />
              <UnformInputAdapt
                name="bankAccountDigit"
                children={({ ref, error, defaultValue }) => (
                  <FloatInput
                    defaultValue={defaultValue}
                    big={true}
                    error={error}
                    color="bg-white"
                    className="account-form-input w-1/4"
                    placeholder="Dígito"
                    type="number"
                    length={2}
                    pattern="[0-9]*"
                    onInput={validateFormAndSetButtonDisabled}
                    ref={ref}
                  />
                )}
              />
            </div>
          </Form>
        </div>

        <div className="text-sm font-normal text-center w-full md:w-4/5 max-w-3xl">
          <p style={{ color: Colors.Gray3 }} className="underline mt-8">
            ATENÇÃO:
          </p>
          <p style={{ color: Colors.Gray3 }} className="mb-10">
            Mantenha seus dados bancários sempre atualizados. Não nos
            responsabilizamos por erros ocasionados pelo preenchimento de dados
            incorretos.
          </p>
        </div>
      </PageBoardSkeleton>
    </>
  );
};

export default BankingDomicilePage;
