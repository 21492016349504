import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import * as API from "../../../api";
import livenessAPI from "../../../api/liveness";
import Colors from "../../../assets/Colors";
import * as Gifs from "../../../assets/gifs";
import * as Icons from "../../../assets/icons";
import { StylePatterns } from "../../../assets/patterns_tailwind_class";
import Button from "../../../components/Buttons/Button";
import UnderlineButton from "../../../components/Buttons/UnderlineButton";
import { DocCard } from "../../../components/Cards/DocCard";
import Divider from "../../../components/Divider/Divider";
import FilterBar from "../../../components/FilterBar/FilterBar";
import AlertModal from "../../../components/Modal/AlertModal";
import ModalCore from "../../../components/Modal/ModalCore";
import UploadDoc, {
  UploadDocProps,
} from "../../../components/UploadDoc/UploadDoc";
import ModalContext from "../../../contexts/ModalProvider";
import UserContext from "../../../contexts/UserProvider";
import {
  Client,
  ClientDocStatusEnum,
  ClientStatusEnum,
  DocumentTypeEnum,
  TypeUserEnum,
  TypeUserEnumInterpreter,
  UserStatusEnum,
} from "../../../models";
import { DocumentsTypeEnum } from "../../../models/enums";
import RoutesPaths from "../../../Routes/RoutesPaths";
import history from "../../../services/history";
import { determineErrorMessage } from "../../../services/i18n/aux";
import PageSkeleton from "../../../components/PageSkeleton/PageSkeleton";
import "./DocumentsPage.css";
import { getLoggedUserDocStatus } from "../../../utils/ClientUtils";

const DocumentListPF: React.FC<{
  goTo(p: DocumentTypeEnum): any;
  client: Client;
}> = ({ goTo, client }) => {
  return (
    <>
      <h2 className="text-xl font-normal mb-3" style={{ color: Colors.Gray3 }}>
        DOCUMENTAÇÃO - {client.name.toUpperCase()}
      </h2>
      <div className="flex flex-col w-full items-center gap-5">
        {/*
          [
            DocumentTypeEnum.ProofOfRedence,
            DocumentTypeEnum.Irpf,
            DocumentTypeEnum.CompanyParticipation
          ].map(doc => (
            <DocCard autoWidth={false} type={doc} doc={client.docs.get(doc)} onClick={() => goTo(doc)} />
          ))
          */}
      </div>
    </>
  );
};
const DocumentListPJ: React.FC<{
  goTo(p: DocumentTypeEnum): any;
  client: Client;
}> = ({ goTo, client }) => {
  return (
    <>
      <h2
        className="text-lg font-extralight mb-3"
        style={{ color: Colors.Gray3 }}
      >
        DOCUMENTAÇÃO - EMPRESA
      </h2>
      <div className="flex flex-col w-full items-center gap-5">
        {/*
          [
            DocumentTypeEnum.SocialContract,
            DocumentTypeEnum.Assembly,
            DocumentTypeEnum.Procuration,
            DocumentTypeEnum.AbcCurve,
            DocumentTypeEnum.BalanceSheet,
            DocumentTypeEnum.LastBillingList
          ].map(doc => (
            <DocCard autoWidth={false} type={doc} doc={client.docs.get(doc)} onClick={() => goTo(doc)} />
          ))
          */}
      </div>

      {/* <h2 className='text-lg font-extralight my-3' style={{ color: Colors.Gray3 }}>
      DOCUMENTAÇÃO - SÓCIOS
    </h2>

    <div className='flex flex-col w-full items-center gap-3'>
      {
        [
          DocumentTypeEnum.partnerPrincipalDoc,
          DocumentTypeEnum.partnerProofOfRedence,
          DocumentTypeEnum.partnerIrpf,
          DocumentTypeEnum.partnerCompanyParticipation
        ].map(doc => (
          <DocCard autoWidth={false} type={doc} doc={client.docs.get(doc)} onClick={() => goTo(doc)} />
        ))
      }
    </div>


    <div className='border rounded cursor-pointer py-3 px-4 items-center mb-7' style={{ borderColor: Colors.BorderColor }}>
      <p style={{ color: Colors.Gray3 }}>+ Adicionar outro sócio</p>
    </div> */}
    </>
  );
};

export const Liveness: React.FC = (props) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [modalLiveness, setModalLiveness] = useState(false);
  var liveness: any;

  async function getJWT(apiKey) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `ApiKey ${apiKey}`,
      },
    };
    const endpoint = "https://api-homolog.nxcd.app/auth-jwt";
    const response = await fetch(endpoint, requestOptions);
    const token = await response.json();
    return token.accessToken;
  }

  function errorCallback(p) {
    // console.log('--------- base64 ------', liveness.base64)

    setError("Ops.. A verificação facial não foi realizada");
    setSuccess("");

    liveness.start();
  }

  function onLivenessSuccess(response) {
    setSuccess("Verificação facial realizada com sucesso");
    livenessAPI.saveImage(liveness.base64);
    setError("");
  }

  async function start() {
    const apiKey = process.env.REACT_APP_LIVENESS_API_KEY;
    const jwt = await getJWT(apiKey);

    const configuration = {
      width: 640, // largura de exibição da câmera
      isDebug: !true,
      token: jwt,
      faceapiPath: "/libs", // caminho para a faceapi e modelos baixados
      livenessUrlBase: "https://api-homolog.nxcd.app", // endpoint da api liveness
      livenessConfirmEndpoint: "", // opcional - default: /liveness
      isShowPreview: !true, // exibir um preview da foto que será enviada
      errorCallback: errorCallback, // metodo de callback em caso de erro
      successCallback: onLivenessSuccess, // metodo de callback em caso de sucesso
    };

    if (!error && !success) {
      const videoWrapper = document.getElementById("video-wrapper");
      liveness = new (window as any).Liveness(videoWrapper, configuration);
      liveness.start();
    }
  }

  function getButtonColor() {
    if (error) return Colors.Red;
    if (success) return Colors.Green1;
    return Colors.Blue1;
  }

  function getModalContent() {
    if (error) return Gifs.IconAlertGif;
    if (success) return Gifs.IconOkGif;
  }

  return (
    <>
      <ModalCore state={modalLiveness} changeState={setModalLiveness}>
        <div style={{ width: "100%", borderRadius: 11.6883 }}>
          {getModalContent() && <img src={getModalContent()} alt="img" />}
          {!error && !success && <div id="video-wrapper" />}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p
            className="font-medium text-2xl text-center m-auto"
            style={{
              backgroundColor: getButtonColor(),
              height: 62,
              paddingTop: 16,
              position: "relative",
              width: 600,
            }}
          >
            {error || success || "Posicione seu rosto no centro da marcação"}
          </p>
          <p
            className="font-bold text-center text-2xl"
            style={{
              color: Colors.Gray4,
              textTransform: "uppercase",
              cursor: "pointer",
              marginBottom: 29,
              marginTop: 40,
            }}
            onClick={() => setModalLiveness(false)}
          >
            interromper verificação
          </p>
        </div>
      </ModalCore>

      <h1 className="text-xl" style={{ color: Colors.Gray2 }}>
        Verificação facial
      </h1>
      <h2 className="text-sm font-light">
        Realize a verificação facial do titular da conta
      </h2>

      <Divider top="mt-3" bottom="mb-5" />

      <h2 style={{ color: Colors.Gray3 }} className="text-center text-xl mb-5">
        Nome completo do titular
      </h2>

      <img src={Icons.CustomerIcon} alt="CustomerIcon" />

      <div
        className="text-center font-light text-xs my-5 px-3 flex flex-col gap-2"
        style={{ color: Colors.Blue1 }}
      >
        <p className="underline">ATENÇÃO:</p>
        <p>
          Certifique-se que seu dispositivo possui uma câmera em funcionamento e
          que as condições de luminosidade estejam adequadas para realizar a
          captação.
        </p>
        <p>
          Clique no botão abaixo para iniciar o processo de verificação facial
          de prova de vida.
        </p>
      </div>

      <Button
        className="skeleton-btn w-3/4"
        onClick={() => {
          setModalLiveness(true);
          start();
        }}
      >
        Iniciar verificação facial
      </Button>

      <UnderlineButton
        className="skeleton-btn2 text-base font-normal"
        style={{ color: Colors.Gray3 }}
        onClick={history.goBack}
      >
        Voltar à listagem
      </UnderlineButton>
    </>
  );
};
export const LivenessPage: React.FC = () => {
  return (
    <PageSkeleton
      goBackBtn
      subTitle=""
      filterBar={"Certifique sua conta (PF) / Liveness"}
    >
      <Liveness />
    </PageSkeleton>
  );
};

export const DocumentPage: React.FC<any> = (props) => {
  const [errorModalState, setErrorModalState] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>("");
  const [documents, setDocuments] = useState([]);
  const { user } = useContext(UserContext);

  const handleSendDocuments = async () => {
    if (!user) return;
    if (documents.length < 2) {
      setTextError("É necessário enviar todos os documentos solicitados");
      setErrorModalState(true);
      return;
    }

    setLoading(true);

    try {
      const data = await API.signup.individual.sendDocuments(
        user.id,
        documents
      );
      console.log(data);
      const hasFedaralIdCertification =
        data.certifications.includes("FEDERALID");
      if (!hasFedaralIdCertification) {
        setTextError(
          "Seu documento não pode ser validado automaticamente. Fique atento ao seu email, o tempo de verificação poderá ser de até 48hs"
        );
        setErrorModalState(true);
      } else {
        history.goBack();
      }
    } catch (err) {
      setTextError(determineErrorMessage(err));
      setErrorModalState(true);
    } finally {
      setLoading(false);
    }
  };

  const changeFile = (file, identifier: string) => {
    let files = [];
    const old = documents.find((doc: any) => doc.identifier === identifier);

    if (old) {
      files = documents.map((doc: any) => {
        if (doc.identifier === identifier) {
          return {
            file: file,
            identifier: identifier,
          };
        }
        return doc;
      }) as never;
    } else {
      files.push({
        file: file,
        identifier: identifier,
      } as never);
      files = documents.concat(files);
    }

    setDocuments(files);
  };

  return (
    <PageSkeleton
      useContentCard
      button={{ label: "Prosseguir", onClick: handleSendDocuments }}
      button2={{ label: "Cancelar procedimento", onClick: history.goBack }}
      loading={isLoading}
      filterBar={
        <FilterBar goBackBtn title="Documento com foto" selection={false} />
      }
    >
      <AlertModal
        state={errorModalState}
        title="Documento em análise"
        src={Gifs.IconAlertGif}
        confirmClick={() => {
          setErrorModalState(false);
          history.push(RoutesPaths.ACCOUT_DOCUMENTS);
        }}
      >
        <p>{textError}</p>
      </AlertModal>

      <div
        className={
          "w-full sm:w-4/5 lg:w-3/5 max-w-2xl flex flex-col items-center"
        }
      >
        <h2
          className="text-xl sm:text-2xl text-center font-medium mt-4 mb-2"
          style={{ color: Colors.Gray2 }}
        >
          Documento de identificação
        </h2>
        <h3
          className="text-sm sm:text-base font-normal mb-11 text-center"
          style={{ color: Colors.Gray3 }}
        >
          Anexe seu documento de identificação (RG ou CNH válidos)
        </h3>

        {/* <p className='text-xl mb-5' style={{ color: Colors.Gray3 }}>
          {user?.userType === TypeUserEnum.CORPORATE ? 'Nome completo do sócio / administrador' : 'Nome completo do titular' }
        </p> */}

        <UploadDoc
          type="IMAGE"
          fullW={true}
          eventOnChange={(file, identifier) => changeFile(file, identifier)}
          document={{
            identifier: "FEDERALID_FRONT",
            label: `Frente do documento com foto`,
            description:
              "(Imagem contendo frente do documento: JPEG, PNG, PDF até 5mb)",
          }}
        />
        <UploadDoc
          type="IMAGE"
          fullW={true}
          eventOnChange={(file, identifier) => changeFile(file, identifier)}
          document={{
            identifier: "FEDERALID_BACK",
            label: `Verso do documento com foto`,
            description:
              "(Imagem contendo verso do documento: JPEG, PNG, PDF até 5mb)",
          }}
        />

        <div
          className="text-center text-xs font-normal my-5 md:px-3 flex flex-col gap-1"
          style={{ color: Colors.Gray3 }}
        >
          <p className="underline">ATENÇÃO:</p>
          <p>
            Caso a imagem do documento anexado já possua a frente e verso do
            documento, favor anexar a mesma imagem nos dois campos.
          </p>
          <br />
          <p>
            Usaremos os dados pessoais informados em seu cadastro para validar o
            documento enviado.
          </p>
          <p>Usaremos sua foto do documento na etapa de verificação facial.</p>
        </div>
      </div>
    </PageSkeleton>
  );
};

export const ComplementaryDocumentationPage: React.FC = () => {
  const { typeDocumentation } = useParams<{
    typeDocumentation?: DocumentTypeEnum;
  }>();
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [errorModalState, setErrorModalState] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>("");
  const { user, userDetails, updateUserDetails } = useContext(UserContext);
  const { handleModal, setModal } = useContext(ModalContext);
  const [files, setFiles] = useState([]);
  const [docType, setDocType] = useState<DocumentTypeEnum | undefined>(
    typeDocumentation
  );

  useEffect(() => {
    updateUserDetails();
  }, [user]);

  const auxTitulariryDoc = (docType: DocumentTypeEnum) =>
    docType.toString().includes("partner") ? "Sócio" : "Titular";

  const changeFile = (file, identifier: string) => {
    let newfiles = [];
    const old = files.find((doc: any) => doc.identifier === identifier);

    if (old) {
      newfiles = files.map((doc: any) => {
        if (doc.identifier === identifier) {
          return {
            file: file,
            identifier: identifier,
          };
        }
        return doc;
      }) as never;
    } else {
      newfiles.push({
        file: file,
        identifier: identifier,
      } as never);
      newfiles = newfiles.concat(files);
    }

    setFiles(newfiles);
  };

  const renderBody = (): JSX.Element | JSX.Element[] => {
    let aux: UploadDocProps[] = [];

    switch (docType) {
      case DocumentTypeEnum.partnerPrincipalDoc:
        aux.push({
          type: "ALL",
          title: "RG (Sócio 1)",
          document: {
            identifier: "document_frente",
            label: "Comprovante do CNPJ da empresa",
            description:
              "(Imagem contendo frente do documento: JPEG, PNG, PDF até 5mb)",
          },
        });
        aux.push({
          type: "ALL",
          title: "",
          document: {
            identifier: "document_verso",
            label: "Verso do documento com foto",
            description:
              "(Imagem contendo verso do documento: JPEG, PNG, PDF até 5mb)",
          },
        });
        break;
      case DocumentTypeEnum.SocialContract:
        aux.push({
          type: "ALL",
          title: "Verificação da empresa",
          document: {
            identifier: "contratosocial",
            label: "Comprovante do CNPJ da empresa",
            description:
              "(Cartão CNPJ ou Contrato social da empresa: JPEG, PNG, PDF até 5mb)",
          },
        });
        break;
      case DocumentTypeEnum.Assembly:
        aux.push({
          type: "ALL",
          title: "Ata da assembléia",
          document: {
            identifier: "ata",
            label: "Ata da assembléia (somente SA)",
            description:
              "Última ata da assembleia registrada na junta (somente de for SA): JPEG, PNG, PDF até 5mb",
          },
        });
        break;
      case DocumentTypeEnum.Procuration:
        aux.push({
          type: "ALL",
          title: "Procurações",
          document: {
            identifier: "procuracao",
            label: "Procurações",
            description:
              "Caso existe alguma procuração no nome da empresa, envie aqui: JPEG, PNG, PDF até 5mb",
          },
        });
        break;
      case DocumentTypeEnum.AbcCurve:
        aux.push({
          type: "ALL",
          title: "Curva ABC",
          document: {
            identifier: "curvaabc",
            label: "Curva ABC",
            description:
              "Documento informando curva ABC (principais clientes): JPEG, PNG, PDF até 5mb)",
          },
        });
        break;
      case DocumentTypeEnum.BalanceSheet:
        aux.push({
          type: "ALL",
          title: "Balanço patrimonial",
          document: {
            identifier: "balancoultimo",
            label: "Balanço patrimonial (Útimo ano)",
            description:
              "Balanço Patrimonial com DRE assinado pelo contador e/ou diretor: JPEG, PNG, PDF até 5mb",
          },
        });
        aux.push({
          type: "ALL",
          title: "Balanço patrimonial",
          document: {
            identifier: "balancopenultimo",
            label: "Balanço patrimonial (penúltimo ano)",
            description:
              "Balanço Patrimonial com DRE assinado pelo contador e/ou diretor: JPEG, PNG, PDF até 5mb",
          },
        });
        aux.push({
          type: "ALL",
          title: "Balanço patrimonial",
          document: {
            identifier: "balancoantepenultimo",
            label: "Balanço patrimonial (antepenúltimo ano)",
            description:
              "Balanço Patrimonial com DRE assinado pelo contador e/ou diretor: JPEG, PNG, PDF até 5mb",
          },
        });
        break;
      case DocumentTypeEnum.LastBillingList:
        aux.push({
          type: "ALL",
          title: "Relação de faturamento",
          document: {
            identifier: "faturamentoultimo",
            label: "Relação de faturamento (Útimo ano)",
            description:
              "Relação de faturamento assinada pelo contador e/ou diretor: JPEG, PNG, PDF até 5mb",
          },
        });
        aux.push({
          type: "ALL",
          title: "Relação de faturamento",
          document: {
            identifier: "faturamentopenultimo",
            label: "Relação de faturamento (penúltimo ano)",
            description:
              "Relação de faturamento assinada pelo contador e/ou diretor: JPEG, PNG, PDF até 5mb",
          },
        });
        aux.push({
          type: "ALL",
          title: "Relação de faturamento",
          document: {
            identifier: "faturamentoantepenultimo",
            label: "Relação de faturamento (antepenúltimo ano)",
            description:
              "Relação de faturamento assinada pelo contador e/ou diretor: JPEG, PNG, PDF até 5mb",
          },
        });
        break;

      case DocumentTypeEnum.partnerIrpf:
      case DocumentTypeEnum.Irpf:
        aux.push({
          type: "ALL",
          title: `Imposto de renda pessoa física (${auxTitulariryDoc(
            docType
          )})`,
          document: {
            identifier: "impostoderendapf",
            label: "Imposto de renda (último exercício)",
            description:
              "Envie o comprovante de IRPF do último ano de exercício: JPEG, PNG, PDF até 5mb",
          },
        });
        break;

      case DocumentTypeEnum.partnerCompanyParticipation:
      case DocumentTypeEnum.CompanyParticipation:
        aux.push({
          type: "ALL",
          title: `Participação direta em empresas (${auxTitulariryDoc(
            docType
          )})`,
          document: {
            identifier: "participacaoempresa",
            label: "Documento informando participação direta em empresas",
            description:
              "Informe a Razão social, CNPJ e % de participação por empresa: JPEG, PNG, PDF, Doc até 5mb",
          },
        });
        break;

      case DocumentTypeEnum.partnerProofOfRedence:
      case DocumentTypeEnum.ProofOfRedence:
        aux.push({
          type: "ALL",
          title: `Comprovante de endereço (${auxTitulariryDoc(docType)})`,
          document: {
            identifier: "comprovanteendereco",
            label: "Comprovante de endereço*",
            description:
              "(Comprovante de conta (água, luz, telefone, etc) no nome do titular: JPEG, PNG, PDF até 5mb)",
          },
        });
        break;
      default:
        return (
          <>
            {userDetails &&
              user &&
              (user.userType === TypeUserEnum.CORPORATE ? (
                <DocumentListPJ goTo={setDocType} client={userDetails} />
              ) : (
                <DocumentListPF goTo={setDocType} client={userDetails} />
              ))}

            <p className="mt-5 font-normal">
              *Itens obrigatórios para a solicitação da análise
            </p>

            <div
              className="mt-8 mb-3 flex flex-col items-center"
              style={{ color: Colors.Blue1 }}
            >
              <p className="underline">ATENÇÃO:</p>
              <p>
                Usaremos os documentos e dados informados para certificação da
                sua conta na plataforma.
              </p>
            </div>

            <p style={{ color: Colors.Gray4 }}>
              Ao solicitar a análise da documentação declaro estar ciente e de
              acordo com os termos de uso da plataforma.{" "}
            </p>
          </>
        );
    }

    return aux.map((elm, index) => {
      return (
        <UploadDoc
          {...elm}
          eventOnChange={(file, identifier) => changeFile(file, identifier)}
          key={index}
        />
      );
    });
  };

  const onClick = async () => {
    if (!user || !docType) return;

    if (docType) {
      setLoadingState(true);

      try {
        if (user.userType === TypeUserEnum.CORPORATE) {
          await API.signup.company.sendDocumentComplementary(
            user.id,
            docType,
            files.map((doc: any) => doc.file)
          );
        } else {
          await API.signup.individual.sendDocumentComplementary(
            user.id,
            docType,
            files.map((doc: any) => doc.file)
          );
          setFiles([]);
        }

        handleModal(AlertModal, {
          title: "Documento enviado com sucesso!",
          src: Gifs.IconOkGif,
          titleColor: Colors.Green3,

          confirmClick: async () => {
            setModal(false);
            setLoadingState(false);
            setDocType(undefined);
          },

          children: <></>,
        });
      } catch (err) {
        setTextError(determineErrorMessage(err));
        setErrorModalState(true);
        setLoadingState(false);
      }
    }
    updateUserDetails();
  };

  return (
    <PageSkeleton
      goBackBtn
      loading={loadingState}
      filterBar={`Certifique sua conta / Comprovante de endereço`}
      button={
        !docType
          ? undefined
          : {
              label: "Enviar",
              onClick: onClick,
            }
      }
      button2={{
        label: !docType ? "Voltar a minha conta" : "Cancelar edição",
        onClick() {
          if (!docType) history.goBack();
          else setDocType(undefined);
        },
      }}
    >
      <AlertModal
        state={errorModalState}
        title="Ops, Tivemos um problema."
        src={Gifs.IconAlertGif}
        confirmClick={() => setErrorModalState(false)}
      >
        <p>O sistema identificou uma irregularidade.</p>
        <p>{textError}</p>
      </AlertModal>
      {renderBody()}
    </PageSkeleton>
  );
};

const DocumentsPage: React.FC<any> = () => {
  const { user, userDetails, updateUserDetails } = useContext(UserContext);
  const [loadingState, setLoadingState] = useState<boolean>(false);

  useEffect(() => {
    setLoadingState(true);
    updateUserDetails()
      .catch((error) => console.error(error))
      .finally(() => {
        setLoadingState(false);
      });
  }, [user]);

  const certificationSteps = [
    {
      title: "Documento de identificação",
      description: "Anexe seu documento de identificação (RG ou CNH válidos)",
      route: RoutesPaths.ACCOUT_DOCUMENT,
      status: getLoggedUserDocStatus(
        [DocumentsTypeEnum.FEDERALID_FRONT, DocumentsTypeEnum.FEDERALID_BACK],
        userDetails
      ), //userDetails?.docs.get(DocumentTypeEnum.FederalID)?.status,
      hidden: false,
      disabled: user?.status === UserStatusEnum.CERTIFICATE,
    },
    {
      title: "Comprovante de endereço",
      description: "Anexe a documentação solicitada para análise.",
      route: RoutesPaths.ACCOUT_DOCUMENT_ADDRESS,
      status: getLoggedUserDocStatus(
        [DocumentsTypeEnum.PROOF_OF_RESIDENCE],
        userDetails
      ),
      hidden: false,
      disabled: user?.status === UserStatusEnum.CERTIFICATE,
    },
  ];

  return (
    <PageSkeleton
      useContentCard
      filterBar={
        <FilterBar
          goBackBtn
          // title={`Certifique sua conta (${TypeUserEnumInterpreter.abrv(user?.userType)})`}
          title={`Certifique sua conta (PF)`}
          selection={false}
        />
      }
      button2={{
        label: "Voltar a minha conta",
        onClick() {
          history.push(RoutesPaths.ACCOUT);
        },
      }}
    >
      <div className="w-4/5 lg:w-3/5 max-w-2xl flex flex-col gap-3 my-3 items-center">
        <h1
          className="text-center text-2xl font-normal"
          style={{ color: Colors.Gray2 }}
        >
          {/* <span className="font-medium">Certifique sua conta</span> ({TypeUserEnumInterpreter.label(user?.userType)}) */}
          <span className="font-medium">Certifique sua conta</span>
        </h1>

        <img
          style={{ width: "290px" }}
          src={Gifs.IconAccountCertificate}
          alt=""
        />

        <h2
          className="text-center text-lg font-normal mb-14 whitespace-pre-line"
          style={{ color: Colors.Gray2 }}
        >
          Realize o envio dos documentos pessoais para certificar sua conta e
          aumentar seus limites de negociação na plataforma.
        </h2>
        {certificationSteps
          .filter(({ hidden }) => !hidden)
          .map((elm, index) => {
            let color: string = "";
            let label: string = "";

            switch (elm.status) {
              case ClientDocStatusEnum.Approved:
                color = Colors.Green3;
                label = "Ok";
                break;
              case ClientDocStatusEnum.Disapproved:
                color = Colors.RedMyAccount;
                label = "Recusado";
                break;
              case ClientDocStatusEnum.Waiting:
                color = Colors.Blue2;
                label = "Em andamento";
                break;
              default:
                color = Colors.RedMyAccount;
                label = "Pendente";
            }

            return (
              <div
                className="cliente-doc-status-card p-5 rounded border w-full"
                style={{ borderColor: Colors.Gray4 }}
                key={index}
              >
                <strong
                  style={{ color: Colors.Gray2 }}
                  className="text-lg font-medium"
                >{`${index + 1} - ${elm.title}`}</strong>
                <p
                  className="text-sm font-normal"
                  style={{ color: Colors.Gray3 }}
                >
                  {elm.description}
                </p>
                <Button
                  loading={loadingState}
                  textColor={Colors.White}
                  disabled={elm.disabled}
                  className="documents-btn mt-5"
                  style={{ backgroundColor: color, borderColor: color }}
                  onClick={() => {
                    if (elm.status === ClientDocStatusEnum.Approved) return;

                    history.push(elm.route);
                  }}
                >
                  {label}
                </Button>
              </div>
            );
          })}
      </div>
    </PageSkeleton>
  );
};

export default DocumentsPage;
