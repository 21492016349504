import React, { createContext, useContext, useEffect, useState } from "react";
import * as API from "../api";
import { Tax } from "../models";
import { TaxTypeEnum } from "../models/enums";
import UserContext from "./UserProvider";

interface TaxesextData {
  taxs: Map<TaxTypeEnum, Tax>;
  setTaxs(p: any): any;

  currentPage: number;
  setCurrentPage(p: number): any;
  refreshTaxes(): any;
}

const TaxesContext = createContext<TaxesextData>({} as TaxesextData);

export const TaxesContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { user } = useContext(UserContext);
  const [taxs, setTaxs] = useState<Map<TaxTypeEnum, Tax>>(new Map());
  const [currentPage, setCurrentPage] = useState<number>(1);

  const refreshTaxes = () => {
    if (user) {
      API.tax.getTotalFees().then((data) => setTaxs(data));
    }
  };

  useEffect(() => {
    refreshTaxes();
  }, [user]);

  return (
    <TaxesContext.Provider
      value={{ taxs, setTaxs, currentPage, setCurrentPage, refreshTaxes }}
    >
      {children}
    </TaxesContext.Provider>
  );
};

export default TaxesContext;
