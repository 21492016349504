import React from "react";
import { Gray2, Gray4, GrayBackGroud } from "../../assets/Colors";
import CopyButton from "../Buttons/CopyButton";

type MyProps = {
  title: string;
  value: string;
  autoWidth?: boolean;
  children?: JSX.Element | JSX.Element[];
  showCopyButton?: boolean;
};

const DataRow: React.FC<MyProps> = ({
  title,
  value,
  children,
  autoWidth,
  showCopyButton,
}) => {
  return (
    <div
      className={
        "relative flex flex-row border rounded py-4 px-4 w-full my-1 " +
        (autoWidth ? "md:w-2/3 lg:w-1/2 max-w-3xl" : "")
      }
      style={{ backgroundColor: GrayBackGroud, borderColor: Gray4 }}
    >
      {children ?? (
        <>
          <div
            className={
              "flex flex-col sm:flex-row justify-start " +
              (showCopyButton ? "w-10/12" : "")
            }
          >
            <p
              className="whitespace-pre font-medium text-xl self-start"
              style={{ color: Gray4 }}
            >
              {title}
            </p>
            <p
              className="font-medium text-xl self-start"
              style={{ color: Gray2 }}
            >
              {value}
            </p>
          </div>
          {showCopyButton && (
            <CopyButton
              valueToCopy={value}
              className="self-center"
              style={{ height: "25px" }}
            />
          )}
        </>
      )}
    </div>
  );
};

DataRow.defaultProps = {
  autoWidth: true,
  showCopyButton: true,
};

export default DataRow;
