import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { getAccessToken, getRefreshToken, makeLogin, makeLogout } from "../jwt";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Authorization",
    "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
    "Content-Type": "application/json;charset=UTF-8",
    x_tenant_id: process.env.REACT_APP_API_TENANT || "",
  },
});

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token = getAccessToken();

  if (token && config?.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const createAxiosResponseInterceptor = () => {
  const interceptor = api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        axios.interceptors.response.eject(interceptor);

        return axios
          .post(process.env.REACT_APP_API_URL + "/auth/refresh", {
            refreshToken: getRefreshToken(),
          })
          .then(({ data, status }) => {
            if (status >= 200 && status < 300) {
              makeLogin(data.accessToken, data.refreshToken);
            } else {
              console.log("aqui");
              makeLogout();
            }

            if (error.response?.config && error.response.config?.headers) {
              const token = getAccessToken();
              error.response.config.headers.Authorization = `Bearer ${token}`;
            }

            return axios(error.response?.config ?? {});
          })
          .catch((error) => {
            makeLogout();
            return Promise.reject(error);
          })
          .finally(createAxiosResponseInterceptor);
      }

      return error.response;
    }
  );
};
createAxiosResponseInterceptor();

export default api;
