import moment from "moment";
import { Client, ClientAdress, DocumentTypeEnum } from "../models";
import api from "../services/api";

const onboarding = api.exchange;
export enum UserBitIdStatusEnum {
  EMAIL_ADDED = "EMAIL_ADDED",
  CONFIRMED_EMAIL = "CONFIRMED_EMAIL",
  PASSWORD_ADDED = "PASSWORD_ADDED",
  APPROVED_TERMS = "APPROVED_TERMS",
  PHONE_ADDED = "PHONE_ADDED",
  CONFIRMED_PHONE = "CONFIRMED_PHONE",
  SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE",
  ADDRESS_ADDED = "ADDRESS_ADDED",
  SIGNUP_INDIVIDUAL = "SIGNUP_INDIVIDUAL",
  SIGNUP_CORPORATE = "SIGNUP_CORPORATE",
  LIVENESS_ADDED = "LIVENESS_ADDED ",
  INDIVIDUAL_DOCUMENT_ADDED = "INDIVIDUAL_DOCUMENT_ADDED",
  CORPORATE_DOCUMENT_ADDED = "CORPORATE_DOCUMENT_ADDED",
  PARTNER_DOCUMENT_ADDED = "PARTNER_DOCUMENT_ADDED",
}

export enum AccountTypeEnum {
  legal = "legal",
  phisic = "phisic",
}

type User = {
  data: any;
  email: string;
  password: string;
  tenant: {
    id: string;
  };
  document: string;
  emailVerified: boolean | null;
  phoneVerified: boolean | null;
  dataVerified: boolean | null;
  otpEmail: string;
  otpPhoneNumber: string;
  id: string;
  role: number;
  userType: string;
  status: string;
  create_at: string;
  update_at: string;
};

type SignupStep = {
  field: string;
  status: boolean;
};
interface NewAccountOnboarding {
  user: User;
  requestSteps: Array<SignupStep>;
}

const ID_ONBOARDING_KEY = "@idOnBoarding";
export const getIdOnboarding = () => localStorage.getItem(ID_ONBOARDING_KEY);
export const setIdOnboarding = (id: string) =>
  localStorage.setItem(ID_ONBOARDING_KEY, id);
const STEP_ONBOARDING_KEY = "@stepOnBoarding";
export const getStepOnboarding = (): NewAccountOnboarding | null => {
  const userString = localStorage.getItem(STEP_ONBOARDING_KEY);
  if (userString) return JSON.parse(userString);

  return null;
};
export const setStepOnboarding = (user: NewAccountOnboarding) =>
  localStorage.setItem(STEP_ONBOARDING_KEY, JSON.stringify(user));
export const clearStepOnboarding = () =>
  localStorage.removeItem(STEP_ONBOARDING_KEY);

const FormateDate = (date: string) => {
  var day = date.split("/")[0];
  var month = date.split("/")[1];
  var year = date.split("/")[2];

  return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
};

interface bank {
  compe: string;
  fullName: string;
  id: string;
  ispb: string;
  mainAccess: string;
  number: string;
  operationStart: string;
  shortName: string;
}

export const signup = {
  getMe: async (accountId: string): Promise<Client> => {
    const response = await api.exchange.get("/user/info");
    const client = response.data;

    const isValidArray = client.banks ? true : false;
    const address = client?.addresses && client?.addresses[0];
    const response2 = {
      id: client.id ?? "",
      name: client?.name ?? "",
      role: client.role ?? "",
      userType: client.userType ?? "",
      phoneNumber: client?.phoneNumber ?? "",
      email: client.email ?? "",
      document: client?.document ?? "",
      partnerDocument: client.partnerDocument ?? "",
      partnerName: client.partnerName ?? "",
      partnerEmail: client.partnerEmail ?? "",
      status: client.status ?? "",
      userBankInfo: {
        bank: 1, //!isValidArray ? '' : client.banks[0]?.bankCode ?? '',
        bankAccount: !isValidArray ? "" : client.banks[0]?.bankAccount ?? "",
        bankAgency: !isValidArray ? "" : client.banks[0]?.bankAgency ?? "",
        ispb: !isValidArray ? "" : client.banks[0]?.ispd ?? "",
        shortName: !isValidArray ? "" : client.banks[0]?.shortName ?? "",
      },
      custodyBankInfo: {
        bank: "",
        bankAccount: "",
        bankAgency: "",
        ispb: "",
        shortName: "",
      },
      address: {
        street: address?.street ?? "",
        number: address?.number ?? "",
        neighborhood: address?.neighborhood ?? "",
        city: address?.city ?? "",
        state: address?.state ?? "",
        complement: address?.complement ?? "",
        postalCode: address?.postalCode ?? "",
        country: address?.country ?? "",
      },
      emailVerified: client.certifications.includes("EMAIL"),
      phoneVerified: client.certifications.includes("PHONE_NUMBER"),
      dataVerified: client.certifications.includes("DATA_VERIFIED"),
      federalId: client.certifications.includes("FEDERALID"),
      proofOfResidence: client.certifications.includes("PROOF_OF_RESIDENCE"),
      otpEmail: client.otpEmail ?? "",
      otpPhoneNumber: client.otpPhoneNumber ?? "",
      data: client.data ?? "",
      tenant: {
        id: client?.tenant?.id ?? "",
      },
      birthday: client?.birthday,
      motherName: client?.montherName,
      certifications: client?.certifications,
      documentations: client?.documentations,
      // flag para mostrar ou esconder wallet
      showWallet: true,
    };

    if (response.status >= 200 && response.status < 300) {
      return new Client(response2);
    }

    throw response.data;
  },

  registerEmailPassword: async (
    email: string,
    password: string
  ): Promise<NewAccountOnboarding> => {
    const body = {
      email,
      password,
    };

    const response = await onboarding.post("/auth/signup", body);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    throw new Error(JSON.stringify(response.data));
  },

  requestEmailVerificationCode: async (accountId: string) => {
    const response = await onboarding.get(
      `auth/account/${accountId}/verify/email`
    );

    if (response.status >= 200 && response.status < 300) {
      return;
    }
    throw new Error(JSON.stringify(response.data));
  },

  sendEmailVerificationCode: async (accountId: string, code: string) => {
    const response = await onboarding.post(
      `auth/account/${accountId}/verify/email`,
      { code: code }
    );

    if (response.status >= 200 && response.status < 300) {
      return;
    }
    throw new Error(JSON.stringify(response.data));
  },

  getBank: async (): Promise<Array<bank>> => {
    const response = await onboarding.get("auth/banks");

    if (response.status >= 200 && response.status < 300) {
      return (response.data as Array<bank>).sort(
        (a, b) => Number(a.number) - Number(b.number)
      );
    }

    throw new Error(JSON.stringify(response.data));
  },
  confirmEmail: async (
    code: string,
    id: string
  ): Promise<NewAccountOnboarding> => {
    const body = {
      id: id,
      confirmEmailCode: code,
    };

    const response = await onboarding.post(
      "onboarding/account/confirm/email",
      body
    );

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(JSON.stringify(response.data));
    } else {
      return response.data;
    }
  },
  registerPassword: async (
    password: string,
    id: string,
    code?: string
  ): Promise<NewAccountOnboarding> => {
    const body = {
      id,
      password,
      code,
    };

    const response = await onboarding.post(
      "onboarding/account/add/password",
      body
    );

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(JSON.stringify(response.data));
    } else {
      return response.data;
    }
  },

  recoveryPassword: async (email: string) => {
    const body = {
      email,
    };

    const response = await onboarding.patch(
      "auth/account/reset-password",
      body
    );

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(JSON.stringify(response.data));
    } else {
      return response.data;
    }
  },

  updatePassword: async (userToken: string, newPassword: string) => {
    const body = {
      token: userToken,
      newPassword,
    };

    const response = await onboarding.patch(
      "auth/account/update-password",
      body
    );

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(JSON.stringify(response.data));
    } else {
      return response.data;
    }
  },

  approveTerms: async (id: string) => {
    const body = {
      id: id,
    };

    const response = await onboarding.post(
      "onboarding/account/approve-terms",
      body
    );

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(JSON.stringify(response.data));
    } else {
      return response.data;
    }
  },

  registerPhone: async (
    phone: string,
    id: string
  ): Promise<NewAccountOnboarding> => {
    const body = {
      phoneNumber: "+" + phone,
    };

    const response = await onboarding.patch("auth/signup/" + id, body);

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(JSON.stringify(response.data));
    } else {
      return response.data;
    }
  },
  resendCodeVerifyPhone: async (id: string): Promise<void> => {
    const response = await onboarding.get(
      `auth/account/${id}/verify/phoneNumber`
    );

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(JSON.stringify(response.data));
    } else {
      return response.data;
    }
  },
  confirmPhone: async (
    code: string,
    id: string
  ): Promise<NewAccountOnboarding> => {
    const body = {
      code: code,
    };
    const response = await onboarding.post(
      `auth/account/${id}/verify/phoneNumber`,
      body
    );

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(JSON.stringify(response.data));
    } else {
      return response.data;
    }
  },
  setAccountType: async (
    type: string,
    id: string
  ): Promise<NewAccountOnboarding> => {
    const body = {
      id: id,
      type: type,
    };
    const response = await onboarding.post(
      "onboarding/account/add/account-type",
      body
    );

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(JSON.stringify(response.data));
    } else {
      return response.data;
    }
  },
  addAdress: async (
    id: string,
    address: ClientAdress
  ): Promise<NewAccountOnboarding> => {
    const response = await onboarding.patch(`/auth/signup/${id}`, {
      address: {
        street: address.street,
        number: address.streetNumber,
        neighborhood: address.neighborhood,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        country: address.country,
        complement: address.complement,
      },
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }

    throw new Error(JSON.stringify(response.data));
  },
  sendWelcomeEmail: async (id: string): Promise<void> => {
    const response = await onboarding.post(`/auth/user/notify/welcome`, {
      userId: id,
    });

    if (response.status >= 200 && response.status < 300) {
      return;
    }

    throw new Error(JSON.stringify(response.data));
  },

  company: {
    registerCompany: async (body: {
      id: string;
      cnpj: string;
      fantasyName: string;
      companyName: string;
      openDate: string;
      bank?: string;
      bankAgency?: string;
      bankAccount?: string;
      partnerName: string;
      partnerDoc: string;
      partnerBirthday: string;
      partnerMotherName: string;
      partnerEmail: string;
    }): Promise<null> => {
      const response = await onboarding.post(
        "onboarding/corporate/add/personaldata",
        {
          id: body.id,
          fantasyName: body.fantasyName,
          companyName: body.companyName,
          openDate: body.openDate,
          cnpj: body.cnpj,
          partner: {
            document: body.partnerDoc,
            name: body.partnerName,
            email: body.partnerEmail,
            birthday: body.partnerBirthday,
            motherName: body.partnerMotherName,
          },
        }
      );

      if (response.status !== 200 && response.status !== 201) {
        throw new Error(JSON.stringify(response.data));
      } else {
        return response.data;
      }
    },

    registerCompanyBank: async (body: {
      id: string;
      bank?: string;
      bankAgency?: string;
      bankAccount?: string;
    }): Promise<null> => {
      const response = await onboarding.post("onboarding/corporate/add/bank", {
        id: body.id,
        bank: body.bank,
        account: body.bankAccount,
        agency: body.bankAgency,
      });

      if (response.status !== 200 && response.status !== 201) {
        throw new Error(JSON.stringify(response.data));
      } else {
        return response.data;
      }
    },

    sendDocumentCompany: async (id: string, files) => {
      const form = new FormData();
      files.forEach((file) => {
        form.append("docs", file);
      });
      form.append("id", id);
      const response = await onboarding.post(
        "onboarding/corporate/add/doc",
        form
      );

      if (response.status !== 200 && response.status !== 201) {
        throw new Error(JSON.stringify(response.data));
      } else {
        return response.data;
      }
    },
    sendDocumentPartners: async (id: string, files) => {
      const form = new FormData();
      files.forEach((file) => {
        form.append("docs", file);
      });
      form.append("id", id);
      const response = await onboarding.post(
        "onboarding/corporate/add/partner/doc",
        form
      );

      if (response.status !== 200 && response.status !== 201) {
        throw new Error(JSON.stringify(response.data));
      } else {
        return response.data;
      }
    },

    sendDocumentComplementary: async (
      id: string,
      docType: DocumentTypeEnum,
      files
    ) => {
      const form = new FormData();
      files.forEach((file) => {
        form.append("docs", file);
      });
      form.append("id", id);
      form.append("doc-type", docType);
      const response = await onboarding.post(
        "onboarding/corporate/add/doc-complementary",
        form
      );

      if (response.status !== 200 && response.status !== 201) {
        throw new Error(JSON.stringify(response.data));
      } else {
        return response.data;
      }
    },
  },

  individual: {
    registerIndividual: async (
      id: string,
      name: string,
      doc: string,
      birthday: Date,
      motherName: string,
      pep: boolean
    ) => {
      const body = {
        personalData: {
          name,
          document: doc,
          birthday: moment(birthday).format("YYYY-MM-DD"),
          montherName: motherName,
          pep,
        },
      };

      const response = await onboarding.patch(`/auth/signup/${id}`, body);

      if (
        (response.status !== 200 && response.status !== 201) ||
        response.data.hasOwnProperty("error")
      ) {
        throw new Error(JSON.stringify(response.data));
      } else {
        return response.data;
      }
    },

    registerIndividualBank: async (
      id: string,
      body: { bank?: string; bankAgency?: string; bankAccount?: string }
    ) => {
      const response = await onboarding.patch(`/auth/signup/${id}`, {
        bank: {
          bankCode: Number(body.bank),
          bankAccount: body.bankAccount,
          bankAgency: body.bankAgency,
        },
      });

      if (response.status !== 200 && response.status !== 201) {
        throw new Error(JSON.stringify(response.data));
      } else {
        return response.data;
      }
    },

    requestEmailVerificationCode: async (accountId: string) => {
      const response = await onboarding.get(
        `auth/account/${accountId}/verify/email`
      );

      if (response.status >= 200 && response.status < 300) {
        return;
      }
      throw new Error(JSON.stringify(response.data));
    },

    sendDocuments: async (accountId: string, documents) => {
      const formData = new FormData();

      documents.forEach((document) => {
        formData.append("types", document.identifier);
        formData.append("files", document.file);
      });
      const response = await onboarding.post(
        `auth/accounts/${accountId}/documentations`,
        formData
      );

      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error(JSON.stringify(response.data));
    },

    sendDocumentComplementary: async (
      id: string,
      docType: DocumentTypeEnum,
      files
    ) => {
      const form = new FormData();
      files.forEach((file) => {
        form.append("docs", file);
      });
      form.append("id", id);
      form.append("doc-type", docType);
      const response = await onboarding.post(
        "onboarding/individual/add/doc-complementary",
        form
      );

      if (response.status !== 200 && response.status !== 201) {
        throw new Error(JSON.stringify(response.data));
      } else {
        return response.data;
      }
    },
  },
};
