import React, { ChangeEvent, CSSProperties, useEffect, useState } from "react";
import { formatToCurrency } from "../../utils/formatters";

type MyProps = {
  onChange?: (p: ChangeEvent<HTMLInputElement>) => any;
  onChangeValue?: (p: number) => any;
  defaultValue?: number;
  className?: string;
  prefix?: string;
  suffix?: string;
  style?: CSSProperties;
  value?: number;
  maxValue?: number;
};

const MoneyInput = React.forwardRef<HTMLInputElement, MyProps>((props, ref) => {
  const [buffer, _setBuffer] = useState<number>(props.defaultValue ?? 0);

  useEffect(() => {
    if (!Number.isNaN(props.value) && props.value !== undefined)
      _setBuffer(props.value);
  }, [props.value]);
  useEffect(() => {
    if (props.onChangeValue) props.onChangeValue(buffer);
  }, [buffer]);

  const formatBuffer = (money: number): string =>
    `${props.prefix ?? ""}${formatToCurrency(money)}${props.suffix ?? ""}`;

  return (
    <input
      ref={ref}
      className={props.className}
      value={formatBuffer(buffer)}
      pattern="[0-9]*"
      onKeyDown={({ key }) => {
        let cleanBuffer = formatBuffer(buffer)
          .trim()
          .replace(/\D/g, "")
          .split("");

        if (!Number.isNaN(Number(key))) {
          cleanBuffer.push(key);
          if (cleanBuffer[0] === "0") {
            cleanBuffer.splice(0, 1);
          }
        } else if (key === "Backspace") {
          cleanBuffer.splice(cleanBuffer.length - 1, 1);
        }

        cleanBuffer.splice(cleanBuffer.length - 2, 0, ".");
        if (props.maxValue && Number(cleanBuffer.join("")) > props.maxValue) {
          _setBuffer(Number(props.maxValue));
        } else {
          _setBuffer(Number(cleanBuffer.join("")));
        }
      }}
      onChange={props.onChange}
      style={{
        fontSize: "1.25rem",
        lineHeight: "1.75rem",
        ...props.style,
      }}
    ></input>
  );
});

MoneyInput.defaultProps = {
  prefix: "R$ ",
};

export default MoneyInput;
