import React from "react";
import { Link } from "react-router-dom";

import history from "../../../services/history";
import RoutesPaths from "../../../Routes/RoutesPaths";
import Colors, {
  AzulEmAndamento,
  Gray2,
  Gray4,
  GrayBackGroud,
} from "../../../assets/Colors";

/// Components
import TopBar from "../../../components/TopBar/TopBar";
import Footer from "../../../components/Footer/Footer";
import Divider from "../../../components/Divider/Divider";
import BigButton from "../../../components/Buttons/BigButton";
import AlertModal from "../../../components/Modal/AlertModal";
import FilterBar from "../../../components/FilterBar/FilterBar";

interface StateProps {}
interface DispatchProps {}
interface OwnPropos {}
interface MyProps {}

type Props = StateProps & DispatchProps & MyProps & OwnPropos;

interface myState {
  modalState: boolean;
}

class PasswordPage extends React.Component<Props> {
  state: myState = {
    modalState: false,
  };

  handleClick() {
    this.setState({
      modalState: true,
    });
  }

  modalOk() {
    this.setState({
      modalState: false,
    });
    history.push(RoutesPaths.ACCOUT);
  }

  render() {
    return (
      <>
        <AlertModal
          title="Solicitação realizada com sucesso!"
          state={this.state.modalState}
          confirmClick={() => this.modalOk()}
        >
          <p className="text-center">
            Um link de redefinição será enviado em breve para o seu email de
            cadastro.
          </p>
        </AlertModal>

        <div
          className="flex flex-col min-h-screen text-sm text-gray-400 font-sans"
          style={{ backgroundColor: GrayBackGroud }}
        >
          <TopBar />
          <main className="flex-grow w-full mb-5">
            <FilterBar title="Senha de acesso" />

            <div className="p-5">
              <p>Gerencie abaixo os dados de cadastro da sua conta</p>
            </div>

            <div
              className="bg-white px-2 pt-1 mx-5 flex flex-col aling-center items-center rounded border"
              style={{ borderColor: Gray4 }}
            >
              <h1 className="mt-4 text-xl" style={{ color: Gray2 }}>
                Senha de acesso
              </h1>

              <Divider top="mt-7" />

              <p style={{ color: AzulEmAndamento }} className="underline">
                ATENÇÃO:
              </p>
              <p style={{ color: AzulEmAndamento }} className="text-center">
                Para redefinir sua senha de sua conta clique no botão abaixo.
                Após isso enviaremos para seu email de cadastro um link para
                redefinição da senha de acesso.
              </p>

              <br />
              <p style={{ color: Gray4 }} className="">
                Ao utilizar os serviços listados declaro estar ciente e de
                acordo com os termos de uso da plataforma.
              </p>

              <Divider top="mt-7" />

              <BigButton
                onClick={() => {
                  this.handleClick();
                }}
              >
                Redefinir senha de acesso
              </BigButton>

              <Link to={RoutesPaths.ACCOUT} className="my-5 items-center">
                <p className="underline">Cancelar redefinição</p>
              </Link>
              <br />
            </div>
            <br />
          </main>
          <Footer />
        </div>
      </>
    );
  }
}

export default PasswordPage;
