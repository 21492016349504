import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Colors from "../../assets/Colors";
import * as Icons from "../../assets/icons";
import AssetCard from "../../components/Cards/AssetCard/AssetCard";
import EmptyCard from "../../components/Cards/EmptyCard";
import FilterBar from "../../components/FilterBar/FilterBar";
import TopBar from "../../components/TopBar/TopBar";
import BalanceContext from "../../contexts/BalanceProvider";
import UserContext from "../../contexts/UserProvider";
import { UserRoleEnum } from "../../models/enums/UserRoleEnum";
import RoutesPaths from "../../Routes/RoutesPaths";
import history from "../../services/history";
import * as API from "./../../api";
import { GoldToken } from "../../models";
import Button from "../../components/Buttons/Button";
import Footer from "../../components/Footer/Footer";

const MyAssets: React.FC = () => {
  const { user } = useContext(UserContext);
  const { balance, setBalance } = useContext(BalanceContext);
  const [tokens, setTokens] = useState<Array<GoldToken>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    loadBalance();
  }, []);

  const loadBalance = () => {
    API.user
      .getUserTokens()
      .then((data) => setTokens(data))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const byDateTime = (a, b) => {
    let redemptionDateA = moment(a.metadata.redemptionDate).toDate();
    let redemptionDateB = moment(b.metadata.redemptionDate).toDate();

    return redemptionDateA.getTime() - redemptionDateB.getTime();
  };

  const amountAvailabelActives: number =
    balance.invoice.available + balance.credit.available;
  const amountConsumedActives: number =
    balance.invoice.consumed + balance.credit.consumed;
  const amountActives: number = balance.invoice.full + balance.credit.full;

  return (
    <>
      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <TopBar />
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar
            title={user?.role === UserRoleEnum.USER ? "Meus Tokens" : "Tokens"}
          />

          <div className="mx-5 flex flex-col">
            {user?.role === UserRoleEnum.USER && (
              <>
                {/* <ResumeBar>
								<ResumeCard invert={true} bgColor={Colors.Green4} title='Total em ativos Disponíveis' value={amountAvailabelActives}/>
								<ResumeCard invert={true} bgColor={Colors.Blue1} title={'Total de ativos em negociação'} value={amountConsumedActives}/>
								<ResumeCard invert={true} bgColor={Colors.Gray11} title={'Total em ativos'} value={amountActives}/>
							</ResumeBar>
							
							<Divider top="mt-0" bottom="mb-0"/> */}
              </>
            )}
            {tokens.length > 0 ? (
              <h3 style={{ color: Colors.Gray2 }} className="text-lg my-4">
                Tokens em sua wallet
              </h3>
            ) : (
              <div className="mt-5">
                <EmptyCard>
                  <div className="flex flex-col items-center">
                    <strong style={{ color: Colors.Gray3 }}>
                      {isLoading ? (
                        "Carregando tokens..."
                      ) : (
                        <div className="flex flex-col items-center gap-6">
                          <img src={Icons.WalletEmptyIcon} />
                          <h3 style={{ fontSize: "22px", color: Colors.Gray4 }}>
                            Nenhum token encontrado
                          </h3>
                          <Button
                            className="my-assets-button mt-7"
                            onClick={() => history.push(RoutesPaths.TOKENS)}
                          >
                            Ver ofertas
                          </Button>
                        </div>
                      )}
                    </strong>
                  </div>
                </EmptyCard>
              </div>
            )}
            {tokens?.sort(byDateTime).map((token, index) => (
              <AssetCard
                token={token}
                onClick={() =>
                  history.push(`${RoutesPaths.MEUSATIVOS}/detalhes/${token.id}`)
                }
                key={index}
              />
            ))}
            {/* {
							tokenizeOptions.filter(({ enable }) => enable).map(({ method }, index) => {
								var { full, available, consumed, tokenQuantity } = balance.gold;

								return (
									<ChartCard
										title={TokenTypeEnumInterpreter.convert(method).sigla}
										subTitle={TokenTypeEnumInterpreter.convert(method).name}

										amount={full}
										negociation={consumed}
										available={available}
										sold={0}
										type={method}
										currencyFormatting={false}

										onClick={() => history.push(`${RoutesPaths.MEUSATIVOS}/${method}`)}

										btnRender={() => <TokenizeButton type={method} />}
										key={index}
									/>
								)
							})
						} */}
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MyAssets;
