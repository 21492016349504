import React from "react";
import { Tax } from "../models";
import * as Colors from "./../assets/Colors";
import { TaxTypeEnum } from "../models/enums";
import TagValue from "./../components/TagValue/TagValue";
import { formatToCurrency } from "../utils/formatters";

export const FeesList: React.FC<{
  taxs: Map<TaxTypeEnum, Tax>;
  loading?: boolean;
}> = ({ taxs, loading }) => {
  const getTaxValue = (type: TaxTypeEnum | Array<TaxTypeEnum>) => {
    if (!Array.isArray(type)) {
      type = [type];
    }

    const value = type.reduce(
      (prev, current) => prev + (taxs.get(current)?.value ?? 0),
      0
    );

    if (!value && (!value || value === 0)) {
      return "Isento";
    } else {
      var aux = [
        TaxTypeEnum.TED_DEPOSIT,
        TaxTypeEnum.TED_WITHDRAW,
        TaxTypeEnum.BOND_TOKENIZATION,
        TaxTypeEnum.CRED_TOKENIZATION,
      ];

      if (aux.find((elm) => elm === type[0])) {
        return `${formatToCurrency(value)} BRL`;
      } else {
        return `${value} %`;
      }
    }
  };

  return (
    <>
      <h2
        className="text-base font-normal mb-3 text-center sm:text-xl"
        style={{ color: Colors.Gray3 }}
      >
        TAXAS E CUSTOS DE NEGOCIAÇÃO
      </h2>
      {[
        {
          label: "Ordem de Compra:",
          value: getTaxValue(TaxTypeEnum.BUY_SPREAD),
        },
        // { label: 'Ordem de Venda:', value: getTaxValue([TaxTypeEnum.SELL_SPREAD, TaxTypeEnum.NETWORK]) },
        {
          label: "Saques via PIX:",
          value: getTaxValue(TaxTypeEnum.TED_WITHDRAW),
        },
        {
          label: "Depósitos via PIX:",
          value: getTaxValue(TaxTypeEnum.TED_DEPOSIT),
        },
        { label: "Resgate dos tokens:", value: "Isento" },
      ].map(({ label, value }, key) => (
        <TagValue
          label={label}
          value={value}
          colorValue={value === "Isento" ? Colors.Gray4 : Colors.Gray2}
          key={key}
          autoWidth={false}
          loading={loading}
        />
      ))}
    </>
  );
};
