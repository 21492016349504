import React from "react";
import DataRow from "../../components/DataRow/DataRow";
import { User } from "../../models";
import { DepositMethodsEnum } from "../../models/enums/DepositMethods";
import { formatCPFOrCPNJ } from "../../utils/formatters";

interface withdrawDataFields {
  [key: string]: {
    title: string;
    value: string;
    component?: JSX.Element;
  }[];
}

const TransferData: React.FC<{
  withdrawMethod: DepositMethodsEnum;
  user: User | undefined;
}> = ({ withdrawMethod, user }) => {
  const withdrawDataFields: withdrawDataFields = {
    PIX: [
      { title: "Favorecido: ", value: user?.name ?? "" },
      {
        title: "CPF/CNPJ: ",
        value: user?.document ? formatCPFOrCPNJ(user?.document) : "",
      },
      {
        title: "Banco: ",
        value: user?.userBankInfo
          ? `${user?.userBankInfo.fullName} (${user?.userBankInfo.bank})`
          : "",
      },
      { title: "Agência: ", value: user?.userBankInfo.bankAgency ?? "" },
      {
        title: "Conta corrente: ",
        value: user?.userBankInfo.bankAccount ?? "",
      },
    ],
    TED: [
      { title: "Favorecido: ", value: user?.name ?? "" },
      {
        title: "CPF/CNPJ: ",
        value: user?.document ? formatCPFOrCPNJ(user?.document) : "",
      },
      { title: "Banco: ", value: user?.userBankInfo.bank ?? "" },
      { title: "Agência: ", value: user?.userBankInfo.bankAgency ?? "" },
      {
        title: "Conta corrente: ",
        value: user?.userBankInfo.bankAccount ?? "",
      },
    ],
  };

  return (
    <>
      {withdrawDataFields[withdrawMethod]
        ? withdrawDataFields[withdrawMethod].map((dataField) => {
            return dataField?.component ? (
              dataField.component
            ) : (
              <DataRow
                title={dataField.title}
                value={dataField.value}
                showCopyButton={false}
              />
            );
          })
        : null}
    </>
  );
};

export default TransferData;
