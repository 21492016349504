import * as API from "../../../../api";
import { Link } from "react-router-dom";
import Colors from "../../../../assets/Colors";
import { TokenTypeEnum } from "../../../../models/enums";
import RoutesPaths from "../../../../Routes/RoutesPaths";
import ModalContext from "../../../../contexts/ModalProvider";
import { handleOptIn, handleOptOut } from "../OptinoutModal";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../../contexts/UserProvider";
import { UserRoleEnum } from "../../../../models/enums/UserRoleEnum";
import * as Icons from "../../../../assets/icons";
import "./TokenizeButton.css";

const TokenizeButton: React.FC<{ type: TokenTypeEnum }> = ({ type }) => {
  const { user } = useContext(UserContext);
  const { handleModal, setModal } = useContext(ModalContext);
  const [optIntState, setOptIntState] = useState<boolean>(false);

  useEffect(() => {
    if (type === TokenTypeEnum.BOND) {
      API.user.getOptInState().then((data) => setOptIntState(data));
    }
  }, []);

  if (user?.role !== UserRoleEnum.ADMIN) return <></>;

  switch (type) {
    case TokenTypeEnum.BOND:
      return (
        <Link
          style={{
            backgroundColor: Colors.Gray6,
            borderColor: Colors.Gray4,
            color: Colors.Gray3,
          }}
          className="tokenize-btn rounded border px-7 py-1 gap-2 font-medium text-base"
          to={RoutesPaths.MEUSATIVOSCRIAR}
        >
          <img src={Icons.Token2Icon} className="h-5 w-5" />
          Tokenizar
        </Link>
      );
    case TokenTypeEnum.CREDIT:
      return (
        <button
          style={{
            backgroundColor: Colors.Gray6,
            borderColor: Colors.Gray4,
            color: optIntState ? Colors.Red4 : Colors.Green3,
          }}
          className="rounded border px-7 py-1 justify-center"
          onClick={() =>
            optIntState
              ? handleOptOut(handleModal, setModal)
              : handleOptIn(handleModal, setModal)
          }
        >
          {optIntState ? "Realizar Opt-out" : "Realizar Opt-in"}
        </button>
      );
    default:
      return (
        <Link
          style={{
            backgroundColor: Colors.Gray6,
            borderColor: Colors.Gray4,
            color: Colors.Gray3,
          }}
          className="tokenize-btn rounded border px-7 py-1 gap-2 font-medium text-base"
          to={RoutesPaths.MEUSATIVOSCRIAR}
        >
          <img src={Icons.Token2Icon} className="h-5 w-5" />
          Tokenizar
        </Link>
      );
  }
};

export default TokenizeButton;
