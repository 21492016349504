import { AuthInfo } from "../features/auth/authTypes";

export const saveCrendentialsOnStorage = (
  credentials: AuthInfo | undefined
) => {
  if (!credentials) return;

  const keys = Object.keys(credentials);

  keys.forEach((value, index) => {
    window?.sessionStorage?.setItem(keys[index], credentials[keys[index]]);
  });
};
