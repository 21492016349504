import React, { CSSProperties } from "react";
import { Gray2, Gray4 } from "../../assets/Colors";
import * as Icons from "../../assets/icons";
import history from "../../services/history";
import "./FilterBar.css";

type MyProps = {
  children?: JSX.Element | Array<JSX.Element>;
  className?: string;
  title?: string | JSX.Element;
  selection?: boolean;
  goBackBtn?: boolean;
};

const FilterBar: React.FC<MyProps> = ({
  children,
  className,
  title,
  selection,
  goBackBtn,
}) => {
  var style: CSSProperties = {};

  if (!children) {
    style.padding = "1.25rem 1.5rem";
  }
  if (selection && !title) {
    style.paddingTop = style.paddingBottom = "0px";
  }

  return (
    <div
      className={`
				filter-bar
				flex flex-row items-center gap-5 w-full
				bg-white border-b border-t text-lg font-medium
				${className}
			`}
      style={{
        borderColor: Gray4,
        color: Gray2,
        ...style,
      }}
    >
      {goBackBtn ? (
        <img
          src={Icons.ArrowPointerIcon}
          alt="Arrow"
          className="cursor-pointer"
          onClick={() => history.goBack()}
        />
      ) : (
        <></>
      )}
      {children ? children : <span>{title}</span>}
    </div>
  );
};

FilterBar.defaultProps = {
  selection: true,
};

export default FilterBar;
