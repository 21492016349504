import { IValidation } from "./IValidation";

export class WithoutSpecialValidation implements IValidation {
  private regex = /[!@#$%^&*+={}\\|;:<>?]/;
  private value: string = "";
  msgError = "Informe um valor válido.";

  isValid(value: string): boolean {
    if (!value) {
      this.value = "";
      return false;
    }
    this.value = value;
    return !this.regex.test(this.value) && this.value.trim().length <= 255;
  }

  isNotValid(value: string): boolean {
    return !this.isValid(value);
  }

  getErrorMessage(): string {
    if (this.isValid(this.value) || this.value === "") {
      return "";
    }
    return this.msgError;
  }
}
