import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import * as API from "../../../api";
import Colors from "../../../assets/Colors";
import { StylePatterns } from "../../../assets/patterns_tailwind_class";
import history from "../../../services/history";
import PageSkeleton from "../../../components/PageSkeleton/PageSkeleton";
import FilterBar from "../../../components/FilterBar/FilterBar";
import SplitedCard from "../../../components/Cards/SplitedCard";
import { DocumentsTypeEnum } from "../../../models/enums";
import Button from "../../../components/Buttons/Button";
import ModalContext from "../../../contexts/ModalProvider";
import Modal from "../../../components/Modal/Modal";
import { ClientDocumentStatusEnum } from "../../../models";
import RoutesPaths from "../../../Routes/RoutesPaths";
import "./ClientPersonalDocumentsAnalyzePage.css";
import ClientDetailsFilterBar from "../ClientDetailsPage/ClientDetailsFilterBar/ClientDetailsFilterBar";

interface ShowModalArgs {
  title: string;
  confirmText: string;
  confirmColor: string;
  newStatus: ClientDocumentStatusEnum;
  text1: string;
  text2: string;
}

export const ClientDocumentsAnalyzePage: React.FC<any> = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [clientDetails, setClientDetails] = useState<any>({});
  const { handleModal, setModal } = useContext(ModalContext);

  useEffect(() => {
    API.admin.loadClient(clientId).then((details) => {
      setClientDetails(details);
    });
  }, []);

  const updateDocumentStatus = async (status: ClientDocumentStatusEnum) => {
    try {
      await API.admin.updateDocumentStatus({
        accountId: clientId,
        documentType: DocumentsTypeEnum.FEDERALID_FRONT,
        status,
      });

      // await API.admin.updateDocumentStatus({
      //   accountId: clientId,
      //   documentType: DocumentsTypeEnum.FEDERALID_BACK,
      //   status,
      // });

      setModal(false);

      history.replace(`${RoutesPaths.ADMIN_CLIENTS_DOCUMENTS}/${clientId}`);
    } catch (error) {
      console.error(error);
    }
  };

  const showUpdateDocumentStatusModal = ({
    title,
    confirmText,
    confirmColor,
    newStatus,
    text1,
    text2,
  }: ShowModalArgs) => {
    handleModal(Modal, {
      title,
      titleColor: Colors.Gray1,
      state: true,
      width: "lg:w-4/12",

      confirmText,
      confirmColor,
      confirmButtonClick: () => updateDocumentStatus(newStatus),
      cancelButtonClick: () => setModal(false),

      children: (
        <div
          className="flex flex-col justify-center text-center items-center"
          style={{ color: Colors.Gray3 }}
        >
          <span className="mb-5">{text1}</span>
          <span>{text2}</span>
        </div>
      ),
    });
  };

  return (
    <PageSkeleton
      isAdmin
      useContentCard
      button2={{ label: "Voltar a certificação", onClick: history.goBack }}
      filterBar={
        <ClientDetailsFilterBar
          clientName={clientDetails?.name}
          pageTitle="Certificação / Documento de identificação"
        />
      }
    >
      <div
        className={StylePatterns.ELEMENT_WIDTH + " flex flex-col items-center"}
      >
        <h2
          className="text-2xl font-medium flex justify-center items-center mt-4 mb-2"
          style={{ color: Colors.Gray2 }}
        >
          Analisa documento
        </h2>
        <h3
          className="text-base font-normal mt-8 mb-5 text-center"
          style={{ color: Colors.Gray3 }}
        >
          Verifique o documento para aprovação:
        </h3>

        <SplitedCard
          title="Documento de identificação (FRENTE)"
          subTitle="Arquivo com a frente do documento"
          autoWidth={true}
          className="w-11/12 mb-4"
        >
          <div className="flex flex-row w-full items-center">
            {clientDetails?.documentations?.map((doc) => {
              if (doc.type === DocumentsTypeEnum.FEDERALID_FRONT) {
                return (
                  <a
                    className="break-all"
                    style={{ color: Colors.Blue1 }}
                    href={doc.path}
                  >
                    {doc.path}
                  </a>
                );
              }
            })}
          </div>
        </SplitedCard>

        <SplitedCard
          title="Documento de identificação (VERSO)"
          subTitle="Arquivo com o verso do documento"
          autoWidth={true}
          className="w-11/12"
        >
          <div className="flex flex-row w-full items-center">
            {clientDetails?.documentations?.map((doc) => {
              if (doc.type === DocumentsTypeEnum.FEDERALID_BACK) {
                return (
                  <a
                    className="break-all"
                    style={{ color: Colors.Blue1 }}
                    href={doc.path}
                  >
                    {doc.path}
                  </a>
                );
              }
            })}
          </div>
        </SplitedCard>

        <div className="flex justify-center w-full mt-5 mb-10">
          <div className="w-32 mr-4 md:mr-10">
            <Button
              big={false}
              className="font-medium document-status-button"
              textColor={Colors.White}
              backgroundColor={Colors.Red}
              onClick={() => {
                showUpdateDocumentStatusModal({
                  title: "Rejeitar documento?",
                  confirmText: "REJEITAR",
                  confirmColor: Colors.Red,
                  newStatus: ClientDocumentStatusEnum.DISAPPROVED,
                  text1:
                    "Após rejeitar este documento o cliente deverá refazer o processo.",
                  text2: "Deseja mesmo reprovar o documento?",
                });
              }}
            >
              Rejeitar
            </Button>
          </div>
          <div className="w-32 ml-4 md:ml-10">
            <Button
              big={false}
              className="font-medium document-status-button"
              textColor={Colors.White}
              backgroundColor={Colors.Green4}
              onClick={() => {
                showUpdateDocumentStatusModal({
                  title: "Aprovar documento?",
                  confirmText: "APROVAR",
                  confirmColor: Colors.Green3,
                  newStatus: ClientDocumentStatusEnum.APPROVED,
                  text1:
                    "Após aprovar este documento, não será mais possível reverter essa ação.",
                  text2: "Deseja mesmo aprovar o documento?",
                });
              }}
            >
              Aprovar
            </Button>
          </div>
        </div>
      </div>
    </PageSkeleton>
  );
};

export default ClientDocumentsAnalyzePage;
