import moment from "moment";
import api from "../services/api";

export enum GoldTokenTypeEnum {
  G = "G",
  OZ = "OZ",
  KG = "KG",
}
export enum FrequencyFeeGoldCustodyEnum {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}
interface goldTokenCreate {
  amount: string;
  certificationCode: string;
  feeCustody: string;
  frequencyFee: FrequencyFeeGoldCustodyEnum;
  emissionDate: Date;
  redemptionDate: Date;
  redemptionDeadline: Date;
  unit: GoldTokenTypeEnum;
  description: string;
}

export const register = {
  async tokenizeGold(gold: goldTokenCreate) {
    const { status, data } = await api.exchange.post(`/register/gold`, {
      // ...gold,
      // redemptionDate: moment(gold.redemptionDate).format('YYYY-MM-DD'),
      // redemptionDeadline: moment(gold.redemptionDeadline).format('YYYY-MM-DD')

      ...{
        ///TODO: alterar isso aqui para não usar mockado!
        name: "GOLD",
        image: "https://www.localhost.com:4001/meusativos",
        description: "uma nota",
        symbol: "GOLD",

        emissionDate: "2022-02-01",
        type: "FUNGIBLE",
        decimals: 8,
        contractAddress: "0x000",

        properties: {
          amount: 100,
          unit: "G",

          feeCustody: "0",
          frequencyFee: "MONTHLY",
          redemptionDate: "2022-02-01",
          redemptionDeadline: "2022-03-01",
          certificationCode: "123123",

          attachments: {
            whitePaper: {
              title: "Um titulo",
              url: "https://www.localhost.com:4001/mydoc",
            },
          },
          rewards: "Um beneficio/repensa",
          warranty: "Uma garantia",
          risks: "Um risco",
        },
      },
    });

    if (status === 200 || status === 201) {
      return data;
    } else {
      throw data;
    }
  },
  async tokenizeInvoice({ token }: { token: string }): Promise<string> {
    try {
      const { status, data } = await api.exchange.post(`/register/invoice`, {
        accessKey: token,
      });

      if (status === 200 || status === 204) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      throw error;
    }
  },
  async optIn(months: number) {
    const { status, data } = await api.exchange.post(`/register/opt_in`, {
      dataBegin: moment(new Date()).format("YYYY-MM-DD"),
      dataEnd: `${moment(new Date()).add(months, "M").format("YYYY-MM-DD")}`,
    });

    if (status === 201) {
      return data;
    } else {
      throw data;
    }
  },
  async tokenizeSchedule(months: number) {
    const { status, data } = await api.exchange.post(
      "/register/tokenize_agenda",
      {
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: `${moment(new Date()).add(months, "M").format("YYYY-MM-DD")}`,
      }
    );

    if (status === 201) {
      return data;
    } else {
      throw data;
    }
  },
  async optOut() {
    const { status, data } = await api.exchange.post(`/register/opt_out`);

    if (status === 200) {
      return data;
    } else {
      throw data;
    }
  },
};

// eslint-disable-next-line no-redeclare
export namespace register {
  export type deposit = {
    bank: string;
    bankAccount: string;
    bankAgency: string;
    favored: string;
    document: string;
  };
}
