import React, { CSSProperties, useState } from "react";
import Colors from "../../../assets/Colors";
import Divider from "../../Divider/Divider";
// import './DetailsCard.css';

type MyProps = {
  sections: Array<DetailsSection>;
  iconSize?: 2 | 4 | 6 | 8;
};

export interface DetailsSection {
  title?: string;
  icon?: string;
  sectionOpenIcon?: string;
  customDivider?: JSX.Element;
  className?: string;
  style?: CSSProperties;
  children?: JSX.Element;
  contentImg?: string;
  sectionRows?: Array<DetailsSectionRow>;
  closed?: boolean;
}

export interface DetailsSectionRow {
  title?: string;
  value?: any;
  className?: string;
  style?: CSSProperties;
  valueStyle?: CSSProperties;
  children?: JSX.Element;
}

const renderRow = (row: DetailsSectionRow, index: number) => {
  if (row.value || row.children) {
    return (
      <p
        className="font-light text-base mb-2"
        style={{ color: Colors.Gray3, ...row.style }}
        key={index}
      >
        {row.title ? row.title : ""}
        {row.children ? (
          row.children
        ) : (
          <span
            className={`font-normal ${row.className}`}
            style={{ color: "black", ...row.valueStyle }}
          >
            {row.value}
          </span>
        )}
      </p>
    );
  }
};

const DetailsCard: React.FC<MyProps> = ({ sections, iconSize }) => {
  const [stateSections, setStateSections] = useState(sections);

  const getIconToRender = (section: DetailsSection): JSX.Element => {
    let iconToRender = section.icon;
    if (section.sectionOpenIcon && !section.closed) {
      iconToRender = section.sectionOpenIcon;
    }

    return iconToRender ? (
      <img className={`mr-2 h-${iconSize} w-${iconSize}`} src={iconToRender} />
    ) : (
      <></>
    );
  };

  const toggleSection = (index: number): void => {
    setStateSections(
      stateSections.map((s, i) => {
        if (i === index) {
          s.closed = !s.closed;
        }
        return s;
      })
    );
  };

  return (
    <>
      <div
        className={
          "border rounded flex flex-col justify-between p-2 px-4 mb-4 bg-white w-full"
        }
        style={{ borderColor: Colors.Gray4 }}
      >
        {stateSections.map((section, index) => (
          <div key={index}>
            {index > 0 ? (
              section.customDivider ? (
                section.customDivider
              ) : (
                <Divider top="mt-2" bottom="mb-2" color={Colors.Gray4} />
              )
            ) : (
              <></>
            )}
            <div className="flex flex-row pb-2">
              <div className="flex flex-col flex-grow justify-start">
                <p
                  className={`flex items-center text-lg font-medium my-4 ${section.className}`}
                  style={{ color: Colors.Gray2, ...section.style }}
                  onClick={() => toggleSection(index)}
                >
                  {getIconToRender(section)}
                  {section.title ? section.title : ""}
                </p>
                <div className="flex flex-row">
                  {section.contentImg ? (
                    <div className="flex flex-col mr-4 justify-center items-center ">
                      <img
                        className="h-24 w-24"
                        src={section.contentImg}
                        alt=""
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="flex flex-col flex-grow justify-start">
                    {section.sectionRows?.map((row, index) =>
                      renderRow(row, index)
                    )}
                  </div>
                </div>
              </div>
            </div>

            {!section.closed ? section.children : <></>}
            {/* {section.children} */}
          </div>
        ))}
      </div>
    </>
  );
};

DetailsCard.defaultProps = { iconSize: 6 };

export default DetailsCard;
