import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import RoutesPaths from "../../Routes/RoutesPaths";
import * as API from "../../api";
import Colors, { GrayBackGroud } from "../../assets/Colors";
import EmptyCard from "../../components/Cards/EmptyCard";
import MyOrderCard from "../../components/Cards/GoldOrderCard";
import FilterBar from "../../components/FilterBar/FilterBar";
import Footer from "../../components/Footer/Footer";
import TopBar from "../../components/TopBar/TopBar";
import OrderContext from "../../contexts/OrderProvider";
import UserContext from "../../contexts/UserProvider";
import { Order } from "../../models";
import {
  OrderStatusEnum,
  OrderTypeEnum,
  TokenTypeEnum,
} from "../../models/enums";
import history from "../../services/history";

const MyOrders: React.FC = () => {
  const userContext = useContext(UserContext);
  const orderContext = useContext(OrderContext);

  const [hasMore, sethasMore] = useState<boolean>(true);
  const [orders, setorders] = useState<Array<Order>>([]);
  const [topBarFilters] = useState<OrderTypeEnum | undefined>(undefined);
  const [filterStateIndex] = useState<OrderStatusEnum | undefined>(undefined);
  const [filterTypeIndex] = useState<TokenTypeEnum | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);

  async function loadOrders(
    status?: OrderStatusEnum,
    typeReceivable?: TokenTypeEnum,
    type?: OrderTypeEnum
  ) {
    try {
      setLoading(true);

      const orders = await API.book.getAllOrders({
        status,
        type,
        typeReceivable,
        userId: userContext.user?.id,
      });

      setorders(orders);
      sethasMore(orders.length !== orders?.length);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadOrders();
  }, []);

  async function showOrderDetails(order: Order) {
    orderContext.setOrder(order);
    history.push(RoutesPaths.ORDENDETALHES);
  }

  return (
    <>
      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <TopBar />
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: GrayBackGroud }}
        >
          <FilterBar title="Minhas ordens"></FilterBar>

          <div className="mx-5 flex flex-col">
            <div className="ml-2 my-4">
              <h3 style={{ color: Colors.Gray2 }} className="text-lg">
                {topBarFilters === undefined
                  ? "Últimas ordens"
                  : topBarFilters === OrderTypeEnum.buy
                  ? "Ordens de compra"
                  : "Ordens  de venda"}
              </h3>
            </div>

            {orders.length > 0 ? (
              <InfiniteScroll
                dataLength={orders.length}
                next={() => {
                  loadOrders(filterStateIndex, filterTypeIndex, topBarFilters);
                }}
                hasMore={hasMore}
                loader={
                  <p style={{ textAlign: "center" }}>
                    <h4>Loading...</h4>
                  </p>
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Todos os registros exibidos</b>
                  </p>
                }
              >
                {orders
                  .sort(
                    (a, b) => b.updateDate.getTime() - a.updateDate.getTime()
                  )
                  .map((order) => {
                    return (
                      <MyOrderCard
                        order={order}
                        blackTheme={false}
                        detailClick={() => showOrderDetails(order)}
                        key={order.id}
                      />
                    );
                  })}
              </InfiniteScroll>
            ) : (
              <EmptyCard>
                <div className="flex flex-col items-center">
                  <strong style={{ color: Colors.Gray3 }}>
                    {isLoading
                      ? "Carregando ordens..."
                      : "Nenhuma ordem encontrada"}
                  </strong>
                </div>
              </EmptyCard>
            )}
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MyOrders;
