import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as API from "../../../api";
import Colors from "../../../assets/Colors";
import Button from "../../../components/Buttons/Button";
import UnderlineButton from "../../../components/Buttons/UnderlineButton";
import ResumeCard from "../../../components/Cards/ResumeCard/ResumeCard";
import Divider from "../../../components/Divider/Divider";
import FilterBar from "../../../components/FilterBar/FilterBar";
import Footer from "../../../components/Footer/Footer";
import GoldNegociationBar from "../../../components/Modal/GoldNegociationBar/GoldNegociationBar";
import PageTopBanner from "../../../components/PageTopBanner/PageTopBanner";
import ResumeBar from "../../../components/ResumeBar/ResumeBar";
import TopBar from "../../../components/TopBar/TopBar";
/// Models
import { GoldToken } from "../../../models";
import { TokenStatusEnum } from "../../../models/enums";
import RoutesPaths from "../../../Routes/RoutesPaths";
/// Assets
import history from "../../../services/history";
import { formatToCurrency } from "../../../utils/formatters";
import * as Icons from "./../../../assets/icons";
import "./../style.css";
import "./MyAssetDetails.css";

const MyAssetsDetails: React.FC<any> = () => {
  const { tokenId } = useParams<{ tokenId: string }>();

  const [negociationBar, setNegociationBar] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [currentToken, setCurrentToken] = useState<GoldToken>();

  useEffect(() => {
    setLoading(true);
    getToken();
  }, []);

  const getToken = async () => {
    try {
      let userTokens = await API.user.getUserTokens();
      let filteredTokens = userTokens?.filter((token) => token.id == tokenId);

      if (!filteredTokens.length) {
        throw Error("Token não encontrado");
      }
      setCurrentToken(filteredTokens[0]);
    } catch (error) {
      history.push(RoutesPaths.MEUSATIVOS);
    } finally {
      setLoading(false);
    }
  };

  var subTitle: string | undefined = undefined;

  switch (currentToken?.status) {
    case TokenStatusEnum.ACTIVE:
      subTitle = "Tokens disponíveis para negociação";
      break;
    case TokenStatusEnum.RESERVED:
      subTitle = "Tokens já estão sendo negociados";
      break;
    case TokenStatusEnum.REMOVED:
      subTitle = "Tokens resgatados em DD/MM/AAAA";
      break;
    case TokenStatusEnum.FINISHED:
      subTitle = "Tokens indisponíveis para negociação";
      break;
  }

  const renderLoadingPage = () => (
    <>
      <div
        className="bg-white px-2 py-1 mx-5 mb-0 flex flex-col aling-center items-center rounded border"
        style={{ borderColor: Colors.Gray4 }}
      >
        <div className="my-3 px-3 w-full justify-start">
          <div className="rounded animate-pulse w-40 bg-gray-200 h-7 mb-3"></div>

          <div className="rounded animate-pulse w-48 bg-gray-200 h-5 mb-2"></div>

          <div className="rounded animate-pulse w-48 bg-gray-200 h-5 mt-1"></div>
          <div className="rounded animate-pulse w-48 bg-gray-200 h-5 mt-1"></div>
          <div className="rounded animate-pulse w-48 bg-gray-200 h-5 mt-1"></div>
          <div className="rounded animate-pulse w-48 bg-gray-200 h-5 mt-1"></div>
          <div className="rounded animate-pulse w-48 bg-gray-200 h-5 mt-1"></div>
        </div>

        <Divider color={Colors.Gray4} top="mt-1" bottom="b-0" />

        <div className="flex flex-row w-full justify-center">
          <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
            <ResumeBar>
              <div
                className="md:flex-col md:w-1/3 border-2 my-1 py-0 px-3 md:p-6 
							rounded animate-pulse w-full lg:w-2/5 bg-gray-200 h-7 md:h-36"
              ></div>

              <div
                className="md:flex-col md:w-1/3 border-2 my-1 py-0 px-3 md:p-6 
							rounded animate-pulse w-full lg:w-2/5 bg-gray-200 h-7 md:h-36"
              ></div>

              <div
                className="md:flex-col md:w-1/3 border-2 my-1 py-0 px-3 md:p-6 
							rounded animate-pulse w-full lg:w-2/5 bg-gray-200 h-7 md:h-36"
              ></div>
            </ResumeBar>
          </div>
        </div>

        <div className="rounded animate-pulse w-44 bg-gray-200 h-8 my-6"></div>

        <div className="rounded animate-pulse w-72 bg-gray-200 h-16"></div>
        <div className="rounded animate-pulse w-72 bg-gray-200 h-16 my-5"></div>

        <UnderlineButton
          className="font-medium text-lg"
          style={{ color: Colors.Gray3 }}
          onClick={history.goBack}
        >
          Voltar a listagem
        </UnderlineButton>
      </div>
    </>
  );

  return (
    <>
      <GoldNegociationBar
        state={negociationBar}
        changeState={() => {
          setNegociationBar(!negociationBar);
        }}
      />
      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <TopBar />
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar goBackBtn title="Detalhes do token" />

          <PageTopBanner
            loading={isLoading}
            borderlessInnerIcon
            title={currentToken?.metadata.symbol}
            subtitle={currentToken?.metadata.name}
            bgIcon={Icons.GoldBanner2Icon}
            imgIcon={currentToken?.metadata.image}
          />

          {isLoading ? (
            renderLoadingPage()
          ) : (
            <>
              <div
                className="bg-white px-2 py-1 mx-5 mb-0 flex flex-col aling-center items-center rounded border"
                style={{ borderColor: Colors.Gray4 }}
              >
                <div className="whitespace-pre my-3 px-3 w-full justify-start">
                  <div
                    className="flex gap-1 mb-3 font-medium text-xl"
                    style={{ color: Colors.Gray2 }}
                  >
                    <span>LOTE #{currentToken?.id}</span>
                    <img src={Icons.InfoBlueIcon} />
                  </div>

                  <p
                    className="text-sm font-light mb-2"
                    style={{ color: Colors.Gray3 }}
                  >
                    Código interno:{" "}
                    {currentToken?.metadata.properties.certificationCode}
                  </p>
                  {[
                    {
                      label: "Emissão: ",
                      value: moment(currentToken?.metadata.emissionDate).format(
                        "DD/MM/YYYY"
                      ),
                    },
                    {
                      label: "Carência: ",
                      value: moment(
                        currentToken?.metadata.redemptionDate
                      ).format("DD/MM/YYYY"),
                    },
                    {
                      label: "Liquidação: ",
                      value: "D+2 após data de carência",
                    },
                    { label: "Validade: ", value: "Não possui" },
                  ].map((row) =>
                    isLoading ? (
                      <div className="rounded animate-pulse w-40 bg-gray-200 h-5 mt-1"></div>
                    ) : (
                      <p
                        style={{ color: Colors.Gray3 }}
                        className="text-base font-light"
                      >
                        {row.label}{" "}
                        <span className="font-normal">{row.value}</span>
                      </p>
                    )
                  )}
                </div>

                <Divider color={Colors.Gray4} top="mt-1" bottom="b-0" />

                <div className="flex flex-row w-full justify-center">
                  <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
                    <ResumeBar>
                      <ResumeCard
                        unit=""
                        bgColor={Colors.Gray11}
                        invert
                        title="Disponíveis"
                        value={currentToken?.available}
                      />
                      <ResumeCard
                        unit=""
                        bgColor={Colors.Gray11}
                        invert
                        title="À venda"
                        value={0}
                      />
                      <ResumeCard
                        unit=""
                        bgColor={Colors.Gray11}
                        invert
                        title="Lockup"
                        value={0}
                      />
                    </ResumeBar>
                  </div>
                </div>

                <p
                  className="text-2xl font-normal my-6"
                  style={{ color: Colors.Gray2 }}
                >
                  Total em wallet:
                  <span className="font-medium">
                    {currentToken
                      ? `${formatToCurrency(
                          currentToken?.available + currentToken?.consumed
                        )} ${currentToken?.metadata.properties.unit}`
                      : "0"}
                  </span>
                </p>

                {currentToken?.status === TokenStatusEnum.RESERVED ? (
                  <Button onClick={() => {}}>Ver ordem de venda</Button>
                ) : (
                  <Button
                    className="text-lg"
                    // disabled={currentToken?.status !== TokenStatusEnum.ACTIVE || currentToken.available <= 0}
                    disabled={true}
                    onClick={() => {
                      setNegociationBar(!negociationBar);
                    }}
                  >
                    Negociar tokens
                  </Button>
                )}

                <Button
                  className="my-5 text-lg"
                  disabled={
                    currentToken?.status !== TokenStatusEnum.ACTIVE ||
                    currentToken.available <= 0
                  }
                  onClick={() => {
                    setNegociationBar(!negociationBar);
                  }}
                  backgroundColor={Colors.Gray3}
                  textColor={Colors.White}
                >
                  Resgatar
                </Button>
                <UnderlineButton
                  className="font-medium text-lg"
                  style={{ color: Colors.Gray3 }}
                  onClick={history.goBack}
                >
                  Voltar a listagem
                </UnderlineButton>
              </div>
            </>
          )}
          <br />
        </main>
        <Footer />
      </div>
    </>
  );
};
export default MyAssetsDetails;
