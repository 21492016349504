import moment from "moment";
import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { token } from "../../api";
import Colors from "../../assets/Colors";
import UserContext from "../../contexts/UserProvider";
import { TokenTypeEnum, TokenTypeEnumInterpreter } from "../../models/enums";
import { UserRoleEnum } from "../../models/enums/UserRoleEnum";
import { formatToCurrency } from "../../utils/formatters";
import Button from "../Buttons/Button";
import * as Icons from "./../../assets/icons";

type MyProps = {
  onClick?: () => any;
  onClickMore?: () => any;
  negociationClick: () => any;
  btnClick?: () => any;

  id?: string;
  amount?: number;
  negociation?: number;
  available?: number;
  sold?: number;
  showNegociation?: boolean;
  redemptionDate?: string;
  unit?: string;
};

const ChartCard: React.FC<MyProps> = ({
  onClick,
  onClickMore,
  id,
  negociationClick,
  amount,
  negociation,
  available,
  redemptionDate,
  sold,
  unit,
  showNegociation,
}) => {
  const { user } = useContext(UserContext);

  return (
    <div
      className={`border-05 rounded flex flex-col justify-between pb-2 pl-0 mb-4 bg-white`}
      style={{ borderColor: Colors.Gray4 }}
    >
      <div className="flex flex-col sm:flex-row pb-2 mt-4">
        <div
          className="flex flex-col flex-grow sm:flex-row"
          onClick={onClick}
          style={{ cursor: onClick ? "pointer" : "default" }}
        >
          <div className="flex flex-col w-2/3 sm:w-1/3 md:w-52 justify-center items-center sm:mr-2 self-center">
            {amount && amount > 0 ? (
              <>
                <Chart
                  type={"pie"}
                  options={{
                    chart: {
                      width: "100%",
                      type: "pie",
                      // yaxis: {
                      //   show: false,
                      //   tickAmount: 3,
                      // },
                    },
                    legend: {
                      show: false,
                    },
                    dataLabels: {
                      enabled: false,
                    },

                    colors: [Colors.Blue1, Colors.Green4, Colors.Red4],
                  }}
                  series={[
                    ((negociation ?? 0) * 100) / amount,
                    ((available ?? 0) * 100) / amount,
                    ((sold ?? 0) * 100) / amount,
                  ]}
                />
                <span
                  style={{ color: Colors.Gray3 }}
                  className="font-medium text-xs"
                >
                  MERCADO
                </span>
              </>
            ) : (
              <img src={Icons.AUChartCardIcon} alt="" />
            )}
          </div>
          <div className="flex flex-col flex-grow justify-end p-2 px-4 sm:p-0">
            <p
              style={{ color: Colors.Gray2 }}
              className="font-light text-sm flex flex-row whitespace-pre mb-1"
            >
              <p>LOTE: #{id}</p>
            </p>
            <p
              style={{ color: Colors.Gray2 }}
              className="font-normal text-base mb-1"
            >
              <span>Total em wallet: </span>
              <span className="font-medium">{formatToCurrency(amount)}</span>
              <span className="font-light"> {unit}</span>
            </p>
            <div
              style={{ color: Colors.Green3 }}
              className="font-normal text-sm flex flex-row whitespace-pre mb-1"
            >
              <p>
                Disponível: {formatToCurrency(available)} {unit}
              </p>
            </div>
            <div
              style={{ color: Colors.Blue1 }}
              className="font-normal text-sm flex flex-row whitespace-pre mb-1"
            >
              <p>
                À venda: {formatToCurrency(negociation)} {unit}
              </p>
            </div>
            <div
              style={{ color: Colors.Gray4 }}
              className="font-normal text-base flex flex-row whitespace-pre mt-3"
            >
              <p>
                <span className="text-sm">Carência:</span>{" "}
                <span>{moment(redemptionDate).format("DD/MM/YYYY")}</span>
              </p>
            </div>
          </div>
        </div>
        {showNegociation && (
          <div className="flex flex-grow justify-between px-4 gap-2 sm:flex-col sm:items-end sm:mr-4 sm:gap-0 sm:px-0">
            <button
              className={"hidden sm:flex flex-row justify-end "}
              onClick={onClickMore}
            >
              <img src={Icons.MoreIcon} alt="" />
            </button>

            <Button
              className="asset-negotiate-btn"
              backgroundColor={
                user?.role === UserRoleEnum.ADMIN
                  ? Colors.Gold4
                  : Colors.UserBGButton
              }
              textColor={
                user?.role === UserRoleEnum.ADMIN ? Colors.White : Colors.Black1
              }
              onClick={(e) => {
                negociationClick();
                e.stopPropagation();
              }}
              disabled={available ? available <= 0 : true}
            >
              Negociar
            </Button>

            <Button
              className="asset-negotiate-btn sm:hidden"
              backgroundColor={Colors.CorDetalhes}
              textColor={
                user?.role === UserRoleEnum.ADMIN ? Colors.White : Colors.Black1
              }
              onClick={onClickMore}
              disabled={available ? available <= 0 : true}
            >
              Mais
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

ChartCard.defaultProps = {
  showNegociation: false,
};

export default ChartCard;
