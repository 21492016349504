import * as Icons from "../../assets/icons";

export enum ApiErrorTypeEnum {
  API_ERROR_USER_NOT_FOUND = "API_ERROR_USER_NOT_FOUND",
}

export class ApiErrorTypeEnumInterpreter {
  static convert(type: String) {
    switch (type) {
      case ApiErrorTypeEnum.API_ERROR_USER_NOT_FOUND:
        return {
          message: "Usuário não encontrado!",
        };
      default:
        return {
          message: "",
        };
    }
  }

  static getMessage(type: ApiErrorTypeEnum) {
    return this.convert(type).message;
  }
}
