import React from "react";
import FilterBar from "../../../../components/FilterBar/FilterBar";

interface MyProps {
  clientName: string;
  pageTitle?: string;
}

const ClientDetailsFilterBar: React.FC<MyProps> = ({
  clientName,
  pageTitle,
}) => {
  return (
    <FilterBar
      goBackBtn
      title={
        <div className="flex flex-row text-base sm:text-lg">
          <span>Cliente /&nbsp;</span>
          {clientName ? (
            clientName
          ) : (
            <div className="rounded animate-pulse w-56 bg-gray-200 h-5 mt-1"></div>
          )}
          <span>&nbsp;{pageTitle && <span>/ {pageTitle}</span>}</span>
        </div>
      }
    />
  );
};

export default ClientDetailsFilterBar;
