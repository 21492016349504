import React from "react";
import Colors from "../../../assets/Colors";
import { Token } from "../../../models";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";

type MyProps = {
  order: any;
};

const TokenDetailsInformations: React.FC<MyProps> = ({ order }) => {
  const token: Token = order.tokens[0];

  const sanitizeData = token?.metadata?.description?.replace(
    "/[\u200B-\u200D\uFEFF]/",
    ""
  );

  return (
    <>
      <div className="flex flex-row w-full justify-center">
        <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
          <div
            className="my-8 ml-8 text-base font-light w-full"
            style={{ color: Colors.Gray2 }}
          ></div>
          <div
            className={
              "markdown-wrapper border rounded flex flex-col justify-between p-2 px-4 mb-4 bg-white w-full"
            }
            style={{ borderColor: Colors.Gray4, color: Colors.Gray2 }}
          >
            <ReactMarkdown
              children={sanitizeData}
              remarkPlugins={[remarkGfm, remarkBreaks]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TokenDetailsInformations;
