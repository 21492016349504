import React, { useContext } from "react";
import { Redirect, Route } from "react-router";
import Colors from "../assets/Colors";
import Footer from "../components/Footer/Footer";
import TopBar from "../components/TopBar/TopBar";
import BalanceContext from "../contexts/BalanceProvider";
import LimitsContext from "../contexts/LimitsProvide";
import UserContext from "../contexts/UserProvider";
import { UserRoleEnum } from "../models/enums/UserRoleEnum";
import { PagesHandler } from "../pages/Auth/Onboarding/PagesHandler";
import { RegisterPageHandler } from "../pages/Auth/Onboarding/RegisterSteps";
import IndexPage from "../pages/IndexPage";
import SplashScreen from "../pages/SplashScreen";
import { isAuthenticated, makeLogout } from "../services/jwt";
import * as API from "./../api";
import { setIdOnboarding } from "./../api";
import RoutesPaths from "./RoutesPaths";

export const AuthRoute: React.FC<any> = ({
  component: Component,
  admin,
  blackTheme,
  ...rest
}) => {
  const { updateBalance } = useContext(BalanceContext);
  const { updateLimits } = useContext(LimitsContext);
  const { user, setUser } = useContext(UserContext);
  const auth = isAuthenticated();

  const getUserData = async () => {
    try {
      const user = await API.user.getUserInfo();
      setUser(user);
      setIdOnboarding(user.id);
    } catch (error) {
      console.error(error);
      makeLogout();
    }
  };

  const renderPage = (component) => {
    return (
      <div className="flex flex-col min-h-screen text-sm text-gray-400 font-sans">
        <TopBar showBlankTopBar />
        <main className="flex flex-col flex-grow w-full items-center bg-white">
          {component}
        </main>
      </div>
    );
  };

  if (!auth) {
    return <IndexPage />;
  }

  if (auth && !user) {
    getUserData();
    return <SplashScreen />;
  }

  if (!user?.emailVerified) {
    if (user?.email) {
      return renderPage(<PagesHandler defaultPage={2} />);
    } else {
      return renderPage(<PagesHandler defaultPage={1} />);
    }
  } else if (!user?.phoneVerified) {
    if (user?.phoneNumber) {
      return renderPage(<PagesHandler defaultPage={4} />);
    } else {
      return renderPage(<PagesHandler defaultPage={3} />);
    }
  } else if (!user?.dataVerified) {
    return renderPage(<RegisterPageHandler defaultPage={0} />);
  } else {
    const isNotValidAddress =
      !user?.address?.street ||
      !user?.address?.number?.toString() ||
      !user?.address?.neighborhood ||
      !user?.address?.city ||
      !user?.address.state ||
      !user?.address?.postalCode ||
      !user?.address?.country;
    if (isNotValidAddress) {
      return renderPage(<RegisterPageHandler defaultPage={1} />);
    }
    const isNotValidBank =
      !user?.userBankInfo?.bank ||
      !user?.userBankInfo?.bankAccount ||
      !user?.userBankInfo?.bankAgency;
    if (isNotValidBank) {
      return renderPage(<RegisterPageHandler defaultPage={2} />);
    }
    updateBalance(false);
    updateLimits(false);
  }

  if (admin) {
    if (!user) {
      return <Redirect to={{ pathname: RoutesPaths.INDEX }} />;
    }

    if (user?.role === UserRoleEnum.USER) {
      return <Redirect to={{ pathname: RoutesPaths.DASHBOARD }} />;
    }

    return (
      <div className="flex flex-col min-h-screen text-sm text-gray-400 font-sans">
        <TopBar />
        <main
          className={`flex-grow w-full `}
          style={{
            backgroundColor: blackTheme ? "black" : Colors.GrayBackGroud,
          }}
        >
          <Route {...rest} render={(props) => <Component {...props} />} />
        </main>
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: RoutesPaths.SIGNIN,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
export const NoAuthRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated() ? (
        <>
          <div className="flex flex-col min-h-screen text-sm text-gray-50 font-sans">
            <TopBar />
            <main className="flex flex-col flex-grow w-full items-center bg-white">
              <Component {...props} />
            </main>
            <Footer backgroundColor={Colors.White} />
          </div>
        </>
      ) : (
        <Redirect
          to={{ pathname: RoutesPaths.HOME, state: { from: props.location } }}
        />
      )
    }
  />
);
export const PublicRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => <Route {...rest} render={(props) => <Component {...props} />} />;
