import React from "react";
import Colors from "../../assets/Colors";
import Button from "../Buttons/Button";
import ModalCore from "./ModalCore";
import { DepositMethodsEnum } from "../../models/enums/DepositMethods";

interface DepositInstructionsType {
  [key: string]: (string | JSX.Element)[];
}

const depositInstructions: DepositInstructionsType = {
  PIX: [
    "O depósito deve ser realizado no valor exato do preenchido na etapa anterior.",
    "Toda transferência na plataforma deve ser feita entre contas de mesma titularidade.",
    // 'Ao fazer a transferência, certifique-se de confirmar com atenção os dados informados para pagamento.',
    // 'Após a confirmação, o valor depositado será automaticamente creditado em seu saldo e visível em seu extrato.'
  ],
  TED: [
    "Deposite o valor exato conforme o preenchido na etapa anterior, caso contrário sua transação poderá não será concluída.",
    <span>
      Para que possamos identificar sua TED você deve realizá-la de uma conta
      bancária do <span className="font-medium">titular</span> cadastrado na
      plataforma.
    </span>,
    "Certifique-se que os dados da conta destino estejam corretos. Não nos responsabilizamos por erros de depósito.",
    "Após a confirmação, o valor depositado será automaticamente creditado em seu saldo e visível em seu extrato.",
  ],
};

export const InstructionsModal: React.FC<{
  onClick(): any;
  depositMethod: DepositMethodsEnum;
}> = ({ onClick, depositMethod }) => {
  return (
    <ModalCore state={true} changeState={() => {}} className="lg:w-5/12">
      <div className="flex flex-col mx-4 my-5 items-center">
        <h1 className="text-lg" style={{ color: Colors.Gray2 }}>
          Instruções importantes:
        </h1>

        <div className="flex flex-col gap-3 my-7 w-full">
          {depositInstructions[depositMethod]
            ? depositInstructions[depositMethod].map((elm, index) => (
                <div
                  key={index}
                  className="container border rounded p-4 text-center"
                  style={{ borderColor: Colors.Gray2 }}
                >
                  <p style={{ color: Colors.Gray2 }}>{elm}</p>
                </div>
              ))
            : null}
        </div>

        <Button onClick={() => onClick()}>OK</Button>
      </div>
    </ModalCore>
  );
};
