import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import * as API from "../../../../api";
import Colors from "../../../../assets/Colors";
import * as Gifs from "../../../../assets/gifs";
import { StylePatterns } from "../../../../assets/patterns_tailwind_class";
import history from "../../../../services/history";
import { determineErrorMessage } from "../../../../services/i18n/aux";
import PageSkeleton from "../../../../components/PageSkeleton/PageSkeleton";
import UserContext from "../../../../contexts/UserProvider";
import AlertModal from "../../../../components/Modal/AlertModal";
import FilterBar from "../../../../components/FilterBar/FilterBar";
import UploadDoc from "../../../../components/UploadDoc/UploadDoc";

export const DocumentAddressPage: React.FC<any> = (props) => {
  const [errorModalState, setErrorModalState] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>("");
  const [documents, setDocuments] = useState([]);
  const { user } = useContext(UserContext);

  const handleSendDocuments = async () => {
    if (!user) return;
    setLoading(true);

    try {
      await API.signup.individual.sendDocuments(user.id, documents);
      history.goBack();
    } catch (err) {
      setTextError(determineErrorMessage(err));
      setErrorModalState(true);
    } finally {
      setLoading(false);
    }
  };

  const changeFile = (file, identifier: string) => {
    let files = [];
    const old = documents.find((doc: any) => doc.identifier === identifier);

    if (old) {
      files = documents.map((doc: any) => {
        if (doc.identifier === identifier) {
          return {
            file: file,
            identifier: identifier,
          };
        }
        return doc;
      }) as never;
    } else {
      files.push({
        file: file,
        identifier: identifier,
      } as never);
      files = documents.concat(files);
    }

    setDocuments(files);
  };

  return (
    <PageSkeleton
      useContentCard
      button={{
        label: "Salvar",
        onClick: handleSendDocuments,
        disabled: documents.length < 1,
      }}
      button2={{ label: "Cancelar edição", onClick: history.goBack }}
      loading={isLoading}
      filterBar={
        <FilterBar
          goBackBtn
          title="Comprovante de endereço"
          selection={false}
        />
      }
    >
      <AlertModal
        state={errorModalState}
        title="Ops, documentos irregulares."
        src={Gifs.IconAlertGif}
        confirmClick={() => setErrorModalState(false)}
      >
        <p>O sistema identificou uma irregularidade nos documentos enviados.</p>
        <p>Por favor verifique os arquivos enviados e tente novamente.</p>
        <p>{textError}</p>
      </AlertModal>

      <div
        className={StylePatterns.ELEMENT_WIDTH + " flex flex-col items-center"}
      >
        <h2
          className="text-2xl font-medium flex justify-center items-center text-center mt-4 mb-2"
          style={{ color: Colors.Gray2 }}
        >
          Foto do comprovante de endereço
        </h2>

        <p className="mb-5" style={{ color: Colors.Gray3 }}>
          Anexe seu comprovante de residência atualizado (últimos três meses)
        </p>

        <UploadDoc
          type="IMAGE"
          fullW={true}
          eventOnChange={(file, identifier) => changeFile(file, identifier)}
          document={{
            identifier: "PROOF_OF_RESIDENCE",
            label: `Comprovante de endereço`,
            description:
              "Comprovante de conta (água, luz ou telefone) JPEG, PNG, PDF até 5mb",
          }}
        />

        <div
          className="text-center text-xs font-normal my-5 md:px-3 flex flex-col gap-1"
          style={{ color: Colors.Gray3 }}
        >
          <p className="underline">ATENÇÃO:</p>
          <br />
          <p>
            Usaremos os dados pessoais informados em seu cadastro para validar o
            documento enviado.
          </p>
        </div>
      </div>
    </PageSkeleton>
  );
};

export default DocumentAddressPage;
