import React from "react";

type MyProps = {
  children: JSX.Element | JSX.Element[];
};

const ResumeBar = ({ children }: MyProps) => {
  return (
    <div className="flex flex-col lg:flex-row gap-1 md:gap-5 my-7 mt-4 w-full">
      {children}
    </div>
  );
};

export default ResumeBar;
