import history from "./history";
import RoutesPaths from "../Routes/RoutesPaths";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const ACCESS_TOKEN_KEY = "@accessToken";
export const REFRESH_TOKEN_KEY = "@refreshToken";

export const FORCED_LOGOUT_KEY = "@forceLogout";
export const LOGOUT_TIMEOUT_INDEX = "@logoutTimeout";
export const LOGOUT_TIMEOUT_AMOUNT_MINUTES = 30;

export const isAuthenticated = () => {
  const lastInteraction = getLogoutTimeout();
  if (dayjs().isAfter(lastInteraction)) {
    return makeLogout(true);
  }

  if (getAccessToken() && getRefreshToken()) {
    setLogoutTimeout();
    return true;
  }

  return false;
};

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

export const setAccessToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
export const setRefreshToken = (token: string) =>
  localStorage.setItem(REFRESH_TOKEN_KEY, token);

export const setLogoutTimeout = () =>
  localStorage.setItem(
    LOGOUT_TIMEOUT_INDEX,
    dayjs().utc().add(LOGOUT_TIMEOUT_AMOUNT_MINUTES, "m").toString()
  );

export const getLogoutTimeout = () =>
  localStorage.getItem(LOGOUT_TIMEOUT_INDEX);

export const clearLogoutTimeout = () =>
  localStorage.removeItem(LOGOUT_TIMEOUT_INDEX);

export const setForcedLogout = () =>
  sessionStorage.setItem("FORCED_LOGOUT_KEY", "1");

export const getForcedLogout = () =>
  sessionStorage.getItem("FORCED_LOGOUT_KEY");

export const clearForcedLogout = () =>
  sessionStorage.removeItem("FORCED_LOGOUT_KEY");

export const makeLogin = (accesToken: string, refreshToken: string) => {
  setAccessToken(accesToken);
  setRefreshToken(refreshToken);
  window.location.reload();
};
export const makeLogout = (forcedLogout: boolean = false) => {
  localStorage.clear();

  if (forcedLogout) {
    setForcedLogout();
  }

  history.push(RoutesPaths.SIGNIN);
};
