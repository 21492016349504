import moment from "moment";
import React, { CSSProperties, useContext, useMemo } from "react";
import Chart from "react-apexcharts";
import Colors from "../../../../assets/Colors";
import { MoreIcon } from "../../../../assets/icons";
import { GoldToken, Token } from "../../../../models";
import {
  TokenTypeEnumInterpreter,
  UserRoleEnum,
} from "../../../../models/enums";
import Button from "../../../../components/Buttons/Button";
import * as Icons from "../../../../assets/icons";
import "./AssetCardAdmin.css";
import UserContext from "../../../../contexts/UserProvider";
import { formatToCurrency } from "../../../../utils/formatters";

type MyProps = {
  tokenProject: any;
  title?: string;
  subTitle?: string;
  onClick: () => any;
};

const AssetCardAdmin: React.FC<MyProps> = ({
  tokenProject,
  title,
  subTitle,
  onClick,
}) => {
  const tokenUnit = useMemo(() => {
    if (tokenProject.tokens?.length > 0) {
      return tokenProject.tokens[0]?.metadata?.properties?.unit || "";
    }
    return "";
  }, [tokenProject.tokens]);

  return (
    <div
      className="border-05 rounded flex flex-col justify-between pb-2 pl-0 mb-4 bg-white"
      style={{ borderColor: Colors.Gray4 }}
    >
      <div className="flex flex-row justify-between my-5 items-center px-2">
        <p className="justify-center text-xl" style={{ color: Colors.Gray2 }}>
          <span className="font-medium px-2" style={{ fontSize: "22px" }}>
            {title}
          </span>
          <span className="font-light">{subTitle}</span>
        </p>
      </div>
      <hr className="mb-3" style={{ borderColor: Colors.Gray5 }} />

      <div
        className="flex flex-col cursor-pointer sm:flex-row sm:pb-2 px-4"
        onClick={onClick}
      >
        <div className="flex flex-col w-2/4 justify-center items-center self-center sm:mr-2 sm:w-40">
          <img src={tokenProject?.tokens[0]?.metadata?.image} alt="" />
        </div>
        <div className="flex flex-col flex-grow justify-center my-4">
          <p
            style={{ color: Colors.Gray2 }}
            className="font-normal mb-4 text-lg"
          >
            Saldo wallet admin:
            <span className="font-medium">{` ${formatToCurrency(
              tokenProject.goldTotal
            )} `}</span>
            {tokenUnit}
          </p>
          <p style={{ color: Colors.Gray4 }} className="font-normal text-lg">
            Total disponível: {formatToCurrency(tokenProject.goldAvailable)}{" "}
            {tokenUnit}
          </p>
          <p style={{ color: Colors.Gray4 }} className="font-normal text-lg">
            Total à venda: {formatToCurrency(tokenProject.goldConsumed)}{" "}
            {tokenUnit}
          </p>
          <p style={{ color: Colors.Gray4 }} className="font-normal text-lg">
            Total de lotes: {tokenProject?.tokens?.length}
          </p>
        </div>
      </div>
    </div>
  );
};

export const GoldAssetCard: React.FC<{
  token: GoldToken;
  onClick: () => any;
  onClick2: () => any;
}> = ({ token, onClick, onClick2 }) => {
  const { user } = useContext(UserContext);

  var text1: CSSProperties = {};
  var text2: CSSProperties = {};
  var textStatus: CSSProperties = {};

  text1.color = Colors.Gray3;
  text2.color = Colors.Gray2;
  textStatus.color = Token.getStatusColor(token.status);

  const defaultUnit = TokenTypeEnumInterpreter.convert(token.type).unity;

  function getPercentage(value: number, total: number): number {
    return ((value ?? 0) * 100) / total;
  }

  return (
    <div className={`border rounded flex flex-row p-2 mb-4 bg-white`}>
      <div className="w-40 flex flex-row justify-center items-center mr-2">
        {token.amount && token.amount > 0 ? (
          <Chart
            type={"pie"}
            height="100%"
            options={{
              chart: {
                type: "pie",
                width: "100%",
                // yaxis: {
                //   show: false,
                //   tickAmount: 3,
                // },
              },
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false,
              },
              labels: ["Disponível", "Em negociação", "Resgatados"],
              colors: [Colors.Green4, Colors.Blue1, Colors.Gray3],
            }}
            series={[
              getPercentage(token.available, token.amount),
              getPercentage(token.amount - token.available, token.amount),
              getPercentage(0, token.amount),
            ]}
          />
        ) : (
          <img src={Icons.AUChartCardIcon} alt="" />
        )}
      </div>
      <div className="flex flex-col gap-1 text-base">
        <p
          style={{ color: Colors.Gray2 }}
          className="font-light"
        >{`LOTE #${token.id}`}</p>
        <p
          style={{ color: Colors.Gray2 }}
          className="font-normal text-base mb-1"
        >
          <span>Total em wallet: </span>
          <span className="font-medium">{token.amount}</span>
          <span className="font-light"> unid</span>
        </p>
        <div
          style={{ color: Colors.Green3 }}
          className="font-normal text-sm flex flex-row whitespace-pre"
        >
          <p>Disponível: {token.available} unid</p>
        </div>
        <div
          style={{ color: Colors.Blue1 }}
          className="font-normal text-sm flex flex-row whitespace-pre"
        >
          {/* <p>À venda: {token.negociation} unid</p> */}
          <p>À venda: {token.amount - token.available} unid</p>
        </div>
        <div
          style={{ color: Colors.Purple2 }}
          className="font-normal text-sm flex flex-row whitespace-pre"
        >
          <p>Retidos: {0} unid</p>
        </div>
        <div
          style={{ color: Colors.Orange2 }}
          className="font-normal text-sm flex flex-row whitespace-pre"
        >
          <p>Queimados: {0} unid</p>
        </div>
        <p style={{ color: Colors.Gray4 }} className="font-light">
          Emissão:{" "}
          <span className="font-normal">
            {moment(token.emissionDate).format("DD/MM/YYYY")}
          </span>
        </p>
        {/* <p style={text1} className='font-light'>
                    Quantidade total: <span style={text2} className='font-medium'>{`${token.amount.toFixed(0)}`}</span>
                </p>
                <p style={{ ...text1, color: Colors.Green3 }} className='font-normal'>
                    Disponível: <span style={text2} className='font-normal'>{`${token.available.toFixed(0)}`}</span>
                </p>
                <p style={{ ...text1, color: Colors.Blue1 }} className='font-normal'>
                    Em negociação: <span style={text2} className='font-normal'>{`${(token.amount - token.available).toFixed(0)}`}</span>
                </p>
                <p style={text1} className='font-light'>
                    Resgatados: <span style={text2} className='font-normal'>{`0`}</span>
                </p> */}
      </div>
      <div className="flex flex-col flex-grow justify-between items-end">
        <button
          className={"flex flex-row justify-end"}
          onClick={() => onClick2()}
        >
          <img src={MoreIcon} alt="" />
        </button>
        <div className="flex flex-col justify-end">
          <Button
            className="asset-negotiate-btn"
            backgroundColor={
              user?.role === UserRoleEnum.ADMIN
                ? Colors.Gold4
                : Colors.UserBGButton
            }
            textColor={
              user?.role === UserRoleEnum.ADMIN ? Colors.White : Colors.Black1
            }
            onClick={onClick}
            disabled={token.available <= 0}
          >
            Negociar
          </Button>
        </div>
      </div>
    </div>
  );
};

AssetCardAdmin.defaultProps = {};

export default AssetCardAdmin;
