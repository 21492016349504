import React, { CSSProperties } from "react";
import Colors, { Gray4 } from "../../assets/Colors";

type MyProps = {
  children?: JSX.Element | Array<JSX.Element>;
  className?: string;
  title?: string;
  selection?: boolean;
  noGap?: boolean;
};

const PageTabBar: React.FC<MyProps> = ({
  children,
  className,
  title,
  selection,
  noGap,
}) => {
  var style: CSSProperties = {};

  if (!children) {
    style.paddingTop = style.paddingBottom = "1.25rem";
  }
  if (selection && !title) {
    style.paddingTop = style.paddingBottom = "0px";
  }

  return (
    <div
      className={`
				flex flex-row items-center w-full
				text-black
				${noGap ? "" : "gap-5"}
				${className}
			`}
      style={{
        borderColor: Gray4,
        color: Colors.Gray1,
        ...style,
      }}
    >
      {children ? children : <p>{title}</p>}
    </div>
  );
};

PageTabBar.defaultProps = {
  selection: true,
  noGap: false,
};

export default PageTabBar;
