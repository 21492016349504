import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import * as API from "../../api";
import Colors from "../../assets/Colors";
import * as Gifs from "../../assets/gifs";
import Button from "../../components/Buttons/Button";
import FloatInput from "../../components/Inputs/FloatInput";
import AlertModal from "../../components/Modal/AlertModal";
import UnformInputAdapt from "../../components/unform/UnformInputAdapt/UnformInputAdapt";
import ModalContext from "../../contexts/ModalProvider";
import {
  ApiErrorTypeEnum,
  ApiErrorTypeEnumInterpreter,
} from "../../models/enums/ApiErrorTypeEnum";
import RoutesPaths from "../../Routes/RoutesPaths";
import history from "../../services/history";
import i18nTranslated from "../../services/i18n";
import "./ForgotPasswordPage.css";

const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
const ForgotPasswordPage: React.FC<any> = () => {
  const formRef: React.RefObject<FormHandles> = React.createRef();

  const [textError, setTextError] = useState<string>("");
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [errorModalState, setErrorModalState] = useState<boolean>(false);
  const { handleModal, setModal } = useContext(ModalContext);

  const handleSubmit = async (inputData: any) => {
    try {
      setLoadingState(true);
      await API.signup.recoveryPassword(inputData.email);

      handleModal(AlertModal, {
        title: "Solicitação realizada",
        src: Gifs.IconOkGif,

        confirmClick: async () => {
          setModal(false);
          history.push(RoutesPaths.SIGNIN);
        },

        children: (
          <div className="flex flex-col justify-center text-center items-center">
            <p>
              Caso existe uma conta cadastrada neste documento você irá receber
              uma mensagem em seu email de cadastro.
            </p>
          </div>
        ),
      });
    } catch (error: any) {
      const errorType: ApiErrorTypeEnum = JSON.parse(error.message).message;
      setErrorModalState(true);
      setTextError(ApiErrorTypeEnumInterpreter.getMessage(errorType));
    } finally {
      setLoadingState(false);
    }
  };

  return (
    <>
      <AlertModal
        state={errorModalState}
        title="Ops, Tivemos um problema."
        src={Gifs.IconAlertGif}
        confirmClick={() => setErrorModalState(false)}
      >
        <p>O sistema identificou uma irregularidade.</p>
        <p>{textError}</p>
      </AlertModal>

      <div
        className="signin-page-container w-4/5 md:w-2/5 flex flex-col justify-center items-center"
        style={{ maxWidth: "448px" }}
      >
        <h1
          className="text-2xl font-medium mt-16 mb-10"
          style={{ color: Colors.Gray2 }}
        >
          Esqueceu sua senha?
        </h1>

        <p className="text-base" style={{ color: Colors.Gray3 }}>
          Informe o email de cadastro da sua conta:
        </p>

        <Form
          onSubmit={handleSubmit}
          ref={formRef}
          className="forgot-password-form w-80"
        >
          <UnformInputAdapt
            name="email"
            children={({ ref, error }) => (
              <FloatInput
                big={true}
                error={error}
                color="bg-white"
                className="px-2 py-2 my-3 bg-white w-full"
                placeholder="Email de cadastro"
                type="text"
                ref={ref}
              />
            )}
          />

          <p
            className="text-xs font-normal mt-4 mb-5 mx-2 text-center"
            style={{ color: Colors.Gray4 }}
          >
            Caso exista uma conta cadastrada com este email enviaremos um link
            para redefinição da senha.
          </p>

          <div className="mt-2">
            <Button
              type="submit"
              loading={loadingState}
              backgroundColor={Colors.UserBGButton}
              className="forgot-password-btn"
            >
              Recuperar senha
            </Button>
          </div>
        </Form>

        <Link
          to={RoutesPaths.SIGNIN}
          className="underline text-lg cursor-pointer mt-10 mb-2"
          style={{ color: Colors.Gray2 }}
        >
          Voltar a tela de login
        </Link>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
