import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Colors from "../../../../assets/Colors";
import { StylePatterns } from "../../../../assets/patterns_tailwind_class";
import PageSkeleton from "../../../../components/PageSkeleton/PageSkeleton";
import UnformInputAdapt from "../../../../components/unform/UnformInputAdapt/UnformInputAdapt";
import { PlataformLimitEnum, Tax } from "../../../../models";
import { TaxTypeEnum } from "../../../../models/enums";
import * as API from "./../../../../api";
import { user } from "./../../../../api";
import { LimitsList } from "../../../../components/LimitsList";
import { FeesList } from "../../../../components/FeesList";
import history from "../../../../services/history";
import { YupAdaptErrorToUnform } from "../../../../utils/yupUtils";
import ModalContext from "../../../../contexts/ModalProvider";
import AlertModal from "../../../../components/Modal/AlertModal";
import * as Gifs from "../../../../assets/gifs";
import ClientDetailsFilterBar from "../ClientDetailsFilterBar/ClientDetailsFilterBar";
import FloatCurrencyInput from "../../../../components/Inputs/FloatCurrencyInput";
import { formatCurrencyAsNumber } from "../../../../utils/formatters";

const ClientLimitTaxesPage: React.FC = () => {
  const [limits, setLimits] = useState<
    Map<PlataformLimitEnum, user.PlataformLimit>
  >(new Map());
  const [taxes, setTaxes] = useState<Map<TaxTypeEnum, Tax>>(new Map());
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [formRef] = useState<React.RefObject<FormHandles>>(useRef(null));
  const [formInitialData, setformInitialData] = useState<any | undefined>(
    undefined
  );
  const [isLoadingLimitData, setLoadingLimitData] = useState<boolean>(false);
  const { clientId } = useParams<{ clientId: string }>();
  const { handleModal, setModal } = useContext(ModalContext);
  const [clientDetails, setClientDetails] = useState<any>({});

  const setInitialData = (limits) => {
    setformInitialData({
      deposit: limits.get(PlataformLimitEnum.DEPOSIT).limit ?? 0,
      withdraw: limits.get(PlataformLimitEnum.WITHDRAW).limit ?? 0,
      goldBuy: limits.get(PlataformLimitEnum.GOLD_BUY).limit ?? 0,
      goldSell: limits.get(PlataformLimitEnum.GOLD_SELL).limit ?? 0,
    });
  };

  useEffect(() => {
    loadClientDetails();
    getLimits();
    getTotalFees();
  }, []);

  const loadClientDetails = async () => {
    const data = await API.admin.loadClient(clientId);
    setClientDetails(data);
  };

  const getLimits = async () => {
    setLoadingLimitData(true);

    const data = await API.user.getLimits(clientId);
    setLimits(data);
    setInitialData(data);

    setLoadingLimitData(false);
  };

  const getTotalFees = async () => {
    await API.tax.getTotalFees().then((data) => setTaxes(data));
  };

  const renderEditForm = () => {
    return (
      <PageSkeleton
        isAdmin
        useContentCard
        goBackBtn
        filterBar={
          <ClientDetailsFilterBar
            clientName={clientDetails?.name}
            pageTitle="Limites e taxas"
          />
        }
        button={{
          label: "Salvar novos valores",
          onClick: () => {
            if (formRef.current) formRef.current.submitForm();
          },
        }}
        button2={{
          label: "Cancelar edição",
          onClick: () => setShowEditForm(false),
        }}
      >
        <div
          className={
            "flex flex-col w-full items-center " + StylePatterns.ELEMENT_WIDTH
          }
        >
          <span
            className="text-xl font-normal flex justify-center items-center mt-5 gap-2"
            style={{ color: Colors.Gray3 }}
          >
            LIMITES E TAXAS
          </span>

          <Form
            className="account-form text-lg font-medium w-full"
            ref={formRef}
            onSubmit={handleFormSubmit}
            initialData={formInitialData}
            onChange={() => {}}
          >
            <UnformInputAdapt
              name="deposit"
              children={({ ref, error, defaultValue }) => (
                <FloatCurrencyInput
                  big={true}
                  defaultValue={defaultValue}
                  error={error}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="Depósitos (mensais)"
                  ref={ref}
                  onChange={(value) => {
                    ref.current.value = value;
                  }}
                />
              )}
            />
            <UnformInputAdapt
              name="withdraw"
              children={({ ref, error, defaultValue }) => (
                <FloatCurrencyInput
                  big={true}
                  defaultValue={defaultValue}
                  error={error}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="Saques (mensais):"
                  ref={ref}
                />
              )}
            />
            <UnformInputAdapt
              name="goldBuy"
              children={({ ref, error, defaultValue }) => (
                <FloatCurrencyInput
                  big={true}
                  defaultValue={defaultValue}
                  error={error}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="Ordens de compra mensais"
                  ref={ref}
                  onChange={(value) => {
                    ref.current.value = value;
                  }}
                />
              )}
            />
            <UnformInputAdapt
              name="goldSell"
              children={({ ref, error, defaultValue }) => (
                <FloatCurrencyInput
                  big={true}
                  defaultValue={defaultValue}
                  error={error}
                  color="bg-white"
                  className="z-1 block p-2 w-full appearance-none focus:outline-none bg-transparent h-full"
                  placeholder="Ordens de venda mensais"
                  ref={ref}
                  onChange={(value) => {
                    ref.current.value = value;
                  }}
                />
              )}
            />
          </Form>
        </div>
      </PageSkeleton>
    );
  };

  const handleFormSubmit = async (input: any, { reset }) => {
    if (!formRef.current) return null;

    try {
      const { deposit, withdraw, goldBuy, goldSell } = input;
      const data = [
        {
          type: PlataformLimitEnum.DEPOSIT,
          value: formatCurrencyAsNumber(deposit),
        },
        {
          type: PlataformLimitEnum.WITHDRAW,
          value: formatCurrencyAsNumber(withdraw),
        },
        {
          type: PlataformLimitEnum.GOLD_BUY,
          value: formatCurrencyAsNumber(goldBuy),
        },
        {
          type: PlataformLimitEnum.GOLD_SELL,
          value: formatCurrencyAsNumber(goldSell),
        },
      ];

      await API.clients.updateLimits(clientId, data);

      handleModal(AlertModal, {
        src: Gifs.IconOkGif,
        title: "Conta atualizada com sucesso!",
        confirmClick: () => window.location.reload(),
        children: (
          <h3 className="my-6">
            A conta agora possui novos limites e taxas de uso.
          </h3>
        ),
      });
    } catch (error) {
      formRef.current.setErrors(YupAdaptErrorToUnform(error as any));

      handleModal(AlertModal, {
        src: Gifs.IconAlertGif,
        title: "Erro ao atualizar limites!",
        confirmClick: () => window.location.reload(),
        children: (
          <h3 className="my-6">
            Houve um erro ao atualizar os limites do usuário
          </h3>
        ),
      });
    }
  };

  return (
    <>
      {showEditForm ? (
        renderEditForm()
      ) : (
        <PageSkeleton
          isAdmin
          useContentCard
          goBackBtn
          filterBar={
            <ClientDetailsFilterBar
              clientName={clientDetails?.name}
              pageTitle="Limites e taxas"
            />
          }
          button={{
            label: "Alterar limites e taxas",
            onClick: () => setShowEditForm(true),
            disabled: isLoadingLimitData,
          }}
          button2={{
            label: "Voltar ao resumo da conta",
            onClick: history.goBack,
          }}
        >
          <div className={StylePatterns.ELEMENT_WIDTH}>
            <LimitsList limits={limits} loading={isLoadingLimitData} />
            <FeesList taxs={taxes} loading={isLoadingLimitData} />
          </div>
        </PageSkeleton>
      )}
    </>
  );
};

export default ClientLimitTaxesPage;
