import Currency from "currency.js";
import moment from "moment";
import React, { useContext, useEffect, useState, useCallback } from "react";
import * as API from "../../../api";
import Colors, { BorderColor } from "../../../assets/Colors";
import * as Gifs from "../../../assets/gifs";
import AssetContext from "../../../contexts/AssetProvider";
import BalanceContext from "../../../contexts/BalanceProvider";
import ModalContext from "../../../contexts/ModalProvider";
import { GoldToken, Match, Order } from "../../../models";
import {
  TokenTypeEnumInterpreter,
  PlataformLimitEnum,
} from "../../../models/enums";
import RoutesPaths from "../../../Routes/RoutesPaths";
import history from "../../../services/history";
import { formatToCurrency } from "../../../utils/formatters";
import Button from "../../Buttons/Button";
import UnderlineButton from "../../Buttons/UnderlineButton";
import Divider from "../../Divider/Divider";
import AlertModal from "../AlertModal";
import Modal from "../Modal";
import "./BuyToken.css";
import MoneyInput from "../../Inputs/MoneyInput";
import CurrencyInput from "../../Inputs/CurrencyInput/CurrencyInput";
import LimitsContext from "../../../contexts/LimitsProvide";
import useEventListener from "@use-it/event-listener";

type MyProps = {
  state: boolean;
  order?: Order;
  backBlur?: boolean;
  changeState(state: boolean): any;
};

const hasOneTab: any = (options: Array<any>) => {
  return options.length === 1;
};

const SelectTable = ({
  options,
  current,
  onClick,
}: {
  onClick?: (p: any) => any;
  options: Array<{ label: string; value: any; onClick?: (p: any) => any }>;
  current: any;
}) => {
  return (
    <div className="flex flex-row text-lg font-medium w-full">
      {options.map(({ label, value }, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              if (onClick) onClick(value);
            }}
            className={`
              cursor-pointer
              flex flex-col flex-1 justify-center py-5
              ${current === value && !hasOneTab ? "border-b-2" : "border-b"}
            `}
            style={{
              borderColor: hasOneTab
                ? Colors.Gray2
                : current === value
                ? Colors.Green2
                : Colors.Gray3,
            }}
          >
            <p>{label}</p>
          </div>
        );
      })}
    </div>
  );
};

enum SellType {
  OPEN = "OPEN",
  DIRECT = "DIRECT",
}

const CustomInput: React.FC<{
  maxValue?: number;
  tokenAmount: string;
  setTokenAmount: (p: string) => any;
  prefix?: any;
  suffix?: any;
}> = ({ maxValue, tokenAmount, setTokenAmount, prefix, suffix }) => {
  return (
    <div className="amount-input flex flex-row mt-2 mb-5 rounded overflow-hidden">
      {/* <div
        className='amount-input-btn border border-r-0'
        style={{ backgroundColor: Colors.Gray5, borderColor: BorderColor }}
        onClick={() => setTokenAmount((Number(tokenAmount) - 0.01).toFixed(2))}
      >
        <p className='font-medium text-xl' style={{ color: Colors.Gray2 }}>-</p>
      </div> */}

      <div
        className="amount-middle-input flex-grow flex flex-col border overflow-hidden"
        style={{ backgroundColor: "#FFFFFF", borderColor: BorderColor }}
      >
        <div className="flex flex-row w-full font-medium text-xl justify-center">
          <p className="font-medium text-xl" style={{ color: Colors.Gray2 }}>
            {prefix}
          </p>
          <div className="flex-grow-0">
            <input
              type="number"
              className="text-center font-medium text-xl w-full h-full outline-none mycustominput"
              style={{ color: Colors.Gray2 }}
              value={tokenAmount}
              onChange={({ target }) => {
                let value: string = target.value || "0.01"; // 0.01 caso valor esteja vazio

                let intPart: string = value.split(".")[0] || "0";
                let floatPart: string = value.split(".")[1];
                if (floatPart?.length > 2) {
                  // move primeiro caracter do float para parte do int
                  // Ex: 9.00 => 9.001 => 90.01
                  intPart += floatPart[0];
                  floatPart = floatPart.substring(1);
                  value = intPart + "." + floatPart;
                } else if (floatPart?.length === 1) {
                  // move parte int para a parte float
                  // Ex: 91,00 => backspace no ultimo 0 => 91,0 => 9,10 => backspace denovo => 0,91
                  floatPart = intPart[intPart.length - 1] + floatPart;
                  intPart = intPart.substring(0, intPart.length - 1) || "0";
                  value = intPart + "." + floatPart;
                }

                let newValue: number = Number(value);
                if (maxValue) {
                  if (newValue === 0) {
                    newValue = 0.01;
                  } else if (newValue > maxValue) {
                    newValue = maxValue;
                  }
                }

                setTokenAmount(newValue.toFixed(2));
              }}
            />
          </div>
          {/* <p className='font-medium text-xl' style={{ color: Colors.Gray2 }}>{suffix}</p> */}
        </div>
      </div>

      {/* <div
        className='amount-input-btn border border-l-0'
        style={{ backgroundColor: Colors.Gray5, borderColor: BorderColor }}
        onClick={() => setTokenAmount((Number(tokenAmount) + 0.01).toFixed(2))}
      >
        <p className='font-medium text-3xl' style={{ color: Colors.Gray2 }}>+</p>
      </div> */}
    </div>
  );
};

const BuyToken: React.FC<MyProps> = ({
  state,
  order,
  changeState,
  backBlur,
}) => {
  const assetContext = useContext(AssetContext);
  const { limits } = useContext(LimitsContext);

  const { handleModal, setModal } = useContext(ModalContext);
  const { balance, updateBalance } = useContext(BalanceContext);

  const [_lastMatch, _setLastMatch] = useState<Match>();
  const lastNegociationValue =
    (_lastMatch?.amount ?? 0) / (_lastMatch?.tokenQuantity ?? 0);

  // Must devide by 100 every calculation logic due to currency input's display value
  const [_amount, _setAmount] = useState<number>(0);

  const [mynode] = useState(React.createRef<HTMLDivElement>());
  const [successState, setSuccessState] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sellType, setNegociationType] = useState<SellType>(SellType.OPEN);
  const [closeModalClassname, setCloseModalClassname] = useState<string>("");

  const token = order?.tokens[0];

  const availableLimit: number = (() => {
    const maxLimit: number =
      limits.get(PlataformLimitEnum.GOLD_BUY)?.limit ?? 0;
    const limitConsumed: number =
      limits.get(PlataformLimitEnum.GOLD_BUY)?.consumed ?? 0;

    let currentBalance = balance.currency.available;
    let buyLimit = maxLimit - limitConsumed;

    return currentBalance < buyLimit ? currentBalance : buyLimit;
  })();

  useEffect(() => {
    let amountValue = Number(_amount / 100);
    // if (order?.remainingTokens && amountValue > order.remainingTokens) {
    //   amountValue = order.remainingTokens;
    // }
    _setAmount(Number(amountValue * 100));
  }, []);

  useEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === "Escape" && state) handleCloseModal();
  });

  const criarOrdem = async () => {
    if (order) {
      try {
        setLoading(true);
        const createOrderRequest = {
          price: getTokenTotalValue(),
          tokenId: Number(token?.id),
          tokenQuantity: Number(_amount / 100),
        };

        const data: GoldToken = await API.book.createBuyOrderByToken({
          ...createOrderRequest,
          dryRun: true,
          sellOrderId: order.id,
        });

        setModal(false);
        handleModal(Modal, {
          title: "Criar ordem de compra",
          titleColor: "white",
          titleBgColor: Colors.Green1,
          state: true,
          confirmText: "CONFIRMAR",
          confirmButtonClick: async () => {
            await API.book.createBuyOrderByToken({
              ...createOrderRequest,
              dryRun: false,
              sellOrderId: order.id,
            });
            updateBalance(true);
            setLoading(false);
            handleModal(AlertModal, {
              state: true,
              src: Gifs.IconOkGif,
              title: "Ordem de compra realizada!",
              confirmText: "OK",

              confirmClick: () => window.location.reload(),
              children: (
                <div className="flex flex-col justify-center text-center items-center">
                  <p className="py-2">
                    Acompanhe o andamento desta operação em sua lista de ordens.
                  </p>
                </div>
              ),
            });
          },
          cancelButtonClick: () => {
            setLoading(false);
            handleCloseModal();
          },
          children: (
            <div
              className="flex flex-col justify-center text-justify items-start font-light mx-7 w-96"
              style={{ color: Colors.Gray3 }}
            >
              <p>
                QTD negociada:
                <span className="font-normal">
                  {formatToCurrency((data as any).tokenQuantity)}{" "}
                  {token?.metadata?.properties?.unit}
                </span>
              </p>
              <p>
                Preço por unidade:
                <span className="font-normal">
                  {formatToCurrency(data.amount)} BRL
                </span>
              </p>
              {/* <p>Data de liberação: <span className='font-normal'>{moment(token.redemptionDate).format('DD/MM/YYYY')}</span></p> */}
              <p className="mt-3">
                Valor total:{" "}
                <strong>
                  R$
                  {formatToCurrency((data as any).tokenQuantity * data.amount)}
                </strong>
              </p>
            </div>
          ),
        });
      } catch (error: any) {
        let errorData = {
          title: "Ops... Token indisponível",
          body: (
            <div className="flex flex-col justify-center text-center items-center">
              <p className="py-2">
                O token pode estar sendo negociado neste momento ou não esta
                mais disponível para compra.
              </p>
              <p className="py-2">
                Por favor acesse novamente o balcão para verificar a listagem de
                recebíveis disponíveis atualmente.
              </p>
            </div>
          ),
        };

        if (
          error.message.includes(
            "user does not have limit to create new gold buy order"
          )
        ) {
          errorData = {
            title: "Limite de negociação excedido.",
            body: (
              <div className="flex flex-col justify-center text-center items-center">
                <p className="py-2">
                  Verifique os limites mensais de compra e venda da sua conta.
                </p>
              </div>
            ),
          };
        }

        setLoading(false);
        handleModal(AlertModal, {
          title: errorData.title,
          src: Gifs.IconAlertGif,
          titleColor: Colors.Red4,

          confirmClick: async () => handleCloseModal(),

          children: errorData.body,
        });
      }
    }
  };

  const { unity } = TokenTypeEnumInterpreter.convert(token?.type);

  const handleCloseModal = (): void => {
    setCloseModalClassname("buy-token-close");
    setTimeout(() => {
      setCloseModalClassname("");
      setModal(false);
      changeState(false);
      document.body.style.overflow = "unset";
    }, 500);
  };

  const getTokenTotalValue = () => {
    return Currency(_amount / 100).multiply(Number(order?.amount)).value;
  };

  const goToDeposit = () => {
    document.body.style.overflow = "unset";
    history.push(RoutesPaths.DEPOSIT);
  };

  const handleAmountChange = useCallback((val) => {
    if (order && Number(val) / 100 > order?.remainingTokens) {
      _setAmount(order?.remainingTokens * 100);
    } else {
      _setAmount(val);
    }
  }, []);

  return (
    <>
      <div
        className="fixed z-10 inset-0 overflow-y-auto"
        style={{ display: state ? "block" : "none", fontSize: "1.5rem" }}
        onClick={({ target }) => {
          if (!mynode.current?.contains(target as any)) {
            handleCloseModal();
          }
        }}
      >
        <div className="flex items-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 w-full">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div
              className={`absolute inset-0 ${
                backBlur ? "bg-gray-500 opacity-75" : ""
              }`}
            ></div>
          </div>

          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className={`buy-token
                        ${closeModalClassname}
                        flex flex-col align-bottom
                        text-white
                        shadow-xl overflow-hidden
                        transform transition-all
                        absolute bottom-0 left-0 right-0
                        w-full`}
            style={{ color: Colors.Gray5, backgroundColor: Colors.Black4 }}
            ref={mynode}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex flex-col flex-grow items-center w-full">
              <SelectTable
                current={sellType}
                onClick={(p) => setNegociationType(p)}
                options={[{ label: "Ordem de compra", value: SellType.OPEN }]}
              />
              <div className="flex flex-col flex-grow justify-around items-center">
                <div className="mt-5">
                  <p className="text-2xl font-bold" style={{ color: "white" }}>
                    {token?.metadata.symbol} - {token?.metadata.name}
                  </p>
                  <p
                    className="text-lg font-normal"
                    style={{ color: Colors.Gray4 }}
                  >
                    LOTE #{token?.id}
                  </p>
                </div>

                <div className="flex flex-col gap-4 my-10 sm:my-14 sm:flex-row sm:gap-0">
                  <span
                    className="font-normal"
                    style={{ fontSize: "26px", color: "white" }}
                  >
                    Preço unitário:
                  </span>
                  <span>
                    <span
                      className="font-medium"
                      style={{ fontSize: "28px", color: "white" }}
                    >
                      {" "}
                      {formatToCurrency(order?.amount)}
                    </span>
                    <span className="font-light" style={{ fontSize: "28px" }}>
                      {" "}
                      BRL
                    </span>
                  </span>
                </div>

                <div>
                  <p className="text-2xl font-normal">Quantidade desejada:</p>
                  {/* <CustomInput
                    maxValue={order?.remainingTokens}
                    tokenAmount={_amount}
                    setTokenAmount={_setAmount}
                    suffix={unity}
                  /> */}
                  <div className="amount-input flex flex-row my-2 rounded">
                    <div
                      className="flex-grow flex flex-col w-5 border items-center justify-center"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderColor: BorderColor,
                      }}
                    >
                      {/* <MoneyInput
                        prefix=""
                        value={_amount.value}
                        defaultValue={_amount.value}
                        onChangeValue={(p) => _setAmount(Currency(p))}
                        maxValue={order?.remainingTokens}
                        style={{
                            color: Colors.Gray2,
                            backgroundColor: 'transparent',
                        }}
                        className="z-1 block p-2 w-full font-medium appearance-none focus:outline-none text-center"
                      /> */}
                      <CurrencyInput
                        value={_amount}
                        onValueChange={handleAmountChange}
                        style={{
                          color: Colors.Gray2,
                          backgroundColor: "transparent",
                        }}
                        className="z-1 block p-2 w-full font-medium appearance-none focus:outline-none text-center"
                      />
                    </div>
                  </div>
                  <p
                    style={{ color: Colors.UserBGButton }}
                    className="text-sm font-normal"
                  >
                    Tokens disponíveis:{" "}
                    {formatToCurrency(order?.remainingTokens)}{" "}
                    {token?.metadata?.properties?.unit}
                  </p>
                </div>

                <div>
                  <p
                    style={{ color: Colors.Green3 }}
                    className="mt-4 text-2xl font-bold"
                  >
                    <span className="font-normal">Valor total:</span>{" "}
                    <strong>
                      {formatToCurrency(getTokenTotalValue())} BRL
                    </strong>
                  </p>
                  {getTokenTotalValue() > availableLimit ? (
                    <p
                      style={{ color: Colors.Red6 }}
                      className="sm:mt-2 text-sm font-normal"
                    >
                      Seu saldo atual é insuficiente:{" "}
                      {`(${formatToCurrency(availableLimit).trim()} BRL)`}
                    </p>
                  ) : (
                    <p
                      style={{ color: Colors.Green3 }}
                      className="sm:mt-2 text-sm font-light"
                    >
                      Seu saldo atual é:{" "}
                      {`${formatToCurrency(availableLimit)} BRL`}
                    </p>
                  )}
                </div>

                <div className="flex flex-col justify-center my-5 text-base">
                  {getTokenTotalValue() > availableLimit ? (
                    <Button
                      onClick={goToDeposit}
                      backgroundColor={Colors.Gray3}
                      textColor={"white"}
                      style={{
                        padding: "2px 4px",
                        width: "300px",
                        height: "50px",
                      }}
                    >
                      Depositar saldo
                    </Button>
                  ) : (
                    <Button
                      loading={isLoading}
                      onClick={criarOrdem}
                      backgroundColor={Colors.Green3}
                      borderColor={Colors.Green1}
                      textColor={"white"}
                      style={{
                        padding: "2px 4px",
                        width: "300px",
                        height: "50px",
                      }}
                      disabled={_amount < 0.01}
                    >
                      Comprar
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col h-15 w-full items-center">
              <Divider top="mt-0" bottom="mb-0" color={Colors.Gray2} />
              <UnderlineButton
                className="flex flex-row flex-grow justify-center my-0 text-lg font-medium"
                style={{ color: Colors.Gray3 }}
                onClick={handleCloseModal}
              >
                Cancelar negociação
              </UnderlineButton>
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        state={successState}
        changeState={() => setSuccessState(!successState)}
        title="Token disponibilizado para venda!"
        src={Gifs.IconOkGif}
      >
        Acompanhe o andamento em sua lista de ordens.
      </AlertModal>
    </>
  );
};

export default BuyToken;
