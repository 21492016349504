import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";

/// Assets
import * as Icons from "../../assets/icons";
import history from "../../services/history";
import * as Images from "./../../assets/images";
import RoutesPaths from "../../Routes/RoutesPaths";
import UserContext from "../../contexts/UserProvider";
import { formatToCurrency } from "../../utils/formatters";
import BalanceContext from "../../contexts/BalanceProvider";
import Colors from "../../assets/Colors";

/// Components
import SideBar from "../Modal/SideBar";
import AccoutTolltip from "../Modal/AccoutTolltip";
import { isAuthenticated, makeLogout } from "../../services/jwt";
import { UserRoleEnum } from "../../models/enums/UserRoleEnum";

import "./TopBar.css";
import Modal from "../Modal/Modal";

const TopBar: React.FC<{ showBlankTopBar?: boolean }> = ({
  showBlankTopBar,
}) => {
  const { user, setUserShowWallet } = useContext(UserContext);
  const userContext = useContext(UserContext);
  const { balance } = useContext(BalanceContext);

  const [active, setActive] = useState<boolean>(false);
  const [modalState, setModalState] = useState<boolean>(false);
  const [sideBarState, setSideBarState] = useState<boolean>(false);
  const [modalExit, setModalExit] = useState<boolean>(false);

  const handleClick = () => {
    setActive(!active);
    setSideBarState(!sideBarState);
  };

  const closeSideBar = () => {
    setActive(false);
    setSideBarState(false);
  };

  const showWallet = useMemo(
    () => (user?.showWallet === false ? false : true),
    [user?.showWallet]
  );

  function renderLinks(
    key: string,
    label: string,
    underLabel?: JSX.Element,
    onlyMobile?: boolean,
    relatedPaths?: Array<string>
  ): JSX.Element {
    let isRelatedPath = false;
    relatedPaths?.forEach((path) => {
      isRelatedPath = isRelatedPath || history.location.pathname.includes(path);
    });
    const isActive: boolean =
      history.location.pathname === key || isRelatedPath;

    return (
      <div
        onClick={closeSideBar}
        className={`${active ? "w-full py-5" : ""} ${
          onlyMobile ? "md:hidden" : ""
        }`}
        style={{
          borderBottom: active ? "1px solid rgba(255, 255, 255, 0.5)" : `none`,
        }}
      >
        <Link to={key} className={`${active ? "w-full" : ""}`}>
          <div className="flex flex-col">
            <div
              className={
                `lg:inline-flex lg:w-auto w-full text-xl lg:text-base px-3 text-white font-medium items-end justify-center hover:text-white ` +
                (isActive ? "" : "text-opacity-60")
              }
            >
              {label}
            </div>
          </div>
        </Link>
        <div
          className={`ml-3 text-white` + (isActive ? "" : " text-opacity-60")}
        >
          {underLabel}
        </div>
      </div>
    );
  }

  if (!isAuthenticated() || showBlankTopBar) {
    return (
      <nav
        className="flex flex-row items-center justify-center p-3 sticky top-0 z-20"
        style={{
          borderBottom: `0.5px solid ${Colors.Gray3}`,
          backgroundColor:
            user?.role === UserRoleEnum.ADMIN ? Colors.BaseADM : Colors.Black1,
        }}
      >
        <Link to="/" className="">
          <div className="inline-flex items-center">
            <img
              id="navbar-logo"
              className="h-10"
              src={Images.LogoIcon}
              alt="logo"
            />
          </div>
        </Link>
      </nav>
    );
  }

  const renderRoutes = () => {
    if (user?.role === UserRoleEnum.ADMIN) {
      return (
        <>
          {renderLinks(RoutesPaths.DASHBOARD_ADMIN, "Dashboard")}
          {renderLinks(
            RoutesPaths.MEUSATIVOS_ADMIN,
            "Tokens",
            undefined,
            undefined,
            [RoutesPaths.MEUSATIVOS_ADMIN, RoutesPaths.MEUSATIVOSDETALHES_ADMIN]
          )}
          {/* { renderLinks(RoutesPaths.MERCADO        , "Exchange"   ) } */}
          {renderLinks(RoutesPaths.MINHASORDENS, "Ordens")}
          {renderLinks(
            RoutesPaths.ADMIN_CLIENTS,
            "Usuários",
            undefined,
            undefined,
            [RoutesPaths.ADMIN_CLIENTS_DETAILS]
          )}
          {renderLinks(
            RoutesPaths.BALANCE,
            "Meu saldo",
            <div className="flex flex-row mt-2">
              <img
                className="eyeToggleIcon-mobile"
                onClick={toogleShowWallet}
                src={
                  showWallet ? Icons.EyeToggleOnIcon : Icons.EyeToggleOffIcon
                }
                alt=""
              />
              <span className="font-normal text-xs h-4 pl-1">
                {showWallet ? (
                  `${formatToCurrency(balance.currency?.full)} BRL`
                ) : (
                  <span
                    className="font-normal text-2xl"
                    style={{ lineHeight: "1rem" }}
                  >
                    ••••
                  </span>
                )}
              </span>
            </div>,
            true
          )}
        </>
      );
    } else {
      return (
        <>
          {renderLinks(RoutesPaths.DASHBOARD, "Dashboard")}
          {renderLinks(RoutesPaths.TOKENS, "Ofertas", undefined, undefined, [
            RoutesPaths.TOKEN_DETAILS,
          ])}
          {renderLinks(RoutesPaths.MINHASORDENS, "Minhas ordens")}
          {renderLinks(
            RoutesPaths.MEUSATIVOS,
            "Meus tokens",
            undefined,
            undefined,
            [RoutesPaths.MEUSATIVOSDETALHES]
          )}
          {renderLinks(
            RoutesPaths.BALANCE,
            "Meu saldo",
            <div className="flex flex-row mt-2">
              <img
                className="eyeToggleIcon-mobile"
                onClick={toogleShowWallet}
                src={
                  showWallet ? Icons.EyeToggleOnIcon : Icons.EyeToggleOffIcon
                }
                alt=""
              />
              <span className="font-normal text-xs h-4 pl-1">
                {showWallet ? (
                  `${formatToCurrency(balance.currency?.full)} BRL`
                ) : (
                  <span
                    className="font-normal text-2xl"
                    style={{ lineHeight: "1rem" }}
                  >
                    ••••
                  </span>
                )}
              </span>
            </div>,
            true
          )}
        </>
      );
    }
  };

  const toogleShowWallet = (e) => {
    e.stopPropagation();
    setUserShowWallet(
      user?.showWallet === undefined ? false : !user?.showWallet
    );
  };

  return (
    <div className="font-top-bar-sans sticky top-0 z-10">
      <Modal
        width={"!w-1"}
        state={modalExit}
        cancelButtonClick={() => setModalExit(false)}
        confirmButtonClick={makeLogout}
        title={"Encerrar sessão?"}
        titleColor={"black"}
        confirmText="LOGOUT"
        cancelText="CANCELAR"
      >
        <p className="text-center" style={{ color: "#828282" }}>
          Após realizar o logout você será desconectado e redirecionado para a
          tela de login da plataforma.
        </p>
        <p className="text-center p-3" style={{ color: "#828282" }}>
          Deseja encerrar sua sessão?
        </p>
      </Modal>
      <SideBar
        state={sideBarState}
        closeSideBar={closeSideBar}
        active={active}
        renderRoutes={renderRoutes}
      />

      <nav
        className="flex items-center flex-wrap p-4 h-18"
        style={{
          borderBottom: `0.5px solid ${Colors.Gray3}`,
          backgroundColor:
            user?.role === UserRoleEnum.ADMIN ? Colors.BaseADM : Colors.Black1,
        }}
      >
        <button
          className="inline-flex p-3 rounded md:hidden text-white hover:text-white outline-none"
          onClick={handleClick}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        <Link
          to={
            user?.role === UserRoleEnum.ADMIN
              ? RoutesPaths.HOME
              : RoutesPaths.DASHBOARD
          }
          className="flex-none inline-flex items-center mx-2 h-full gap-3"
        >
          <img className="h-10" src={Images.LogoIcon} alt="logo" />
          {user?.role === UserRoleEnum.ADMIN && (
            <p
              className="px-2 py-1 rounded-md font-bold"
              style={{
                backgroundColor: "white",
                color:
                  user?.role === UserRoleEnum.ADMIN
                    ? Colors.BaseADM
                    : Colors.BaseUser,
              }}
            >
              ADMIN
            </p>
          )}
        </Link>

        {/* Nav Bar */}
        {/*Note that in this div we will use a ternary operator to decide whether or not to display the content of the div  */}
        <div
          className={`items-menu w-full md:flex-grow inline-flex md:w-auto md:pl-6`}
        >
          <div className="md:inline-flex md:flex-row md:ml-auto md:w-auto w-full flex flex-col md:h-auto md:space-x-5 flex-grow items-center">
            {renderRoutes()}
          </div>

          {/* Right bar */}
          <div className={`flex flex-row flex-none space-x-2`}>
            <AccoutTolltip
              state={modalState}
              email={userContext.user?.email ?? "email@email.com"}
              clickExit={() => setModalExit(true)}
              changeState={(state) => {
                setModalState(state);
              }}
            />
            <div
              className="flex flex-row h-full justify-center items-center cursor-pointer"
              onClick={() => history.push(RoutesPaths.BALANCE)}
            >
              <img
                src={Icons.WalletSmallIcon}
                style={{ height: "15px", width: "15px", marginRight: "5px" }}
                alt=""
              />
              <p className="text-white text-sm">
                {showWallet ? (
                  `${formatToCurrency(balance.currency?.full)} BRL`
                ) : (
                  <span className="text-2xl">••••</span>
                )}
              </p>
              <img
                className="eyeToggleIcon"
                onClick={toogleShowWallet}
                src={
                  showWallet ? Icons.EyeToggleOnIcon : Icons.EyeToggleOffIcon
                }
                alt=""
              />
            </div>

            <div
              className="
                            rounded-full h-10 w-10 cursor-pointer
                            flex items-center justify-center border-opacity-50
                            border-2 border-white text-white
                        "
              onClick={() => setModalState(!modalState)}
              style={{
                backgroundColor:
                  user?.role === UserRoleEnum.ADMIN
                    ? Colors.BaseADM
                    : Colors.Black1,
              }}
            >
              {userContext.user?.name[0]?.toUpperCase() ?? "U"}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default TopBar;
