import { DepositMethodsEnum } from "../models/enums/DepositMethods";

export const withdrawOptions = [
  {
    enable: true,
    type: DepositMethodsEnum.PIX,
    subLabel: "Transferir saldo via pix.",
  },
  // { enable: true, type: DepositMethodsEnum.TED, subLabel: 'Transferir saldo para uma conta bancária.' },
  // { enable: false, type: DepositMethodsEnum.CREDIT, subLabel: 'Retirar saldo usando cartão de crédito.' },
  // { enable: false, type: DepositMethodsEnum.BITCOIN, subLabel: 'Retirar saldo via wallet bitcoin.' },
  // { label: 'Ethereum (ETH)', subLabel: 'Depositar saldo via wallet ethereum' },
];
