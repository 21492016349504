import React, { useEffect, useRef, useState, useContext } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

/// Utils
import { adressValidation } from "../../../validations/adressValidation";
import { UnFormValidate, YupAdaptErrorToUnform } from "../../../utils/yupUtils";
import UnformInputAdapt from "../../../components/unform/UnformInputAdapt/UnformInputAdapt";

/// Assets
import * as API from "../../../api";
import history from "../../../services/history";
import RoutesPaths from "../../../Routes/RoutesPaths";
import Colors from "../../../assets/Colors";
import * as Gifs from "../../../assets/gifs";

import { StylePatterns } from "../../../assets/patterns_tailwind_class";
import "./../style.css";

/// Components../../../components/PageSkeleton/PageSkeleton
import AlertModal from "../../../components/Modal/AlertModal";
import FloatInput from "../../../components/Inputs/FloatInput";
import UserContext from "../../../contexts/UserProvider";
import PageBoardSkeleton from "../../../components/PageBoardSkeleton/PageBoardSkeleton";
import inputValidations from "../../../utils/inputValidations";

type msgErrorProps = {
  uf: string;
  city: string;
  neighborhood: string;
  street: string;
  streetNumber: string;
};

const msgErrorInitialState = {
  uf: "",
  city: "",
  neighborhood: "",
  street: "",
  streetNumber: "",
};

const AddressPage: React.FC<any> = () => {
  const userContext = useContext(UserContext);
  const { setUser } = useContext(UserContext);
  const [formRef] = useState<React.RefObject<FormHandles>>(useRef(null));

  const [modalSuccessState, setmodalSuccessState] = useState<boolean>(false);
  const [modalErrorState, setmodalErrorState] = useState<boolean>(false);
  const [firstSubmit, setfirstSubmit] = useState<boolean>(true);

  const [formInitialData, setformInitialData] = useState<any | undefined>(
    undefined
  );
  const [msgError, setMsgError] = useState<msgErrorProps>(msgErrorInitialState);

  setInitialData();
  function setInitialData() {
    if (userContext.user && !formInitialData) {
      setformInitialData({
        pais: userContext.user.address.country,
        cep: userContext.user.address.postalCode,
        uf: userContext.user.address.state,
        cidade: userContext.user.address.city,
        bairro: userContext.user.address.neighborhood,
        logradouro: userContext.user.address.street,
        numero: userContext.user.address.number,
        complemento: userContext.user.address.complement,
      });
    }
  }

  async function updateUser() {
    try {
      const data = await API.user.getUserInfo();
      setUser(data);
    } catch (error) {
      // console.error(error);
    }
  }

  const getFormData = () => {
    const bairro = formRef?.current?.getFieldValue("bairro");
    const cep = formRef?.current?.getFieldValue("cep");
    const cidade = formRef?.current?.getFieldValue("cidade");
    const complemento = formRef?.current?.getFieldValue("complemento");
    const logradouro = formRef?.current?.getFieldValue("logradouro");
    const numero = formRef?.current?.getFieldValue("numero");
    const pais = formRef?.current?.getFieldValue("pais");
    const uf = formRef?.current?.getFieldValue("uf");
    return {
      bairro,
      cep,
      cidade,
      complemento,
      logradouro,
      numero,
      pais,
      uf,
    };
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && buttonSubmitProps.disabled === false) {
      const formData = getFormData();
      handleFormSubmit(formData);
    }
  };

  const handleFormSubmit = async (input: any) => {
    // setLoading(true);
    setButtonSubmitProps({
      ...buttonSubmitProps,
      loading: true,
    });

    if (!formRef.current) return null;

    if (firstSubmit) {
      setfirstSubmit(false);
    }

    try {
      input.cep = input.cep.replaceAll("-", "");

      const schema = adressValidation;
      const data = await schema.validate(input, {
        abortEarly: false,
      });

      await updateData(data);
    } catch (error) {
      // formRef.current.setErrors(
      // 	YupAdaptErrorToUnform(error as any)
      // );
    } finally {
      // setLoading(false);
      setButtonSubmitProps({
        ...buttonSubmitProps,
        loading: false,
      });
    }
  };

  const updateData = async (data: any) => {
    try {
      const body = {
        address: {
          street: data.logradouro,
          number: data.numero,
          neighborhood: data.bairro,
          city: data.cidade,
          state: data.uf,
          complement: data.complemento,
          postalCode: data.cep,
          country: data.pais,
        },
      };
      await API.user.updateAddressInfo(body, userContext.user?.id as string);
      await updateUser();
      setmodalSuccessState(true);
    } catch (error) {
      setmodalErrorState(true);
    }
  };

  const [buttonSubmitProps, setButtonSubmitProps] = useState({
    label: "Atualizar endereço",
    onClick() {
      if (formRef.current) formRef.current.submitForm();
    },
    className: "skeleton-btn",
    disabled: false,
    loading: false,
  });

  function validateFormAndSetButtonDisabled() {
    const uf = formRef?.current?.getFieldValue("uf");
    const cep = formRef?.current?.getFieldValue("cep");
    const cidade = formRef?.current?.getFieldValue("cidade");
    const bairro = formRef?.current?.getFieldValue("bairro");
    const numero = formRef?.current?.getFieldValue("numero");
    const logradouro = formRef?.current?.getFieldValue("logradouro");

    if (
      cep === "" ||
      uf === "" ||
      cidade === "" ||
      bairro === "" ||
      logradouro === "" ||
      numero === "" ||
      inputValidations.uf.isNotValid(uf) ||
      inputValidations.city.isNotValid(cidade) ||
      inputValidations.neighborhood.isNotValid(bairro) ||
      inputValidations.street.isNotValid(logradouro) ||
      inputValidations.streetNumber.isNotValid(numero)
    ) {
      setButtonSubmitProps({
        ...buttonSubmitProps,
        disabled: true,
      });
    } else {
      setButtonSubmitProps({
        ...buttonSubmitProps,
        disabled: false,
      });
    }
  }

  const clearAddressFields = () => {
    formRef?.current?.setFieldValue("uf", "");
    formRef?.current?.setFieldValue("cidade", "");
    formRef?.current?.setFieldValue("bairro", "");
    formRef?.current?.setFieldValue("numero", "");
    formRef?.current?.setFieldValue("logradouro", "");
    formRef?.current?.setFieldValue("complemento", "");
    setMsgError(msgErrorInitialState);
  };

  useEffect(() => {
    const getAddressByViaCep = async () => {
      const cep = formRef?.current?.getFieldValue("cep").replace(/-/g, "");
      if (cep.length !== 8) {
        clearAddressFields();
        return;
      }
      try {
        const { data } = await API.viaCep.get(cep);
        if (!data.erro) {
          formRef?.current?.setFieldValue("uf", data?.uf ? data?.uf : "");
          formRef?.current?.setFieldValue(
            "logradouro",
            data?.logradouro ? data?.logradouro : ""
          );
          formRef?.current?.setFieldValue(
            "cidade",
            data?.localidade ? data?.localidade : ""
          );
          formRef?.current?.setFieldValue(
            "bairro",
            data?.bairro ? data?.bairro : ""
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAddressByViaCep();
  }, [formRef?.current?.getFieldValue("cep")]);

  const ufValidateHandler = (event) => {
    inputValidations.uf.isValid(event.target.value.toString().trim());
    setMsgError((state) => {
      return {
        ...state,
        uf: inputValidations.uf.getErrorMessage(),
      };
    });
  };
  const cityValidateHandler = (event) => {
    inputValidations.city.isValid(event.target.value.toString());
    setMsgError((state) => {
      return {
        ...state,
        city: inputValidations.city.getErrorMessage(),
      };
    });
  };

  const neighborhoodValidateHandler = (event) => {
    inputValidations.neighborhood.isValid(event.target.value.toString());
    setMsgError((state) => {
      return {
        ...state,
        neighborhood: inputValidations.neighborhood.getErrorMessage(),
      };
    });
  };
  const streetValidateHandler = (event) => {
    inputValidations.street.isValid(event.target.value.toString());
    setMsgError((state) => {
      return {
        ...state,
        street: inputValidations.street.getErrorMessage(),
      };
    });
  };
  const streetNumberValidateHandler = (event) => {
    inputValidations.streetNumber.isValid(event.target.value.toString());
    setMsgError((state) => {
      return {
        ...state,
        streetNumber: inputValidations.streetNumber.getErrorMessage(),
      };
    });
  };

  return (
    <>
      <AlertModal
        src={Gifs.IconOkGif}
        title="Endereço atualizado com sucesso!"
        state={modalSuccessState}
        confirmClick={() => {
          history.push(RoutesPaths.ACCOUT);
          document.body.style.overflow = "unset";
        }}
      >
        <p>Clique no botão para voltar a tela principal da sua conta.</p>
      </AlertModal>
      <AlertModal
        src={Gifs.IconAlertGif}
        title="Erro na atualização!"
        state={modalErrorState}
        confirmClick={() => {
          setmodalErrorState(false);
        }}
      />
      <PageBoardSkeleton
        onClickFilterBar={() => history.goBack()}
        filterBar="Endereço de cadastro"
        button={buttonSubmitProps}
        button2={{
          label: "Voltar à minha conta",
          onClick() {
            history.push(RoutesPaths.ACCOUT);
          },
        }}
      >
        <div
          className={
            "flex flex-col w-full items-center " + StylePatterns.ELEMENT_WIDTH
          }
        >
          <span
            className="text-xl font-normal flex justify-center items-center mt-5 gap-2"
            style={{ color: Colors.Gray3 }}
          >
            ENDEREÇO DE CADASTRO
          </span>

          <Form
            className="account-form text-lg font-medium w-full"
            ref={formRef}
            onSubmit={handleFormSubmit}
            onKeyDown={handleKeyDown}
            initialData={formInitialData}
            onChange={() => {
              // if (!firstSubmit) {
              // 	UnFormValidate(formRef, adressValidation)
              // }
              validateFormAndSetButtonDisabled();
            }}
          >
            <UnformInputAdapt
              name="pais"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  big={true}
                  disabled={true}
                  defaultValue={defaultValue}
                  error={error}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="País"
                  type="text"
                  ref={ref}
                />
              )}
            />
            <UnformInputAdapt
              name="cep"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  big={true}
                  defaultValue={defaultValue}
                  error={error}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="CEP"
                  length={8}
                  type="text"
                  ref={ref}
                />
              )}
            />

            <UnformInputAdapt
              name="uf"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  big={true}
                  defaultValue={defaultValue}
                  error={error || msgError.uf}
                  color="bg-white"
                  className="account-form-input margin-bottom-zero text-uppercase"
                  placeholder="UF"
                  length={2}
                  type="text"
                  ref={ref}
                  onChange={ufValidateHandler}
                />
              )}
            />
            <UnformInputAdapt
              name="cidade"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  big={true}
                  defaultValue={defaultValue}
                  error={error || msgError.city}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="Cidade"
                  type="text"
                  ref={ref}
                  onChange={cityValidateHandler}
                />
              )}
            />
            <UnformInputAdapt
              name="bairro"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  big={true}
                  defaultValue={defaultValue}
                  error={error || msgError.neighborhood}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="Bairro"
                  type="text"
                  ref={ref}
                  onChange={neighborhoodValidateHandler}
                />
              )}
            />
            <UnformInputAdapt
              name="logradouro"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  big={true}
                  defaultValue={defaultValue}
                  error={error || msgError.street}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="Logradouro"
                  type="text"
                  ref={ref}
                  onChange={streetValidateHandler}
                />
              )}
            />
            <UnformInputAdapt
              name="numero"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  big={true}
                  defaultValue={defaultValue}
                  error={error || msgError.streetNumber}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="Número"
                  type="text"
                  ref={ref}
                  onChange={streetNumberValidateHandler}
                />
              )}
            />
            <UnformInputAdapt
              name="complemento"
              children={({ ref, error, defaultValue }) => (
                <FloatInput
                  big={true}
                  defaultValue={defaultValue}
                  error={error}
                  color="bg-white"
                  className="account-form-input"
                  placeholder="Complemento"
                  type="text"
                  ref={ref}
                />
              )}
            />
          </Form>
          <p style={{ color: Colors.Gray3 }} className="underline mt-8 text-sm">
            ATENÇÃO:
          </p>
          <p style={{ color: Colors.Gray3 }} className="text-center text-sm">
            Certifique-se de que os dados informados acima são verdadeiros e
            estão atualizados. Estas informações serão utilizadas para a
            certificação da sua conta e comunicação da plataforma.
          </p>
        </div>
      </PageBoardSkeleton>
    </>
  );
};

export default AddressPage;
