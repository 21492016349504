import React, { ChangeEvent, createRef } from "react";
import { useState } from "react";
import * as Icons from "../../assets/icons";
import FloatInputCore, {
  FloatInputCoreProps,
} from "./FloatInputCore/FloatInputCore";

interface MyProps extends FloatInputCoreProps {
  type?: "text" | "number" | "date" | "password" | "email";
  defaultValue?: string;
  disabled?: boolean;
  textColor?: string;
  length?: number;
  pattern?: string;
  onChange?: (p: ChangeEvent<HTMLInputElement>) => any;
  onBlur?: (p: ChangeEvent<HTMLInputElement>) => any;
  onInput?: (p: any) => any;
  onKeyDown?: (e: any) => any;
}

const FloatInput = React.forwardRef<HTMLInputElement, MyProps>((props, ref) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  var mytype: string = props.type ?? "";
  if (props.type === "password") {
    mytype = showPassword ? "text" : "password";
  }

  return (
    <FloatInputCore
      ref={ref ?? createRef()}
      big={props.big}
      color={props.color}
      labelColor={props.labelColor}
      disabled={props.disabled}
      className={props.className}
      placeholder={props.placeholder}
      error={props.error}
      children={(ref1) => (
        <input
          ref={ref1}
          type={mytype}
          size={props.length}
          maxLength={props.length}
          disabled={props.disabled}
          defaultValue={props.defaultValue}
          pattern={props.pattern}
          placeholder=" "
          className="z-1 block p-2 w-full appearance-none focus:outline-none bg-transparent h-full"
          style={props.textColor ? { color: props.textColor } : {}}
          onChange={props.onChange}
          onBlur={props?.onBlur}
          onKeyDown={props?.onKeyDown}
          onInput={
            mytype === "number" && props.length
              ? (e) => {
                  e.currentTarget.value = e.currentTarget.value.slice(
                    0,
                    props.length
                  );
                  if (props.onInput) {
                    props.onInput(e);
                  }
                }
              : (e) => {
                  if (props.onInput) {
                    props.onInput(e);
                  }
                }
          }
        />
      )}
      buttonEnd={
        props.type !== "password"
          ? undefined
          : () => (
              <img
                className="mr-2 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
                src={Icons.ShowHiddenIcon}
                alt=""
              />
            )
      }
    />
  );
});

FloatInput.defaultProps = {
  className: "",
  type: "text",
  ref: React.createRef(),
};

export default FloatInput;
