import { TokenTypeEnum } from "./../models/enums/tokenTypeEnum";
import { ClientDocumentStatusEnum } from "./../models/clients";
import { Client, GoldToken } from "../models";
import { DocumentsTypeEnum } from "../models/enums";
import api from "../services/api";
import { getTokensProjectWithTotal } from "../utils/AdminUtils";
import { TokenProjectsEnum } from "../models/enums/tokenProjectsEnum";
import { balance } from "../models/balance";
import { debug } from "console";

export const admin = {
  async loadAccounts(): Promise<Array<Client>> {
    const { status, data } = await api.exchange.get("/auth/admin/accounts");

    if (status === 200) {
      const response: Array<Client> = data
        .map((elm) => ({ ...elm, ...elm.metadata }))
        .map((elm) => ({
          ...elm,
          name: elm.name,
          create_at: new Date(elm.create_at),
        }));
      return response;
    } else {
      throw data;
    }
  },

  async loadClientBalance(accountId: string): Promise<balance> {
    try {
      const { status, data } = await api.exchange.get(
        `/user/${accountId}/balance`
      );

      if (status === 200) {
        const gold = data.filter((project) => project.name === "GOLD");
        let goldAvailable = 0;
        let goldConsumed = 0;
        let goldTokenQuantity = 0;

        gold.forEach((project) => {
          goldTokenQuantity = project.tokens.length;
          project.tokens.forEach((token) => {
            goldAvailable += token.available;
            goldConsumed += token.consumed;
          });
        });

        const brl = data.filter((project) => project.name === "BRL");
        let brlAvailable = 0;
        let brlConsumed = 0;
        let brlTokenQuantity = 0;

        brl.forEach((project) => {
          brlTokenQuantity = project.length;
          project.tokens.forEach((token) => {
            brlAvailable += token.available;
            brlConsumed += token.consumed;
          });
        });

        return {
          gold: {
            available: goldAvailable,
            consumed: goldConsumed,
            full: goldAvailable + goldConsumed,
            tokenQuantity: goldTokenQuantity,
          },
          invoice: {
            available: 0,
            consumed: 0,
            full: 0,
            tokenQuantity: 0,
          },
          credit: {
            available: 0,
            consumed: 0,
            full: 0,
            tokenQuantity: 0,
          },
          currency: {
            available: brlAvailable,
            consumed: brlConsumed,
            full: brlAvailable + brlConsumed,
            tokenQuantity: brlTokenQuantity,
          },
        };
      } else {
        return {
          gold: {
            available: 0,
            consumed: 0,
            full: 0,
            tokenQuantity: 0,
          },
          invoice: {
            available: 0,
            consumed: 0,
            full: 0,
            tokenQuantity: 0,
          },
          credit: {
            available: 0,
            consumed: 0,
            full: 0,
            tokenQuantity: 0,
          },
          currency: {
            available: 0,
            consumed: 0,
            full: 0,
            tokenQuantity: 0,
          },
        };
      }
    } catch (error) {
      throw error;
    }
  },
  async getClientTokens(accountId: string): Promise<any> {
    try {
      const { status, data } = await api.exchange.get(
        `/user/${accountId}/balance`
      );
      if (status === 200) {
        const tokenProject = data.filter(
          (project) => project.name === "GOLD"
        )[0];
        return tokenProject?.tokens;
      } else {
        return undefined;
      }
    } catch (error) {
      throw error;
    }
  },
  async loadClient(accountId: string) {
    const response = await api.exchange.get(`/auth/admin/account/${accountId}`);
    const client = response.data;

    if (response.status === 200 && client) {
      const isValidArray = client.banks ? true : false;
      const address = client?.addresses && client?.addresses[0];
      return {
        id: client.id ?? "",
        name: client?.name ?? "",
        role: client.role ?? "",
        userType: client.userType ?? "",
        phoneNumber: client?.phoneNumber ?? "",
        email: client.email ?? "",
        document: client?.document ?? "",
        partnerDocument: client.partnerDocument ?? "",
        partnerName: client.partnerName ?? "",
        partnerEmail: client.partnerEmail ?? "",
        status: client.status ?? "",
        userBankInfo: {
          bank: !isValidArray ? "" : client?.banks[0]?.bankCode ?? "",
          bankAccount: !isValidArray ? "" : client?.banks[0]?.bankAccount ?? "",
          bankAgency: !isValidArray ? "" : client?.banks[0]?.bankAgency ?? "",
          ispb: !isValidArray ? "" : client?.banks[0]?.ispd ?? "",
          shortName: !isValidArray ? "" : client?.banks[0]?.shortName ?? "",
          fullName: !isValidArray ? "" : client?.banks[0]?.fullName ?? "",
        },
        custodyBankInfo: {
          bank: "",
          bankAccount: "",
          bankAgency: "",
          ispb: "",
          shortName: "",
        },
        address: {
          street: address?.street ?? "",
          number: address?.number ?? "",
          neighborhood: address?.neighborhood ?? "",
          city: address?.city ?? "",
          state: address?.state ?? "",
          complement: address?.complement ?? "",
          postalCode: address?.postalCode ?? "",
          country: address?.country ?? "",
        },
        emailVerified: client.certifications.includes("EMAIL"),
        phoneVerified: client.certifications.includes("PHONE_NUMBER"),
        dataVerified: client.certifications.includes("DATA_VERIFIED"),
        otpEmail: client.otpEmail ?? "",
        otpPhoneNumber: client.otpPhoneNumber ?? "",
        data: client.data ?? "",
        tenant: {
          id: client?.tenant?.id ?? "",
        },
        birthday: new Date(client?.birthday),
        motherName: client?.montherName,
        certifications: client?.certifications,
        documentations: client?.documentations,
      };
    }

    throw new Error("Não foi possivel obter os dados do usuário.");
  },

  async updateDocumentStatus({
    accountId,
    documentType,
    status,
  }: {
    accountId: string;
    documentType: DocumentsTypeEnum;
    status: ClientDocumentStatusEnum;
  }) {
    try {
      const response = await api.exchange.put(
        `/auth/accounts/${accountId}/documentations/${documentType}`,
        {
          type: documentType,
          status,
        }
      );

      if (response.status !== 200) {
        throw new Error("Não foi possível atualizar o documento.");
      } else {
        return response.data;
      }
    } catch (err) {
      throw new Error("Não foi possível atualizar o documento.");
    }
  },

  async getTokensAdmin(tokenType?: TokenProjectsEnum) {
    const { status, data } = await api.exchange.get("/user/balance");

    try {
      if (status == 200) {
        let tokensProject = getTokensProjectWithTotal(data);
        if (tokenType) {
          tokensProject = tokensProject.find(
            (tokens) => tokens.name === tokenType
          );
        } else {
          tokensProject = tokensProject.filter(
            (tokens) => tokens.name !== TokenProjectsEnum.BRL
          );
        }

        return tokensProject;
      } else {
        throw new Error("Não foi possível buscar tokens.");
      }
    } catch (error) {
      throw new Error("Não foi possível buscar tokens.");
    }
  },
  async getTokenDetailsAdmin(tokenType: TokenProjectsEnum, tokenId: number) {
    let response = await api.exchange.get("/user/balance");

    try {
      if (response.status == 200) {
        let tokensProject = getTokensProjectWithTotal(response.data);
        tokensProject = tokensProject.find(
          (tokens) => tokens.name === tokenType
        );

        let token = tokensProject.tokens.find((token) => token.id === tokenId);
        response = await api.exchange.get("/token/" + tokenId);

        if (response.status == 200) {
          token = {
            ...token,
            ...response.data,
            totalIssued: response.data.totalSupply - response.data.burned,
          };
        }
        return token;
      } else {
        throw new Error("Não foi possível buscar detalhes do token.");
      }
    } catch (error) {
      throw new Error("Não foi possível buscar detalhes do token.");
    }
  },
};
