import React from "react";
import BarButton from "../BarButton/BarButton";
import FilterBar from "../FilterBar/FilterBar";
import TopBar from "../TopBar/TopBar";
import * as Icons from "../../assets/icons";
import Colors from "../../assets/Colors";
import UnderlineButton from "../Buttons/UnderlineButton";
import Divider from "../Divider/Divider";
import Button from "../Buttons/Button";
import Footer from "../Footer/Footer";

interface MyProps {
  children: JSX.Element | JSX.Element[];
  filterBar: string;
  onClickFilterBar: () => any;
  button?: {
    label: string;
    onClick: () => any;
    className?: string;
    big?: boolean;
    disabled?: boolean;
    loading?: boolean;
  };
  button2?: {
    label: string;
    onClick: () => any;
  };
}

const PageBoardSkeleton: React.FC<MyProps> = ({
  children,
  filterBar,
  onClickFilterBar,
  button,
  button2,
}) => {
  return (
    <>
      <TopBar />
      <main
        className="text-xl flex-grow w-full min-h-screen"
        style={{ backgroundColor: Colors.GrayBackGroud }}
      >
        <FilterBar className="pt-5">
          <div className="flex flex-row">
            <BarButton
              state={false}
              style={{ paddingBottom: 0 }}
              onClick={onClickFilterBar}
              children={
                <div className="flex flex-row gap-3 items-center">
                  <img src={Icons.ArrowPointerIcon} alt="" />
                  <span style={{ color: Colors.Gray2 }}>{filterBar}</span>
                </div>
              }
            />
          </div>
        </FilterBar>

        <div className="w-full px-5 py-5">
          <div
            className="w-full flex flex-col p-5 border-05 rounded-md bg-white justify-center items-center"
            style={{ borderColor: Colors.Gray4 }}
          >
            {children}

            <Divider />

            {button && (
              <Button
                onClick={button.onClick}
                big={button.big}
                loading={button.loading}
                disabled={button.disabled}
                className={`flex justify-center font-medium text-lg my-5 ${button.className}`}
                style={{
                  backgroundColor: Colors.UserBGButton,
                  color: Colors.Black1,
                }}
              >
                {button.label}
              </Button>
            )}

            {button2 && (
              <UnderlineButton
                className="mb-2 md:mb-10 text-lg font-medium"
                style={{ color: Colors.Gray3 }}
                onClick={button2.onClick}
              >
                {button2.label}
              </UnderlineButton>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PageBoardSkeleton;
