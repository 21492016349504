import { OrderStatusEnum } from "../models/enums";
import {
  AzulEmAndamento,
  GrayExecuted,
  OrangeCanceled,
  PurpleExpirete,
} from "../assets/Colors";

export const determineOrderStatusColor = (
  status: OrderStatusEnum | string
): string => {
  switch (status) {
    case "ACTIVE":
      return AzulEmAndamento;
    case "CANCELED":
      return OrangeCanceled;
    case "INACTIVE":
      return PurpleExpirete;
    case "FULFILLED":
      return GrayExecuted;
    default:
      return "";
  }
};
