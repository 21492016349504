import React, { useCallback, useContext, useEffect, useState } from "react";
import * as Colors from "../../../assets/Colors";
import FilterBar from "../../../components/FilterBar/FilterBar";
import UserContext from "../../../contexts/UserProvider";
import { TokenTypeEnum } from "../../../models/enums";
import { UserRoleEnum } from "../../../models/enums/UserRoleEnum";
import RoutesPaths from "../../../Routes/RoutesPaths";
import history from "../../../services/history";
import * as API from "../../../api";
import EmptyCard from "../../../components/Cards/EmptyCard";
import AssetCardAdmin from "./AssetCardAdmin/AssetCardAdmin";
import TokenizeButton from "../../MyAssets/TokenizeButton/TokenizeButton";
import Footer from "../../../components/Footer/Footer";

const MyAssetsAdmin: React.FC = () => {
  const { user } = useContext(UserContext);
  const [tokensProjects, setTokensProjects] = useState<Array<any>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    loadBalance();
  }, []);

  const loadBalance = () => {
    API.admin
      .getTokensAdmin()
      .then((data) => setTokensProjects(data))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const tokenUnit = useCallback(
    (tokenProject) => {
      if (tokenProject.tokens?.length > 0) {
        return tokenProject.tokens[0]?.metadata?.properties?.unit || "";
      }
      return "";
    },
    [tokensProjects]
  );

  return (
    <>
      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar title={"Tokens"} />

          <div className="mx-5 flex flex-col">
            {tokensProjects.length > 0 ? (
              <div className="flex items-center justify-between">
                <h3 style={{ color: Colors.Gray2 }} className="text-lg my-4">
                  Tokens emitidos
                </h3>
                <TokenizeButton type={TokenTypeEnum.BOND} />
              </div>
            ) : (
              <div className="mt-5">
                <EmptyCard>
                  <div className="flex flex-col items-center">
                    <strong style={{ color: Colors.Gray3 }}>
                      {isLoading
                        ? "Carregando tokens..."
                        : "Nenhum token encontrado"}
                    </strong>
                  </div>
                </EmptyCard>
              </div>
            )}
            {tokensProjects.map((tokenProject, index) => (
              <AssetCardAdmin
                title={tokenUnit(tokenProject)}
                subTitle={`- ${tokenProject?.tokens[0]?.metadata?.name}`}
                tokenProject={tokenProject}
                onClick={() =>
                  history.push(
                    `${RoutesPaths.MEUSATIVOS_ADMIN}/${tokenProject.name}`
                  )
                }
                key={index}
              />
            ))}
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MyAssetsAdmin;
