import React, { CSSProperties } from "react";

import moment from "moment";
import { XIcon } from "../../assets/icons";
import { formatToCurrency } from "../../utils/formatters";
import Colors, { CorPrincipal2 } from "../../assets/Colors";
import { SignalStatementEnum, Stament } from "../../models";

type MyProps = {
  state: boolean;
  onChange(): any;

  title: string;
  stament: Stament;
};

const ReceiveModal = ({ state, onChange, title, stament }: MyProps) => {
  const titleStyle: CSSProperties = {};

  titleStyle.color = Stament.determineTextColor(stament.signal);

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      style={{ display: state ? "block" : "none" }}
    >
      <div className="flex items-end justify-center items-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-80 lg:w-2/5"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex flex-col w-full justify-center">
            <div
              className="flex flex-row w-full p-3 relative"
              style={{ backgroundColor: Colors.CorDetalhes }}
            >
              <div className="absolute transform -translate-y-1/2 top-1/2 left-4">
                <img
                  src={XIcon}
                  alt="x icon"
                  className="cursor-pointer"
                  height={15}
                  width={15}
                  onClick={() => onChange()}
                />
              </div>
              <p className="font-medium text-white text-center text-lg md:text-xl whitespace-nowrap w-full">
                {title}
              </p>
            </div>

            <p
              className="text-2xl md:text-3xl font-medium text-center w-full mt-3"
              style={titleStyle}
            >
              {(stament.signal === SignalStatementEnum.CREDIT ? "+" : "-") +
                formatToCurrency(stament.amount) +
                ` ${stament?.token?.metadata?.symbol}`}
            </p>
            <p
              className="text-center font-medium md:text-lg w-full"
              style={{ color: Colors.Gray4 }}
            >
              {Stament.determineSubTitle(stament.signal)}
            </p>

            <div className="mx-4 mb-5 mt-3">
              <div className="rounded md:text-base font-normal border flex flex-col items-left w-full overflow-hidden">
                <div
                  className="flex flex-row pl-2 md:px-5 py-2"
                  style={{ backgroundColor: Colors.Gray6 }}
                >
                  <p style={{ color: Colors.Gray3 }}>Data: </p>
                  <p style={{ color: Colors.Gray2 }}>
                    &nbsp;{moment(stament.date).format("DD/MM/YYYY HH:mm")}
                  </p>
                </div>
                <div
                  className="flex flex-row pl-2 md:px-5 py-2"
                  style={{ backgroundColor: "white" }}
                >
                  <p style={{ color: Colors.Gray3 }}>Valor: </p>
                  <p style={{ color: Colors.Gray2 }}>
                    &nbsp;
                    {(stament.signal === SignalStatementEnum.CREDIT
                      ? "+"
                      : "-") + formatToCurrency(stament.amount)}{" "}
                    {stament?.token?.metadata?.symbol}
                  </p>
                </div>
                <div
                  className="flex flex-row pl-2 md:px-5 py-2"
                  style={{ backgroundColor: Colors.Gray6 }}
                >
                  <p style={{ color: Colors.Gray3 }}>ID: </p>
                  <p style={{ color: Colors.Gray2 }}>&nbsp;{stament.id}</p>
                </div>
                <div
                  className="flex flex-row pl-2 md:px-5 py-2"
                  style={{ backgroundColor: "white" }}
                >
                  <p style={{ color: Colors.Gray3 }}>Detalhes: </p>
                  <p style={{ color: Colors.Gray2 }}>
                    &nbsp;
                    {Stament.determineDescriptionText(stament).description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiveModal;
