import React, { createContext, useState } from "react";
import { Client } from "../models";
import { UserRoleEnum } from "../models/enums/UserRoleEnum";
import { User } from "../models/user";
import * as API from "./../api";

interface UserContextData {
  user?: User;
  userDetails?: Client;
  setUser(p: User): any;
  updateUser(): any;
  updateUserDetails(): any;
  setUserShowWallet(showWallet: boolean): any;
}

const UserContext = createContext<UserContextData>({} as UserContextData);

export const UserContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [userDetails, setUserDetails] = useState<Client>();

  const updateUserDetails = async () => {
    if (user) {
      const data = await API.signup.getMe(user.id);
      setUserDetails(data);
    }
  };

  const updateUser = async () => {
    const data = await API.user.getUserInfo();
    setUser({ ...data });
  };

  const setUserShowWallet = (showWallet: boolean) => {
    if (user) {
      setUser({ ...user, showWallet });
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        userDetails,
        setUser,
        updateUser,
        updateUserDetails,
        setUserShowWallet,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
