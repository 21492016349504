import React from "react";
import { user } from "../api";
import * as Colors from "../assets/Colors";
import TagValue from "./TagValue/TagValue";
import { PlataformLimitEnum } from "../models";
import ProgresBar from "./ProgressBar/ProgressBar";
import { formatToCurrency } from "../utils/formatters";

interface MyProps {
  limits: Map<PlataformLimitEnum, user.PlataformLimit>;
  loading?: boolean;
}

export const LimitsList: React.FC<MyProps> = ({ limits, loading }) => {
  const getLimit = (type: PlataformLimitEnum): number | undefined =>
    Number(limits?.get(type)?.limit);
  const getProgress = (type: PlataformLimitEnum) => {
    let consumed: number = limits?.get(type)?.consumed ?? 0;
    let limit: number = limits?.get(type)?.limit ?? 0;

    return (100 * consumed) / limit;
  };

  return (
    <>
      <h2
        className="text-base font-normal mb-3 text-center sm:text-xl"
        style={{ color: Colors.Gray3 }}
      >
        LIMITES DE NEGOCIAÇÃO
      </h2>

      {[
        {
          label: "Depósitos (mensais):",
          value: getLimit(PlataformLimitEnum.DEPOSIT),
          progress: getProgress(PlataformLimitEnum.DEPOSIT),
        },
        {
          label: "Saques (mensais):",
          value: getLimit(PlataformLimitEnum.WITHDRAW),
          progress: getProgress(PlataformLimitEnum.WITHDRAW),
        },
        {
          label: "Ordens de compra mensais:",
          value: getLimit(PlataformLimitEnum.GOLD_BUY),
          progress: getProgress(PlataformLimitEnum.GOLD_BUY),
        },
        {
          label: "Ordens de venda mensais:",
          value: getLimit(PlataformLimitEnum.GOLD_SELL),
          progress: getProgress(PlataformLimitEnum.GOLD_SELL),
        },
      ].map(({ label, value, progress }, key) => (
        <TagValue
          label={label}
          value={formatToCurrency(value) + " BRL"}
          autoWidth={false}
          height="h-20"
          key={key}
          loading={loading}
        >
          <ProgresBar progress={progress} />
        </TagValue>
      ))}
    </>
  );
};
