import React from "react";
import Divider from "../Divider/Divider";
import Colors from "../../assets/Colors";

type MyProps = {
  state: boolean;
  changeState?: () => any;

  children?: JSX.Element | JSX.Element[] | string;

  title: string;
  titleColor: string;

  buttonText: string;
  buttonColor: string;
  confirmClick?: () => any;

  src?: string;
  iconSize?: "32" | "40";
  className?: string;
};

const AlertModal = ({
  state,
  changeState,
  children,
  title,
  titleColor,
  buttonText,
  buttonColor,
  confirmClick,
  src,
  iconSize,
  className,
}: MyProps) => {
  const confirm = (p: any) => {
    if (changeState) changeState();
    if (confirmClick) {
      confirmClick();
    }
  };

  return (
    <div
      className="fixed z-20 inset-0 overflow-y-auto"
      style={{ display: state ? "block" : "none" }}
    >
      <div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>

        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="px-5 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-96 lg:w-2/6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white w-full py-1">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left flex flex-col items-center w-full">
                {src && (
                  <img
                    src={src}
                    className={`w-${Number(iconSize) - 4} lg:w-${iconSize}`}
                    alt=""
                    height="90"
                    width="90"
                  />
                )}
                <h3
                  className={
                    "text-lg lg:text-2xl leading-6 py-3 lg:py-5 font-medium text-gray-900 text-center " +
                    titleColor
                  }
                  id="modal-headline"
                >
                  {title}
                </h3>
                <div
                  className="text-sm lg:text-lg font-normal text-center"
                  style={{ color: Colors.Gray3 }}
                >
                  {children}
                </div>
              </div>
            </div>
          </div>

          {/* Barra inferior */}
          <Divider bottom="mb-0" top="mt-0" />
          <div className="w-full py-3 sm:px-6 sm:flex sm:flex-row justify-center">
            <button
              type="button"
              className={
                "w-full inline-flex justify-center py-2 font-medium text-gray-500 sm:w-auto text-base lg:text-2xl " +
                buttonColor
              }
              onClick={confirm}
              style={{ color: buttonColor }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AlertModal.defaultProps = {
  buttonText: "OK",
  titleColor: "text-gray-800",
  buttonColor: "text-gray-800",
  confirmClick: () => {},
  iconSize: "32",
};

export default AlertModal;
