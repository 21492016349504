import React, { useContext, useEffect, useState } from "react";
import * as Colors from "../../../assets/Colors";
import { StylePatterns } from "../../../assets/patterns_tailwind_class";
import { FeesList } from "../../../components/FeesList";
import { LimitsList } from "../../../components/LimitsList";
import TaxesContext from "../../../contexts/TaxesProvide";
import UserContext from "../../../contexts/UserProvider";
import { PlataformLimitEnum, UserStatusEnum } from "../../../models";
import RoutesPaths from "../../../Routes/RoutesPaths";
import history from "../../../services/history";
import PageSkeleton from "../../../components/PageSkeleton/PageSkeleton";
import * as API from "./../../../api";
import { user } from "./../../../api";
import PageBoardSkeleton from "../../../components/PageBoardSkeleton/PageBoardSkeleton";

const LimitsPage: React.FC = () => {
  const { user } = useContext(UserContext);
  const { taxs, refreshTaxes } = useContext(TaxesContext);
  const [limits, setLimits] = useState<
    Map<PlataformLimitEnum, user.PlataformLimit>
  >(new Map());
  const [isLoadingLimitData, setIsLoadingLimitData] = useState<boolean>(false);

  useEffect(() => {
    setIsLoadingLimitData(true);
    refreshTaxes();

    API.user
      .getLimits()
      .then((data) => setLimits(data))
      .finally(() => setIsLoadingLimitData(false));
  }, []);

  return (
    <PageBoardSkeleton
      onClickFilterBar={() => history.goBack()}
      filterBar="Limites e taxas"
      button2={{
        label: "Voltar a minha conta",
        onClick: () => {
          history.push(RoutesPaths.ACCOUT);
        },
      }}
    >
      <div className="w-full flex flex-row items-center justify-center px-5">
        {[UserStatusEnum.CREATED, UserStatusEnum.ANALYSIS].find(
          (e) => user?.status === e
        ) ? (
          <div
            className="text-base flex flex-row justify-center items-center rounded py-3 px-5 my-7 text-center w-full sm:w-4/5 lg:w-3/5 max-w-2xl"
            style={{ backgroundColor: Colors.RedMyAccount }}
          >
            <p className="text-white">
              Certifique sua conta para aumentar os seus limites!
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className={StylePatterns.ELEMENT_WIDTH}>
        <LimitsList limits={limits} loading={isLoadingLimitData} />
        <FeesList taxs={taxs} loading={isLoadingLimitData} />
      </div>

      <br />

      <div
        style={{ color: Colors.Gray3 }}
        className="lg:w-3/5 max-w-2xl text-sm font-normal sm:px-5 sm:text-lg"
      >
        <p className="text-base font-medium sm:text-lg">
          Confira abaixo os níveis de negociação da plataforma:
        </p>
        <p className="mt-5">
          1 - Contas não certificadas: <br />
          Depósitos e saques: R$0,00 <br />
          Ordens de compra e venda: até R$0,00 <br />
        </p>
        <p className="mt-5">
          2 - Contas certificadas: <br />
          Depósitos e saques: até R$100.000,00 por mês <br />
          Ordens de compra e venda: até R$100.000,00 por mês <br />
        </p>
        <p className="mt-5">
          {
            "Para aumentar o limite de negociação da sua conta acima destes valores, favor entrar em contato através do email "
          }
          <a
            className="underline underline-offset-2"
            style={{ color: Colors.Blue1 }}
            href="mailto:falecom@oz.inc"
          >
            falecom@oz.inc
          </a>
        </p>
      </div>
    </PageBoardSkeleton>
  );
};

export default LimitsPage;
