import ClientsContext from "../../../../contexts/ClientsProvider";
import { useContext, useEffect, useState } from "react";
import SplitedCard from "../../../../components/Cards/SplitedCard";
import * as Icons from "../../../../assets/icons";
import Colors from "../../../../assets/Colors";
import React from "react";
import PageSkeleton from "../../../../components/PageSkeleton/PageSkeleton";
import history from "../../../../services/history";
import * as API from "./../../../../api";
import { useParams } from "react-router-dom";
import ClientDetailsFilterBar from "../ClientDetailsFilterBar/ClientDetailsFilterBar";

const ClientBankAccountPage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [clientDetails, setClientDetails] = useState<any>({});

  useEffect(() => {
    loadClientDetails();
  }, []);

  const loadClientDetails = async () => {
    const data = await API.admin.loadClient(clientId);
    setClientDetails(data);
  };
  return (
    <PageSkeleton
      isAdmin
      useContentCard
      goBackBtn
      filterBar={
        <ClientDetailsFilterBar
          clientName={clientDetails?.name}
          pageTitle="Contas de depósitos e saques"
        />
      }
      button2={{
        label: "Voltar ao resumo da conta",
        onClick: history.goBack,
      }}
    >
      <SplitedCard
        icon={Icons.BankIcon}
        title="Dados Bancários"
        subTitle="Conta bancária para registro dos títulos, identificação de depósitos e saques em TED."
        className="mt-8"
        autoWidth={true}
      >
        <div className="flex flex-row w-full items-center">
          <div
            className="flex flex-col flex-grow"
            style={{ color: Colors.Gray2 }}
          >
            <strong>{`${clientDetails?.userBankInfo?.shortName} (${clientDetails?.userBankInfo?.bank})`}</strong>
            <p>
              AG: {clientDetails?.userBankInfo?.bankAgency} <br /> CC:{" "}
              {clientDetails?.userBankInfo?.bankAccount}
            </p>
          </div>
          <div className="flex flex-col">
            {/* <img src={Icons.EditIcon} alt='EditIcon' style={{ height: '30px', width: '30px' }} /> */}
          </div>
        </div>
      </SplitedCard>
    </PageSkeleton>
  );
};

export default ClientBankAccountPage;
