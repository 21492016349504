import React from "react";

interface MyProps {
  label?: string | true;
  defaultValue?: string;
  name?: string;
  type?: string;
  mask?: (value: string) => string;
  placeholder?: string;
  disabled?: boolean;
  error?: any;
  value?: string;
  register?: any;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MaskedInput = React.forwardRef<HTMLInputElement, MyProps>(
  (
    {
      label,
      defaultValue,
      name,
      type,
      mask = (value: string) => value,
      value,
      placeholder,
      disabled,
      error,
      register,
      className,
      onChange,
      ...inputProps
    },
    ref
  ) => {
    return (
      <input
        onChange={(e) => {
          e.target.value = `${mask(e.target.value)}`;
          onChange && onChange(e);

          return e.target.value;
        }}
        disabled={disabled}
        ref={ref}
        id={name}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={className}
        {...inputProps}
      />
    );
  }
);

export default MaskedInput;
