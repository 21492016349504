import { TokenProjectsEnum } from "../models/enums/tokenProjectsEnum";

export const getTokensProjectWithTotal = (
  projects,
  projectName?: TokenProjectsEnum
) => {
  if (projectName)
    projects = projects.filter((project) => project.name === projectName);

  let totalData = {
    totalAvaiable: 0,
    totalConsumed: 0,
    tokenQuantity: 0,
    goldAvailable: 0,
    goldConsumed: 0,
    goldTotal: 0,
  };

  projects = projects.map((project) => {
    totalData.tokenQuantity = project.tokens.length;
    project.tokens.forEach((token) => {
      totalData.totalAvaiable += token.available;
      totalData.totalConsumed += token.consumed;

      if (project.name === TokenProjectsEnum.GOLD) {
        totalData.goldAvailable += token.available;
        totalData.goldConsumed += token.consumed;
        totalData.goldTotal += token.available + token.consumed;
      }
    });

    return { ...project, ...totalData };
  });

  return projects;
};
