import ClientsContext from "../../../../contexts/ClientsProvider";
import { useContext, useEffect, useState } from "react";
import SplitedCard from "../../../../components/Cards/SplitedCard";
import * as Icons from "../../../../assets/icons";
import Colors from "../../../../assets/Colors";
import React from "react";
import PageSkeleton from "../../../../components/PageSkeleton/PageSkeleton";
import history from "../../../../services/history";
import UnformInputAdapt from "../../../../components/unform/UnformInputAdapt/UnformInputAdapt";
import { Form } from "@unform/web";
import { StylePatterns } from "../../../../assets/patterns_tailwind_class";
import FloatInput from "../../../../components/Inputs/FloatInput";
import { User } from "../../../../models";
import * as API from "./../../../../api";
import { useParams } from "react-router-dom";
import ClientDetailsFilterBar from "../ClientDetailsFilterBar/ClientDetailsFilterBar";

const ClientAddressPage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [clientDetails, setClientDetails] = useState<any>({});

  useEffect(() => {
    loadClientDetails();
  }, []);

  const loadClientDetails = async () => {
    const data = await API.admin.loadClient(clientId);
    setClientDetails(data);
  };

  return (
    <PageSkeleton
      isAdmin
      useContentCard
      goBackBtn
      filterBar={
        <ClientDetailsFilterBar
          clientName={clientDetails?.name}
          pageTitle="Endereço de cadastro"
        />
      }
      button2={{
        label: "Voltar ao resumo da conta",
        onClick: history.goBack,
      }}
    >
      <div
        className={
          "flex flex-col w-full items-center " + StylePatterns.ELEMENT_WIDTH
        }
      >
        <h2
          className="text-xl font-normal flex justify-center items-center mt-5 gap-2"
          style={{ color: Colors.Gray3 }}
        >
          ENDEREÇO DO TITULAR
        </h2>

        <FloatInput
          big
          disabled
          defaultValue={clientDetails?.address?.country}
          color="bg-white"
          className="account-form text-lg font-medium w-full account-form-input"
          placeholder="País"
          type="text"
        />
        <FloatInput
          big
          disabled
          defaultValue={clientDetails?.address?.postalCode}
          color="bg-white"
          className="account-form text-lg font-medium w-full account-form-input"
          placeholder="CEP"
          type="text"
        />
        <FloatInput
          big
          disabled
          defaultValue={clientDetails?.address?.state}
          color="bg-white"
          className="account-form text-lg font-medium w-full account-form-input"
          placeholder="UF"
          type="text"
        />
        <FloatInput
          big
          disabled
          defaultValue={clientDetails?.address?.city}
          color="bg-white"
          className="account-form text-lg font-medium w-full account-form-input"
          placeholder="Cidade"
          type="text"
        />
        <FloatInput
          big
          disabled
          defaultValue={clientDetails?.address?.neighborhood}
          color="bg-white"
          className="account-form text-lg font-medium w-full account-form-input"
          placeholder="Logradouro"
          type="text"
        />
        <FloatInput
          big
          disabled
          defaultValue={clientDetails?.address?.number}
          color="bg-white"
          className="account-form text-lg font-medium w-full account-form-input"
          placeholder="Número"
          type="text"
        />
        <FloatInput
          big
          disabled
          defaultValue={clientDetails?.address?.complement}
          color="bg-white"
          className="account-form text-lg font-medium w-full account-form-input"
          placeholder="Complemento"
          type="text"
        />
      </div>
    </PageSkeleton>
  );
};

export default ClientAddressPage;
