import * as Yup from "yup";
import { FormHandles } from "@unform/core";

export const YupAdaptErrorToUnform = (error: Error) => {
  if (error instanceof Yup.ValidationError) {
    const validationErrors = {};

    //@ts-ignore
    error.inner.forEach((error) => {
      validationErrors[error.path] = error.message;
    });

    return validationErrors;
  } else {
    return {};
  }
};
export const UnFormValidate = async (
  formRef: React.RefObject<FormHandles>,
  schema: Yup.ObjectSchema<any>
) => {
  if (!formRef.current) return null;

  try {
    const inputData = formRef.current.getData();
    await schema.validate(inputData, {
      abortEarly: false,
    });
    formRef.current.setErrors({});
  } catch (error: any) {
    formRef.current.setErrors(YupAdaptErrorToUnform(error));
  }
};
