import React, { useState, useContext, useEffect } from "react";
import moment from "moment";

/// Models
import {
  OrderStatusEnum,
  OrderTypeEnum,
  TokenTypeEnum,
  TokenTypeEnumInterpreter,
  FeeTypeEnum,
} from "../../models/enums";

/// Assets
import * as API from "./../../api";
import history from "../../services/history";
import Colors from "../../assets/Colors";
import * as Gifs from "./../../assets/gifs";
import RoutesPaths from "../../Routes/RoutesPaths";
import { formatToCurrency } from "../../utils/formatters";

/// Componentes
import { Order } from "../../models/order";
import Modal from "../../components/Modal/Modal";
import TopBar from "../../components/TopBar/TopBar";
import Divider from "../../components/Divider/Divider";
import OrderContext from "../../contexts/OrderProvider";
import FilterBar from "../../components/FilterBar/FilterBar";
import UnderlineButton from "../../components/Buttons/UnderlineButton";
import { CardActive } from "../../components/Cards/CardActive";
import ModalContext from "../../contexts/ModalProvider";
import AlertModal from "../../components/Modal/AlertModal";
import { GoldToken, Match, Token } from "../../models";
import { formatRescueDate } from "../../utils/DateUtils";
import BigButton from "../../components/Buttons/BigButton";
import Footer from "../../components/Footer/Footer";

const OrderDetails: React.FC = () => {
  const { order } = useContext(OrderContext);
  const { handleModal, setModal } = useContext(ModalContext);
  const [orderDetails, setOrderDetails] = useState<Order.Detail | undefined>(
    undefined
  );

  const statusColor = Order.determineStatusColor(orderDetails?.status);

  if (!order) {
    history.push(RoutesPaths.MINHASORDENS);
  }

  const loadOrderDetails = async () => {
    if (order) {
      setOrderDetails(
        await API.book.getOrderDetails({
          orderId: order.id,
          orderType: order.type,
        })
      );
    }

    // use this if mocking this component for test
    // setOrderDetails(createMockedOrderDetails);
  };

  const createMockedOrderDetails = (): Order.Detail => {
    return {
      matchs: [],
      tokens: [],
      statusRunningOrder: "ACTIVE",
      id: "",
      feeType: FeeTypeEnum.FIXED,
      fee: 1,
      amount: 1,
      consumed: 0,
      processing: 0,
      updateDate: new Date(),
      creationDate: new Date(),
      tokenExpirationDateEnd: new Date(),
      tokenExpirationDateStart: new Date(),
      quantity: 200,
      available: 2,
      tokenQuantity: 2,
      remainingTokens: 2,
      finished: false,

      averageFeeNegotiated: 1,
      create_at: new Date(),
      freeAmount: 12,
      fullAmount: 20,
      orderExpirationDateEnd: new Date(),
      orderExpirationDateStart: new Date(),
      status: OrderStatusEnum.ACTIVE,
      type: OrderTypeEnum.buy,
      typeReceivable: TokenTypeEnum.GOLD,
      update_at: new Date(),
      usedAmount: 0,
      userId: "1",
    };
  };

  useEffect(() => {
    loadOrderDetails();
  }, []);

  const stopOrderButton = async () => {
    handleModal(Modal, {
      title: "Deseja interromper a ordem?",
      titleColor: Colors.Gray1,
      confirmButtonClick: () => {
        if (order) {
          API.book
            .deleteOrder(order)
            .then(() => {
              handleModal(AlertModal, {
                title: "Ordem imterrompida com sucesso!",
                src: Gifs.IconOkGif,
                titleColor: Colors.Green3,

                confirmClick: async () => setModal(false),

                children: <p>Acompanhe o status em sua lista de ordens.</p>,
              });
            })
            .catch(() => {
              setModal(false);
            })
            .finally(() => {
              loadOrderDetails();
            });
        }
      },
      cancelButtonClick: () => setModal(false),

      children: (
        <>
          <p>
            Após isso a ordem será interrompida e não poderá ser reativada
            novamente.
          </p>
          <p>
            Todas as negociações já realizadas serão mantidas mesmo após o
            cancelamento.
          </p>
        </>
      ),
    });
  };

  var tokenList: Array<GoldToken | Match> = [];
  if (order?.type === OrderTypeEnum.sell) {
    tokenList = [...tokenList, ...(orderDetails?.tokens ?? [])];
  }
  if (order?.type === OrderTypeEnum.buy) {
    tokenList = [...tokenList, ...(orderDetails?.matchs ?? [])];
  }

  const lancamento =
    (orderDetails?.amount ?? 0) * (orderDetails?.tokenQuantity ?? 0);
  const negociacao =
    (orderDetails?.amount ?? 0) * (orderDetails?.remainingTokens ?? 0);
  const executado = orderDetails?.matchs.reduce(
    (prev, { bid, tokenQuantity }) => prev + bid * tokenQuantity,
    0
  );

  return (
    <>
      <div
        className="flex flex-col min-h-screen text-sm text-gray-50 font-sans"
        style={{ color: Colors.Gray4 }}
      >
        <TopBar />
        <main
          className="flex-grow bg-gray-200 w-full"
          style={{ backgroundColor: Colors.GrayBackGroud }}
        >
          <FilterBar title="Detalhes da ordem" />

          <div className="p-5">
            <p>COD: {orderDetails?.id}</p>
          </div>
          <div
            className="bg-white px-4 py-1 mx-5 flex flex-col aling-center items-center rounded border"
            style={{ borderColor: Colors.Gray4 }}
          >
            <div className="whitespace-pre my-3 px-3 w-full justify-start">
              <p
                style={{
                  color:
                    order?.type === OrderTypeEnum.sell
                      ? Colors.Red
                      : Colors.Green3,
                }}
              >
                {Order.determineTypeText(order?.type)?.toUpperCase() ??
                  "undefined"}
              </p>
              <p className="text-xs font-light" style={{ color: Colors.Gray3 }}>
                Criada em{" "}
                {moment(order?.creationDate).format("DD/MM/YYYY [às] HH:mm")}
              </p>

              <br />
              <p
                className="font-extralight whitespace-pre text-sm"
                style={{ color: Colors.Gray3 }}
              >
                Tipo de ativo:
                <span className="font-medium" style={{ color: Colors.Gray2 }}>
                  {" "}
                  Ouro 24k (
                  {
                    TokenTypeEnumInterpreter.convert(TokenTypeEnum.GOLD)
                      .unityName
                  }
                  )
                </span>
              </p>

              <p
                className="font-extralight whitespace-pre text-sm"
                style={{ color: Colors.Gray3 }}
              >
                Preço(BRL):
                <span className="font-normal" style={{ color: Colors.Gray2 }}>
                  {" "}
                  {formatToCurrency(orderDetails?.amount)}
                </span>
              </p>
              <p
                className="font-extralight whitespace-pre text-sm"
                style={{ color: Colors.Gray3 }}
              >
                Quantidade:
                <span className="font-normal" style={{ color: Colors.Gray2 }}>
                  {" "}
                  {formatToCurrency(orderDetails?.tokenQuantity)}{" "}
                  {TokenTypeEnumInterpreter.convert(TokenTypeEnum.GOLD).unity}
                </span>
              </p>
              <p
                className="font-extralight whitespace-pre text-sm"
                style={{ color: Colors.Gray3 }}
              >
                Período de resgate:
                <span className="font-normal" style={{ color: Colors.Gray2 }}>
                  {" "}
                  {formatRescueDate(
                    orderDetails?.orderExpirationDateStart ?? new Date()
                  )}{" "}
                  à{" "}
                  {formatRescueDate(
                    orderDetails?.orderExpirationDateEnd ?? new Date()
                  )}
                </span>
              </p>
            </div>

            <br />

            <div
              className="flex flex-row w-full"
              style={{ backgroundColor: Colors.Gray6 }}
            >
              <div
                className="flex flex-col items-center flex-1 border border-r-0"
                style={{ borderColor: Colors.Gray4 }}
              >
                <p
                  className="mt-3 mb-2 text-lg"
                  style={{ color: Colors.Gray2, fontWeight: 300 }}
                >
                  Valor de lançamento:
                </p>
                <p
                  className="mt-2 mb-3 text-lg font-medium"
                  style={{ color: Colors.Gray2 }}
                >
                  {formatToCurrency(lancamento)} BRL
                </p>
              </div>
              <div
                className="flex flex-col items-center flex-1 border border-r-0"
                style={{ borderColor: Colors.Gray4 }}
              >
                <p
                  className="mt-2 mb-3 text-lg"
                  style={{ color: Colors.Gray2, fontWeight: 300 }}
                >
                  Valor em negociação:
                </p>
                <p
                  className="mt-2 mb-3 text-lg font-medium"
                  style={{
                    color: negociacao > 0 ? Colors.Blue1 : Colors.Gray4,
                  }}
                >
                  {formatToCurrency(negociacao)} BRL
                </p>
              </div>
              <div
                className="flex flex-col items-center flex-1 border"
                style={{ borderColor: Colors.Gray4 }}
              >
                <p
                  className="mt-2 mb-3 text-lg"
                  style={{ color: Colors.Gray2, fontWeight: 300 }}
                >
                  Valor executado:
                </p>
                <p
                  className="mt-2 mb-3 text-lg font-medium"
                  style={{
                    color: Colors.Green3,
                  }}
                >
                  {formatToCurrency(executado)} BRL
                </p>
              </div>
            </div>

            <div className="w-full my-5 items-center">
              <p
                className="text-2xl text-center mb-3"
                style={{ color: statusColor }}
              >
                {Order.determineStatusText(orderDetails?.status)}
              </p>
              {orderDetails?.status === OrderStatusEnum.ACTIVE ? (
                <div
                  className="flex flex-row w-full overflow-hidden"
                  style={{ backgroundColor: Colors.Gray6 }}
                >
                  <div
                    className="w-1/12 relative fluentProgressBar-waiting"
                    style={{
                      backgroundColor: statusColor,
                      animationDelay: "0.2s",
                    }}
                  ></div>
                  <div
                    className="w-1/12 relative fluentProgressBar-waiting"
                    style={{
                      backgroundColor: statusColor,
                      animationDelay: "0.1s",
                    }}
                  ></div>
                  <div
                    className="w-1/3 relative fluentProgressBar-waiting"
                    style={{ backgroundColor: statusColor }}
                  ></div>
                </div>
              ) : (
                <div className="flex flex-row w-full overflow-hidden">
                  <div
                    className="w-full relative"
                    style={{ backgroundColor: statusColor, height: "4px" }}
                  ></div>
                </div>
              )}
            </div>

            {/* <p className='mb-5 w-full justify-start font-medium' style={{ color: Colors.Gray2 }}>Tokens nesta ordem: {tokenList.length}</p>

					<div className='flex flex-col w-full'>
						{
							orderDetails?.tokens.map(token => <CardActive token={token} key={token.id}/>)
						}
						{
							orderDetails?.matchs.map(match => <CardActive token={match} key={match.id}/>)
						}
					</div> */}

            <Divider full={true} top="mt-5 md:mt-10" bottom="mb-5 md:mb-10" />

            <BigButton
              className="skeleton-btn"
              onClick={stopOrderButton}
              disabled={
                !![
                  OrderStatusEnum.FULFILLED,
                  OrderStatusEnum.EXPIRED,
                  OrderStatusEnum.INACTIVE,
                  OrderStatusEnum.SUSPENDED,
                  OrderStatusEnum.CANCELED,
                ].find((elm) => elm === orderDetails?.status)
              }
            >
              Interromper esta ordem
            </BigButton>

            <UnderlineButton
              className={"skeleton-btn2 text-lg font-medium"}
              style={{ color: Colors.Gray3 }}
              onClick={() => history.push(RoutesPaths.MINHASORDENS)}
            >
              Voltar a listagem
            </UnderlineButton>
          </div>
          <br />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default OrderDetails;
