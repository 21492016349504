import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { StateError } from "../../types/StateError";
import { StateStatus, StateStatusType } from "../../types/StateStatus";
import { clearCrendentialsOnStorage } from "../../utils/clearCrendentialsOnStorage";
import { getCrendentialsOnStorage } from "../../utils/getCrendentialsOnStorage";
import { saveCrendentialsOnStorage } from "../../utils/saveCrendentialsOnStorage";
import { translateErrorMessages } from "../../utils/translateErrorMessages";
import { authSignin } from "./authAPI";
import { AuthInfo, AuthRequest } from "./authTypes";

export type AuthState = {
  auth: AuthInfo | undefined;
  error: StateError | undefined;
  status: StateStatusType;
};

const initialState: AuthState = {
  auth: undefined,
  error: undefined,
  status: StateStatus.idle,
};

export const signin = createAsyncThunk(
  "auth/signin",
  async (payload: AuthRequest, { rejectWithValue }) => {
    try {
      const response = await authSignin(payload);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    checkSignedIn: (state) => {
      const credentials = getCrendentialsOnStorage();

      if (
        !credentials?.accessToken ||
        !credentials?.expiresIn ||
        !credentials?.refreshToken
      )
        return;

      state.status = "idle";
      state.auth = { ...credentials };
    },
    signout: (state) => {
      state.status = "idle";
      state.auth = undefined;

      clearCrendentialsOnStorage();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state) => {
        state.status = "loading";
        state.error = undefined;
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = undefined;
        state.auth = action.payload;

        saveCrendentialsOnStorage(action.payload);
      })
      .addCase(signin.rejected, (state, action) => {
        const message = translateErrorMessages(String(action.payload));

        state.status = "failed";
        state.error = {
          error: true,
          message,
        };
      });
  },
});

export const { signout, checkSignedIn } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
