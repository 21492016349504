import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import Colors from "../../../../../assets/Colors";
import * as Gifs from "../../../../../assets/gifs";
import Button from "../../../../../components/Buttons/Button";
import AlertModal from "../../../../../components/Modal/AlertModal";
import "./PageSkeleton.css";
import TermsOfUseModal from "../../../../../components/TermsOfUseModal/TermsOfUseModal";

type MyProps = {
  title: string;
  subTitle: string;
  hidePageNote?: boolean;
  button: {
    label?: string;
    onClick(): any;
    disable?: boolean;
  };
  loading?: boolean;
  className?: string;
  button2?: any;
};

export const PageSkeleton: React.FC<React.PropsWithChildren<MyProps>> = ({
  title,
  subTitle,
  hidePageNote,
  children,
  button,
  button2,
  loading,
  className,
}) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [errorModalState, setErrorModalState] = useState<boolean>(false);
  const [textError] = useState<string>(
    "Verifique seu código de validação e tente novamente."
  );
  const [showTermsOfUse, setShowTermsOfUse] = useState<boolean>(false);

  useEffect(() => {
    if (loading) setLoadingState(loading);
  }, [loading]);

  async function aux() {
    try {
      await button.onClick();
    } catch (err) {
      const error = err as any;

      // setTextError('');
      // if (error && error.response) {
      //   setTextError(i18nTranslated(error.response.data.message));
      // } else if (error && error.message) {
      //   let msgError = 'API_ERROR_UNEXPECTED';
      //   if (IsJsonString(error.message)) {
      //     const erroObject = JSON.parse(error.message);
      //     if (erroObject.statusCode !== 500) {
      //       msgError = erroObject.message;
      //     }
      //   }

      //   setTextError(i18nTranslated(msgError));
      // }

      setErrorModalState(true);
      setLoadingState(false);
    }
  }

  return (
    <>
      <TermsOfUseModal
        isOpen={showTermsOfUse}
        handleState={setShowTermsOfUse}
      />
      <AlertModal
        state={errorModalState}
        title="Falha na validação."
        src={Gifs.IconAlertGif}
        confirmClick={() => setErrorModalState(false)}
      >
        <p>{textError}</p>
      </AlertModal>
      <div
        className={
          "pt-1 flex flex-col aling-center items-center mt-16 " +
          (className ?? "")
        }
      >
        <h1
          className="text-2xl font-medium mb-10 text-center"
          style={{ color: Colors.Gray2 }}
        >
          {title}
        </h1>
        <h2
          className="text-base font-normal text-center"
          style={{ color: Colors.Gray3 }}
        >
          {subTitle}
        </h2>

        <div className="flex flex-col items-center mt-5 mb-3 w-80">
          {children}
          {!hidePageNote && (
            <button onClick={() => setShowTermsOfUse(true)}>
              <p
                className="text-xs my-3 text-center w-full"
                style={{ color: Colors.Gray4 }}
              >
                Ao prosseguir declaro estar de acordo com os termos de uso e
                privacidade da plataforma.
              </p>
            </button>
          )}
        </div>

        <Button
          backgroundColor={Colors.UserBGButton}
          className="onboarding-first-btn mt-3 text-lg font-medium"
          onClick={aux}
          loading={loadingState}
          disabled={button.disable}
        >
          {button.label ?? "Prosseguir"}
        </Button>
        <br />
        {button2 && (
          <p
            className="underline cursor-pointer text-black"
            onClick={() => button2.onClick()}
          >
            {button2.label}
          </p>
        )}
      </div>
    </>
  );
};
