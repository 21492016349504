export enum PlataformLimitEnum {
  CREDIT_SELL = "CREDIT_SELL",
  CREDIT_BUY = "CREDIT_BUY",
  BOND_SELL = "BOND_SELL",
  BOND_BUY = "BOND_BUY",
  GOLD_SELL = "SELL",
  GOLD_BUY = "BUY",
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
}
