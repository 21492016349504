import React from "react";
import PropTypes from "prop-types";
import * as Icons from "../../assets/icons";
import Colors from "../../assets/Colors";
import "./PageTopBanner.css";

type PageTopBannerProps = {
  title?: string;
  subtitle?: string;
  bgIcon?: string;
  imgIcon?: string;
  borderlessInnerIcon?: boolean;
  loading?: boolean;
};

const PageTopBanner: React.FC<PageTopBannerProps> = ({
  title,
  subtitle,
  bgIcon,
  imgIcon,
  borderlessInnerIcon,
  loading,
}) => {
  const iconContainerStyle = !borderlessInnerIcon
    ? { border: "2px solid #DCDCDC" }
    : {};
  const iconBorderCss = borderlessInnerIcon
    ? ""
    : " border-2 md:p-6 p-4 bg-white";
  const innerIconClassName = borderlessInnerIcon
    ? "banner-inner-icon-borderless"
    : "banner-inner-icon-bordered";

  return (
    <div>
      {loading ? (
        <div className="animate-pulse w-full bg-gray-200 h-52"></div>
      ) : (
        <img className="w-full" src={bgIcon} />
      )}
      <div className="banner-content flex flex-col justify-center">
        <div className="flex flex-col items-center">
          {loading ? (
            <>
              <div className="banner-icon-container rounded-full animate-pulse bg-gray-400"></div>
              <div className="py-9">
                <p
                  className="banner-content-title justify-center font-normal"
                  style={{ color: Colors.Gray1 }}
                >
                  {
                    <>
                      <span className="font-bold">Carregando...</span>
                    </>
                  }
                </p>
              </div>
            </>
          ) : (
            <div
              className={"banner-icon-container rounded-full " + iconBorderCss}
              style={iconContainerStyle}
            >
              <img
                src={imgIcon}
                className={innerIconClassName}
                alt="banner inner icon"
              />
            </div>
          )}
          {title && (
            <div className="py-9">
              <p
                className="banner-content-title justify-center font-normal"
                style={{ color: Colors.Gray1 }}
              >
                {
                  <>
                    <span className="font-bold">
                      {" "}
                      {title} {subtitle ? "-" : ""}{" "}
                    </span>
                    <span className="">{subtitle}</span>
                  </>
                }
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageTopBanner;
