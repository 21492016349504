import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/// Assets
import Colors from "../../../assets/Colors";
import RoutesPaths from "../../../Routes/RoutesPaths";
import * as Icons from "./../../../assets/icons";
import { makeLogout } from "../../../services/jwt";

/// Components
import TopBar from "../../../components/TopBar/TopBar";
import Footer from "../../../components/Footer/Footer";
import FilterBar from "../../../components/FilterBar/FilterBar";
import { useContext } from "react";
import UserContext from "../../../contexts/UserProvider";
import { UserRoleEnum } from "../../../models/enums";
import { UserStatusEnum, ClientStatusEnum } from "../../../models";
import Modal from "../../../components/Modal/Modal";
import history from "../../../services/history";
import { getClientStatusBasedOnDocs } from "../../../utils/ClientUtils";

const Card: React.FC<{
  title: string;
  description: string;
  route?: RoutesPaths;
  onClick?: () => any;
  alert?: boolean;
}> = ({ title, description, route, onClick, alert }) => {
  return (
    <Link
      className={
        "bg-white px-3 py-2 rounded border flex flex-row w-full lg:w-2/5 my-1"
      }
      style={{ borderColor: Colors.Gray4 }}
      to={route ? route : "/conta"}
      onClick={onClick}
    >
      <div className="flex flex-col flex-grow">
        <h3 className="font-medium text-lg" style={{ color: Colors.Gray2 }}>
          {title}
        </h3>
        <p className="font-light" style={{ color: Colors.Gray3 }}>
          {description}
        </p>
      </div>
      {alert && (
        <div className="flex flex-col justify-center">
          <img
            src={Icons.AlertIcon}
            alt="AlertIcon"
            style={{ height: "25px", width: "25px" }}
          />
        </div>
      )}
    </Link>
  );
};

const AccoutPage: React.FC<any> = () => {
  const [modalExit, setModalExit] = useState(false);
  const userContext = useContext(UserContext);

  const openDocument = () => {
    history.push(RoutesPaths.ACCOUT_DOCUMENTS);
    // window.history.pushState({}, '', );
    // window.location.reload();
  };

  useEffect(() => {
    userContext.updateUser();
  }, []);

  return (
    <>
      <Modal
        width={"!w-1"}
        state={modalExit}
        cancelButtonClick={() => setModalExit(false)}
        confirmButtonClick={makeLogout}
        title={"Encerrar sessão?"}
        titleColor={"black"}
        confirmText="LOGOUT"
        cancelText="CANCELAR"
      >
        <p className="text-center" style={{ color: "#828282" }}>
          Após realizar o logout você será desconectado e redirecionado para a
          tela de login da plataforma.
        </p>
        <p className="text-center p-3" style={{ color: "#828282" }}>
          Deseja encerrar sua sessão?
        </p>
      </Modal>
      <div
        className="flex flex-col min-h-screen text-sm text-gray-400 font-sans"
        style={{ backgroundColor: Colors.GrayBackGroud }}
      >
        <TopBar />
        <main className="flex-grow w-full mb-5">
          <FilterBar title="Minha Conta" />

          <div className="flex flex-col items-center mt-10">
            <div
              className="rounded-full h-24 w-24 flex items-center justify-center "
              style={{ backgroundColor: Colors.Primary }}
            >
              <p className="text-5xl text-white font-medium">
                {userContext.user?.name[0].toUpperCase() ?? "U"}
              </p>
            </div>
            <h3
              className="my-2 text-xl font-semibold"
              style={{ color: Colors.Gray2 }}
            >
              {
                // format a a string pra comecar as palavras com letra maiuscula.
                userContext.user?.name
                  .toLowerCase()
                  .replace(/\b[a-z]/g, function (letra) {
                    return letra.toUpperCase();
                  }) ?? ""
              }
            </h3>

            <div className="w-full flex flex-row items-center justify-center px-5">
              {userContext.user?.role === UserRoleEnum.USER &&
                (() => {
                  let text1: string = "";
                  let text2: string = "";
                  let backgroundColor: string = "";

                  switch (getClientStatusBasedOnDocs(userContext.user)) {
                    case ClientStatusEnum.ANALYSIS:
                      text1 = "Sua conta está em análise.";
                      text2 =
                        "Por favor aguarde a verifucação de seus documentos.";
                      backgroundColor = Colors.Blue1;
                      break;
                    case ClientStatusEnum.CERTIFICATE:
                      text1 = "Sua conta está certificada.";
                      text2 = "Mantenha seus documentos sempre atualizados.";
                      backgroundColor = Colors.Green3;
                      break;
                    case ClientStatusEnum.CREATED:
                      text1 = "Sua conta ainda não está certificada.";
                      text2 =
                        "Clique aqui e realize agora a certificação da sua conta.";
                      backgroundColor = Colors.RedMyAccount;
                      break;
                    default:
                      return <></>;
                  }

                  return (
                    <button
                      className="my-2 py-3 px-4 rounded border flex flex-col w-full lg:w-2/5 items-center justify-center"
                      style={{
                        backgroundColor,
                        cursor: "pointer",
                      }}
                      onClick={() => openDocument()}
                    >
                      <p className="text-white font-medium md:text-base py-1">
                        {text1}
                      </p>
                      <p className="text-white md:text-sm py-1">{text2}</p>
                    </button>
                  );
                })()}
            </div>
            <br />

            <div className="flex flex-col w-full px-5 items-center">
              {userContext.user?.role === UserRoleEnum.USER && (
                <>
                  <Card
                    title="Limites e taxas"
                    description="Consulte seus limites e taxas de negociação"
                    route={RoutesPaths.ACCOUT_LIMITES}
                  />
                  <Card
                    title="Dados bancários"
                    description="Gerencie seus dados bancários para saques"
                    route={RoutesPaths.ACCOUT_BANK}
                  />
                  <Card
                    title="Endereço de cadastro"
                    description="Gerencie o endereço de cadastro de sua conta"
                    route={RoutesPaths.ACCOUT_ENDERECO}
                  />
                </>
              )}
              <Card
                title="Dados pessoais"
                description="Visualize seus dados pessoais de cadastro"
                route={RoutesPaths.ACCOUT_DATA}
              />
              {/* <Card title='Senha de acesso' description='Redefina sua senha' route={RoutesPaths.ACCOUT_PASSWORD} /> */}
              <Card
                title="Sair"
                description="Encerre sua sessão com segurança"
                onClick={() => {
                  setModalExit(true);
                }}
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default AccoutPage;
