import React from "react";
import Colors from "../../../../assets/Colors";
import { StylePatterns } from "../../../../assets/patterns_tailwind_class";
import Button from "../../../../components/Buttons/Button";

interface propsPageSkeleton {
  title: string;
  subTitle: string;
  finalNote?: string;
  loading?: boolean;
  nextPage: () => any;
  nextPageLabel?: string;
  prevPage?: () => any;
  buttonDisabled?: boolean;
}

const PageSkeleton: React.FC<React.PropsWithChildren<propsPageSkeleton>> = ({
  title,
  subTitle,
  children,
  finalNote,
  loading,
  prevPage,
  nextPage,
  nextPageLabel,
  buttonDisabled,
}) => {
  return (
    <div
      className={
        "pt-1 flex flex-col align-center items-center mt-16 " +
        StylePatterns.ELEMENT_WIDTH
      }
    >
      <h1
        className="text-2xl font-medium mb-10 text-center"
        style={{ color: Colors.Gray2 }}
      >
        {title}
      </h1>
      {subTitle && (
        <h2
          className="text-base font-normal mb-5 text-center"
          style={{ color: Colors.Gray3 }}
        >
          {subTitle}
        </h2>
      )}

      {children}

      {finalNote !== "" && (
        <div
          className="my-5 text-xs md:text-sm font-normal"
          style={{ color: Colors.Gray3 }}
        >
          <p className="underline text-center mb-1">ATENÇÃO:</p>
          <p className="text-center whitespace-pre-line">
            {finalNote ??
              "Usaremos os dados informados para identificar todos os depósitos e destinar os saques realizados por sua conta."}
          </p>
        </div>
      )}
      <div className="mb-5">
        <Button
          backgroundColor={Colors.UserBGButton}
          className="onboarding-first-btn mt-3 text-lg font-medium"
          loading={loading}
          disabled={buttonDisabled}
          onClick={nextPage}
        >
          {nextPageLabel ?? "Prosseguir"}
        </Button>
      </div>
    </div>
  );
};

export default PageSkeleton;
