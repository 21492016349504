import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as API from "../../../api";
import Colors from "../../../assets/Colors";
import DataRow from "../../../components/DataRow/DataRow";
import { DepositMethodsEnum } from "../../../models/enums/DepositMethods";
import RoutesPaths from "../../../Routes/RoutesPaths";
import history from "../../../services/history";
import CopyButton from "../../../components/Buttons/CopyButton";
import { CopyIcon } from "../../../assets/icons";
import "./DepositData.css";
import copyToClipboard from "../../../utils/copyToClipboard";
import AlertModal from "../../../components/Modal/AlertModal";
import * as Gifs from "../../../assets/gifs";

interface DepositDataComponentType {
  [key: string]: JSX.Element;
}

type depositData = API.bank.deposit | undefined;

type propsTypes = {
  depositData: depositData;
  hideModalInstruction?: Function;
  showInstructionModal?: Function;
};

const DepositDataPix: React.FC<propsTypes> = ({
  depositData,
  hideModalInstruction,
  showInstructionModal,
}) => {
  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const [qrCode, setQRCode] = useState<string>("");
  const [showCopiedTooltip, setShowCopiedTooltip] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  useEffect(() => {
    const showTimeout: number = 1500;

    if (showCopiedTooltip) {
      const timeout = setTimeout(
        () => setShowCopiedTooltip(false),
        showTimeout
      );
      return () => clearTimeout(timeout);
    }
  }, [showCopiedTooltip]);

  const copyText = () => {
    copyToClipboard(qrCode);
    setShowCopiedTooltip(true);
  };

  useEffect(() => {
    const amount = query.get("amount");

    if (!amount) {
      history.push(RoutesPaths.DEPOSIT);
    } else {
      loadQRCode(amount);
    }
  }, []);

  const loadQRCode = async (amount) => {
    try {
      const { qrcode } = await API.bank.getQRCode(amount);
      setQRCode(qrcode);
      if (showInstructionModal != undefined) {
        showInstructionModal();
      }
    } catch {
      setShowErrorModal(true);
      if (hideModalInstruction != undefined) {
        hideModalInstruction();
      }
      console.error("Ocorreu um erro ao gerar o QRCODE");
    }
  };

  return (
    <>
      <AlertModal
        src={Gifs.IconAlertGif}
        title="Atenção!"
        state={showErrorModal}
        buttonText="Tente Novamente"
        confirmClick={() => {
          history.push(RoutesPaths.DEPOSIT);
        }}
      >
        <p>Ocorreu um erro ao gera o QRCode!</p>
      </AlertModal>
      {qrCode ? (
        <section className="flex flex-col mb-6">
          <QRCode className="mb-6 my-4" size={256} value={qrCode} />
          <div className="my-0.5 text-base" style={{ color: Colors.Blue1 }}>
            {showCopiedTooltip ? (
              <div className="relative text-center">
                <span
                  className="relative z-10 p-1 rounded text-white no-underline"
                  style={{ backgroundColor: Colors.Green4 }}
                >
                  copiado
                </span>
              </div>
            ) : (
              <a
                className="underline cursor-pointer flex gap-2 justify-center"
                onClick={copyText}
              >
                <CopyIcon height={25} width={25} className="QRCopyIcon" />
                <h2 className="text-base  font-bold">{"Código do QR Code"}</h2>
              </a>
            )}
            <p className="text-sm text-center">
              Para pagar com PIX copia e cola
            </p>
          </div>
        </section>
      ) : (
        <section className="flex flex-col mb-6">
          <div
            className="rounded mb-4 my-4 animate-pulse w-full bg-gray-200"
            style={{ height: "256px", width: "256px" }}
          ></div>
          <div
            className="my-0.5 text-base text-center w-full"
            style={{ color: Colors.Blue1 }}
          >
            <div className="rounded animate-pulse w-2/3 m-auto self-center bg-gray-200 h-5 mt-1"></div>
            <div className="rounded animate-pulse w-5/6 m-auto self-center bg-gray-200 h-5 mt-1"></div>
          </div>
        </section>
      )}
      <DataRow title={"Favorecido: "} value={depositData?.favored ?? ""} />
      <DataRow title={"CNPJ: "} value={depositData?.document ?? ""} />
      <DataRow
        title={"Banco: "}
        value={`${depositData?.bank} (${depositData?.bankNumber})` ?? ""}
      />
      <DataRow title={"Agência: "} value={depositData?.bankAgency ?? ""} />
      <DataRow
        title={"Conta corrente: "}
        value={depositData?.bankAccount ?? ""}
      />
    </>
  );
};

const DepositDataTed: React.FC<{ depositData: depositData }> = ({
  depositData,
}) => {
  return (
    <>
      <DataRow title={"Favorecido: "} value={depositData?.favored ?? ""} />
      <DataRow title={"CNPJ: "} value={depositData?.document ?? ""} />
      <DataRow
        title={"Banco: "}
        value={`${depositData?.bank} (${depositData?.bankNumber})` ?? ""}
      />
      <DataRow title={"Agência: "} value={depositData?.bankAgency ?? ""} />
      <DataRow
        title={"Conta corrente: "}
        value={depositData?.bankAccount ?? ""}
      />
    </>
  );
};

const DepositData: React.FC<{
  depositMethod: DepositMethodsEnum;
  depositData: depositData;
  hideModalInstruction: Function;
  showInstructionModal: Function;
}> = ({
  depositMethod,
  depositData,
  hideModalInstruction,
  showInstructionModal,
}) => {
  const DepositDataComponent: DepositDataComponentType = {
    PIX: (
      <DepositDataPix
        depositData={depositData}
        hideModalInstruction={hideModalInstruction}
        showInstructionModal={showInstructionModal}
      />
    ),
    TED: <DepositDataTed depositData={depositData} />,
  };

  return (
    <>
      <h2
        className="font-normal text-lg mb-2 mt-5"
        style={{ color: Colors.Gray3 }}
      >
        DADOS PARA DEPÓSITO
      </h2>
      {DepositDataComponent[depositMethod] ?? null}
    </>
  );
};

export default DepositData;
