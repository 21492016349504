import { AxiosRequestConfig } from "axios";
import { PlataformLimitEnum } from "../models";
import { Client, ClientDocStatusEnum, TypeUserEnum } from "../models/clients";
import { TaxTypeEnum } from "../models/enums";
import api from "../services/api";

export const clients = {
  async loadClients({ type }: { type?: TypeUserEnum }): Promise<Array<Client>> {
    try {
      var config: AxiosRequestConfig = {};

      enum UserTypeEnum {
        physical = "physical",
        legal = "legal",
      }

      let auxType: any = null;
      if (type === TypeUserEnum.CORPORATE) {
        auxType = UserTypeEnum.legal;
      } else {
        auxType = UserTypeEnum.physical;
      }

      config.params = {
        type: auxType ?? null,
      };
      const response = await api.exchange.get(
        `/onboarding/admin/list/account`,
        config
      );

      if (response.status === 200) {
        return response.data.map((elm) => {
          return {
            ...elm,
            birthday: new Date(elm.birthday),
            type: elm.userType.toUpperCase(),
          };
        });
      } else if (response.status === 204) {
        return [];
      } else {
        throw response.data;
      }
    } catch (error) {
      throw error;
    }
  },
  async loadClientDetail(accountId: string) {
    const { status, data } = await api.exchange.get(
      `/onboarding/admin/account/${accountId}`
    );

    if (status === 200) {
      return data;
    }

    throw data;
  },
  async updateLimits(
    clientID: string,
    limits: Array<{ type: PlataformLimitEnum; value: number }>
  ): Promise<null> {
    await api.exchange.put(`/user/${clientID}/daily-limit`, limits);
    return null;
  },
  async updateNetworkFee(
    clientID: string,
    networkFees: Array<{ type: TaxTypeEnum; value: number }>
  ) {
    try {
      await api.exchange.put(`/clients/${clientID}/network/fee`, networkFees);
    } catch (error) {}
  },
  async updateDocumentStatus({
    clientId,
    featureId,
    status,
  }: {
    clientId: string;
    featureId: number;
    status: ClientDocStatusEnum;
  }) {
    return await api.exchange.put(
      `/onboarding/clients/${clientId}/documents/${featureId}`,
      { status }
    );
  },
};
