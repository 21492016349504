import React, { useContext, useRef } from "react";
import Colors, { CorPrincipal2 } from "../../assets/Colors";
import UserContext from "../../contexts/UserProvider";
import { UserRoleEnum } from "../../models/enums";
import RoutesPaths from "../../Routes/RoutesPaths";
import history from "../../services/history";

type MyProps = {
  state: boolean;
  closeSideBar(): void;
  active: boolean;
  renderRoutes: () => JSX.Element;
};

const SideBar = ({ state, closeSideBar, active, renderRoutes }: MyProps) => {
  const innerRef = useRef<any>(null);
  const userContext = useContext(UserContext);

  const isAdmin = () => userContext?.user?.role === UserRoleEnum.ADMIN;

  return (
    <>
      <div
        onClick={(e) => {
          // close sidebar when click outside it
          if (innerRef?.current && !innerRef.current?.contains(e.target)) {
            closeSideBar();
          }
        }}
        className={`fixed z-10 inset-0 overflow-y-auto ease-in-out transition transform ${
          state ? " translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-1 pb-20 text-center sm:block sm:p-0">
          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            ref={innerRef}
            className={`
                        inline-block align-bottom overflow-hidden
                        text-left sm:align-middle h-screen w-3/4
                        fixed inset-0
                    `}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style={{
              backgroundColor: isAdmin() ? Colors.BaseADM : Colors.Black1,
            }}
          >
            <div>
              <div
                style={{
                  backgroundColor: isAdmin() ? Colors.Black2 : Colors.Black1,
                }}
                className={`${
                  active ? "flex flex-row" : ""
                } px-2 py-5 justify-start items-center cursor-pointer`}
                onClick={() => history.push(RoutesPaths.ACCOUT)}
              >
                <div
                  style={{ backgroundColor: Colors.Black1 }}
                  className={`${
                    active ? "" : "hidden"
                  } rounded-full h-11 w-11 mr-2 flex items-center justify-center bg-red-600 border-2 border-white border-opacity-50 text-white text-2xl lg:hidden`}
                >
                  {userContext.user?.name[0]?.toUpperCase() ?? "U"}
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-sm font-bold text-white">
                    {userContext.user?.name}
                  </p>
                  <p className="text-xs font-normal text-white">
                    {userContext.user?.email}
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-full">
                {renderRoutes()}
                <span className="text-white absolute bottom-2 self-center text-opacity-60">
                  Termos de uso | Privacidade
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="fixed inset-0 transition-opacity"
        style={{ display: state ? "block" : "none" }}
        aria-hidden="true"
      >
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
    </>
  );
};

export default SideBar;
