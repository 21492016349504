import * as Icons from "../../assets/icons";

export enum DepositMethodsEnum {
  PIX = "PIX",
  TED = "TED",
  CREDIT = "CREDIT",
  BITCOIN = "BITCOIN",
}

export class DepositMethodEnumInterpreter {
  static convert(method: DepositMethodsEnum) {
    switch (method) {
      case DepositMethodsEnum.TED:
        return {
          translate: "Transferência bancária (TED)",
          icon: Icons.BankIcon,
        };
      case DepositMethodsEnum.PIX:
        return {
          translate: "Pagamento instantâneo (Pix)",
          icon: Icons.PixIcon,
        };
      case DepositMethodsEnum.CREDIT:
        return {
          translate: "Cartão de crédito",
          icon: Icons.CreditCardIcon,
        };
      case DepositMethodsEnum.BITCOIN:
        return {
          translate: "Bitcoin (BTC)",
          icon: Icons.BitcoinIcon,
        };
    }
  }

  static translate(method: DepositMethodsEnum): string {
    return this.convert(method).translate;
  }
  static getIcon(method: DepositMethodsEnum): string {
    return this.convert(method).icon;
  }
}
