import React, { useRef, useEffect } from "react";
import { useField } from "@unform/core";

type MyProps = {
  name: string;
  refPath?: string;
  children({ ref, error, defaultValue }): JSX.Element;
};

const UnformInputAdapt = ({ name, refPath, children }: MyProps) => {
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,

      getValue: (ref) => {
        if (refPath) return ref.current[refPath].value;
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return children({ ref: inputRef, error, defaultValue });
};

export default UnformInputAdapt;
