import api from "../services/api";

export const auth = {
  async signin({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<auth.signin> {
    try {
      const { status, data } = await api.exchange.post("/auth/signin", {
        email,
        password,
      });

      if (status === 200 || status === 201) {
        return {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          expiresIn: data.expiresIn,
        };
      } else {
        throw data;
      }
    } catch (error) {
      throw error;
    } finally {
      localStorage.removeItem("password");
    }
  },
  async signup(): Promise<auth.signin> {
    try {
      const { status, data } = await api.exchange.post("/auth/signin", {});

      if (status === 200 || status === 201) {
        return {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          expiresIn: data.expiresIn,
        };
      } else {
        throw new Error(data);
      }
    } catch (error) {
      throw error;
    }
  },
  async refresh(refreshToken: string): Promise<auth.signin> {
    try {
      const { status, data } = await api.exchange.post("/auth/refresh", {
        refreshToken,
      });

      if (status === 200) {
        return {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          expiresIn: data.expiresIn,
        };
      } else {
        throw new Error(data);
      }
    } catch (error) {
      throw error;
    }
  },
  async invalidate(refreshToken: string): Promise<null> {
    try {
      const { status, data } = await api.exchange.post("/auth/invalidate", {
        refreshToken,
      });

      if (status === 200) {
        return null;
      } else {
        throw new Error(data);
      }
    } catch (error) {
      throw error;
    }
  },
  async getTenantInfo(): Promise<any> {
    try {
      const { status, data } = await api.exchange.get("/auth/tenant/info");

      if (status === 200 || status === 201) {
        return data;
      } else {
        throw new Error(data);
      }
    } catch (error) {
      throw error;
    }
  },
};

// eslint-disable-next-line no-redeclare
export namespace auth {
  export type signin = {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
  };
}
