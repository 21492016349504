import React from "react";
import Colors from "../../../assets/Colors";
import * as Icons from "../../../assets/icons";
import "./InlineInput.css";

export type InlineInputProps = {
  value?: string;
  error?: string;
  placeholder?: string;
  label?: string;
  bottomText?: string;
  type?: "text" | "number" | "date";
  className?: string;
  inputBoxClassName?: string;
  textColorActive?: boolean;
  onIconClick?: () => any;
  onChange?: (selectedOption) => any;
};
interface MyProps extends InlineInputProps {
  children(ref: any): JSX.Element | boolean;
}

const InlineInput = React.forwardRef<HTMLInputElement, MyProps>(
  (
    {
      value,
      placeholder,
      label,
      bottomText,
      className,
      inputBoxClassName,
      type,
      textColorActive,
      onIconClick,
      onChange,
      children,
    },
    ref
  ) => {
    return (
      <div className="mb-2">
        <div
          className={`flex items-left flex-col justify-center flex-grow rounded border px-5 py-4 mb-2 ${className}`}
        >
          <div
            className={`flex flex-grow items-center gap-3 w-full ${inputBoxClassName}`}
            style={{ borderColor: Colors.Gray4 }}
          >
            {type === "date" && (
              <img src={Icons.CalendarIcon} onClick={onIconClick} />
            )}
            {label && (
              <span
                className="inline text-xl font-normal"
                style={{ color: Colors.Gray3 }}
              >
                {label}
              </span>
            )}
            {children(ref) ? (
              children(ref)
            ) : (
              <input
                ref={ref}
                className="inline-input w-full text-xl mr-4"
                type={type}
                placeholder={placeholder}
                value={undefined}
              />
            )}
          </div>
        </div>
        {bottomText && (
          <p
            className="pl-5 text-xs font-normal"
            style={{ color: Colors.Gray3 }}
          >
            {bottomText}
          </p>
        )}
      </div>
    );
  }
);

InlineInput.defaultProps = {
  className: "",
  type: "text",
  textColorActive: false,
};

export default InlineInput;
