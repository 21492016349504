import React, { useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import * as API from "../../../../api";
import { getIdOnboarding, setIdOnboarding } from "../../../../api";
import Colors from "../../../../assets/Colors";
import * as Gifs from "../../../../assets/gifs";
import FloatInput from "../../../../components/Inputs/FloatInput";
import PhoneCodeInput from "../../../../components/Inputs/PhoneCodeInput/PhoneCodeInput";
import PhoneInputCustom from "../../../../components/Inputs/PhoneInput/PhoneInput";
import AlertModal from "../../../../components/Modal/AlertModal";
import useInterruptionModal from "../../../../hooks/useInterruptionModal";
import { useTemporaryAuth } from "../../../../hooks/useTemporaryAuth";
import RoutesPaths from "../../../../Routes/RoutesPaths";
import { makeLogin } from "../../../../services/jwt";
import inputValidations from "../../../../utils/inputValidations";
import {
  regexSpecialCharacters,
  regexStrongPassword,
} from "../../../../utils/RegextUtils";
import { PageSkeleton } from "./PageSkeleton/PageSkeleton";

const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

interface BasicPropsPages {
  nextPage(): any;
}

interface EmailRegisterProps extends BasicPropsPages {
  setAccountId(p: string): any;
}
export const EmailRegister: React.FC<EmailRegisterProps> = (props) => {
  const [emailValidate, setEmailValidate] = useState<{
    error: boolean;
    first: boolean;
  }>({ error: false, first: true });
  const [userExistModalState, setUserExistModalState] =
    useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [emailValue, setEmailValue] = useState<string>("");

  const [ref_pswd1] = useState<React.RefObject<HTMLInputElement>>(useRef(null));
  const [ref_pswd2] = useState<React.RefObject<HTMLInputElement>>(useRef(null));
  const [pswd1, setPswd1] = useState<string>("");
  const [pswd2, setPswd2] = useState<string>("");

  const [minChars, setMinChars] = useState<boolean>(false);
  const [passwordMatch, setPasswordMatch] = useState<boolean>(false);
  const [weakPassword, setWeakPassword] = useState<boolean>(true);
  const [hasUppercase, setHasUppercase] = useState<boolean>(false);
  const [hasLowercase, setHasLowercase] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] =
    useState<boolean>(false);

  const [error, setError] = useState<string | undefined>();
  const [firstSubmit, setFirstSubmit] = useState<boolean>(true);

  const [passwordIsInvalid, setPasswordIsInvalid] = useState<boolean>(true);
  const [emailValidationStatus, setEmailValidationStatus] = useState({
    messageError: "",
    touched: false,
  });

  const [modalError, setModalError] = useState<boolean>(false);

  const { setAuthData } = useTemporaryAuth();

  const validatePassword = () => {
    const regexUppercase = /(?=.*[A-Z])/;
    const regexLowercase = /(?=.*[a-z])/;
    const regexNumber = /(?=.*\d)/;

    const errors: string[] = [];
    let isValid = true;

    if (pswd1.length < 8) {
      errors.push("Senha muito curta!");
      setMinChars(false);
      isValid = false;
    } else {
      setMinChars(true);
    }

    if (pswd1 !== pswd2) {
      errors.push("Senhas não coicidem!");
      setPasswordMatch(false);
      isValid = false;
    } else {
      setPasswordMatch(true);
    }

    if (!regexStrongPassword.test(pswd1)) {
      errors.push("Senha muito fraca!");
      setWeakPassword(true);
      isValid = false;
    } else {
      setWeakPassword(false);
    }

    if (regexUppercase.test(pswd1)) {
      setHasUppercase(true);
    } else {
      errors.push("Senha deve possuir letra maiúscula!");
      setHasUppercase(false);
      isValid = false;
    }

    if (regexLowercase.test(pswd1)) {
      setHasLowercase(true);
    } else {
      errors.push("Senha deve possuir letra minúscula!");
      setHasLowercase(false);
      isValid = false;
    }

    if (regexNumber.test(pswd1)) {
      setHasNumber(true);
    } else {
      errors.push("Senha deve possuir números!");
      setHasNumber(false);
      isValid = false;
    }

    if (regexSpecialCharacters.test(pswd1)) {
      setHasSpecialCharacter(true);
    } else {
      errors.push(
        `Senha deve conter pelo menos 1 caracter especial! (ex.: " ! @ # $ % & *)`
      );
      setHasSpecialCharacter(false);
      isValid = false;
    }

    setPasswordIsInvalid(!isValid);

    setError(errors.join("\n"));
  };

  useEffect(() => {
    validatePassword();
  }, [pswd1, pswd2]);

  const validate = async (input = emailValue) => {
    try {
      const data = await Yup.string().email().required().validate(input);
      setEmailValidate({ ...emailValidate, error: false });
      return data;
    } catch (error) {
      setEmailValidate({ ...emailValidate, error: true });
    }
  };

  const onClick = async () => {
    try {
      const email = await validate(emailValue);
      if (emailValidate.first) {
        setEmailValidate({ ...emailValidate, first: false });
      }

      if (!email) return;

      const { user } = await API.signup.registerEmailPassword(email, pswd1);

      setAuthData(email, pswd1);

      setIdOnboarding(user.id);
      props.setAccountId(user.id);

      await API.signup.requestEmailVerificationCode(user.id);

      props.nextPage();
    } catch (e: any) {
      if (e.message && e.message.includes("USER_ALREADY_EXISTS"))
        setUserExistModalState(true);
    }
  };

  const emailValidation = (email: string) => {
    inputValidations.email.isValid(email);
    setEmailValidationStatus((status) => {
      return {
        ...status,
        messageError: inputValidations.email.getErrorMessage(),
      };
    });
  };

  const emailBlurHandler = (event) => {
    setEmailValidationStatus((status) => {
      return {
        ...status,
        touched: true,
      };
    });
    const email = event.target.value.toString().trim();
    setEmailValue(email);
    emailValidation(email);
  };

  const emailChangeValidateHandler = (event) => {
    if (!emailValidationStatus.touched) {
      return;
    }
    const email = event.target.value.toString().trim();
    setEmailValue(email);
    emailValidation(email);
  };

  const buttonDisabled = () => {
    return (
      emailValue.toString().trim() === "" ||
      inputValidations.email.isNotValid(emailValue) ||
      passwordIsInvalid
    );
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      onClick();
    }
  };

  API.clearStepOnboarding();

  return (
    <>
      <AlertModal
        state={modalError}
        title="Ops, temos um problema"
        src={Gifs.IconAlertGif}
        confirmClick={() => setModalError(false)}
      >
        <p>Verifique os campos e tente novamente por favor.</p>
      </AlertModal>

      <AlertModal
        title="Ops, Este usuário já existe."
        src={Gifs.IconAlertGif}
        iconSize="32"
        state={userExistModalState}
        confirmClick={() => {
          setUserExistModalState(false);
        }}
      >
        <div className="flex flex-col gap-2 mt-6">
          <p>
            Este email já está cadastrado
            <br /> Tente fazer o{" "}
            <Link to={RoutesPaths.SIGNIN}>
              <span style={{ textDecoration: "underline" }}>login</span>
            </Link>{" "}
            na plataforma.
          </p>
        </div>
      </AlertModal>

      <PageSkeleton
        loading={loadingState}
        title="Cadastre-se agora"
        subTitle="Informe seus dados de cadastro:"
        button={{
          onClick,
          disable: buttonDisabled(),
        }}
      >
        <FloatInput
          big={true}
          type="email"
          placeholder="Email de cadastro"
          className="w-full my-3"
          error={emailValidationStatus.messageError}
          onChange={emailChangeValidateHandler}
          onBlur={emailBlurHandler}
          onKeyDown={handleKeyDown}
        />

        <FloatInput
          ref={ref_pswd1}
          big={true}
          type="password"
          className="w-full my-3"
          placeholder="Senha de acesso"
          onChange={({ target }) => setPswd1(target.value)}
          onKeyDown={handleKeyDown}
        />
        <FloatInput
          ref={ref_pswd2}
          big={true}
          type="password"
          className="w-full my-3"
          placeholder="Confirmar senha de acesso"
          onChange={({ target }) => setPswd2(target.value)}
          onKeyDown={handleKeyDown}
        />

        {(pswd1 || pswd2) && (
          <ul className="text-base" style={{ color: Colors.Gray2 }}>
            <li>
              <span
                className={hasUppercase ? "text-green-500" : "text-red-400"}
              >
                <input type="checkbox" checked={hasUppercase} readOnly /> Ao
                menos 1 letra maiúscula
              </span>
            </li>
            <li>
              <span
                className={hasLowercase ? "text-green-500" : "text-red-400"}
              >
                <input type="checkbox" checked={hasLowercase} readOnly /> Ao
                menos 1 letra minúscula
              </span>
            </li>
            <li>
              <span className={hasNumber ? "text-green-500" : "text-red-400"}>
                <input type="checkbox" checked={hasNumber} readOnly /> Ao menos
                1 Número
              </span>
            </li>
            <li>
              <span
                className={
                  hasSpecialCharacter ? "text-green-500" : "text-red-400"
                }
              >
                <input type="checkbox" checked={hasSpecialCharacter} readOnly />{" "}
                1 caracter especial (ex: " ! @ # $ % & *)
              </span>
            </li>
            <li>
              <span className={minChars ? "text-green-500" : "text-red-400"}>
                <input type="checkbox" checked={minChars} readOnly /> Mínimo de
                8 caracteres
              </span>
            </li>
            <li>
              <span
                className={passwordMatch ? "text-green-500" : "text-red-400"}
              >
                <input type="checkbox" checked={passwordMatch} readOnly />{" "}
                Confirmação de senha está correta
              </span>
            </li>
          </ul>
        )}
      </PageSkeleton>
      {error && !firstSubmit ? (
        <p className="font-light underline text-red-600">{error}</p>
      ) : (
        <></>
      )}
    </>
  );
};

interface EmailKeyRegisterProps extends BasicPropsPages {
  setCurrentPage(p: number): any;
  accountId: string;
}
export const EmailKeyRegister: React.FC<EmailKeyRegisterProps> = (props) => {
  const [modalVerifyEmail, setModalVerifyEmail] = useState<boolean>(false);
  const [modalVerifyEmailError, setModalVerifyEmailError] =
    useState<boolean>(false);
  const [resendCodeTimer, setResendCodeTimer] = useState<number>(120);
  const [inputValidate, setInputValidate] = useState<{
    error: boolean;
    first: boolean;
  }>({ error: false, first: true });
  const [inputValue, setInputValue] = useState<string>();

  const { getAuthData } = useTemporaryAuth();

  if (resendCodeTimer >= 0) {
    setTimeout(() => {
      setResendCodeTimer(resendCodeTimer - 1);
    }, 1000);
  }

  const resend = async () => {
    if (props.accountId)
      await API.signup.requestEmailVerificationCode(props.accountId);
    setResendCodeTimer(120);
  };

  const validate = async (input = inputValue) => {
    try {
      const data = await Yup.string().min(1).required().validate(input);
      setInputValidate({ ...inputValidate, error: false });
      return data;
    } catch (error) {
      setInputValidate({ ...inputValidate, error: true });
    }
  };

  const onClick = async () => {
    const code = await validate(inputValue);
    const id = getIdOnboarding();

    if (inputValidate.first)
      setInputValidate({ ...inputValidate, first: false });
    if (!code || !id) return;

    try {
      await API.signup.sendEmailVerificationCode(props.accountId, code);

      const authData = getAuthData();

      const email = authData?.login ?? "";
      const pswd1 = authData?.password ?? "";

      const { accessToken, refreshToken } = await API.auth.signin({
        email,
        password: pswd1,
      });

      makeLogin(accessToken, refreshToken);

      setModalVerifyEmail(true);
    } catch (err) {
      setModalVerifyEmailError(true);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !inputValidate.error) {
      onClick();
    }
  };

  return (
    <>
      <AlertModal
        src={Gifs.IconOkGif}
        iconSize="32"
        state={modalVerifyEmail}
        title="Acesso criado"
        confirmClick={() => {
          setModalVerifyEmail(false);
          props.nextPage();
        }}
      >
        <div className="flex flex-col gap-2 mt-6">
          <p>
            A partir de agora utilize seu email e senha de cadastro para acessar
            a plataforma.
          </p>
          <p className="text-center whitespace-pre-line">
            {`Clique no botão para continuar o processo de ${"\n"}criação da sua conta.`}
          </p>
        </div>
      </AlertModal>

      <AlertModal
        src={Gifs.IconAlertGif}
        iconSize="32"
        state={modalVerifyEmailError}
        title="Erro ao validar o código"
        confirmClick={() => {
          setModalVerifyEmailError(false);
        }}
      >
        <div className="flex flex-col gap-2 mt-6">
          <p>
            Houve um erro ao tentar validar o código, o mesmo pode ter expirado
            ou ter sido digitado incorretamente, verifique o campo e tente
            novamente
          </p>
        </div>
      </AlertModal>

      <PageSkeleton
        title="Enviamos um email de validação para você!"
        subTitle="Informe o código recebido via email:"
        hidePageNote
        button={{ onClick }}
      >
        <FloatInput
          big={true}
          type="text"
          placeholder="Código de confirmação"
          className="w-full my-3"
          error={
            inputValidate.error
              ? "É necessário informar o código recebido via email"
              : undefined
          }
          onChange={({ target }) => {
            if (!inputValidate.first) validate(target.value);
            setInputValue(target.value);
          }}
          onKeyDown={handleKeyDown}
        />
      </PageSkeleton>
      {resendCodeTimer > 0 ? (
        <p
          className="text-base underline cursor-not-allowed"
          style={{ color: Colors.Gray2 }}
        >
          Reenviar código
          <span className="font-light"> ({resendCodeTimer} seg..)</span>
        </p>
      ) : (
        <p
          className="font-light underline text-red-600 cursor-pointer"
          onClick={resend}
        >
          Clique aqui para reenviar código de acesso por email
        </p>
      )}
    </>
  );
};

interface PasswordRegisterProps extends BasicPropsPages {
  emailCode: string;
}
export const PasswordRegister: React.FC<PasswordRegisterProps> = (props) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [errorModalState, setErrorModalState] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>("");
  const [ref_pswd1] = useState<React.RefObject<HTMLInputElement>>(useRef(null));
  const [ref_pswd2] = useState<React.RefObject<HTMLInputElement>>(useRef(null));
  const [firstSubmit, setFirstSubmit] = useState<boolean>(true);
  const [pswd1, setPswd1] = useState<string>("");
  const [pswd2, setPswd2] = useState<string>("");
  const [minChars, setMinChars] = useState<boolean>(false);
  const [passwordMatch, setPasswordMatch] = useState<boolean>(false);
  const [weakPassword, setWeakPassword] = useState<boolean>(true);

  useEffect(() => {
    const errors: string[] = [];

    if (pswd1.length < 8) {
      errors.push("Senha muito curta!");
      setMinChars(false);
    } else {
      setMinChars(true);
    }

    if (pswd1 !== pswd2) {
      errors.push("Senhas não coicidem!");
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }

    if (!regexStrongPassword.test(pswd1)) {
      errors.push("Senha muito fraca!");
      setWeakPassword(true);
    } else {
      setWeakPassword(false);
    }

    setError(errors.join("\n"));
  }, [pswd1, pswd2]);

  const onClick = async () => {
    setLoadingState(true);
    setFirstSubmit(false);
    if (!error) {
      const id = getIdOnboarding();
      if (id) {
        try {
          await API.signup.registerPassword(pswd1, id);
          await API.signup.approveTerms(id);
          props.nextPage();
          setLoadingState(false);
        } catch (err) {
          console.error(err);
          const error = err as any;

          setErrorModalState(true);
          setLoadingState(false);
        }
      }
    } else {
      setLoadingState(false);
    }
  };

  return (
    <>
      <AlertModal
        state={errorModalState}
        title="Ops, Tivemos um problema."
        src={Gifs.IconAlertGif}
        confirmClick={() => setErrorModalState(false)}
      >
        <p>O sistema identificou uma irregularidade.</p>
        <p>{textError}</p>
      </AlertModal>
      <PageSkeleton
        loading={loadingState}
        title="Defina uma senha de acesso!"
        subTitle="Mínimo de 8 caracteres contendo ao menos 1 letra maiuscula, 1 letra minuscula, 1 caracter especial e 1 número"
        button={{ onClick }}
      >
        <FloatInput
          ref={ref_pswd1}
          big={true}
          type="password"
          className="w-full my-3"
          placeholder="Senha de acesso"
          onChange={({ target }) => setPswd1(target.value)}
        />
        <FloatInput
          ref={ref_pswd2}
          big={true}
          type="password"
          className="w-full my-3"
          placeholder="Confirmar senha de acesso"
          onChange={({ target }) => setPswd2(target.value)}
        />

        {(pswd1 || pswd2) && (
          <ul className="mx-14 text-sm" style={{ color: Colors.Gray2 }}>
            <li>
              <span className={minChars ? "text-green-500" : "text-red-400"}>
                <input type="checkbox" checked={minChars} readOnly /> Contém
                mais de 8 caracteres
              </span>
            </li>
            <li>
              <span
                className={passwordMatch ? "text-green-500" : "text-red-400"}
              >
                <input type="checkbox" checked={passwordMatch} readOnly />{" "}
                Senhas coincidem
              </span>
            </li>
            <li>
              <span
                className={!weakPassword ? "text-green-500" : "text-red-400"}
              >
                <input type="checkbox" checked={!weakPassword} readOnly /> Senha
                forte
              </span>
            </li>
          </ul>
        )}
      </PageSkeleton>
      {error && !firstSubmit ? (
        <p className="font-light underline text-red-600">{error}</p>
      ) : (
        <></>
      )}
    </>
  );
};

interface CellPhoneRegisterProps extends BasicPropsPages {
  setPhoneMain(p: string): any;
}
export const CellPhoneRegister: React.FC<CellPhoneRegisterProps> = (props) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");
  const [errorModal, setErrorModal] = useState<boolean>(false);

  useInterruptionModal();

  const onClick = async () => {
    setLoadingState(true);
    const id = getIdOnboarding();
    if (!id) return;

    if (inputValidations.phone.isNotValid(phone)) {
      setLoadingState(false);
      setErrorModal(true);
      return;
    }

    await API.signup.registerPhone(phone, id);
    await API.signup.resendCodeVerifyPhone(id);
    setLoadingState(false);
    props.setPhoneMain(phone);
    props.nextPage();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && phone) {
      onClick();
    }
  };

  return (
    <>
      <PageSkeleton
        loading={loadingState}
        title="Informe seu número de celular"
        subTitle="Informe abaixo o nº para validarmos seu celular:"
        hidePageNote
        button={{
          onClick,
        }}
      >
        <AlertModal
          state={errorModal}
          title="Ops, Tivemos um problema."
          src={Gifs.IconAlertGif}
          confirmClick={() => setErrorModal(false)}
        >
          <p>
            O número de telefone digitado não parece válido, verifique-o e tente
            novamente, por favor.
          </p>
        </AlertModal>
        <PhoneInputCustom
          className="w-full"
          country={"br"}
          value={phone}
          change={(phone) => setPhone(phone)}
          onKeyDown={handleKeyDown}
        />
      </PageSkeleton>
    </>
  );
};

interface CellPhoneKeyRegisterProps extends BasicPropsPages {
  phone: string | undefined;
  previousPage(): any;
}
export const CellPhoneKeyRegister: React.FC<CellPhoneKeyRegisterProps> = (
  props
) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [resendCodeTimer, setResendCodeTimer] = useState<number>(120);
  const [modalVerifyCell, setModalVerifyCell] = useState<boolean>(false);
  const [modalResendSMS, setModalResendSMS] = useState<boolean>(false);
  const [phoneCode, setPhoneCode] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    props.phone
  );

  useInterruptionModal();

  if (resendCodeTimer >= 0) {
    setTimeout(() => {
      setResendCodeTimer(resendCodeTimer - 1);
    }, 1000);
  }

  useEffect(() => {
    if (!phoneNumber) {
      API.user.getUserInfo().then((user) => setPhoneNumber(user?.phoneNumber));
    }
  }, []);

  const resend = async () => {
    const id = getIdOnboarding();

    if (id && phoneNumber) {
      await API.signup.resendCodeVerifyPhone(id);
    }
    setResendCodeTimer(120);
  };

  const onClick = async () => {
    const id = getIdOnboarding();

    if (!id || !phoneCode) return;
    setLoadingState(true);

    await API.signup.confirmPhone(phoneCode, id);
    setLoadingState(false);
    setModalVerifyCell(true);
  };

  const getFormattedPhoneNumber = () => {
    // currently formatting only Brazil phone number
    let formattedPhoneNumber = phoneNumber;
    let countryCode = phoneNumber?.substring(0, 3);
    if (countryCode === "+55") {
      let dddNumber = phoneNumber?.substring(3, 5);
      formattedPhoneNumber = `${countryCode} ${dddNumber} ${phoneNumber?.substr(
        -9,
        5
      )}-${phoneNumber?.substr(-4, 4)}`;
    }

    return formattedPhoneNumber;
  };

  return (
    <>
      <AlertModal
        src={Gifs.CadastroSMSGif}
        iconSize="40"
        state={modalVerifyCell}
        title="Celular verificado com sucesso"
        confirmClick={() => {
          setModalVerifyCell(false);

          // Comentado pois estava enviando para uma pagina em branco,
          // mas nextPage() seria o certo se isso nao acontecesse
          // props.nextPage();
          window.location.reload();
        }}
      >
        <div className="flex flex-col gap-2 my-4">
          <p>Clique no botão para prosseguir.</p>
        </div>
      </AlertModal>

      <AlertModal
        state={modalResendSMS}
        title="Código SMS reenviado"
        confirmClick={() => {
          setModalResendSMS(false);
        }}
      >
        <div className="flex flex-col gap-2 my-4">
          <p>O código SMS foi reenviado para o email informado.</p>
        </div>
      </AlertModal>

      <PageSkeleton
        loading={loadingState}
        title="Enviamos um SMS de validação para você!"
        subTitle="Informe o código SMS recebido no nº informado:"
        button={{ onClick }}
        hidePageNote
      >
        {phoneNumber && (
          <div
            className="flex gap-2 mx-5 mb-6 text-lg font-normal p-4 w-full border rounded-sm cursor-pointer hover:border-gray-300"
            style={{ backgroundColor: Colors.Gray6 }}
            onClick={props.previousPage}
          >
            <h4 style={{ color: Colors.Blue1 }}>{getFormattedPhoneNumber()}</h4>
            <h4 className="underline" style={{ color: Colors.Gray4 }}>
              alterar número
            </h4>
          </div>
        )}

        <PhoneCodeInput length={6} change={(p) => setPhoneCode(p)} />
      </PageSkeleton>
      {resendCodeTimer > 0 ? (
        <p
          className="text-base underline cursor-not-allowed"
          style={{ color: Colors.Gray2 }}
        >
          Reenviar código SMS
          <span className="font-light"> ({resendCodeTimer} seg..)</span>
        </p>
      ) : (
        <p
          className="font-light underline text-red-600 cursor-pointer"
          onClick={resend}
        >
          Clique aqui para reenviar código SMS
        </p>
      )}
    </>
  );
};
