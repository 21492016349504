import moment from "moment-timezone";

export const isMinDate = (mydate: Date): boolean =>
  mydate.getTime() == getMinDate().getTime();
export const isMaxDate = (mydate: Date): boolean =>
  mydate.getTime() === getMaxDate().getTime();

export function getMinDate() {
  return new Date(0);
}
export function getMaxDate() {
  // MAX javascript Date new Date(100000000 * 8.64e7);
  // return '9999-12-05T08:53:20.000Z'
  return new Date(100000000 * 2534000);
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d as any);
}

export function formatRescueDate(dt: Date): string {
  let aux = moment.tz(dt, "GMT");
  return isMinDate(aux.toDate()) || isMaxDate(aux.toDate())
    ? "indefinido"
    : aux.format("DD/MM/YYYY");
}
