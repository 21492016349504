import { Token } from ".";
import Colors from "../assets/Colors";

export class Stament {
  static determineDescriptionText(stament: Stament): {
    description: string;
    details: string;
  } {
    switch (stament.type) {
      // case TypeStatementEnum.:
      //     return 'Taxa de saque'
      // case TypeStatementEnum.:
      //     return 'Taxa de rede sobre venda  “CODIGODOATIVO”';
      // case 'SELL':
      //     return 'Deságio sobre venda “CODIGODOATIVO”';
      // case 'BUY':
      //     return 'Lucro realizado recebível “CODIGODOATIVO”';
      // case 'BALANCE_REMOVED_IN_NEGOCIATION':
      //     return 'Saldo liberado em negociação “CODDAORDEM’';
      // case 'DEPOSIT_WITH_TED':
      //     return 'Saldo retido em negociação “CODDAORDEM’';

      case TypeStatementEnum.WITHDRAW:
        return {
          description: "Saque via PIX",
          details: "Saque em BRL (PIX)",
        };
      case TypeStatementEnum.DEPOSIT:
        return {
          description: "Depósito via PIX",
          details: "Depósito em BRL identificado (PIX)",
        };
      case TypeStatementEnum.PAYMENT_BY_TOKEN:
        return {
          description: `Ordem de compra “${stament.orderId}”`,
          details: `Order de compra “${stament.orderId}”`,
        };
      case TypeStatementEnum.RECEIVE_SALE_TOKEN:
        return {
          description: `Ordem de venda “${stament.orderId}”`,
          details: `Ordem de venda “${stament.orderId}”`,
        };
      case TypeStatementEnum.INSURANCE_FEE_DISCOUNT:
        return {
          description:
            `Taxa de seguro ` +
            (stament.percentFee ? `(${stament.percentFee.toFixed(2)}%)` : ""),
          details:
            `Taxa de seguro ` +
            (stament.percentFee ? `(${stament.percentFee.toFixed(2)}%)` : ""),
        };
      case TypeStatementEnum.NEGOCIATION_FEE_DISCOUNT:
        return {
          description:
            `Taxa de negociação ` +
            (stament.percentFee ? `(${stament.percentFee.toFixed(2)}%)` : ""),
          details:
            `Taxa de negociação ` +
            (stament.percentFee ? `(${stament.percentFee.toFixed(2)}%)` : ""),
        };
      case TypeStatementEnum.NETWORK_FEE_DISCOUNT:
        return {
          description: `Taxa de rede sobre venda “${stament.orderId}”`,
          details:
            "Custos operacionais sobre negociação " +
            (stament.percentFee ? `(${stament.percentFee.toFixed(2)}%)` : ""),
        };
      case TypeStatementEnum.CONSULT_BOND_FEE_DISCOUNT:
        return {
          description:
            `Taxa de consulta da NF ` +
            (stament.percentFee ? `(${stament.percentFee.toFixed(2)}%)` : ""),
          details:
            `Taxa de consulta da NF ` +
            (stament.percentFee ? `(${stament.percentFee.toFixed(2)}%)` : ""),
        };
      case TypeStatementEnum.SELL_DISCOUNT_FEE:
        return {
          description: "Ordem de Venda: “CODIGODOATIVO”".replaceAll(
            "CODIGODOATIVO",
            stament.orderId
          ),
          details: "Ordem de Venda: “CODIGODOATIVO”".replaceAll(
            "CODIGODOATIVO",
            stament.orderId
          ),
        };
      case TypeStatementEnum.WITHDRAW_FEE_DISCOUNT:
        return {
          description: "Taxa de saque",
          details: "Taxa de saque em BRL (PIX)",
        };
      default:
        return {
          description: "",
          details: "",
        };
    }
  }

  static determineTextColor(statementSignal: string): string {
    switch (statementSignal) {
      case SignalStatementEnum.CREDIT:
        return Colors.Green1;
      case SignalStatementEnum.DEBIT:
        return Colors.Red;
      default:
        return Colors.Blue1;
    }
  }

  static determineSubTitle(signal: SignalStatementEnum) {
    if (signal === SignalStatementEnum.CREDIT) return "Crédito em conta";
    if (signal === SignalStatementEnum.DEBIT) return "Débito em conta";
  }
}

export enum SignalStatementEnum {
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
}
export enum TypeStatementEnum {
  WITHDRAW = "WITHDRAW",
  DEPOSIT = "DEPOSIT",
  PAYMENT_BY_TOKEN = "PAYMENT_BY_TOKEN", // Compra de um ativo
  RECEIVE_SALE_TOKEN = "RECEIVE_SALE_TOKEN", // Recebendo a venda de um token
  INSURANCE_FEE_DISCOUNT = "INSURANCE_FEE_DISCOUNT",
  NEGOCIATION_FEE_DISCOUNT = "NEGOCIATION_FEE_DISCOUNT",
  NETWORK_FEE_DISCOUNT = "NETWORK_FEE_DISCOUNT",
  CONSULT_BOND_FEE_DISCOUNT = "CONSULT_BOND_FEE_DISCOUNT",
  SELL_DISCOUNT_FEE = "SELL_DISCOUNT_FEE",
  WITHDRAW_FEE_DISCOUNT = "WITHDRAW_FEE_DISCOUNT",
}

export interface Stament {
  id: string;
  date: Date;
  amount: number;
  signal: SignalStatementEnum;
  type: TypeStatementEnum;
  orderId: string;
  token: Token;
  tokenID: string;
  tokenValue: number;
  percentFee: number;

  // Quando faz um saque
  // name: bankTransfer.favoredName,
  // document: bankTransfer.favoredDocument,
  // bankNumber: bankTransfer.bankNumber,
  // bankAgency: bankTransfer.bankAgency,
  // bankAccount: bankTransfer.bankAccount,

  // quando faz um deposito
  // bankAccount: deposit.ContaOrigem.Conta,
  // bankNumber: deposit.ContaOrigem.Banco,
  // bankAgency: deposit.ContaOrigem.Agencia,
  // name: deposit.Cliente.Nome,
  // document: deposit.Cliente.CpfCnpj,
  // type: deposit.Movimento.Tipo,
  // typeDescription: deposit.Movimento.Descricao,
  // typeCode: deposit.Movimento.Codigo,
}
