import * as Yup from "yup";

export const bankingDomicileValidation = Yup.object()
  .shape({
    favorecido: Yup.string().required(),
    documento: Yup.string().required(),
    bank: Yup.string().required(),
    bankAgency: Yup.string().required(),
    bankAccount: Yup.string().required(),
  })
  .required();
