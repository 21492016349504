import * as API from "../../api";
import * as Gifs from "../../assets/gifs";
import history from "../../services/history";
import * as Colors from "../../assets/Colors";
import { withdrawOptions } from "../../options";
import RoutesPaths from "../../Routes/RoutesPaths";
import React, { useContext, useState, useCallback } from "react";
import UserContext from "../../contexts/UserProvider";
import Divider from "../../components/Divider/Divider";
import TaxesContext from "../../contexts/TaxesProvide";
import ModalContext from "../../contexts/ModalProvider";
import LimitsContext from "../../contexts/LimitsProvide";
import { formatToCurrency } from "../../utils/formatters";
import AlertModal from "../../components/Modal/AlertModal";
import BalanceContext from "../../contexts/BalanceProvider";
import { SelectMethod, SelectMethods } from "../../components/SelectMethod";
import {
  DepositMethodEnumInterpreter,
  DepositMethodsEnum,
} from "../../models/enums/DepositMethods";
import MoneyInput from "../../components/Inputs/MoneyInput";
import TransferData from "../MyBalance/TransferData";
import "./TransferPage.css";
import PageBoardSkeleton from "../../components/PageBoardSkeleton/PageBoardSkeleton";
import CurrencyInput from "../../components/Inputs/CurrencyInput/CurrencyInput";
import { PlataformLimitEnum } from "../../models";

const TransferPage: React.FC = () => {
  const { user } = useContext(UserContext);
  const { taxs } = useContext(TaxesContext);
  const { limits } = useContext(LimitsContext);
  const { handleModal, setModal } = useContext(ModalContext);
  const { balance, updateBalance } = useContext(BalanceContext);

  // Must devide by 100 every calculation logic due to currency input's display value
  const [_amount, _setAmount] = useState<number>(0);

  const [_currenctMethod, _setCurrentMethod] = useState<DepositMethodsEnum>(
    DepositMethodsEnum.PIX
  );
  const [isLoading, setLoading] = useState<boolean>(false);

  // const tax = taxs.get(TaxTypeEnum.TED_WITHDRAW)?.value ?? 0;
  const tax = 0;

  const [debited, setDebited] = useState<number>(
    _amount > tax ? _amount + tax : 0
  );

  const availableLimit: number = (() => {
    const maxLimit: number =
      limits.get(PlataformLimitEnum.WITHDRAW)?.limit ?? 0;
    const limitConsumed: number =
      limits.get(PlataformLimitEnum.WITHDRAW)?.consumed ?? 0;

    let currentBalance = balance.currency.available;
    let withdrawLimit = maxLimit - limitConsumed;

    return currentBalance < withdrawLimit ? currentBalance : withdrawLimit;
  })();

  const disableButton: boolean =
    _amount > availableLimit ||
    _amount > balance?.currency?.full ||
    _amount === 0 ||
    _amount <= tax;

  const onClick = () => {
    handleModal(SelectMethods, {
      currenctMethod: _currenctMethod,
      onClick(method) {
        _setCurrentMethod(method);
        setModal(false);
      },
    });
  };

  function requestBankDraft() {
    setLoading(true);
    API.bank
      .postWithdraw({ amount: Math.abs(_amount / 100) + Math.abs(tax) })
      .then(() => {
        updateBalance(true);
        handleModal(AlertModal, {
          src: Gifs.IconOkGif,
          title: "Solicitação realizada com sucesso!",
          changeState: () => setModal(false),
          confirmClick: () => history.push(RoutesPaths.BALANCE),
          children: (
            <p>
              Acompanhe o andamento desta solicitação no saldo de sua conta.
            </p>
          ),
        });
      })
      .catch(() => {
        handleModal(AlertModal, {
          src: Gifs.IconAlertGif,
          title: "Erro ao realizar a solicitação!",
          changeState: () => setModal(false),
          confirmClick: () => setModal(false),
          children: <></>,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleAmountChange = useCallback((inputData) => {
    const val = inputData.val;
    const limit = inputData.availableLimit;

    if (Number(val) / 100 > limit) {
      _setAmount(limit * 100);
    } else {
      _setAmount(val);
    }
  }, []);

  return (
    <>
      <PageBoardSkeleton
        filterBar="Saque"
        onClickFilterBar={() => history.goBack()}
        button={{
          label: "Confirmar saque",
          onClick: requestBankDraft,
          className: "mb-10 h-16",
          disabled: _amount === 0,
          loading: isLoading,
        }}
      >
        <>
          <h2 className="font-normal my-3" style={{ color: Colors.Gray3 }}>
            MÉTODO DE TRANSFERÊNCIA
          </h2>

          <div className="relative flex flex-row rounded w-full py-3 md:w-2/3 lg:w-1/2 max-w-3xl">
            <SelectMethod
              title="Selecionar método de transferência"
              subtitle="Selecione uma das opções de transferência disponíveis:"
              methods={withdrawOptions}
              myClass={DepositMethodEnumInterpreter}
              currenctMethod={_currenctMethod}
              changeCurrentMethod={(method) => _setCurrentMethod(method)}
            />
          </div>

          <h2
            className="font-normal mt-10 mb-5"
            style={{ color: Colors.Gray3 }}
          >
            SUA CONTA DESTINO
          </h2>

          <TransferData withdrawMethod={_currenctMethod} user={user} />

          {/* hide link - based on task: https://app.clickup.com/t/30u6ape */}
          {/* <p
            style={{ color: Colors.Blue1 }}
            className='font-normal underline cursor-pointer mt-7'
            onClick={() => history.push(RoutesPaths.ACCOUT_BANK)}
          >
            Editar conta destino
          </p> */}

          <Divider />

          <div className="flex flex-col mb-10 items-center">
            <h3
              className="font-normal text-xl text-center"
              style={{ color: Colors.Green3 }}
            >
              {availableLimit <= 0
                ? "Sem limite para transferencia"
                : `Limite disponível para saque: ${formatToCurrency(
                    availableLimit
                  )} BRL`}
            </h3>
          </div>

          <p className="font-normal" style={{ color: Colors.Gray3 }}>
            VALOR DO SAQUE (BRL)
          </p>
          <div
            className="border rounded justify-center items-center w-full md:w-2/3 lg:w-1/2 max-w-3xl overflow-hidden my-4"
            style={{ borderColor: Colors.Gray3 }}
          >
            {/* <MoneyInput
              prefix='R$ '
              className='w-full h-full text-left px-4 py-4'
              style={{
                color: Colors.Gray2,
              }}
              onChangeValue={p => {
                _setAmount(p);
                setDebited(_amount > tax ? _amount + tax : 0);
              }}
            /> */}
            <CurrencyInput
              value={_amount}
              onValueChange={(val) => {
                const inputData = { val, availableLimit };
                handleAmountChange(inputData);
              }}
              style={{
                color: Colors.Gray2,
                backgroundColor: "transparent",
                fontSize: "22px",
              }}
              className="z-1 block p-2 w-full font-medium appearance-none focus:outline-none text-center sm:text-left sm:p-5"
            />
          </div>
        </>
        {/* 
              COMENTADO ENQUANTO NAO TEM OUTRAS FORMAS DE TRANSFERENCIA QUE POSSUEM TAXAS 
            */}

        {/* <p className='text-lg mt-3' style={{ color: Colors.Gray4 }}>( Taxa: R${formatToCurrency(tax)} )</p>

            <h3 className='font-normal mt-10' style={{ color: Colors.Red4 }}>
              {
                balance?.currency?.full <= 0
                  ? 'Sem saldo'
                  : `Será debitado de seu saldo: R$${formatToCurrency(debited)}`
              }

            </h3> */}
      </PageBoardSkeleton>
    </>
  );
};

export default TransferPage;
