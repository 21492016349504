import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { StylePatterns } from "../../assets/patterns_tailwind_class";
import BigButton from "../../components/Buttons/BigButton";
import UnderlineButton from "../../components/Buttons/UnderlineButton";
import Divider from "../../components/Divider/Divider";
import FilterBar from "../../components/FilterBar/FilterBar";
import FloatInput from "../../components/Inputs/FloatInput";
import AlertModal from "../../components/Modal/AlertModal";
import ModalContext from "../../contexts/ModalProvider";
import { ClientStatusEnum } from "../../models";
import history from "../../services/history";
import * as API from "../../api";
import * as Colors from "../../assets/Colors";
import * as Gifs from "../../assets/gifs";
import Footer from "../../components/Footer/Footer";

const BanAccountPage: React.FC = () => {
  const params: any = useParams();
  const clientId = params.clientId;
  const { handleModal, setModal } = useContext(ModalContext);
  const [confirmIdClient, setConfirmIdClient] = useState<string>(clientId);

  return (
    <>
      <FilterBar className="pt-5" title="Dados de cadastro / Banir conta" />

      <div className="my-4 ml-4">
        <p style={{ color: Colors.Gray3 }}>
          Verifique os detalhes para banir a conta
        </p>
      </div>

      <div
        className="bg-white px-2 pt-8 mx-3 flex flex-col aling-center items-center rounded border"
        style={{ borderColor: Colors.Gray4 }}
      >
        <div
          className={
            `flex flex-col items-center w-full gap-5 text-center ` +
            StylePatterns.ELEMENT_WIDTH
          }
        >
          <h1
            className="text-2xl font-semibold"
            style={{ color: Colors.Gray2 }}
          >
            Banir conta?
          </h1>
          <p>
            Antes de banir a conta, certifique-se de conferir os <br />{" "}
            seguintes passos listados abaixo:
          </p>

          <div className="flex flex-col w-full gap-3">
            {[
              "Remoção de todos recebíveis em negociação da conta",
              "Realização do opt-out de agenda de recebíveis de cartão de crédito",
              "Finalização de todas operações em andamento da conta",
              "Verificar do direcionamento do saldo da conta",
            ].map((label, index) => (
              <div
                className="flex flex-row items-center gap-3 px-2 py-3 border rounded w-full"
                style={{
                  borderColor: Colors.Gray4,
                  backgroundColor: Colors.GrayBackGroud,
                }}
              >
                {/* <div className='w-5 h-5 bg-white border' style={{ borderColor: Colors.Gray4 }} /> */}
                <p className="text-base" style={{ color: Colors.Gray3 }}>{`${
                  index + 1
                } - ${label}`}</p>
              </div>
            ))}
          </div>

          <p className="text-xs" style={{ color: Colors.Blue1 }}>
            ATENÇÃO:
            <br />
            Após realizar essa ação o usuário não poderá mais acessar a
            plataforma enquanto a sua conta estiver banida. Você poderá
            restaurar essa a conta posteriormente.
          </p>

          <Divider />

          <p className="text-lg">INFORME O ID DA CONTA BANIDA</p>
          <FloatInput
            placeholder="Insira o ID da conta banida"
            type="text"
            className="w-full"
            big={true}
            onChange={({ target }) => setConfirmIdClient(target.value)}
          />
        </div>

        <Divider />

        <BigButton
          backgroundColor={Colors.Gray2}
          textColor={"white"}
          disabled={confirmIdClient !== clientId}
          onClick={async () => {
            await API.user.updateUserStatus(
              clientId,
              ClientStatusEnum.SUSPENDED
            );
            handleModal(AlertModal, {
              src: Gifs.IconOkGif,
              title: "A conta foi banida",
              confirmClick: () => history.goBack(),
              children: (
                <>
                  Para reativar essa conta novamente, por favor acesse a página
                  de detalhes da conta.
                </>
              ),
            });
          }}
        >
          Banir esta conta
        </BigButton>
        <UnderlineButton onClick={history.goBack}>
          Cancelar processo de banimento
        </UnderlineButton>
      </div>
      <Footer />
    </>
  );
};

export default BanAccountPage;
