export enum TaxTypeEnum {
  NETWORK = "NETWORK",
  INSURANCE = "INSURANCE",
  CREDIT_OPT_IN = "CREDIT_OPT_IN",
  CREDIT_OPT_OUT = "CREDIT_OPT_OUT",
  TED_WITHDRAW = "TED_WITHDRAW",
  TED_DEPOSIT = "TED_DEPOSIT",
  BUY_SPREAD = "BUY_SPREAD",
  SELL_SPREAD = "SELL_SPREAD",
  BOND_TOKENIZATION = "BOND_TOKENIZATION",
  CRED_TOKENIZATION = "CRED_TOKENIZATION",
  BOND_OPT_IN = "BOND_OPT_IN",
  BOND_OPT_OUT = "BOND_OPT_OUT",
}
