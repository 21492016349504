import * as Yup from "yup";

export const adressValidation = Yup.object()
  .shape({
    pais: Yup.string().required(),
    cep: Yup.string().required(),
    uf: Yup.string().required(),
    cidade: Yup.string().required(),
    bairro: Yup.string().required(),
    logradouro: Yup.string().required(),
    numero: Yup.string().required(),
    complemento: Yup.string().optional(),
  })
  .required();
