import moment from "moment";
import React from "react";
import Colors from "../../../assets/Colors";
import DetailsCard, {
  DetailsSection,
  DetailsSectionRow,
} from "../../../components/Cards/DetailsCard/DetailsCard";
import { Order } from "../../../models";

type MyProps = {
  order: Order;
};

const TokenDetailsAttachments: React.FC<MyProps> = ({ order }) => {
  const token = order.tokens[0];
  const attachments = token?.metadata.properties.attachments;

  const getAttachmentsSections = (): Array<DetailsSection> => {
    return Object.keys(attachments).map((key) => {
      const rows: Array<DetailsSectionRow> = [
        {
          title: "Data de upload: ",
          value: moment(token.emissionDate).format("DD/MM/YYYY"),
          style: { color: Colors.Gray3 },
        },
        {
          children: (
            <a
              style={{ color: Colors.Blue1 }}
              className="flex items-center mt-2 font-medium cursor-pointer"
              target="_blank"
              href={attachments[key].url}
              rel="noreferrer"
            >
              {
                attachments[key].url.split("/")[
                  attachments[key].url.split("/").length - 1
                ]
              }
            </a>
          ),
        },
      ];

      return {
        title: attachments[key].title,
        style: { fontSize: "20px" },
        sectionRows: rows,
      };
    });
  };

  return (
    <>
      <div className="flex flex-row w-full justify-center">
        <div className="mx-3 md:mx-5 flex flex-col items-center w-full">
          <div
            className="my-8 ml-8 text-base font-light w-full"
            style={{ color: Colors.Gray2 }}
          >
            {Object.keys(attachments).length} arquivos anexos
          </div>

          {getAttachmentsSections().map((section) => (
            <DetailsCard sections={[section]} />
          ))}
        </div>
      </div>
    </>
  );
};

export default TokenDetailsAttachments;
