import React, { createContext, useState } from "react";
import { Order } from "../models/order";

interface OrderContextData {
  order?: Order;
  setOrder(p: Order): any;
}

const OrderContext = createContext<OrderContextData>({} as OrderContextData);

export const OrderContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [order, setOrder] = useState<Order | undefined>(undefined);

  return (
    <OrderContext.Provider value={{ order, setOrder }}>
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContext;
