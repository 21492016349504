import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { createRef, useState, useContext } from "react";
import * as Yup from "yup";
import * as API from "../../../api";
import { getIdOnboarding, UserBitIdStatusEnum } from "../../../api";
import livenessAPI from "../../../api/liveness";
import Colors, { GrayBackGroud } from "../../../assets/Colors";
import * as Icons from "../../../assets/icons";
import * as Gifs from "../../../assets/gifs";
import Divider from "../../../components/Divider/Divider";
import FloatInput from "../../../components/Inputs/FloatInput";
import FloatInputCore from "../../../components/Inputs/FloatInputCore/FloatInputCore";
import CPFMask from "../../../components/Inputs/MaskedInput/Mask/CPFMask";
import MaskedInput from "../../../components/Inputs/MaskedInput/MaskedInput";
import MaskedInput1 from "../../../components/Inputs/MaskedInput/MaskedInput1";
import AlertModal from "../../../components/Modal/AlertModal";
import ModalCore from "../../../components/Modal/ModalCore";
import UnformInputAdapt from "../../../components/unform/UnformInputAdapt/UnformInputAdapt";
import UploadDoc from "../../../components/UploadDoc/UploadDoc";
import i18nTranslated from "../../../services/i18n";
import { UnFormValidate } from "../../../utils/yupUtils";
import { CompanyBankPage, CompanyPage } from "./Company/Company";
import { IndividualBankPage } from "./Individual/Individual";
import PageSkeleton from "./OnboardingPageSkeleton/OnboardingPageSkeleton";
import RegisterSuccessPage from "./RegisterSuccessPage/RegisterSuccessPage";
import UserContext from "../../../contexts/UserProvider";
import useInterruptionModal from "../../../hooks/useInterruptionModal";
import DateMask from "../../../components/Inputs/MaskedInput/Mask/DateMask";
import inputValidations from "../../../utils/inputValidations";

const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
export interface basicPropsPages {
  prevPage(): any;
  nextPage(step?: number): any;
  socio?: boolean;
  disable?: boolean;
}

export const CardAccoutType: React.FC<{
  type: "fisica" | "juridica";
  requisitos: Array<string>;
  onClick(): any;
  disable: boolean;
}> = ({ type, requisitos, onClick, disable }) => {
  return (
    <div
      className="flex flex-col items-center border rounded p-8 shadow w-full"
      style={{
        borderColor: Colors.Gray3,
        cursor: disable ? "default" : "pointer",
        opacity: disable ? "0.2" : "1",
      }}
      onClick={disable ? () => {} : () => onClick()}
    >
      <div
        className="flex flex-row whitespace-pre text-lg"
        style={{ color: Colors.Gray2 }}
      >
        <p className="font-medium">
          {type === "fisica" ? "Pessoa Física" : "Pessoa Jurídica"}
        </p>
      </div>
      <p className="my-3" style={{ color: Colors.Gray3 }}>
        Documentos necessários
      </p>
      <ul className="list-disc" style={{ color: Colors.Gray3 }}>
        {requisitos.map((e, i) => (
          <li key={i}>{e}</li>
        ))}
      </ul>
    </div>
  );
};

export const SwitchAccout: React.FC<{
  setAccoutType(p: "legal" | "phisic"): any;
}> = ({ setAccoutType }) => {
  return (
    <div className="flex flex-col items-center mt-3">
      <h1 className="text-xl" style={{ color: Colors.Gray2 }}>
        Selecione a opção de conta que deseja cadastrar
      </h1>

      <p className="font-light my-4" style={{ color: Colors.Gray3 }}>
        Selecione o tipo de conta que deseja cadastrar:
      </p>

      <CardAccoutType
        type="fisica"
        disable={false}
        requisitos={[
          "Dados legais (titular)",
          "Conta bancária (titular)",
          "Endereço (titular)",
        ]}
        onClick={() => setAccoutType("phisic")}
      />
      <br />
      <CardAccoutType
        type="juridica"
        disable={false}
        requisitos={[
          "Dados legais (empresa)",
          "Dados legais (sócio/administrador)",
          "Dados bancária (empresa)",
          "Endereço (empresa)",
        ]}
        onClick={() => setAccoutType("legal")}
      />
    </div>
  );
};

export const RegisterPageHandler = ({ defaultPage }) => {
  const [currentPage, setCurrentPage] = useState<number>(defaultPage ?? 0);
  const [loadContinue, setLoadContinue] = useState<boolean>(false);
  const [accoutType, setAccoutType] = useState<"phisic" | undefined>("phisic");

  useInterruptionModal();

  if (!loadContinue) {
    const stepOnboarding = API.getStepOnboarding();

    // if (stepOnboarding && !accoutType && currentPage === 0) {
    //   switch(stepOnboarding.status) {
    //     case UserBitIdStatusEnum.SET_ACCOUNT_TYPE:
    //       // setAccoutType(stepOnboarding.type);
    //       setCurrentPage(1)
    //       break;
    //     case UserBitIdStatusEnum.ADDRESS_ADDED:
    //       // setAccoutType(stepOnboarding.type);
    //       setCurrentPage(2)
    //       break;
    //     case UserBitIdStatusEnum.SIGNUP_INDIVIDUAL:
    //       // setAccoutType(stepOnboarding.type);
    //       setCurrentPage(4)
    //       break;
    //     case UserBitIdStatusEnum.SIGNUP_CORPORATE:
    //       // setAccoutType(stepOnboarding.type);
    //       setCurrentPage(4)
    //       break;
    //     default:
    //       // setAccoutType(undefined);
    //       setCurrentPage(0)
    //       break;
    //   }
    // }
    setLoadContinue(true);
  }

  const prevPage = () => {
    if (currentPage === 3 && accoutType === "phisic") {
      setCurrentPage(currentPage - 2);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPage = (step: number = 1) => {
    setCurrentPage(currentPage + step);
  };

  const page = () => {
    if (!accoutType) {
      return (
        <SwitchAccout
          setAccoutType={async (p) => {
            const id = getIdOnboarding();
            if (id) {
              await API.signup.setAccountType(p, id);
              // setAccoutType(p);
              setCurrentPage(1);
            }
          }}
        />
      );
    }

    const accountIsLegal: boolean = false; //accoutType === 'legal';
    //Foi comentado o codigo que inclui as steps do registro da conta PJ(Pessoa juridica)
    //Por que? Porque atualmente o back end aceita apenas Pessoa Fisica.
    switch (currentPage) {
      // case 0:
      //   return <SwitchAccout
      //     setAccoutType={(p) => {
      //       // setAccoutType(p);
      //       setCurrentPage(1)
      //     }}
      //   />;
      case 0:
        return (
          <DadosLegaisPage
            socio={accountIsLegal}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        );
      case 1:
        return <MyAdressPage prevPage={prevPage} nextPage={nextPage} />;
      // if (accountIsLegal) {
      //   return <CompanyPage prevPage={prevPage} nextPage={nextPage} />;
      // } else {
      //   return <DadosLegaisPage socio={accountIsLegal} prevPage={prevPage} nextPage={nextPage} />
      // }
      // case 3:
      //   return <EnviarDocumentacaoPage socio={aux} prevPage={prevPage} nextPage={nextPage} />
      // case 4:
      //   return <FaceMatchPage prevPage={prevPage} nextPage={nextPage} />
      case 2:
        return <IndividualBankPage prevPage={prevPage} nextPage={nextPage} />;

      // if (accountIsLegal) {
      //   return <CompanyBankPage prevPage={prevPage} nextPage={nextPage} />;
      // } else {
      //   return <IndividualBankPage prevPage={prevPage} nextPage={nextPage} />
      // }
      case 3:
        return <RegisterSuccessPage />;
      // return <DocumentsFinalCheck pageHandler={setCurrentPage} />
      default:
        return <></>;
    }
  };

  return page();
};

type msgErrorProps = {
  uf: string;
  city: string;
  neighborhood: string;
  street: string;
  streetNumber: string;
};

const msgErrorInitialState = {
  uf: "",
  city: "",
  neighborhood: "",
  street: "",
  streetNumber: "",
};

export const MyAdressPage: React.FC<basicPropsPages> = (props) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [errorModalState, setErrorModalState] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>(
    "Verifique se todos os campos foram preenchidos corretamente e tente novamente."
  );
  const [buttomDisabled, setButtonDisabled] = useState(true);
  const [formRef] = useState<React.RefObject<FormHandles>>(createRef());
  const [firstValidate] = useState<boolean>(true);
  const { user } = useContext(UserContext);
  const [msgError, setMsgError] = useState<msgErrorProps>(msgErrorInitialState);

  const schema = Yup.object()
    .shape({
      country: Yup.string().required(),
      state: Yup.string().required(),
      city: Yup.string().required(),
      neighborhood: Yup.string().required(),
      street: Yup.string().required(),
      streetNumber: Yup.string().required(),
      complement: Yup.string().optional(),
      postalCode: Yup.string().required(),
    })
    .required();

  const enableButtonChecker = () => {
    const uf = formRef?.current?.getFieldValue("state");
    const cep = formRef?.current?.getFieldValue("postalCode");
    const cidade = formRef?.current?.getFieldValue("city");
    const bairro = formRef?.current?.getFieldValue("neighborhood");
    const logradouro = formRef?.current?.getFieldValue("street");
    const numero = formRef?.current?.getFieldValue("streetNumber");

    if (
      cep.trim() === "" ||
      uf.trim() === "" ||
      cidade.trim() === "" ||
      bairro.trim() === "" ||
      logradouro.trim() === "" ||
      numero.trim() === "" ||
      inputValidations.uf.isNotValid(uf) ||
      inputValidations.city.isNotValid(cidade) ||
      inputValidations.neighborhood.isNotValid(bairro) ||
      inputValidations.street.isNotValid(logradouro) ||
      inputValidations.streetNumber.isNotValid(numero)
    ) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };

  const onClick = async () => {
    setLoadingState(true);

    try {
      const id = user?.id;

      if (!id) {
        throw new Error("Houve um erro ao consultar o registro do usuário");
      }

      const address = await schema.validate(formRef.current?.getData());
      await API.signup.addAdress(id, address);

      props.nextPage();
      setLoadingState(false);
    } catch (err) {
      const error = err as any;

      // if (error && error.response) {
      //   setTextError(i18nTranslated(error.response.data.message));
      // } else if (error && error.message) {
      //   let msgError = 'API_ERROR_UNEXPECTED';
      //   if (IsJsonString(error.message)) {
      //     const erroObject = JSON.parse(error.message);
      //     if (erroObject.statusCode !== 500) {
      //       msgError = erroObject.message;
      //     }
      //   }

      //   // setTextError(i18nTranslated(msgError));
      // }

      setErrorModalState(true);
      setLoadingState(false);
    }
    // } else {
    //   setTextError('É necessário preencher todos os campos!');
    //   setErrorModalState(true);
    //   setLoadingState(false);
    // }
  };

  const formValidateHandler = () => {
    enableButtonChecker();
    if (!firstValidate) {
      UnFormValidate(formRef, schema);
    }
  };

  const clearAddressFields = () => {
    formRef?.current?.setFieldValue("state", "");
    formRef?.current?.setFieldValue("city", "");
    formRef?.current?.setFieldValue("neighborhood", "");
    formRef?.current?.setFieldValue("street", "");
    formRef?.current?.setFieldValue("streetNumber", "");
    formRef?.current?.setFieldValue("complement", "");
    setMsgError(msgErrorInitialState);
  };

  const changeCepHandler = async (event) => {
    const cep = event.target.value.replace(/-/g, "");

    if (cep.length !== 8) {
      clearAddressFields();
      return;
    }

    try {
      const { data } = await API.viaCep.get(cep);
      if (!data.erro) {
        formRef?.current?.setFieldValue("state", data?.uf ? data?.uf : "");
        formRef?.current?.setFieldValue(
          "street",
          data?.logradouro ? data?.logradouro : ""
        );
        formRef?.current?.setFieldValue(
          "city",
          data?.localidade ? data?.localidade : ""
        );
        formRef?.current?.setFieldValue(
          "neighborhood",
          data?.bairro ? data?.bairro : ""
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ufValidateHandler = (event) => {
    inputValidations.uf.isValid(event.target.value.toString());
    setMsgError((state) => {
      return {
        ...state,
        uf: inputValidations.uf.getErrorMessage(),
      };
    });
  };
  const cityValidateHandler = (event) => {
    inputValidations.city.isValid(event.target.value.toString());
    setMsgError((state) => {
      return {
        ...state,
        city: inputValidations.city.getErrorMessage(),
      };
    });
  };
  const neighborhoodValidateHandler = (event) => {
    inputValidations.neighborhood.isValid(event.target.value.toString());
    setMsgError((state) => {
      return {
        ...state,
        neighborhood: inputValidations.neighborhood.getErrorMessage(),
      };
    });
  };
  const streetValidateHandler = (event) => {
    inputValidations.street.isValid(event.target.value.toString());
    setMsgError((state) => {
      return {
        ...state,
        street: inputValidations.street.getErrorMessage(),
      };
    });
  };
  const streetNumberValidateHandler = (event) => {
    inputValidations.streetNumber.isValid(event.target.value.toString());
    setMsgError((state) => {
      return {
        ...state,
        streetNumber: inputValidations.streetNumber.getErrorMessage(),
      };
    });
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !buttomDisabled) {
      onClick();
    }
  };

  return (
    <>
      <AlertModal
        state={errorModalState}
        title="Falha na validação."
        src={Gifs.IconAlertGif}
        confirmClick={() => setErrorModalState(false)}
      >
        <>
          <p>{textError}</p>
        </>
      </AlertModal>
      <PageSkeleton
        loading={loadingState}
        title="Endereço de cadastro"
        subTitle="Informe o endereço de cadastro do titular da conta"
        prevPage={props.prevPage}
        nextPage={onClick}
        finalNote="O endereço informado será usado posteriormente no processo de certificação da sua conta."
        buttonDisabled={buttomDisabled}
      >
        <Form
          onSubmit={() => {}}
          className="w-11/12 text-lg"
          ref={formRef}
          onChange={() => {
            formValidateHandler();
          }}
          onKeyDown={handleKeyDown}
        >
          <UnformInputAdapt
            name="postalCode"
            children={({ ref, error }) => (
              <FloatInput
                big={true}
                ref={ref}
                error={error}
                placeholder="CEP"
                color="bg-white"
                labelColor={Colors.Gray3}
                className="py-2 mb-3"
                length={8}
                type="number"
                onChange={changeCepHandler}
              />
            )}
          />

          <UnformInputAdapt
            name="country"
            children={({ ref, error }) => (
              <FloatInput
                big={true}
                error={error}
                color="bg-white"
                labelColor={Colors.Gray3}
                disabled={true}
                className="py-2"
                placeholder="País"
                type="text"
                ref={ref}
                defaultValue="Brasil"
              />
            )}
          />

          <UnformInputAdapt
            name="state"
            children={({ ref, error }) => (
              <FloatInput
                big={true}
                error={error || msgError.uf}
                length={2}
                color="bg-white"
                labelColor={Colors.Gray3}
                className="py-2 text-uppercase"
                placeholder="Estado"
                type="text"
                ref={ref}
                onChange={ufValidateHandler}
              />
            )}
          />

          <UnformInputAdapt
            name="city"
            children={({ ref, error }) => (
              <FloatInput
                big={true}
                error={error || msgError.city}
                color="bg-white"
                labelColor={Colors.Gray3}
                className="py-2"
                placeholder="Cidade"
                type="text"
                ref={ref}
                onChange={cityValidateHandler}
              />
            )}
          />

          <UnformInputAdapt
            name="neighborhood"
            children={({ ref, error }) => (
              <FloatInput
                big={true}
                error={error || msgError.neighborhood}
                color="bg-white"
                labelColor={Colors.Gray3}
                className="py-2"
                placeholder="Bairro"
                type="text"
                ref={ref}
                onChange={neighborhoodValidateHandler}
              />
            )}
          />

          <UnformInputAdapt
            name="street"
            children={({ ref, error }) => (
              <FloatInput
                big={true}
                error={error || msgError.street}
                color="bg-white"
                labelColor={Colors.Gray3}
                className="py-2"
                placeholder="Logradouro"
                type="text"
                ref={ref}
                onChange={streetValidateHandler}
              />
            )}
          />

          <UnformInputAdapt
            name="streetNumber"
            children={({ ref, error }) => (
              <FloatInput
                big={true}
                error={error || msgError.streetNumber}
                color="bg-white"
                labelColor={Colors.Gray3}
                className="py-2"
                placeholder="Número"
                type="text"
                ref={ref}
                onChange={streetNumberValidateHandler}
              />
            )}
          />

          <UnformInputAdapt
            name="complement"
            children={({ ref, error }) => (
              <FloatInput
                big={true}
                error={error}
                color="bg-white"
                labelColor={Colors.Gray3}
                className="py-2"
                placeholder="Complemento"
                type="text"
                ref={ref}
              />
            )}
          />
        </Form>
      </PageSkeleton>
    </>
  );
};

export const DadosLegaisPage: React.FC<basicPropsPages> = (props) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [formRef] = useState<React.RefObject<FormHandles>>(createRef());
  const [errorModalState, setErrorModalState] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>(
    "Verifique se todos os campos foram preenchidos corretamente e tente novamente."
  );
  const [modalSuccesState, setModalSuccesState] = useState<boolean>(false);
  const [pep, setPep] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  type FormDataStateProps = {
    cpfError?: string;
    nomeError?: string;
    dataNascimentoError?: string;
    buttonDisabled: boolean;
  };
  const [formDataState, setFormDataState] = useState<FormDataStateProps>({
    buttonDisabled: true,
  });

  const sendPersonalData = async () => {
    setLoadingState(true);
    var input = formRef.current?.getData();
    const id = user?.id;

    const allInputsInformed =
      input?.cpf &&
      input?.datanasc &&
      input?.nometitular &&
      input?.nomemaetitular;

    if (id && allInputsInformed) {
      try {
        if (input) {
          const datanascStr = input.datanasc.split("/");
          const datanasc = new Date();
          datanasc.setDate(Number(datanascStr[0]));
          datanasc.setMonth(Number(datanascStr[1]) - 1);
          datanasc.setFullYear(Number(datanascStr[2]));

          const response = await API.signup.individual.registerIndividual(
            id,
            input.nometitular,
            input.cpf,
            datanasc,
            input.nomemaetitular,
            pep
          );

          if (!response.user?.certifications?.includes("DATA_VERIFIED")) {
            setErrorModalState(true);
          } else {
            setModalSuccesState(true);
          }
        }
      } catch (error: any) {
        if (error?.message?.includes("DOCUMENT_ALREADY_REGISTERED")) {
          setTextError(
            "Este documento já está cadastrado na nossa base de dados"
          );
        }

        if (error && error?.response) {
          setTextError(
            "Verifique se todos os campos foram preenchidos corretamente e tente novamente."
          );
        }

        setErrorModalState(true);
      } finally {
        setLoadingState(false);
      }
    } else {
      setTextError(
        "Verifique se todos os campos foram preenchidos corretamente e tente novamente."
      );
      setErrorModalState(true);
      setLoadingState(false);
    }
  };

  const formValidationHandler = (
    field: "cpf" | "nome" | "nomeDaMae" | "dataNascimento",
    value: string
  ) => {
    const state: FormDataStateProps = formDataState;

    const validateCpf = () => {
      state.cpfError = inputValidations.cpf.isNotValid(value)
        ? inputValidations.cpf.getErrorMessage()
        : undefined;
    };

    const validateNome = () => {
      state.nomeError = inputValidations.notEmpty.isNotValid(value)
        ? inputValidations.notEmpty.getErrorMessage()
        : undefined;
    };

    const validateDataNascimento = () => {
      state.dataNascimentoError = inputValidations.birthDate.isNotValid(value)
        ? inputValidations.birthDate.getErrorMessage()
        : undefined;
    };

    if (field === "cpf") validateCpf();

    if (field === "nome") validateNome();

    if (field === "dataNascimento") validateDataNascimento();

    let input = formRef.current?.getData();

    state.buttonDisabled = !(
      !state.cpfError &&
      !state.dataNascimentoError &&
      !state.nomeError &&
      input?.cpf &&
      input?.datanasc &&
      input?.nometitular &&
      input?.nomemaetitular
    );

    setFormDataState(() => ({ ...state }));
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !formDataState?.buttonDisabled) {
      sendPersonalData();
    }
  };

  return (
    <>
      <AlertModal
        title="Falha na validação"
        state={errorModalState}
        src={Gifs.IconAlertGif}
        confirmClick={() => {
          setErrorModalState(false);
        }}
      >
        <div style={{ color: Colors.Gray3 }}>
          {<p className="pb-2">{textError}</p>}
        </div>
      </AlertModal>

      <AlertModal
        src={Gifs.IconOkGif}
        iconSize="32"
        title="Dados pessoais verificados com sucesso!"
        state={modalSuccesState}
        confirmClick={() => {
          setModalSuccesState(false);
          props.nextPage();
          setLoadingState(false);
        }}
      >
        <div className="flex flex-col gap-2 my-4">
          <p>Clique no botão para prosseguir.</p>
        </div>
      </AlertModal>

      <PageSkeleton
        loading={loadingState}
        title="Dados pessoais"
        subTitle="Informe os dados legais da pessoa titular da conta:"
        prevPage={props.prevPage}
        nextPage={sendPersonalData}
        finalNote={`Os dados informados serão utilizados nos processos de verificação da conta. ${"\n"}Certifique-se de que estão corretos`}
        buttonDisabled={formDataState?.buttonDisabled}
      >
        <Form
          onSubmit={() => {}}
          onKeyDown={handleKeyDown}
          className="w-full md:w-11/12 text-lg"
          ref={formRef}
        >
          <UnformInputAdapt
            name="cpf"
            children={({ ref, error }) => (
              <FloatInputCore
                big
                ref={ref}
                error={error || formDataState.cpfError}
                placeholder={
                  "CPF do " + (props.socio ? "sócio administrador" : "titular")
                }
                color="bg-white"
                labelColor={Colors.Gray3}
                className="my-2 mb-3"
                children={(ref2) => (
                  <MaskedInput
                    ref={ref2}
                    placeholder={
                      "CPF do " +
                      (props.socio ? "sócio administrador" : "titular")
                    }
                    mask={CPFMask}
                    className="block p-2 w-full appearance-none focus:outline-none"
                    onChange={(e) =>
                      formValidationHandler("cpf", e.target.value)
                    }
                  />
                )}
              />
            )}
          />
          <UnformInputAdapt
            name="nometitular"
            children={({ ref, error }) => (
              <FloatInput
                big
                error={error || formDataState.nomeError}
                color="bg-white"
                labelColor={Colors.Gray3}
                className="my-2 mb-3"
                placeholder={
                  "Nome completo do " +
                  (props.socio ? "sócio administrador" : "titular")
                }
                type="text"
                ref={ref}
                onChange={(e) => formValidationHandler("nome", e.target.value)}
              />
            )}
          />
          <UnformInputAdapt
            name="datanasc"
            children={({ ref, error }) => (
              <FloatInputCore
                big
                ref={ref}
                error={error || formDataState.dataNascimentoError}
                placeholder="Data de nascimento (DD/MM/AAAA)"
                color="bg-white"
                labelColor={Colors.Gray3}
                className="my-2 mb-3"
                children={(ref2) => (
                  <MaskedInput
                    ref={ref2}
                    mask={DateMask}
                    type="text"
                    error={error}
                    placeholder="DD/MM/AAAA"
                    className="block p-2 w-full appearance-none focus:outline-none"
                    onChange={(e) =>
                      formValidationHandler("dataNascimento", e.target.value)
                    }
                  />
                )}
              />
            )}
          />
          <UnformInputAdapt
            name="nomemaetitular"
            children={({ ref, error }) => (
              <FloatInput
                big
                error={error}
                color="bg-white"
                labelColor={Colors.Gray3}
                className="mt-2 mb-7 py-0"
                placeholder={
                  "Nome completo da mãe" +
                  (props.socio ? " do sócio administrador" : "")
                }
                type="text"
                ref={ref}
                onChange={(e) =>
                  formValidationHandler("nomeDaMae", e.target.value)
                }
              />
            )}
          />

          <UnformInputAdapt
            name="pep"
            children={({ ref, error }) => (
              <div className="px-5 mb-16">
                <input
                  color="bg-white"
                  type="checkbox"
                  name="pep-input"
                  ref={ref}
                  title="Caso você seja uma pessoa exposta politicamente (PEP) marque esta opção."
                  className="rounded-none h-3 w-3"
                  checked={pep}
                  onClick={() => setPep(!pep)}
                ></input>
                <label
                  className="text-base ml-2"
                  htmlFor="pep-input"
                  style={{ color: Colors.Gray3 }}
                >
                  Caso você seja uma pessoa exposta politicamente (PEP) marque
                  esta opção.
                </label>
              </div>
            )}
          />
        </Form>
      </PageSkeleton>
    </>
  );
};

export const EnviarDocumentacaoPage: React.FC<basicPropsPages> = (props) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [errorModalState, setErrorModalState] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>("");
  const [documents, setDocuments] = useState([]);

  const onclick = async () => {
    setLoadingState(true);
    const id = getIdOnboarding();
    if (
      id &&
      ((props.socio && documents.length === 3) ||
        (!props.socio && documents.length === 2))
    ) {
      try {
        const docs = documents.map((doc: any) => doc.file);
        if (props.socio) {
          const partnerDoc = documents.filter(
            (item: any) =>
              item.identifier === "rgfrente" || item.identifier === "rgverso"
          );
          const companyDoc = documents.filter(
            (item: any) => item.identifier === "cnpjempresa"
          );
          await API.signup.company.sendDocumentCompany(
            id,
            companyDoc.map((doc: any) => doc.file)
          );
          await API.signup.company.sendDocumentPartners(
            id,
            partnerDoc.map((doc: any) => doc.file)
          );
        } else {
          await API.signup.individual.sendDocuments(id, docs);
        }

        props.nextPage();
        setLoadingState(false);
      } catch (err) {
        console.error(err);
        const error = err as any;

        setTextError("");
        if (error && error.response) {
          setTextError(i18nTranslated(error.response.data.message));
        } else if (error && error.message) {
          let msgError = "API_ERROR_UNEXPECTED";
          if (IsJsonString(error.message)) {
            const erroObject = JSON.parse(error.message);
            if (erroObject.statusCode !== 500) {
              msgError = erroObject.message;
            }
          }

          setTextError(i18nTranslated(msgError));
        }

        setErrorModalState(true);
        setLoadingState(false);
      }
    } else {
      setTextError("É necessário enviar todos os documentos solicitados");
      setErrorModalState(true);
    }
    setLoadingState(false);
  };

  const changeFile = (file, identifier: string) => {
    let files = [];
    const old = documents.find((doc: any) => doc.identifier === identifier);

    if (old) {
      files = documents.map((doc: any) => {
        if (doc.identifier === identifier) {
          return {
            file: file,
            identifier: identifier,
          };
        }
        return doc;
      }) as never;
    } else {
      files.push({
        file: file,
        identifier: identifier,
      } as never);
      files = documents.concat(files);
    }

    setDocuments(files);
  };

  return (
    <>
      <AlertModal
        state={errorModalState}
        title="Ops, documentos irregulares."
        src={Gifs.IconAlertGif}
        confirmClick={() => setErrorModalState(false)}
      >
        <>
          <p>
            O sistema identificou uma irregularidade nos documentos enviados.
          </p>
          <p>{textError}</p>
        </>
      </AlertModal>

      <PageSkeleton
        loading={loadingState}
        prevPage={props.prevPage}
        nextPage={onclick}
        title={`Documentação do ${
          props.socio ? "sócio adminstrador" : "titular"
        }`}
        subTitle="Envie abaixo os documentos solicitados para análise da conta:"
        finalNote="Caso o arquivo do documento anexado possua a imagem do documento frente e verso, favor anexar o mesmo arquivo novamente. Usaremos os arquivos anexados para o processo de KYC e validação do seu cadastro plataforma."
      >
        <>
          <Divider top="mt-3" bottom="mb-5" />
          <p className="text-xl mb-5" style={{ color: Colors.Red }}>
            Documento do {props.socio ? "sócio adminstrador" : "titular"}
          </p>

          <UploadDoc
            type="IMAGE"
            fullW={true}
            eventOnChange={(file, identifier) => changeFile(file, identifier)}
            document={{
              identifier: "rgfrente",
              label: `Frente do documento com foto do ${
                props.socio ? "sócio adminstrador" : "titular"
              }`,
              description:
                "(Imagem contendo frente do documento do titular: JPEG, PNG, PDF até 5mb)",
            }}
          />
          <UploadDoc
            type="IMAGE"
            fullW={true}
            eventOnChange={(file, identifier) => changeFile(file, identifier)}
            document={{
              identifier: "rgverso",
              label: `Verso do documento com foto do ${
                props.socio ? "sócio adminstrador" : "titular"
              } `,
              description:
                "(Imagem contendo verso do documento do titular: JPEG, PNG, PDF até 5mb)",
            }}
          />
          {props.socio && (
            <UploadDoc
              type="ALL"
              fullW={true}
              eventOnChange={(file, key) => changeFile(file, key)}
              document={{
                identifier: "cnpjempresa",
                label: "Comprovante do CNPJ da empresa",
                description:
                  "(Cartão CNPJ ou Contrato social da empresa: JPEG, PNG, PDF até 5mb)",
              }}
            />
          )}
        </>
      </PageSkeleton>
    </>
  );
};

export const FaceMatchPage: React.FC<basicPropsPages> = (props) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [modalLiveness, setModalLiveness] = useState(false);

  var liveness: any;

  async function getJWT(apiKey) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `ApiKey ${apiKey}`,
      },
    };
    const endpoint = "https://api-homolog.nxcd.app/auth-jwt";
    const response = await fetch(endpoint, requestOptions);
    const token = await response.json();
    return token.accessToken;
  }

  function errorCallback(p) {
    // console.log('--------- base64 ------', liveness.base64)

    setError("Ops.. A verificação facial não foi realizada");
    setSuccess("");

    liveness.start();
  }

  function onLivenessSuccess(response) {
    setSuccess("Verificação facial realizada com sucesso");
    livenessAPI.saveImage(liveness.base64);
    props.nextPage();
    setError("");
  }

  async function start() {
    const apiKey = process.env.REACT_APP_LIVENESS_API_KEY;
    const jwt = await getJWT(apiKey);

    const configuration = {
      width: 640, // largura de exibição da câmera
      isDebug: !true,
      token: jwt,
      faceapiPath: "/libs", // caminho para a faceapi e modelos baixados
      livenessUrlBase: "https://api-homolog.nxcd.app", // endpoint da api liveness
      livenessConfirmEndpoint: "", // opcional - default: /liveness
      isShowPreview: !true, // exibir um preview da foto que será enviada
      errorCallback: errorCallback, // metodo de callback em caso de erro
      successCallback: onLivenessSuccess, // metodo de callback em caso de sucesso
    };

    if (!error && !success) {
      const videoWrapper = document.getElementById("video-wrapper");
      liveness = new (window as any).Liveness(videoWrapper, configuration);
      liveness.start();
    }
  }

  function getButtonColor() {
    if (error) return Colors.Red;
    if (success) return Colors.Green1;
    return Colors.Blue1;
  }

  function getModalContent() {
    if (error) return Gifs.IconAlertGif;
    if (success) return Gifs.IconOkGif;
  }

  return (
    <PageSkeleton
      prevPage={props.prevPage}
      title="Verificação facial"
      subTitle="Realize a verificação facial do titular da conta"
      finalNote=""
      nextPage={() => {
        setModalLiveness(true);
        start();
      }}
    >
      <ModalCore state={modalLiveness} changeState={setModalLiveness}>
        <div style={{ width: "100%", borderRadius: 11.6883 }}>
          {getModalContent() && <img src={getModalContent()} alt="img" />}
          {!error && !success && <div id="video-wrapper" />}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p
            className="font-medium text-2xl text-center m-auto"
            style={{
              backgroundColor: getButtonColor(),
              height: 62,
              paddingTop: 16,
              position: "relative",
              width: 600,
            }}
          >
            {error || success || "Posicione seu rosto no centro da marcação"}
          </p>
          <p
            className="font-bold text-center text-2xl"
            style={{
              color: Colors.Gray4,
              textTransform: "uppercase",
              cursor: "pointer",
              marginBottom: 29,
              marginTop: 40,
            }}
            onClick={() => setModalLiveness(false)}
          >
            interromper verificação
          </p>
        </div>
      </ModalCore>

      <Divider top="mt-3" bottom="mb-5" />

      <h2 style={{ color: Colors.Gray3 }} className="text-center text-xl mb-5">
        Nome completo do titular
      </h2>

      <img src={Icons.CustomerIcon} alt="CustomerIcon" />

      <div
        className="text-center font-light text-xs my-5 px-3 flex flex-col gap-2"
        style={{ color: Colors.Blue1 }}
      >
        <p className="underline">ATENÇÃO:</p>
        <p>
          Certifique-se que seu dispositivo possui uma câmera em funcionamento e
          que as condições de luminosidade estejam adequadas para realizar a
          captação.
        </p>
        <p>
          Clique no botão abaixo para iniciar o processo de verificação facial
          de prova de vida.
        </p>
        <p
          style={{ color: Colors.Gray3 }}
          className="text-xs font-extralight underline cursor-pointer"
          onClick={() => props.nextPage()}
        >
          Caso deseje fazer essa etapa em outro momento, clique aqui.
        </p>
      </div>
    </PageSkeleton>
  );
};

export const DocumentsFinalCheck: React.FC<{ pageHandler(p: any): any }> = (
  props
) => {
  const aux: Array<{
    label: string;
    gotopage(): any;
    state: "ok" | "error" | "loading";
  }> = [
    { label: "Email", state: "ok", gotopage: () => props.pageHandler(1) },
    {
      label: "Telefone celular",
      state: "ok",
      gotopage: () => props.pageHandler(2),
    },
    { label: "Endereço", state: "ok", gotopage: () => props.pageHandler(3) },
    {
      label: "Dados legais",
      state: "ok",
      gotopage: () => props.pageHandler(4),
    },
  ];

  const pendencias: boolean = !!aux.find((e) => e.state !== "ok");

  return (
    <>
      <PageSkeleton
        title="Cadastro concluído"
        subTitle="Acompanhe o processo de verificação da sua conta"
        finalNote={""}
        nextPage={() => {
          window.history.pushState({}, "", "/auth/signin");
          window.location.reload();
        }}
      >
        <>
          {aux.map(({ label, state, gotopage }, index) => {
            var icon: any;
            switch (state) {
              case "ok":
                icon = Gifs.IconOkGif;
                break;
              case "error":
                icon = Gifs.IconAlertGif;
                break;
              case "loading":
                icon = Gifs.IconAlertGif;
                break;
            }
            return (
              <div
                className="flex flex-row justify-between px-3 py-2 border rounded w-full mb-2 cursor-pointer"
                style={{
                  borderColor: Colors.Gray4,
                  backgroundColor: GrayBackGroud,
                }}
                onClick={() => {
                  if (state === "error") {
                    gotopage();
                  }
                }}
                key={index}
              >
                <p className="text-lg" style={{ color: Colors.Gray2 }}>
                  {label}
                </p>
                <img src={icon} style={{ height: "25px", width: "25px" }} />
              </div>
            );
          })}

          {pendencias && (
            <div
              className="rounded flex flex-row justify-center py-4 w-full my-3"
              style={{ backgroundColor: Colors.Red4 }}
            >
              <p>(Documentação inconsistente)</p>
            </div>
          )}

          {pendencias ? (
            <div
              className="flex flex-col items-center"
              style={{ color: Colors.Red }}
            >
              <p className="font-medium">Sua cadastro possui pendências</p>
              <p>
                Por favor verifique os itens listados para realizar uma nova
                análise da conta.
              </p>
            </div>
          ) : (
            <div
              className="flex flex-col items-center my-5"
              style={{ color: Colors.Green3 }}
            >
              <p className="font-medium">
                Seu cadastro foi aprovado para acesso!
              </p>
              <p>
                Para aumentar o limite da sua conta envie os documentos extras
                solicitados na seção “minha conta”
              </p>
            </div>
          )}
        </>
      </PageSkeleton>
    </>
  );
};
