import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Colors from "../../../../assets/Colors";
import { StylePatterns } from "../../../../assets/patterns_tailwind_class";
import FloatInput from "../../../../components/Inputs/FloatInput";
import FloatInputCore from "../../../../components/Inputs/FloatInputCore/FloatInputCore";
import PageSkeleton from "../../../../components/PageSkeleton/PageSkeleton";
import history from "../../../../services/history";
import ClientDetailsFilterBar from "../ClientDetailsFilterBar/ClientDetailsFilterBar";
import * as API from "./../../../../api";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("UTC");

const ClientDataPage: React.FC = () => {
  const [clientDetails, setClientDetails] = useState<any>({});
  const { clientId } = useParams<{ clientId: string }>();

  useEffect(() => {
    loadClientDetails();
  });

  const loadClientDetails = async () => {
    const data = await API.admin.loadClient(clientId);
    setClientDetails(data);
  };

  return (
    <PageSkeleton
      isAdmin
      useContentCard
      goBackBtn
      filterBar={
        <ClientDetailsFilterBar
          clientName={clientDetails?.name}
          pageTitle="Dados de cadastro"
        />
      }
      button2={{
        label: "Voltar ao resumo da conta",
        onClick: history.goBack,
      }}
    >
      <div
        className={
          "flex flex-col w-full items-center " + StylePatterns.ELEMENT_WIDTH
        }
      >
        <h2
          className="text-xl font-normal flex justify-center items-center mt-5 gap-2"
          style={{ color: Colors.Gray3 }}
        >
          DADOS LEGAIS DA CONTA
        </h2>

        {clientDetails?.pep && (
          <FloatInputCore
            big
            disabled
            children={(ref) => (
              <label className="p-2">
                Pessoa Física <span style={{ color: Colors.Red6 }}>(PEP)</span>
              </label>
            )}
            placeholder=""
            color="bg-white"
            className="account-form text-lg font-normal w-full account-form-input"
          />
        )}
        <FloatInput
          big
          disabled
          defaultValue={clientDetails?.document}
          color="bg-white"
          className="account-form text-lg font-medium w-full account-form-input"
          placeholder="Documento"
          type="text"
        />
        <FloatInput
          big
          disabled
          defaultValue={clientDetails?.name}
          color="bg-white"
          className="account-form text-lg font-medium w-full account-form-input"
          placeholder="Nome do titular"
          type="text"
        />
        <FloatInput
          big
          disabled
          defaultValue={clientDetails?.motherName}
          color="bg-white"
          className="account-form text-lg font-medium w-full account-form-input"
          placeholder="Nome da mãe"
          type="text"
        />
        <FloatInput
          big
          disabled
          defaultValue={
            clientDetails?.birthday
              ? dayjs(clientDetails.birthday).tz().format("DD/MM/YYYY")
              : ""
          }
          color="bg-white"
          className="account-form text-lg font-medium w-full account-form-input"
          placeholder="Data de nascimento"
          type="text"
        />

        <h2
          className="text-xl font-normal flex justify-center items-center mt-5 gap-2"
          style={{ color: Colors.Gray3 }}
        >
          TELEFONE CELULAR
        </h2>
        <FloatInput
          big
          disabled
          defaultValue={clientDetails?.phoneNumber}
          color="bg-white"
          className="account-form text-lg font-medium w-full account-form-input"
          placeholder="DDD + Telefone"
          type="text"
        />

        <h2
          className="text-xl font-normal flex justify-center items-center mt-5 gap-2"
          style={{ color: Colors.Gray3 }}
        >
          E-MAIL DE CADASTRO
        </h2>
        <FloatInput
          big
          disabled
          defaultValue={clientDetails?.email}
          color="bg-white"
          className="account-form text-lg font-medium w-full account-form-input"
          placeholder="E-mail"
          type="text"
        />
        {/* <FloatInput big disabled defaultValue={clientDetails?.} color="bg-white" className="account-form text-lg font-medium w-full account-form-input" placeholder="Senha de acesso" type="text" /> */}
      </div>
    </PageSkeleton>
  );
};

export default ClientDataPage;
