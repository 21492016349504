import moment from "moment";
import React, { CSSProperties, useContext } from "react";
import Chart from "react-apexcharts";
import Colors, { CorPrincipal2 } from "../../../assets/Colors";
import { MoreIcon } from "../../../assets/icons";
import { GoldToken, Token } from "../../../models";
import {
  TokenStatusEnum,
  TokenTypeEnumInterpreter,
  UserRoleEnum,
} from "../../../models/enums";
import { formatToCurrency, getResidualDate } from "../../../utils/formatters";
import Button from "../../Buttons/Button";
import * as Icons from "../../../assets/icons";
import "./AssetCard.css";
import { convertCompilerOptionsFromJson } from "typescript";
import UserContext from "../../../contexts/UserProvider";

type MyProps = {
  token: any;
  onClick?: () => any;
};

const AssetCard: React.FC<MyProps> = ({ token, onClick }) => {
  const { user } = useContext(UserContext);
  var { id, amount, status, settlementDate } = token;

  var text1: CSSProperties = {};
  var text2: CSSProperties = {};
  var textStatus: CSSProperties = {};

  text1.color = Colors.Gray3;
  text2.color = Colors.Gray2;
  textStatus.color = Token.getStatusColor(status);

  return (
    <div
      className="border-05 rounded flex flex-col justify-between pl-0 mb-4 bg-white"
      style={{ borderColor: Colors.Gray4 }}
    >
      <div className="flex flex-row justify-between my-5 items-center px-2">
        <p
          className="asset-card-title justify-center"
          style={{ color: Colors.Gray2 }}
        >
          <span className="font-medium px-2" style={{ fontSize: "22px" }}>
            {token?.metadata.symbol}
          </span>
          <span className="font-normal"> - {token?.metadata.name}</span>
        </p>
      </div>
      <hr className="mb-0" style={{ borderColor: Colors.Gray5 }} />

      <div
        className={`flex flex-col gap-2 sm:flex-row sm:gap-0 ${
          onClick ? "cursor-pointer" : ""
        }`}
        onClick={onClick}
      >
        <div className="flex flex-col w-2/4 md:w-auto justify-center items-center self-center mt-2 sm:m-6 sm:ml-4">
          <img style={{ width: "140px" }} src={token?.metadata.image} alt="" />
        </div>
        <div className="flex flex-col flex-grow justify-center sm:my-4 p-2 px-4 sm:p-0">
          <p
            className="mt-2 mb-3 font-light text-sm flex-grow"
            style={{ color: Colors.Gray2 }}
          >
            LOTE #{token?.id}
          </p>

          <p style={{ color: Colors.Gray2 }} className="font-normal text-base">
            Total em wallet:{" "}
            {`${formatToCurrency(token.available + token.consumed)} ${
              token?.metadata.properties.unit
            }`}
          </p>
          <p className="font-light text-sm" style={{ color: Colors.Gray4 }}>
            Disponível:{" "}
            {`${token?.available.toFixed(2)} ${
              token?.metadata.properties.unit
            }`}
          </p>
          <p className="font-light text-sm" style={{ color: Colors.Gray4 }}>
            À venda:{" "}
            {`${token?.consumed.toFixed(2)} ${token?.metadata.properties.unit}`}
          </p>
          <p className="font-light text-sm" style={{ color: Colors.Gray4 }}>
            {/* Lock-up: {`${token?.available.toFixed(2)} ${token?.metadata.properties.unit}`} */}
            Lock-up:{" "}
            {`${Number(0).toFixed(2)} ${token?.metadata.properties.unit}`}
          </p>
          <p
            className="font-light text-sm mt-4"
            style={{ color: Colors.Gray3 }}
          >
            Carência:{" "}
            {moment(token?.metadata.redemptionDate).format("DD/MM/YYYY")}
          </p>
        </div>
      </div>
    </div>
  );
};

export const GoldAssetCard: React.FC<{
  token: GoldToken;
  onClick: () => any;
  onClick2: () => any;
}> = ({ token, onClick, onClick2 }) => {
  const { user } = useContext(UserContext);

  var text1: CSSProperties = {};
  var text2: CSSProperties = {};
  var textStatus: CSSProperties = {};

  text1.color = Colors.Gray3;
  text2.color = Colors.Gray2;
  textStatus.color = Token.getStatusColor(token.status);

  const defaultUnit = TokenTypeEnumInterpreter.convert(token.type).unity;

  function getPercentage(value: number, total: number): number {
    return ((value ?? 0) * 100) / total;
  }

  return (
    <div className={`border rounded flex flex-row p-2 mb-4 bg-white`}>
      <div className="w-40 flex flex-row justify-center items-center mr-2">
        {token.amount && token.amount > 0 ? (
          <Chart
            type={"pie"}
            height="100%"
            options={{
              chart: {
                type: "pie",
                width: "100%",
                // yaxis: {
                //   show: false,
                //   tickAmount: 3,
                // },
              },
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false,
              },
              labels: ["Disponível", "Em negociação", "Resgatados"],
              colors: [Colors.Green4, Colors.Blue1, Colors.Gray3],
            }}
            series={[
              getPercentage(token.available, token.amount),
              getPercentage(token.amount - token.available, token.amount),
              getPercentage(0, token.amount),
            ]}
          />
        ) : (
          <img src={Icons.AUChartCardIcon} alt="" />
        )}
      </div>
      <div className="flex flex-col gap-1 text-base">
        <p
          style={{ color: Colors.Gray2 }}
          className="font-light"
        >{`LOTE #${token.id}`}</p>
        <p
          style={{ color: Colors.Gray2 }}
          className="font-normal text-base mb-1"
        >
          <span>Total em wallet: </span>
          <span className="font-medium">{token.amount}</span>
          <span className="font-light"> unid</span>
        </p>
        <div
          style={{ color: Colors.Green3 }}
          className="font-normal text-sm flex flex-row whitespace-pre"
        >
          <p>Disponível: {token.available} unid</p>
        </div>
        <div
          style={{ color: Colors.Blue1 }}
          className="font-normal text-sm flex flex-row whitespace-pre"
        >
          {/* <p>À venda: {token.negociation} unid</p> */}
          <p>À venda: {token.amount - token.available} unid</p>
        </div>
        <div
          style={{ color: Colors.Purple2 }}
          className="font-normal text-sm flex flex-row whitespace-pre"
        >
          <p>Retidos: {0} unid</p>
        </div>
        <div
          style={{ color: Colors.Orange2 }}
          className="font-normal text-sm flex flex-row whitespace-pre"
        >
          <p>Queimados: {0} unid</p>
        </div>
        <p style={{ color: Colors.Gray4 }} className="font-light">
          Emissão:{" "}
          <span className="font-normal">
            {moment(token.emissionDate).format("DD/MM/YYYY")}
          </span>
        </p>
        {/* <p style={text1} className='font-light'>
                    Quantidade total: <span style={text2} className='font-medium'>{`${token.amount.toFixed(0)}`}</span>
                </p>
                <p style={{ ...text1, color: Colors.Green3 }} className='font-normal'>
                    Disponível: <span style={text2} className='font-normal'>{`${token.available.toFixed(0)}`}</span>
                </p>
                <p style={{ ...text1, color: Colors.Blue1 }} className='font-normal'>
                    Em negociação: <span style={text2} className='font-normal'>{`${(token.amount - token.available).toFixed(0)}`}</span>
                </p>
                <p style={text1} className='font-light'>
                    Resgatados: <span style={text2} className='font-normal'>{`0`}</span>
                </p> */}
      </div>
      <div className="flex flex-col flex-grow justify-between items-end">
        <button
          className={"flex flex-row justify-end"}
          onClick={() => onClick2()}
        >
          <img src={MoreIcon} alt="" />
        </button>
        <div className="flex flex-col justify-end">
          <Button
            className="asset-negotiate-btn"
            backgroundColor={
              user?.role === UserRoleEnum.ADMIN
                ? Colors.Gold4
                : Colors.UserBGButton
            }
            textColor={
              user?.role === UserRoleEnum.ADMIN ? Colors.White : Colors.Black1
            }
            onClick={onClick}
            disabled={token.available <= 0}
          >
            Negociar
          </Button>
        </div>
      </div>
    </div>
  );
};

AssetCard.defaultProps = {};

export default AssetCard;
